import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerRafStatsAction } from './get-player-raf-stats.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const APPROVE_DENY_REFERRAL = `APPROVE_DENY_REFERRAL${suffix}`;

export const approveDenyReferralAction = createAction<any>(APPROVE_DENY_REFERRAL);

const APPROVE_DENY_REFERRAL_SUCCESS = `APPROVE_DENY_REFERRAL_SUCCESS${suffix}`;
const approveDenyReferralSuccessAction = createAction<IPlayerInfo[]>(APPROVE_DENY_REFERRAL_SUCCESS);

const APPROVE_DENY_REFERRAL_ERROR = `APPROVE_DENY_REFERRAL_ERROR${suffix}`;
const approveDenyReferralErrorAction = createAction<string>(APPROVE_DENY_REFERRAL_ERROR);

function* approveDenyReferralWatcher(): SagaIterator {
  yield takeEvery(APPROVE_DENY_REFERRAL, approveDenyReferralWorker);
}
mergeSaga(approveDenyReferralWatcher);

function* approveDenyReferralWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(approveDenyReferralApi, action.payload);
    yield put(approveDenyReferralSuccessAction(result.data));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerRafStatsAction(action.payload.player_number));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(approveDenyReferralErrorAction(e.response.data.message));
  }
}

const approveDenyReferralApi = (x: any): Request => {
  return vipApi(`/cashier/approvedenyReferral/${x.id}`, 'post', {
    status: x.status,
  });
};

const reduceHandlers = {
  [APPROVE_DENY_REFERRAL]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [APPROVE_DENY_REFERRAL_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [APPROVE_DENY_REFERRAL_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
