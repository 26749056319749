import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BonusHistoryCard } from './player-transactions-cards/bonus-history-card';
import { ReferralsHistoryCard } from './player-transactions-cards/referrals-history-card';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentIcon from '@mui/icons-material/Payment';
import { PendingWagersCard } from './player-transactions-cards/pending-wagers-card';
import { WagerHistoryCard } from './player-transactions-cards/wager-history-card';
import { WithdrawalHistoryCard } from './player-transactions-cards/withdrawal-history-card';
import { DepositHistoryCard } from './player-transactions-cards/deposit-history-card';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export interface ExternalActionProps {
  disableRolloverAction: (string: string) => void;
  removeBonusAmountAction: (string: string) => void;
  getPlayerDepositsAction: (x: any) => void;
  getPlayerWithdrawalsAction: (x: any) => void;
  getPlayerPendingWagersAction: (x: any) => void;
  getPlayerWagerHistoryAction: (x: any) => void;
  getPlayerBonusHistoryAction: (x: any) => void;
  getPlayerRafStatsAction: (x: any) => void;
  approveDenyReferralAction: (x: any) => void;
}

export interface ExternalProps {
  playerInfo: any;
  withdrawals: any;
  pendingWagers: any;
  wagerHistory: any;
  bonusHistory: any;
  deposits: any;
  rafHistory: any;
  isLoading: any;
}

export default function AccountTransactionsTab(props: ExternalProps & ExternalActionProps) {
  const theme = useTheme();
  const {
    isLoading,
    playerInfo,
    deposits,
    withdrawals,
    pendingWagers,
    wagerHistory,
    bonusHistory,
    rafHistory,
    disableRolloverAction,
    removeBonusAmountAction,
    getPlayerDepositsAction,
    getPlayerWithdrawalsAction,
    getPlayerPendingWagersAction,
    getPlayerWagerHistoryAction,
    getPlayerBonusHistoryAction,
    getPlayerRafStatsAction,
    approveDenyReferralAction,
  } = props;

  const [value, setValue] = React.useState(3);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    getTabData(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  function getMediaScrollable(): boolean {
    return window.innerWidth >= 1025;
  }

  const getScrollableBar = () => {
    if (getMediaScrollable()) {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  };

  const getTabData = (value: any) => {
    if (value == 0) {
      getPlayerDepositsAction(playerInfo.personal.player_number);
    }
    if (value == 1) {
      getPlayerWithdrawalsAction(playerInfo.personal.player_number);
    }
    if (value == 2) {
      getPlayerPendingWagersAction(playerInfo.personal.player_number);
    }
    if (value == 3) {
      getPlayerWagerHistoryAction({
        username: playerInfo.personal.player_number,
        weeks: 0,
      });
    }
    if (value == 4) {
      getPlayerBonusHistoryAction(playerInfo.personal.player_number);
    }
    if (value == 5) {
      getPlayerRafStatsAction(playerInfo.personal.player_number);
    }
  };

  React.useEffect(() => {
    getTabData(value);
  }, [playerInfo.personal.player_number]);

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%', boxShadow: 2 }}>
      <AppBar position='static' color='primary'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant={getScrollableBar()}
          aria-label='full width tabs example'
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          <Tab label='Deposits' icon={<PaymentIcon />} {...a11yProps(0)} />
          <Tab label='Withdrawals' icon={<AccountBalanceWalletIcon />} {...a11yProps(1)} />
          <Tab label='Pending Wagers' icon={<ReceiptIcon />} {...a11yProps(2)} />
          <Tab label='Wager History' icon={<ReceiptLongIcon />} {...a11yProps(3)} />
          <Tab label='Bonus History' icon={<RedeemIcon />} {...a11yProps(4)} />
          <Tab label='Referrals' icon={<GroupIcon />} {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <DepositHistoryCard isLoading={isLoading} deposits={deposits} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <WithdrawalHistoryCard withdrawals={withdrawals} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <PendingWagersCard wagers={pendingWagers} isLoading={isLoading} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <WagerHistoryCard
            isLoading={isLoading}
            wagers={wagerHistory}
            getPlayerWagerHistoryAction={getPlayerWagerHistoryAction}
            playerInfo={playerInfo}
          />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <BonusHistoryCard
            isLoading={isLoading}
            playerInfo={playerInfo}
            disableRolloverAction={disableRolloverAction}
            removeBonusAmountAction={removeBonusAmountAction}
            bonusHistory={bonusHistory}
          />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <ReferralsHistoryCard
            rafHistory={rafHistory}
            isLoading={isLoading}
            approveDenyReferralAction={approveDenyReferralAction}
            playerInfo={playerInfo}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
