import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IdentityCard } from './player-settings-cards/identity-card';
import { ThirdPartyRights } from './player-settings-cards/third-party-rights';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserSettingsCard } from './player-settings-cards/player-settings-card';
import ErrorIcon from '@mui/icons-material/Error';
import { PlayerNotes } from './player-settings-cards/player-notes';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { ManualTransactionCard } from './player-settings-cards/manual-transaction-card';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export interface ExternalActionProps {
  setCognitoStatus: (x: any) => void;
  setCognitoDataDialogOpen: (x: boolean) => void;
  setCognitoStatusDialogOpen: (x: boolean) => void;
  updateTpiRightsAction: (x: any) => void;
  updatePlayerAction: (x: any) => void;
  getPlayerNotesAction: (x: any) => void;
  addPlayerNoteAction: (x: any) => void;
  manualTransactionAction: (x: any) => void;
}

export interface ExternalProps {
  verified: any;
  rights: any;
  userId: any;
  personal: any;
  playerNotes: any;
  isLoading: any;
}

export default function AccountSettingsTabs(props: ExternalActionProps & ExternalProps) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const {
    verified,
    rights,
    userId,
    personal,
    playerNotes,
    isLoading,
    setCognitoStatus,
    setCognitoDataDialogOpen,
    setCognitoStatusDialogOpen,
    updateTpiRightsAction,
    updatePlayerAction,
    getPlayerNotesAction,
    addPlayerNoteAction,
    manualTransactionAction,
  } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  function getMediaScrollable(): boolean {
    return window.innerWidth >= 1025;
  }

  const getScrollableBar = () => {
    if (getMediaScrollable()) {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  };

  const getSettingsData = (value: any) => {
    if (value == 0) {
      getPlayerNotesAction({
        player_number: personal.player_number,
      });
    }
  };

  React.useEffect(() => {
    getSettingsData(value);
  }, [personal.player_number]);

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%', boxShadow: 2 }}>
      <AppBar position='static' sx={{ bgcolor: 'primary.main' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant={getScrollableBar()}
          scrollButtons
          aria-label='full width tabs example'
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          <Tab label='Notes' icon={<ErrorIcon />} {...a11yProps(0)} />{' '}
          <Tab label='Manual' icon={<MonetizationOnIcon />} {...a11yProps(1)} />
          <Tab label='Identity' icon={<VisibilityIcon />} {...a11yProps(2)} />
          <Tab label='Third Party Rights' icon={<AppsIcon />} {...a11yProps(3)} />
          <Tab label='Settings' icon={<SettingsIcon />} {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PlayerNotes notes={playerNotes} addPlayerNoteAction={addPlayerNoteAction} personal={personal} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ManualTransactionCard isLoading={isLoading} userId={userId} manualTransactionAction={manualTransactionAction} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <IdentityCard
            setCognitoStatus={setCognitoStatus}
            setCognitoDataDialogOpen={setCognitoDataDialogOpen}
            setCognitoStatusDialogOpen={setCognitoStatusDialogOpen}
            verified={verified}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ThirdPartyRights rights={rights} updateTpiRightsAction={updateTpiRightsAction} userId={userId} />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <UserSettingsCard userId={userId} updatePlayerAction={updatePlayerAction} editedTimes={personal.is_edited} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
