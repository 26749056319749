import { createSelector } from 'reselect';
import { chartAccountAdminReducer, ChartAccountAdminSliceType } from './chart-account.reducer';

export const chartAccountAdminSliceSelector = (state: any): any => state[chartAccountAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  chartAccountAdminSliceSelector,
  (chartAccountAdminSlice: ChartAccountAdminSliceType) => chartAccountAdminSlice.isLoading,
);

export const chartAccountsSelector = createSelector(
  chartAccountAdminSliceSelector,
  (chartAccountAdminSlice: ChartAccountAdminSliceType) => chartAccountAdminSlice.chartAccounts,
);
