import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { getHistory } from '../../utils/history.util';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Divider, Typography } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ViewListIcon from '@mui/icons-material/ViewList';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import AgentIcon from '@mui/icons-material/People';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaidIcon from '@mui/icons-material/Paid';

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  userIconDown: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    width: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#121212', //121212
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  removeRightBorder: {
    borderRight: 'none',
  },
  navBody: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  listItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  white: {
    color: 'white',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  shrinkLogo: {
    maxHeight: '45px',
    maxWidth: '45px',
    marginRight: '2rem',
  },
  whiteYup: {
    color: 'white',
    minWidth: 40,
  },
  categoryTitle: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    marginLeft: theme.spacing(2),
    fontWeight: 'bolder',
    opacity: '.60',
    fontSize: '.75rem',
  },
}));

interface LeftNavProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  user: any;
}

export const LeftNav = ({ open, setOpen, user }: LeftNavProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const getListItem = (route: IRoute) => {
    return (
      <React.Fragment key={route.name.split(' ').join('_')}>
        {route.name !== RoutesEnum.Cashier && (
          <ListItem
            key={route.name}
            classes={{
              gutters: classes.listItemGutters,
            }}
            onClick={() => getHistory().push(route.path)}
            button
          >
            <ListItemIcon>
              <IconButton classes={{ root: classes.white }} size='large'>
                {getIcon(route.name)}
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        )}
      </React.Fragment>
    );
  };

  const getIcon = (x: RoutesEnum) => {
    switch (x) {
      case RoutesEnum.Dashboard:
        return <DashboardIcon />;
      case RoutesEnum.LandingPageAdmin:
        return <QuestionAnswerIcon />;
      case RoutesEnum.Cashier:
        return <MonetizationOnIcon />;
      case RoutesEnum.Transactions:
        return <ReceiptIcon />;
      case RoutesEnum.ChartOfAccounts:
        return <AccountTreeIcon />;
      case RoutesEnum.PaymentMethods:
        return <AccountBalanceWalletIcon />;
      case RoutesEnum.Banks:
        return <AccountBalanceIcon />;
      case RoutesEnum.BitcoinAddresses:
        return <ViewListIcon />;
      case RoutesEnum.PlayerInfo:
        return <PersonIcon />;
      case RoutesEnum.TourCreator:
        return <MarkChatUnreadIcon />;
      case RoutesEnum.Guides:
        return <MenuBookIcon />;
      case RoutesEnum.Rules:
        return <HelpOutlineIcon />;
      case RoutesEnum.AgentManagement:
        return <AgentIcon />;
      case RoutesEnum.Reports:
        return <BarChartIcon />;
      case RoutesEnum.Bonuses:
        return <ConfirmationNumberIcon />;
      case RoutesEnum.BonusProfiles:
        return <SupervisedUserCircleIcon />;
      case RoutesEnum.Referrals:
        return <GroupAddIcon />;
      case RoutesEnum.AgentCommStructures:
        return <AccountTreeIcon />;
      case RoutesEnum.AgentPayouts:
        return <PaidIcon />;
      default:
        return null;
    }
  };

  const routes = [
    RoutesDictionary[RoutesEnum.Dashboard],
    // RoutesDictionary[RoutesEnum.Cashier],
  ];

  const cashierRoutes = [
    RoutesDictionary[RoutesEnum.Transactions],
    RoutesDictionary[RoutesEnum.PaymentMethods],
    RoutesDictionary[RoutesEnum.Banks],
    RoutesDictionary[RoutesEnum.BitcoinAddresses],
    // RoutesDictionary[RoutesEnum.Reports],
  ];

  const playerRoutes = [
    RoutesDictionary[RoutesEnum.PlayerInfo],
    RoutesDictionary[RoutesEnum.Referrals],
    RoutesDictionary[RoutesEnum.TourCreator],
    RoutesDictionary[RoutesEnum.Guides],
    RoutesDictionary[RoutesEnum.Rules],
  ];

  const agentRoutes = [
    RoutesDictionary[RoutesEnum.AgentManagement],
    RoutesDictionary[RoutesEnum.AgentCommStructures],
    RoutesDictionary[RoutesEnum.AgentPayouts],
  ];

  const bonusRoutes = [RoutesDictionary[RoutesEnum.BonusProfiles], RoutesDictionary[RoutesEnum.Bonuses]];

  const accountingRoutes = [RoutesDictionary[RoutesEnum.Reports], RoutesDictionary[RoutesEnum.ChartOfAccounts]];

  const lineRulesRoutes = [RoutesDictionary[RoutesEnum.LineRules]];

  // const marketingRoutes = [RoutesDictionary[RoutesEnum.LandingPageAdmin]];

  return (
    <div className={classes.root}>
      <Drawer
        variant='permanent'
        open={open}
        onClose={() => setOpen(false)}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
          paperAnchorDockedLeft: classes.removeRightBorder,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        elevation={8}
      >
        <div className={classes.toolbar}>
          <div>
            {/* <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} /> */}
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/images/misc/vip-christmas.png`} />
          </div>
          <IconButton classes={{ root: classes.white }} onClick={() => setOpen(false)} size='large'>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <div className={classes.navBody}>
          <List>
            {/* Dashboard Nav List */}
            {routes.map((route: IRoute) => getListItem(route))}

            {user.role == 'admin' && (
              <>
                <Divider variant='middle' />
                {/* Cashier  List */}

                <Typography variant='subtitle2' className={classes.categoryTitle}>
                  CASHIER
                </Typography>
                {cashierRoutes.map((route: IRoute) => getListItem(route))}

                {/* Player */}
                <Divider variant='middle' />
                <Typography variant='subtitle2' className={classes.categoryTitle}>
                  PLAYER
                </Typography>
                {playerRoutes.map((route: IRoute) => getListItem(route))}
              </>
            )}

            {/* Agent Management */}
            <Divider variant='middle' />
            <Typography variant='subtitle2' className={classes.categoryTitle}>
              AGENT
            </Typography>
            {agentRoutes.map((route: IRoute) => getListItem(route))}

            {user.role == 'admin' && (
              <>
                {/* Bonuses */}
                <Divider variant='middle' />
                <Typography variant='subtitle2' className={classes.categoryTitle}>
                  BONUSES
                </Typography>
                {bonusRoutes.map((route: IRoute) => getListItem(route))}

                {/* Bonuses */}
                <Divider variant='middle' />
                <Typography variant='subtitle2' className={classes.categoryTitle}>
                  ACCOUNTING
                </Typography>
                {accountingRoutes.map((route: IRoute) => getListItem(route))}

                {/* Bonuses */}
                <Divider variant='middle' />
                <Typography variant='subtitle2' className={classes.categoryTitle}>
                  Line Rules
                </Typography>
                {lineRulesRoutes.map((route: IRoute) => getListItem(route))}
              </>
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
