import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import WagerHistoryDetails from './wager-history-details';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getPlayerWagerHistoryAction: (x: any) => void;
}

export interface ExternalProps {
  isLoading: any;
  wagers: any;
  playerInfo: any;
}

export const WagerHistoryCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoading, wagers, playerInfo, getPlayerWagerHistoryAction } = props;
  const [weeksAgo, setWeeksAgo] = React.useState(0);
  const [wagerType, setWagerType] = React.useState(0);

  const handleWeekChange = (event: any) => {
    setWeeksAgo(event.target.value);
    getPlayerWagerHistoryAction({
      username: playerInfo.personal.player_number,
      weeks: event.target.value,
    });
  };

  return (
    <div className={clsx(classes.root)}>
      {!isLoading && wagers && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6' gutterBottom>
                Wager History ({wagers.StartDate + ' to ' + wagers.EndDate})
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ marginLeft: '.5rem', marginRight: '.5rem' }}>
                <InputLabel id='demo-simple-select-label'>Wager Type</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={wagerType}
                  label='Wager Type'
                  onChange={(event: any) => {
                    setWagerType(event.target.value);
                  }}
                >
                  <MenuItem value={0}>All History</MenuItem>
                  <MenuItem value={1}>Transactions</MenuItem>
                  <MenuItem value={2}>Sportsbook</MenuItem>
                  <MenuItem value={3}>Third Party Casinos</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ marginLeft: '.5rem', marginRight: '.5rem' }}>
                <InputLabel id='demo-simple-select-label'>Weeks Ago</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={weeksAgo}
                  label='Weeks Ago'
                  onChange={(event: any) => {
                    handleWeekChange(event);
                  }}
                >
                  <MenuItem value={0}>This Week</MenuItem>
                  <MenuItem value={1}>1 Week Ago</MenuItem>
                  <MenuItem value={2}>2 Weeks Ago</MenuItem>
                  <MenuItem value={3}>3 Weeks Ago</MenuItem>
                  <MenuItem value={4}>4 Weeks Ago</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <WagerHistoryDetails wagerHistory={wagers} isLoading={isLoading} wagerType={wagerType} />
        </>
      )}
    </div>
  );
};
