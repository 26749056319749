export interface IGuide {
  id?: number;
  name: string;
  category: number;
  content: string;
  staff: string;
  visible: number;
  deleted: number;
  created_at?: string;
  updated_at?: string;
}

export interface IGuideUpdate {
  id?: number;
  name: string;
  category: number;
  content: string;
  staff: string;
  visible: number;
  deleted: number;
  created_at?: string;
  updated_at?: string;
}

export const emptyGuide: IGuideUpdate = {
  name: '',
  category: 0,
  content: '',
  staff: '',
  visible: 0,
  deleted: 0,
};
