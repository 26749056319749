import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { AccountingSliceType, accountingReducer } from '../accounting.reducer';

const suffix = `/${accountingReducer.sliceName}/app`;

const GET_REPORTING_OVERVIEW = `GET_REPORTING_OVERVIEW${suffix}`;
export const getReportingOverviewAction = createAction<any>(GET_REPORTING_OVERVIEW);

const GET_REPORTING_OVERVIEW_ERROR = `GET_REPORTING_OVERVIEW_ERROR${suffix}`;
const getReportingOverviewErrorAction = createAction<any>(GET_REPORTING_OVERVIEW_ERROR);
const GET_REPORTING_OVERVIEW_SUCCESS = `GET_REPORTING_OVERVIEW_SUCCESS${suffix}`;
const getReportingOverviewSuccessAction = createAction<any>(GET_REPORTING_OVERVIEW_SUCCESS);

function* getReportingOverviewWatcher(): SagaIterator {
  yield takeEvery(GET_REPORTING_OVERVIEW, getReportingOverviewWorker);
}
mergeSaga(getReportingOverviewWatcher);

function* getReportingOverviewWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(getReportingOverviewSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getReportingOverviewErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/cashier/accounting/overview`, 'post', {
    start_date: x.start_date,
    end_date: x.end_date,
  });
};

const reduceHandlers = {
  [GET_REPORTING_OVERVIEW]: (slice: AccountingSliceType): AccountingSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_REPORTING_OVERVIEW_SUCCESS]: (slice: AccountingSliceType, action: any): AccountingSliceType => ({
    ...slice,
    isLoading: false,
    overview: action.payload.data.overview,
    deposits: action.payload.data.deposits,
    withdrawals: action.payload.data.withdrawals,
    total: action.payload.data.total,
  }),
  [GET_REPORTING_OVERVIEW_ERROR]: (slice: AccountingSliceType): AccountingSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

accountingReducer.addActionReducerMap(reduceHandlers);
