import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';

export const DisableRolloverDialog = (props: any) => {
  const { open, onClose, playerNumber, disableRolloverAction, id } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Alert severity='error'>
            Are you sure you want to disable <b>{playerNumber}&apos;s</b> rollover?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              disableRolloverAction({ playerNumber: playerNumber, id: id });
              onClose();
            }}
          >
            Disable Rollover
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
