import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { ILandingPage } from '../../../models/landing-page';
import { landingPageAdminReducer, LandingPageAdminSliceType } from '../landing-page.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPDATE_ACTIVE_LANDING_PAGE_SUCCESS } from './update-active-landing-page.action';
import { UPSERT_LANDING_PAGE_SUCCESS } from './upsert-landing-page.action';
import { DELETE_LANDING_PAGE_SUCCESS } from './delete-landing-page.action';

const suffix = `/${landingPageAdminReducer.sliceName}/app`;

const GET_LANDING_PAGES = `GET_LANDING_PAGES${suffix}`;

export const getLandingPagesAction = createAction(GET_LANDING_PAGES);

const GET_LANDING_PAGES_SUCCESS = `GET_LANDING_PAGES_SUCCESS${suffix}`;
const getLandingPagesSuccessAction = createAction<ILandingPage[]>(GET_LANDING_PAGES_SUCCESS);

const GET_LANDING_PAGES_ERROR = `GET_LANDING_PAGES_ERROR${suffix}`;
const getLandingPagesErrorAction = createAction<string>(GET_LANDING_PAGES_ERROR);

function* getLandingPagesWatcher(): SagaIterator {
  yield takeEvery(
    [GET_LANDING_PAGES, UPDATE_ACTIVE_LANDING_PAGE_SUCCESS, UPSERT_LANDING_PAGE_SUCCESS, DELETE_LANDING_PAGE_SUCCESS],
    getLandingPagesWorker,
  );
}
mergeSaga(getLandingPagesWatcher);

function* getLandingPagesWorker(): SagaIterator {
  try {
    const result: IGetAll<ILandingPage> = yield call(getLandingPagesApi);
    if (result.status === 'Success') {
      yield put(getLandingPagesSuccessAction(result.data));
    } else {
      yield put(getLandingPagesErrorAction(result.message));
    }
  } catch (e: any) {
    yield put(getLandingPagesErrorAction(e));
  }
}

const getLandingPagesApi = (): Request => {
  return vipApi(`/LandingPage/GetAllPages`, 'post', {});
};

const reduceHandlers = {
  [GET_LANDING_PAGES]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_LANDING_PAGES_SUCCESS]: (slice: LandingPageAdminSliceType, action: any): LandingPageAdminSliceType => ({
    ...slice,
    isLoading: false,
    landingPages: action.payload,
  }),
  [GET_LANDING_PAGES_ERROR]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isLoading: false,
    landingPages: [],
  }),
};

landingPageAdminReducer.addActionReducerMap(reduceHandlers);
