export interface Transactions {
  id: number;
  account: string;
  address: string;
  amount: number;
  amount_received: number;
  bank: string;
  bank_id: number;
  bonus: string;
  created_at: string;
  created_by: string;
  exchangeRate: string;
  fee: number;
  fingerprint: string | null;
  ip: string | null;
  method: string;
  notes: string | null;
  player_number: string;
  player_email: string;
  player_first_name: string;
  player_last_name: string;
  player_phone: string;
  site: string;
  status: number;
  totalToSend: number;
  totalToSendBtc: any;
  type: string;
  updated_at: string;
  updated_by: string;
  invoice: string;
  actions?: Array<any>;
  parent_amount: any | null;
}

export interface ITransactionUpdate {
  id?: number;
  status?: number;
  notes?: string;
  staff: string;
  bank?: any;
  account?: string;
  amount?: any;
  exchange_rate?: any;
}

export const emptyUpdate: ITransactionUpdate = {
  id: 0,
  status: 0,
  notes: '',
  staff: '',
  bank: '',
  account: '',
};

export interface ITransactionLoad {
  username: string;
  id: number;
  amount: any;
  updated_by: string;
}

export const emptyLoad: ITransactionLoad = {
  username: '',
  id: 0,
  amount: '',
  updated_by: '',
};

export interface ITransactionVerify {
  username: string;
  id: number;
  pin: string;
}

export const emptyVerify: ITransactionVerify = {
  username: '',
  id: 0,
  pin: '',
};

export interface ITransactionFilterData {
  type: number;
  page: number;
  status: any;
  count: number;
  search?: string;
}

export const emptyFilterData: ITransactionFilterData = {
  type: 1,
  page: 0,
  status: 1,
  count: 25,
  search: '',
};
