import { connect } from 'react-redux';
import { createBonusAction } from '../../../api/bonuses/actions/create-bonus.action';
import { getBonusesAction } from '../../../api/bonuses/actions/get-bonuses.action';
import { getBonusProfilesAction } from '../../../api/bonuses/actions/get-profiles.action';
import { updateBonusAction } from '../../../api/bonuses/actions/update-bonus.action';
import { bonusesSelector, isLoadingSliceSelector, profilesSelector } from '../../../api/bonuses/bonuses.selector';
import { BonusPage, ExternalActionProps, ExternalProps } from './bonuses-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    bonuses: bonusesSelector(state),
    profiles: profilesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getBonusesAction,
  createBonusAction,
  getBonusProfilesAction,
  updateBonusAction,
};

export const BonusesPageContainer = connect(mapStateToProps, mapDispatchToProps)(BonusPage);
