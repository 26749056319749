import globToRe from 'glob-to-regexp';
import login from './login.json';
import { getAppSettings } from '../../../utils/app-settings.util';
import { axiosResult } from '../../../utils/axios/axios-result';

export function mockApi(axiosMock: any): any {
  axiosMock.onPost(globToRe(`${getAppSettings().apiUrl}/login`)).reply(() => {
    return axiosResult(login);
  });
}
