import { Grid, Theme, Typography, TextField, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import OverviewTable from './components/reporting-overview-table';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DepositMethodTable from './components/deposit-method-table';
import WithdrawalMethodTable from './components/withdrawal-method-table';
import { Loading } from '../../../shared/loading/loading.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 140,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    container: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    inline: {
      display: 'inline-flex',
    },
    margin: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    padding: {
      padding: '.5rem',
    },
  }),
);

export interface ExternalProps {
  isLoading: boolean;
  overview: any;
  deposits: any;
  withdrawals: any;
  totals: any;
}

export interface ExternalActionProps {
  getReportingOverviewAction: (x: any) => void;
  getDetailedReportAction: (x: any) => void;
}

const getToday = () => {
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const dateObj = new Date();
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  // const output = month + '/' + day + '/' + year;
  const output = year + '-' + month + '-' + day;
  return output;
};

export function ReportsPage(props: ExternalActionProps & ExternalProps) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState('');
  const { isLoading, deposits, withdrawals, overview, totals, getReportingOverviewAction, getDetailedReportAction } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };

  const [startDate, setStartDate] = React.useState(getToday());
  const [endDate, setEndDate] = React.useState(getToday());

  useEffect(() => {
    getReportingOverviewAction({
      start_date: startDate,
      end_date: endDate,
    });
  }, []);

  return (
    <div className={classes.container}>
      <Loading loading={isLoading} />

      <Grid container>
        <Grid xs={12} sm={6} className={classes.inline}>
          <h1>Reporting Overview</h1>

          <FormControl className={classes.formControl}>
            <Select
              value={filter}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value='' disabled>
                Filter
              </MenuItem>
              <MenuItem value='This Week'>This Week</MenuItem>
              <MenuItem value='Last week'>Last Week</MenuItem>
              <MenuItem value='This Month'>This Month</MenuItem>
              <MenuItem value='This Year'>This Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ paddingTop: '1rem' }}>
          <TextField
            type='Date'
            value={startDate}
            onChange={(event: any) => {
              setStartDate(event.target.value);
            }}
          />
          <TextField
            type='Date'
            value={endDate}
            onChange={(event: any) => {
              setEndDate(event.target.value);
            }}
          />
          <Button
            variant='contained'
            color='primary'
            style={{ margin: 5 }}
            onClick={() => {
              getReportingOverviewAction({
                start_date: startDate,
                end_date: endDate,
              });
            }}
          >
            Select Date
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12}>
          <OverviewTable
            data={overview}
            totals={totals}
            getDetailedReportAction={getDetailedReportAction}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin}>
        <Grid xs={12} sm={6} className={classes.padding}>
          <Typography variant='h5' gutterBottom>
            Deposits
          </Typography>

          <DepositMethodTable data={deposits} />
        </Grid>
        <Grid xs={12} sm={6} className={classes.padding}>
          <Typography variant='h5' gutterBottom>
            Withdrawals
          </Typography>

          <WithdrawalMethodTable data={withdrawals} />
        </Grid>
      </Grid>
    </div>
  );
}
