import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  root: {},
  textCenter: {
    textAlign: 'center',
  },
});

interface ExternalProps {
  data: any;
  gameRules: any[];
  setGameRules: (x: any) => void;
  addUpdateGameRule: (x: any) => void;
}

export const RowGameRules = (props: ExternalProps) => {
  const classes = useStyles();
  const { data, gameRules, setGameRules, addUpdateGameRule } = props;

  useEffect(() => {
    console.log('');
  }, []);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component='th' scope='row'>
          {data.description}
        </TableCell>
        <TableCell>{data.rotation}</TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.total}
            onChange={(event: any) => {
              data.total = Number(event.target.value);
              setGameRules([...gameRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.spread}
            onChange={(event: any) => {
              data.spread = Number(event.target.value);
              setGameRules([...gameRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.money_line}
            onChange={(event: any) => {
              data.money_line = Number(event.target.value);
              setGameRules([...gameRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <Checkbox
            checked={data.overwrite == 1}
            name='bonus'
            color='primary'
            onChange={() => {
              if (data.overwrite == 1) data.overwrite = 0;
              else data.overwrite = 1;
              setGameRules([...gameRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <Checkbox
            checked={data.status == 1}
            name='bonus'
            color='primary'
            onChange={() => {
              if (data.status == 1) data.status = 0;
              else data.status = 1;
              setGameRules([...gameRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={() => {
              data.deleted = 1;
              addUpdateGameRule(data);
            }}
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            aria-label='save'
            size='small'
            onClick={() => {
              addUpdateGameRule(data);
            }}
          >
            <SaveIcon fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
