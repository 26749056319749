import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { tourCreatorAdminReducer, TourCreatorAdminSliceType } from '../tourCreator.reducer';
import { vipApi } from '../../../utils/vip-api';
import { ITourCreator } from '../../../models/tour-creator';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${tourCreatorAdminReducer.sliceName}/app`;

const DELETE_STEPS = `DELETE_STEPS${suffix}`;

export const deleteStepsAction = createAction(DELETE_STEPS);

const DELETE_STEPS_SUCCESS = `DELETE_STEPS_SUCCESS${suffix}`;
const deleteStepsSuccessAction = createAction<ITourCreator[]>(DELETE_STEPS_SUCCESS);

const DELETE_STEPS_ERROR = `DELETE_STEPS_ERROR${suffix}`;
const deleteStepsErrorAction = createAction<string>(DELETE_STEPS_ERROR);

function* deleteStepsWatcher(): SagaIterator {
  yield takeEvery(DELETE_STEPS, deleteStepsWorker);
}
mergeSaga(deleteStepsWatcher);

function* deleteStepsWorker(action: any): SagaIterator {
  try {
    yield call(deleteStepsApi, action.payload.id);
    yield put(deleteStepsSuccessAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(deleteStepsErrorAction(e));
  }
}

const deleteStepsApi = (id: any): Request => {
  return vipApi(`/tours/DeleteTour/${id}`, 'delete', {});
};

const reduceHandlers = {
  [DELETE_STEPS]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [DELETE_STEPS_SUCCESS]: (slice: TourCreatorAdminSliceType, action: any): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: false,
    stepsList: slice.stepsList.filter(item => item.id != action.payload),
  }),
  [DELETE_STEPS_ERROR]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: false,
    stepsList: [],
  }),
};

tourCreatorAdminReducer.addActionReducerMap(reduceHandlers);
