import { createSelector } from 'reselect';
import { referralsReducer, ReferralsSliceType } from './referrals.reducer';

export const referralsSliceSelector = (state: any): any => state[referralsReducer.sliceName];

export const isLoadingSelector = createSelector(
  referralsSliceSelector,
  (referralsSlice: ReferralsSliceType) => referralsSlice.isLoading || false,
);

export const referralsSelector = createSelector(referralsSliceSelector, (referralsSlice: ReferralsSliceType) => referralsSlice.referrals);

export const topClaimedSelector = createSelector(referralsSliceSelector, (referralsSlice: ReferralsSliceType) => referralsSlice.topClaimed);

export const topSignedUpSelector = createSelector(
  referralsSliceSelector,
  (referralsSlice: ReferralsSliceType) => referralsSlice.topSignedUp,
);

export const referralsCountSelector = createSelector(
  referralsSliceSelector,
  (referralsSlice: ReferralsSliceType) => referralsSlice.referralsCount,
);
