import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { IAgentPayout } from '../../../../models/agent-payout';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
// import { Box } from '@mui/system';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  payouts: IAgentPayout[];
}

export const AgentPayoutsTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { payouts } = props;

  const parseNumber = (number: number) => {
    if (number < 0) {
      return <span style={{ color: 'red' }}>{'-$' + number.toString().replace('-', '')}</span>;
    }
    if (number > 0) {
      return <span style={{ color: 'green' }}>{'$' + number}</span>;
    }
    return '$' + number;
  };

  return (
    <div className={clsx(classes.root)}>
      <Paper>
        <Table stickyHeader>
          <TableHead sx={{ top: '60px', left: 'auto', right: '0px', position: 'sticky', backgroundColor: 'pink' }}>
            <TableRow>
              <TableCell>Agent</TableCell>
              <TableCell align='center'>Active Players</TableCell>
              <TableCell>W/L Details</TableCell>
              <TableCell>Comm. Figure</TableCell>
              <TableCell>Make Up Details</TableCell>
              <TableCell>Comm. Details</TableCell>
              <TableCell>Split?</TableCell>
              <TableCell>Payout</TableCell>
              <TableCell>Pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(payouts) && payouts.length == 0 && (
              <TableRow>
                <TableCell>
                  <Typography variant='h6'>No Payout Data</Typography>
                </TableCell>
              </TableRow>
            )}
            {payouts.length > 0 &&
              payouts.map((payout: IAgentPayout, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{payout.agent}</Typography>
                      <Typography variant='caption'>{payout.player_number}</Typography>
                    </TableCell>
                    <TableCell align='center'>{payout.active_players}</TableCell>
                    <TableCell>
                      <Typography>Win Loss: {parseNumber(payout.win_loss)}</Typography>
                      <Typography variant='caption'>Red Figure: {parseNumber(payout.red_figure)}</Typography>
                      <br />
                      <Typography variant='caption'>Green Figure: {parseNumber(payout.green_figure)}</Typography>
                    </TableCell>
                    <TableCell>{parseNumber(payout.commission_figure)}</TableCell>
                    <TableCell>
                      <Typography variant='caption'>Previous:{parseNumber(payout.previous_make_up)}</Typography>
                      <br />
                      <Typography variant='caption'>Additional: {parseNumber(payout.additional_make_up)}</Typography>
                      <br />
                      <Typography variant='caption'>New:{parseNumber(payout.new_make_up)}</Typography>
                      <br />
                      <Typography variant='caption'>Net Comm: {parseNumber(payout.net_commission_figure)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{payout.comm_description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Agent: {parseNumber(payout.split_agent)}</Typography>
                      <Typography>House: {parseNumber(payout.split_house)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Pay: {parseNumber(payout.payout)}</Typography>
                      <Typography variant='caption'>Red %: {payout.net_red_percentage + '%'}</Typography>
                    </TableCell>
                    <TableCell>
                      {payout.paid_at == null ? (
                        <Button variant='contained'>Mark Paid</Button>
                      ) : (
                        payout.paid_staff + ' paid at: ' + payout.paid_at
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
