import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  // container: {
  //   maxHeight: 300,
  //   overflowY: 'scroll',
  // },
  table: {},
  stickyFooter: {
    bottom: 0,
    position: 'fixed',
    backgroundColor: '#ffffff',
  },
});

export interface ExternalProps {
  data: any;
  totals: any;
  selectedStartDate: any;
  selectedEndDate: any;
}

export interface ExternalActionProps {
  getDetailedReportAction: (x: any) => void;
}

export default function OverviewTable(props: ExternalProps & ExternalActionProps) {
  const classes = useStyles();
  const { data, totals, selectedStartDate, selectedEndDate, getDetailedReportAction } = props;

  const [displayedDate, setDisplayedDate] = React.useState('');

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table' className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Deposits</TableCell>
                <TableCell>Withdrawals</TableCell>
                <TableCell>Net</TableCell>
                <TableCell>Win/Loss</TableCell>
                <TableCell>Sign Ups</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => {
                  return (
                    <TableRow key={row.date}>
                      <TableCell>
                        {row.date}
                        <br />
                        <small>{row.dayOfWeek}</small>
                      </TableCell>
                      <TableCell>
                        ${row.depositSum}
                        <br />
                        <small>
                          Count: {row.depositCount}
                          <br />
                          Avg: ${row.depositAvg}
                        </small>
                      </TableCell>
                      <TableCell>
                        ${row.withdrawalSum}
                        <br />
                        <small>
                          Count: {row.withdrawalCount}
                          <br />
                          Avg: ${row.withdrawalAvg}
                        </small>
                      </TableCell>
                      <TableCell>
                        <span style={row.netCashFlow.includes('-') ? { color: 'red' } : { color: 'green' }}>${row.netCashFlow}</span>
                      </TableCell>
                      <TableCell>
                        <span style={row.winloss.includes('-') ? { color: 'red' } : { color: 'green' }}>${row.winloss}</span>
                      </TableCell>
                      <TableCell>{row.signupCount}</TableCell>
                      <TableCell>
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => {
                            getDetailedReportAction({
                              start_date: row.date,
                              end_date: row.date,
                            });
                            setDisplayedDate(row.date);
                          }}
                        >
                          View Data
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {totals && (
              <TableHead>
                <TableCell>
                  <b>Total</b>
                  <br />
                  <small>{totals.date}</small>
                </TableCell>
                <TableCell>
                  ${totals.depositSum}
                  <br />
                  <small>
                    Count: {totals.depositCount}
                    <br />
                    Avg: ${totals.depositAvg}
                  </small>
                </TableCell>
                <TableCell>
                  ${totals.withdrawalSum}
                  <br />
                  <small>
                    Count: {totals.withdrawalCount}
                    <br />
                    Avg: ${totals.withdrawalAvg}
                  </small>
                </TableCell>
                <TableCell>
                  <span style={totals.netCashFlow && totals.netCashFlow.includes('-') ? { color: 'red' } : { color: 'green' }}>
                    ${totals.netCashFlow}
                  </span>
                </TableCell>
                <TableCell>
                  <span style={totals.winloss && totals.winloss.includes('-') ? { color: 'red' } : { color: 'green' }}>
                    ${totals.winloss}
                  </span>
                </TableCell>
                <TableCell>{totals.signupCount}</TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      getDetailedReportAction({
                        start_date: selectedStartDate,
                        end_date: selectedEndDate,
                      });
                      setDisplayedDate('Total');
                    }}
                  >
                    View Total Data
                  </Button>
                </TableCell>
              </TableHead>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      </Paper>
      <Typography variant='h5' style={{ marginTop: '2rem' }}>
        Detailed Report for {displayedDate == '' ? 'Total' : displayedDate}
      </Typography>
      <hr style={{ marginBottom: '.5rem' }} />
    </>
  );
}
