import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { tourCreatorAdminReducer, TourCreatorAdminSliceType } from '../tourCreator.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { ITourCreator } from '../../../models/tour-creator';

const suffix = `/${tourCreatorAdminReducer.sliceName}/app`;

const GET_STEPS = `GET_STEPS${suffix}`;

export const getStepsAction = createAction(GET_STEPS);

const GET_STEPS_SUCCESS = `GET_STEPS_SUCCESS${suffix}`;
const getStepsSuccessAction = createAction<ITourCreator[]>(GET_STEPS_SUCCESS);

const GET_STEPS_ERROR = `GET_STEPS_ERROR${suffix}`;
const getStepsErrorAction = createAction<string>(GET_STEPS_ERROR);

function* getStepsWatcher(): SagaIterator {
  yield takeEvery(GET_STEPS, getStepsWorker);
}
mergeSaga(getStepsWatcher);

function* getStepsWorker(): SagaIterator {
  try {
    const result: IGetAll<ITourCreator> = yield call(getStepsApi);
    yield put(getStepsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getStepsErrorAction(e));
  }
}

const getStepsApi = (): Request => {
  return vipApi(`/tours/GetAllTours`, 'get', {});
};

const reduceHandlers = {
  [GET_STEPS]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_STEPS_SUCCESS]: (slice: TourCreatorAdminSliceType, action: any): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: false,
    stepsList: action.payload,
  }),
  [GET_STEPS_ERROR]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: false,
    stepsList: [],
  }),
};

tourCreatorAdminReducer.addActionReducerMap(reduceHandlers);
