import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { TransactionLogsTable } from './transaction-logs-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function TransactionLogsDialoge(props: any) {
  const classes = useStyles();
  const { open, handleClose, selectedLogs } = props;

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close' size='large'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Action Logs
            </Typography>
            <Button autoFocus color='inherit' onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <TransactionLogsTable selectedLogs={selectedLogs} />
      </Dialog>
    </div>
  );
}
