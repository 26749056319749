export interface IGuideCategory {
  id?: number;
  name: string;
}

export interface IGuideCategoryUpdate {
  id?: number;
  name: string;
}

export const emptyGuideCategory: IGuideCategoryUpdate = {
  name: '',
};
