import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, CardHeader, Divider, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

export interface ExternalActionProps {
  updateAppSettingAction: (x: any) => void;
}

export interface ExternalProps {
  communications: any;
}
const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const CommunicationsCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { communications, updateAppSettingAction } = props;
  const [smsProvider, setSmsProvider] = React.useState('');

  React.useEffect(() => {
    console.log(communications);
    // setSmsProvider('ClickSend');
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader
          title='Communications'
          subheader='How we communicate to our players.'
          titleTypographyProps={{ fontSize: '1.2rem' }}
          subheaderTypographyProps={{ fontSize: '.8rem' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {communications &&
              communications.map((item: any) => {
                return (
                  <React.Fragment key={item.id}>
                    <Grid item xs={3}>
                      {capitalizeFirstLetter(item.module)}:
                    </Grid>
                    <Grid item xs={9}>
                      Currently: {item.setting}
                      <FormControl
                        sx={{
                          marginTop: '.5rem',
                          minWidth: 80,
                          width: '100%',
                        }}
                      >
                        <InputLabel id='demo-simple-select-autowidth-label'>Provider</InputLabel>
                        <Select
                          labelId='demo-simple-select-autowidth-label'
                          id='demo-simple-select-autowidth'
                          value={smsProvider}
                          onChange={(event: any) => {
                            setSmsProvider(event.target.value);
                            updateAppSettingAction({
                              id: item.id,
                              module: item.module,
                              setting: event.target.value,
                            });
                          }}
                          autoWidth
                          label='Provider'
                        >
                          <MenuItem value=''>
                            <em>Select a Provider</em>
                          </MenuItem>
                          <MenuItem value={'ClickSend'}>ClickSend</MenuItem>
                          <MenuItem value={'Twilio'}>Twilio</MenuItem>
                          <MenuItem value={'TextMagic'}>TextMagic</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                );
              })}
            <Grid item xs={3}>
              Email:
            </Grid>
            <Grid item xs={9}>
              MailChimp/Mandrill
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
