import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IBTCAddress } from '../../../models/btc-addresses';
import { btcAddressAdminReducer, BtcAddressAdminSliceType } from '../btc-address.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_BTC_ADDRESS_SUCCESS } from './upsert-btc-address.action';
import { DELETE_BTC_ADDRESS_SUCCESS } from './delete-btc-address.action';

const suffix = `/${btcAddressAdminReducer.sliceName}/app`;

const GET_BTC_ADDRESSES = `GET_BTC_ADDRESSES${suffix}`;

export const getBtcAddressesAction = createAction(GET_BTC_ADDRESSES);

const GET_BTC_ADDRESSES_SUCCESS = `GET_BTC_ADDRESSES_SUCCESS${suffix}`;
const getBtcAddressesSuccessAction = createAction<IBTCAddress[]>(GET_BTC_ADDRESSES_SUCCESS);

const GET_BTC_ADDRESSES_ERROR = `GET_BTC_ADDRESSES_ERROR${suffix}`;
const getBtcAddressesErrorAction = createAction<string>(GET_BTC_ADDRESSES_ERROR);

function* getBtcAddressesWatcher(): SagaIterator {
  yield takeEvery([GET_BTC_ADDRESSES, UPSERT_BTC_ADDRESS_SUCCESS, DELETE_BTC_ADDRESS_SUCCESS], getBtcAddressesWorker);
}
mergeSaga(getBtcAddressesWatcher);

function* getBtcAddressesWorker(): SagaIterator {
  try {
    const result: IGetAll<IBTCAddress> = yield call(getBtcAddressesApi);
    if (result.message === 'Retrieved all btc addresses') {
      yield put(getBtcAddressesSuccessAction(result.data));
    } else {
      yield put(getBtcAddressesErrorAction(result.message));
    }
  } catch (e: any) {
    yield put(getBtcAddressesErrorAction(e));
  }
}

const getBtcAddressesApi = (): Request => {
  return vipApi(`/cashier/btcAddresses`, 'get', {});
};

const reduceHandlers = {
  [GET_BTC_ADDRESSES]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BTC_ADDRESSES_SUCCESS]: (slice: BtcAddressAdminSliceType, action: any): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: false,
    btcAddresses: action.payload,
  }),
  [GET_BTC_ADDRESSES_ERROR]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: false,
    btcAddresses: [],
  }),
};

btcAddressAdminReducer.addActionReducerMap(reduceHandlers);
