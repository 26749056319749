import { createSelector } from 'reselect';
import { bankAdminReducer, BankAdminSliceType } from './bank.reducer';

export const bankAdminSliceSelector = (state: any): any => state[bankAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  bankAdminSliceSelector,
  (bankAdminSlice: BankAdminSliceType) => bankAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  bankAdminSliceSelector,
  (bankAdminSlice: BankAdminSliceType) => bankAdminSlice.isSaving,
);

export const banksSelector = createSelector(bankAdminSliceSelector, (bankAdminSlice: BankAdminSliceType) => bankAdminSlice.banks);
