import { connect } from 'react-redux';
import { getAgentPayoutsAction } from '../../../api/agent/actions/get-agent-payouts.action';
import { agentPayoutsSelector, isLoadingSliceSelector } from '../../../api/agent/agent.selector';
import { AgentPayoutsPage, ExternalActionProps, ExternalProps } from './agent-payouts-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    payouts: agentPayoutsSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAgentPayoutsAction,
};

export const AgentPayoutsPageContainer = connect(mapStateToProps, mapDispatchToProps)(AgentPayoutsPage);
