import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TransactionAdminSliceType, transactionAdminReducer } from '../transaction.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { ITransactionUpdate } from '../../../models/transactions';

const suffix = `/${transactionAdminReducer.sliceName}/app`;

const UPDATE_TRANSACTION = `UPDATE_TRANSACTION${suffix}`;
export const updateTransactionAction = createAction<ITransactionUpdate>(UPDATE_TRANSACTION);

export const UPDATE_TRANSACTION_SUCCESS = `UPDATE_TRANSACTION_SUCCESS${suffix}`;
export const updateTransactionSuccessAction = createAction(UPDATE_TRANSACTION_SUCCESS);

const UPDATE_TRANSACTION_ERROR = `UPDATE_TRANSACTION_ERROR${suffix}`;
export const updateTransactionErrorAction = createAction<string>(UPDATE_TRANSACTION_ERROR);

function* updateTransactionWatcher(): SagaIterator {
  yield takeEvery(UPDATE_TRANSACTION, updateTransactionWorker);
}
mergeSaga(updateTransactionWatcher);

function* updateTransactionWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateTransaction, action.payload);
    }
    yield put(updateTransactionSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(updateTransactionErrorAction(e.response.data.message));
  }
}

const updateTransaction = (transaction: { id: number; status?: number; notes?: string }): Request => {
  const url = '/cashier/transactions/' + transaction.id;
  return vipApi(url, 'post', transaction);
};

const reduceHandlers = {
  [UPDATE_TRANSACTION]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
    callTransactions: false,
  }),
  [UPDATE_TRANSACTION_SUCCESS]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      callTransactions: true,
    };
  },
  [UPDATE_TRANSACTION_ERROR]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
    callTransactions: false,
  }),
};

transactionAdminReducer.addActionReducerMap(reduceHandlers);
