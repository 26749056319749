import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { ITourCreator, ITourCreatorUpdate } from '../../../models/tour-creator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    dFlex: {
      display: 'flex',
    },
    textCenter: {
      textAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
    iconOrder: {
      color: '#bababa',
      cursor: 'pointer',
    },
  }),
);

export interface ExternalProps {
  stepsList: ITourCreator[];
  selected: ITourCreatorUpdate;
}

export interface ExternalActionProps {
  setEditOrAddModal: (status: boolean) => void;
  setSelected: (tour: ITourCreatorUpdate) => void;
  deleteStepsAction: (tour: ITourCreatorUpdate) => void;
}

export const StepsTable = (props: ExternalProps & ExternalActionProps) => {
  const { stepsList, setEditOrAddModal, setSelected, selected, deleteStepsAction } = props;
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          Tour List
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Tour Name</TableCell>
                <TableCell>Tour Page</TableCell>
                <TableCell align='right'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stepsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name || '-'}</TableCell>
                    <TableCell>{row.page || '-'}</TableCell>
                    <TableCell align='right'>
                      <div className={classes.actions}>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              name: row.name,
                              page: row.page,
                              steps: row.steps,
                              section: '',
                              content: '',
                              sectionId: '',
                              stepName: '',
                              stepDescription: '',
                              previewImage: '',
                            });
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              name: row.name,
                              page: row.page,
                              steps: row.steps,
                              section: '',
                              content: '',
                              sectionId: '',
                              stepName: '',
                              stepDescription: '',
                              previewImage: '',
                            });
                            setIsDeleting(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {stepsList.length === 0 && (
                <TableRow>
                  <TableCell className={classes.textCenter} colSpan={4}>
                    No Tour
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component='div'
          count={stepsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog fullWidth={true} maxWidth='xs' onClose={() => setIsDeleting(false)} open={isDeleting}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setIsDeleting(false)} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteStepsAction(selected);
                setIsDeleting(false);
              }}
              variant='contained'
              color='primary'
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
};
