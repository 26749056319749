import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IBanks } from '../../../models/banks';
import { vipApi } from '../../../utils/vip-api';
import { bonusesAdminReducer, BonusesAdminSliceType } from '../bonuses.reducer';

const suffix = `/${bonusesAdminReducer.sliceName}/app`;

const GET_BONUSES = `GET_BONUSES${suffix}`;

export const getBonusesAction = createAction(GET_BONUSES);

const GET_BONUSES_SUCCESS = `GET_BONUSES_SUCCESS${suffix}`;
const getBonusesSuccessAction = createAction<IBanks[]>(GET_BONUSES_SUCCESS);

const GET_BONUSES_ERROR = `GET_BONUSES_ERROR${suffix}`;
const getBonusesErrorAction = createAction<string>(GET_BONUSES_ERROR);

function* getBonusesWatcher(): SagaIterator {
  yield takeEvery(GET_BONUSES, getBonusesWorker);
}
mergeSaga(getBonusesWatcher);

function* getBonusesWorker(): SagaIterator {
  try {
    const result = yield call(getBonusesApi);
    yield put(getBonusesSuccessAction(result));
  } catch (e: any) {
    yield put(getBonusesErrorAction(e));
  }
}

const getBonusesApi = (): Request => {
  return vipApi(`/cashier/bonus`, 'get', {});
};

const reduceHandlers = {
  [GET_BONUSES]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BONUSES_SUCCESS]: (slice: BonusesAdminSliceType, action: any): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    bonuses: action.payload.data,
  }),
  [GET_BONUSES_ERROR]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    bonuses: [],
  }),
};

bonusesAdminReducer.addActionReducerMap(reduceHandlers);
