import { connect } from 'react-redux';
import { TransactionsPage, ExternalProps, ExternalActionProps } from './transactions-page.component';
import { getTransactionsAction } from '../../../api/transaction/actions/get-transactions.action';
import {
  transactionsSelector,
  isSavingSliceSelector,
  isLoadingSliceSelector,
  totalTransactionsSelector,
  callTransactionsSelector,
} from '../../../api/transaction/transaction.selector';
import { getBanksAction } from '../../../api/bank/actions/get-banks.action';
import { banksSelector } from '../../../api/bank/bank.selector';
import { updateTransactionAction } from '../../../api/transaction/actions/update-transaction.action';
import { getBtcAddressesByBankAction } from '../../../api/btc-address/actions/get-btc-addresses-bank.action';
import { btcAddressesSelector } from '../../../api/btc-address/btc-address.selector';
import { updateLoadTransactionAction } from '../../../api/transaction/actions/load-transaction.action';
import { verifyLoadTransactionAction } from '../../../api/transaction/actions/verify-transaction.action';
import { upsertBtcAddressAction } from '../../../api/btc-address/actions/upsert-btc-address.action';
import { removeBonusAction } from '../../../api/transaction/actions/remove-bonus.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    transactions: transactionsSelector(state),
    banks: banksSelector(state),
    btcAddresses: btcAddressesSelector(state),
    isSaving: isSavingSliceSelector(state),
    isLoading: isLoadingSliceSelector(state),
    totalTransactions: totalTransactionsSelector(state),
    callTransactions: callTransactionsSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getTransactionsAction,
  getBanksAction,
  updateTransactionAction,
  getBtcAddressesByBankAction,
  updateLoadTransactionAction,
  verifyLoadTransactionAction,
  upsertBtcAddressAction,
  removeBonusAction,
};

export const TransactionsPageContainer = connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
