import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Typography, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const useStyles: any = makeStyles({
  root: {
    // padding: 24,
    width: '100%',
  },
  paper: {
    // borderRadius: '8px',
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  setCognitoStatus: (x: any) => void;
  setCognitoDataDialogOpen: (x: boolean) => void;
  setCognitoStatusDialogOpen: (x: boolean) => void;
}

export interface ExternalProps {
  verified: any;
}

export const IdentityCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { verified, setCognitoStatus, setCognitoDataDialogOpen, setCognitoStatusDialogOpen } = props;

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h6' className={classes.headTitle}>
        Identity Information
        <br />
        <small>If identity matches, player is allowed all payment methods.</small>
      </Typography>

      <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Typography variant='subtitle2' className={classes.tableTitle}>
            Identity Match? :
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle2' className={classes.tableContent}>
            {verified != null ? (
              verified == 1 ? (
                <span style={{ color: 'green' }}>Yes</span>
              ) : (
                <span style={{ color: 'red' }}>No</span>
              )
            ) : (
              'Player has NOT yet visited cashier.'
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {verified != null ? (
            <Button
              variant='outlined'
              onClick={() => {
                setCognitoDataDialogOpen(true);
              }}
            >
              View Identity Data
            </Button>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={6}>
          {verified != null ? (
            verified == 1 ? (
              <Button
                variant='contained'
                onClick={() => {
                  setCognitoStatus(0);
                  setCognitoStatusDialogOpen(true);
                }}
              >
                Disable Verified Methods
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setCognitoStatus(1);
                  setCognitoStatusDialogOpen(true);
                }}
              >
                Enable Verified Methods
              </Button>
            )
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
