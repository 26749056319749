import React, { useEffect, useState } from 'react';
import { Button, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { emptyChartAccount, IChartAccount, IChartAccountUpload } from '../../../models/chart-account';
import { IncomeTable } from './components/income-table';
import { CreateAccountDialog } from './components/create-account-dialog';
import { Loading } from '../../../shared/loading/loading.component';
import { ContraTable } from './components/contra-table';
import { ExpenseTable } from './components/expense-table';
import { CurrentAssetsTable } from './components/current-assets-table';
import { LongTermAssetsTable } from './components/long-term-assets-table';
import { CurrentLiabilitiesTable } from './components/current-liabilities-table';
import { LongTermLiabilitiesTable } from './components/long-term-liabilities-table';
import { EquityTable } from './components/equity-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPadding: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '1rem',
    },
  }),
);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export interface ExternalActionProps {
  getChartAccountsAction: () => void;
  upsertChartAccountAction: (landingPage: IChartAccountUpload) => void;
  deleteChartAccountAction: (landingPage: IChartAccountUpload) => void;
}

export interface ExternalProps {
  chartAccounts: IChartAccount[];
  isLoading: boolean;
}

export const ChartOfAccountsPage = (props: ExternalActionProps & ExternalProps) => {
  const { chartAccounts, getChartAccountsAction, upsertChartAccountAction, deleteChartAccountAction, isLoading } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState(emptyChartAccount);
  const classes = useStyles();

  useEffect(() => {
    getChartAccountsAction();
  }, []);

  return (
    <div className={classes.containerPadding}>
      <div className={classes.header}>
        <Button
          onClick={() => {
            setSelected({
              ...emptyChartAccount,
              staff: getSessionUser(),
              action: 'create',
            });
            setEditOrAddModal(true);
          }}
          variant='contained'
          color='primary'
        >
          Create
        </Button>

        <CreateAccountDialog
          open={editOrAddModal}
          onClose={() => {
            setSelected(emptyChartAccount);
            setEditOrAddModal(false);
          }}
          chartAccount={selected}
          selected={selected}
          setSelected={setSelected}
          upsertChartAccountAction={upsertChartAccountAction}
        />
      </div>

      <Grid container>
        <Grid item xs={12}>
          <IncomeTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '1')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <ContraTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '2')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <ExpenseTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '3')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrentAssetsTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '4')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <LongTermAssetsTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '5')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrentLiabilitiesTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '6')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <LongTermLiabilitiesTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '7')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Grid item xs={12}>
          <EquityTable
            chartAccounts={chartAccounts.filter(item => item.account_type == '8')}
            selected={selected}
            setSelected={setSelected}
            setEditOrAddModal={setEditOrAddModal}
            deleteChartAccountAction={deleteChartAccountAction}
            username={getSessionUser()}
          />
        </Grid>
        <Loading loading={isLoading} />
      </Grid>
    </div>
  );
};
