import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { IChartAccount, IChartAccountUpload, emptyChartAccount } from '../../../../models/chart-account';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
  }),
);

export interface ExternalProps {
  chartAccounts: IChartAccount[];
  selected: IChartAccountUpload;
  setSelected: (yup: IChartAccountUpload) => void;
  setEditOrAddModal: (yup: boolean) => void;
  deleteChartAccountAction: (chartAccount: IChartAccountUpload) => void;
  username: any;
}

export const IncomeTable = (props: ExternalProps) => {
  const { chartAccounts, selected, setSelected, setEditOrAddModal, deleteChartAccountAction, username } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          Income
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chartAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.account_name}</TableCell>
                    <TableCell>{row.sub_code}</TableCell>
                    <TableCell align='right'>
                      <div className={classes.actions}>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              account_name: row.account_name,
                              account_type: row.account_type,
                              is_archived: row.is_archived,
                              staff: username,
                              id: row.id,
                              previousId: row.id,
                              sub_code: row.sub_code_id,
                              sub_code_id: row.sub_code_id,
                              action: 'update',
                            });
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              account_name: row.account_name,
                              account_type: row.account_type,
                              is_archived: row.is_archived,
                              staff: row.inserted_by,
                              id: row.id,
                              previousId: row.id,
                              sub_code: row.sub_code_id,
                              sub_code_id: row.sub_code_id,
                              action: 'delete',
                            });
                            setIsDeleting(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={chartAccounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog maxWidth='md' onClose={() => setIsDeleting(false)} open={isDeleting}>
        <DialogTitle>Are you sure you want to delete {selected.account_name}?</DialogTitle>
        <DialogContent>
          {'Deleting cannot be undone. If you want to disactive '}
          <br />
          {'the page, please use the toggle to deactive the page.'}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelected(emptyChartAccount);
              setIsDeleting(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteChartAccountAction(selected);
              setSelected(emptyChartAccount);
              setIsDeleting(false);
            }}
            variant='contained'
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
