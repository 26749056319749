import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${userReducer.sliceName}/app`;

const GET_ADMIN_DASHBOARD = `GET_ADMIN_DASHBOARD${suffix}`;
export const getAdminDashboardAction = createAction<any>(GET_ADMIN_DASHBOARD);

const GET_ADMIN_DASHBOARD_ERROR = `GET_ADMIN_DASHBOARD_ERROR${suffix}`;
const getAdminDashboardErrorAction = createAction<any>(GET_ADMIN_DASHBOARD_ERROR);
const GET_ADMIN_DASHBOARD_SUCCESS = `GET_ADMIN_DASHBOARD_SUCCESS${suffix}`;
const getAdminDashboardSuccessAction = createAction<any>(GET_ADMIN_DASHBOARD_SUCCESS);

function* getAdminDashboardWatcher(): SagaIterator {
  yield takeEvery(GET_ADMIN_DASHBOARD, getAdminDashboardWorker);
}
mergeSaga(getAdminDashboardWatcher);

function* getAdminDashboardWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);

    yield put(getAdminDashboardSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getAdminDashboardErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/cashier/dashboard/admin`, 'post', x);
};

const reduceHandlers = {
  [GET_ADMIN_DASHBOARD]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_ADMIN_DASHBOARD_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      dashboardDeposits: action.payload.data.deposit,
      dashboardWithdrawals: action.payload.data.withdrawal,
      dashboardSignUps: action.payload.data.signup,
      dashboardReferrals: action.payload.data.referral,
      dashboardDate: action.payload.data.dates,
      dashboardPackages: action.payload.data.packages,
    };
  },
  [GET_ADMIN_DASHBOARD_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
