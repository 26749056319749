import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { clearSearchedPlayerAction } from './clear-searched-player.action';
import { getPlayerNotesAction } from './get-player-notes.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const ADD_PLAYER_NOTE = `ADD_PLAYER_NOTE${suffix}`;

export const addPlayerNoteAction = createAction<any>(ADD_PLAYER_NOTE);

const ADD_PLAYER_NOTE_SUCCESS = `ADD_PLAYER_NOTE_SUCCESS${suffix}`;
const addPlayerNoteSuccessAction = createAction<IPlayerInfo[]>(ADD_PLAYER_NOTE_SUCCESS);

const ADD_PLAYER_NOTE_ERROR = `ADD_PLAYER_NOTE_ERROR${suffix}`;
const addPlayerNoteErrorAction = createAction<string>(ADD_PLAYER_NOTE_ERROR);

function* addPlayerNoteWatcher(): SagaIterator {
  yield takeEvery(ADD_PLAYER_NOTE, addPlayerNoteWorker);
}
mergeSaga(addPlayerNoteWatcher);

function* addPlayerNoteWorker(action: any): SagaIterator {
  clearSearchedPlayerAction();
  try {
    const result: any = yield call(addPlayerNoteApi, action.payload);
    console.log(action.payload);
    yield put(addPlayerNoteSuccessAction(result.data));

    const data = {
      player_number: action.payload.player_number,
    };
    yield put(getPlayerNotesAction(data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(addPlayerNoteErrorAction(e.response.data.message));
  }
}

const addPlayerNoteApi = (x: any): Request => {
  return vipApi(`/cashier/AddNoteToPlayer`, 'post', x);
};

const reduceHandlers = {
  [ADD_PLAYER_NOTE]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [ADD_PLAYER_NOTE_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
    };
  },
  [ADD_PLAYER_NOTE_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
