import { createSelector } from 'reselect';
import { btcAddressAdminReducer, BtcAddressAdminSliceType } from './btc-address.reducer';

export const btcAddressAdminSliceSelector = (state: any): any => state[btcAddressAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  btcAddressAdminSliceSelector,
  (btcAddressAdminSlice: BtcAddressAdminSliceType) => btcAddressAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  btcAddressAdminSliceSelector,
  (btcAddressAdminSlice: BtcAddressAdminSliceType) => btcAddressAdminSlice.isSaving,
);

export const btcAddressesSelector = createSelector(
  btcAddressAdminSliceSelector,
  (btcAddressAdminSlice: BtcAddressAdminSliceType) => btcAddressAdminSlice.btcAddresses,
);
