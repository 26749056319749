import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IDistributors } from '../../../models/distributor';
import { distributorAdminReducer, DistributorAdminSliceType } from '../distributor.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${distributorAdminReducer.sliceName}/app`;

const GET_DISTRIBUTORS = `GET_DISTRIBUTORS${suffix}`;

export const getDistributorAction = createAction(GET_DISTRIBUTORS);

const GET_DISTRIBUTORS_SUCCESS = `GET_DISTRIBUTORS_SUCCESS${suffix}`;
const getDistributorsSuccessAction = createAction<IDistributors[]>(GET_DISTRIBUTORS_SUCCESS);

const GET_DISTRIBUTORS_ERROR = `GET_DISTRIBUTORS_ERROR${suffix}`;
const getDistributorsErrorAction = createAction<string>(GET_DISTRIBUTORS_ERROR);

function* getDistributorsWatcher(): SagaIterator {
  yield takeEvery(GET_DISTRIBUTORS, getDistributorsWorker);
}
mergeSaga(getDistributorsWatcher);

function* getDistributorsWorker(): SagaIterator {
  try {
    const result: IGetAll<IDistributors> = yield call(getDistributorsApi);
    yield put(getDistributorsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getDistributorsErrorAction(e));
  }
}

const getDistributorsApi = (): Request => {
  return vipApi(`/cashier/ListDistributors`, 'get', {});
};

const reduceHandlers = {
  [GET_DISTRIBUTORS]: (slice: DistributorAdminSliceType): DistributorAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_DISTRIBUTORS_SUCCESS]: (slice: DistributorAdminSliceType, action: any): DistributorAdminSliceType => ({
    ...slice,
    isLoading: false,
    distributors: action.payload,
  }),
  [GET_DISTRIBUTORS_ERROR]: (slice: DistributorAdminSliceType): DistributorAdminSliceType => ({
    ...slice,
    isLoading: false,
    distributors: [],
  }),
};

distributorAdminReducer.addActionReducerMap(reduceHandlers);
