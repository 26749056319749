import React from 'react';
import clsx from 'clsx';
import { Button, Card, CardContent, CardHeader, CardMedia, Chip, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { Loading } from '../../../shared/loading/loading.component';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CreateBonusDialog from './components/create-bonus-dialog.component';
import ContributionTableDialog from './components/contribution-table-dialog.component';
import { IBonus, emptyBonus } from '../../../models/bonus';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  paper: {
    padding: 12,
  },
  btnGrid: {
    textAlign: 'center',
  },
  formControl: {
    margin: 4,
  },
});

export interface ExternalActionProps {
  getBonusesAction: () => void;
  createBonusAction: (x: any) => void;
  getBonusProfilesAction: () => void;
  updateBonusAction: (x: any) => void;
}

export interface ExternalProps {
  isLoading: any;
  bonuses: any;
  profiles: any;
}

export const BonusPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getBonusesAction, bonuses, isLoading, createBonusAction, getBonusProfilesAction, profiles, updateBonusAction } = props;
  const [bonusType, setBonusType] = React.useState(1);
  const [selectedBonus, setSelectedBonus] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const [selected, setSelected] = React.useState<IBonus>({
    ...emptyBonus,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBonus(null);
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    getBonusesAction();
    getBonusProfilesAction();
  }, []);

  const handleChange = (event: any) => {
    setBonusType(event.target.value);
  };

  const [createOpen, setCreateOpen] = React.useState(false);
  const handleCreateOpen = () => {
    setCreateOpen(true);
  };
  const handleCreateClose = () => {
    setSelectedBonus(null);
    setCreateOpen(false);
  };

  const getBonusCategoryText = (bonusType: any) => {
    if (bonusType == 'deposit') {
      return 'Deposit';
    }
    return 'Risk Free Bet';
  };

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />

      <Grid container>
        <Grid item xs={12} style={{ display: 'contents' }}>
          <Button variant='contained' color='primary' onClick={handleCreateOpen}>
            Create Bonus
          </Button>

          <CreateBonusDialog
            createBonusAction={createBonusAction}
            isLoading={isLoading}
            bonuses={bonuses}
            profiles={profiles}
            bonus={selectedBonus ? selectedBonus : null}
            // setCreateOpen={setCreateOpen}
            createOpen={createOpen}
            handleCreateClose={() => {
              handleCreateClose();
              setSelected(emptyBonus);
            }}
            updateBonusAction={updateBonusAction}
            setSelected={setSelected}
            selected={selected}
          />

          <FormControl variant='outlined' className={classes.formControl} size='small'>
            <Select labelId='demo-simple-select-filled-label' id='demo-simple-select-filled' value={bonusType} onChange={handleChange}>
              <MenuItem value={1}>
                <VisibilityIcon fontSize='small' /> Viewable
              </MenuItem>
              <MenuItem value={0}>
                <VisibilityOffIcon fontSize='small' />
                Hidden
              </MenuItem>
              <hr />
              <MenuItem value={2}>
                <DeleteIcon fontSize='small' /> Archived
              </MenuItem>
            </Select>
            <FormHelperText>Select a bonus status</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

      <Grid container spacing={4}>
        {bonuses
          .filter((b: any) => {
            if (bonusType == 2) {
              if (b.deleted == 1) {
                return true;
              }
            }
            if (b.active == bonusType && b.deleted != 1) {
              return true;
            }
            return false;
          })
          .map((bonus: any) => {
            return (
              <Grid item xs={12} sm={4} key={bonus.id}>
                <Card>
                  <Grid container>
                    <Grid item xs={8}>
                      <CardHeader title={'[' + bonus.code + ']'} sx={{ marginBottom: '0' }} />
                      <Typography
                        dangerouslySetInnerHTML={{ __html: bonus.description }}
                        sx={{ color: 'text.secondary', marginLeft: '1rem', marginRight: '1rem' }}
                      ></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {' '}
                      {bonus.image != null && <CardMedia component='img' height='auto' image={bonus.image} />}
                    </Grid>
                  </Grid>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        ID:{' '}
                      </Grid>
                      <Grid item xs={6}>
                        #{bonus.id}
                      </Grid>
                      <Grid item xs={6}>
                        Type:{' '}
                      </Grid>
                      <Grid item xs={6}>
                        {getBonusCategoryText(bonus.category)}
                      </Grid>
                      <Grid item xs={6}>
                        Reward:{' '}
                      </Grid>
                      <Grid item xs={6}>
                        <Chip label={bonus.type} size='small' color='default' />
                      </Grid>
                      <Grid item xs={6}>
                        Deposit Min:{' '}
                      </Grid>
                      <Grid item xs={6}>
                        ${bonus.min_deposit}
                      </Grid>
                      <Grid item xs={6}>
                        Deposit Max:{' '}
                      </Grid>
                      <Grid item xs={6}>
                        ${bonus.max_deposit}
                      </Grid>
                      <Grid item xs={6}>
                        Match:
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.match_multiplier * 100}%
                      </Grid>
                      <Grid item xs={6}>
                        Rollover:
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.rollover_multiplier * 1}x
                      </Grid>
                      <Grid item xs={6}>
                        Visible in Cashier?:
                        <br />
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.active == 1 ? (
                          <span style={{ color: 'green', fontWeight: 'bold' }}>Yes</span>
                        ) : (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>No</span>
                        )}
                      </Grid>
                    </Grid>
                    <hr style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />

                    <Grid container>
                      <Grid item xs={6}>
                        Start Date:
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.start_date}
                      </Grid>
                      <Grid item xs={6}>
                        End Date:
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.end_date}
                      </Grid>
                      <Grid item xs={6}>
                        Redeemable?:
                      </Grid>
                      <Grid item xs={6}>
                        {bonus.recurring == 1 ? 'Multiple' : 'One Time'}
                      </Grid>
                    </Grid>

                    <hr style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />

                    {bonus.ft_bonus_id != null && bonus.ft_bonus_id != 'null' && (
                      <>
                        <Grid container>
                          <Grid item xs={6}>
                            Required Bonus ID?:
                            <br />
                            <small>Bonus to redeem first.</small>
                          </Grid>
                          <Grid item xs={6}>
                            {bonus.ft_bonus_id}
                          </Grid>
                        </Grid>

                        <hr style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />
                      </>
                    )}

                    <Grid container spacing={4}>
                      <Grid item xs={3} className={classes.btnGrid}>
                        <IconButton
                          onClick={() => {
                            updateBonusAction({
                              id: bonus.id,
                              active: bonus.active == 1 ? 0 : 1,
                            });
                          }}
                          size='large'
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <br />
                        <small>{bonus.active == 1 ? 'Hide Bonus' : 'Make Visible'}</small>
                      </Grid>
                      <Grid item xs={3} className={classes.btnGrid}>
                        <IconButton
                          onClick={() => {
                            setSelected(bonus);
                            handleCreateOpen();
                          }}
                          size='large'
                        >
                          <EditIcon />
                        </IconButton>
                        <br />
                        <small>Edit</small>
                      </Grid>
                      <Grid item xs={3} className={classes.btnGrid}>
                        <IconButton
                          onClick={() => {
                            updateBonusAction({
                              id: bonus.id,
                              deleted: bonus.deleted == 1 ? 0 : 1,
                            });
                          }}
                          size='large'
                        >
                          <DeleteIcon />
                        </IconButton>
                        <br />
                        <small>{bonus.deleted == null || bonus.deleted == 0 ? 'Archive' : 'Restore'}</small>
                      </Grid>
                      <Grid item xs={3} className={classes.btnGrid}>
                        <IconButton
                          onClick={() => {
                            setSelectedBonus(bonus);
                            handleClickOpen();
                          }}
                          size='large'
                        >
                          <ViewHeadlineIcon />
                        </IconButton>
                        <br />
                        <small>Contribution Table</small>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        <ContributionTableDialog
          open={open}
          descriptionElementRef={descriptionElementRef}
          handleClose={handleClose}
          bonus={selectedBonus}
        />
      </Grid>
    </div>
  );
};
