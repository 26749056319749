import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  fees: any;
}

export const FeeFreeCard = (props: ExternalProps) => {
  const classes = useStyles();
  const { fees } = props;

  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader
          title='Fee Free Times'
          subheader='Withdrawal Free times, enabled or disabled.'
          titleTypographyProps={{ fontSize: '1.2rem' }}
          subheaderTypographyProps={{ fontSize: '.8rem' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {fees &&
              fees.map((fee: any) => {
                return (
                  <React.Fragment key={fee.id}>
                    <Grid item xs={4}>
                      {fee.day}
                    </Grid>
                    <Grid item xs={4}>
                      Start: {fee.start_time}
                      <br />
                      End: {fee.end_time}
                    </Grid>
                    <Grid item xs={4}>
                      {fee.enabled == 1 ? <CheckCircleIcon sx={{ color: 'green' }} /> : <BlockIcon sx={{ color: 'red' }} />}
                    </Grid>
                  </React.Fragment>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
