import { ITourCreator } from '../../models/tour-creator';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'tourCreatorAdminSlice';

export interface TourCreatorAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  stepsList: ITourCreator[];
}

export const initialState: TourCreatorAdminSliceType = {
  isLoading: false,
  isSaving: false,
  stepsList: [],
};

export const tourCreatorAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
