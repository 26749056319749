import { createSelector } from 'reselect';
import { guideReducer, GuideSliceType } from './guide.reducer';

export const guideSliceSelector = (state: any): any => state[guideReducer.sliceName];

export const isLoadingSliceSelector = createSelector(guideSliceSelector, (guideSlice: GuideSliceType) => guideSlice.isLoading);

export const isSavingSliceSelector = createSelector(guideSliceSelector, (guideSlice: GuideSliceType) => guideSlice.isSaving);

export const guideSelector = createSelector(guideSliceSelector, (guideSlice: GuideSliceType) => guideSlice.guides);
