import React from 'react';
import { Grid, Paper, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ProfileContributionTable } from './contribution-table.component';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: { padding: 10 },
});

export interface ExternalProps {
  profile: any;
}

export const ProfilePaper = (props: ExternalProps) => {
  const { profile } = props;
  const classes = useStyles();
  const [show, setShow] = React.useState(false);

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant='h6'>{profile.name}</Typography>
            <Typography variant='subtitle2'>
              Description: <br />
              {profile.description}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <div style={{ textAlign: 'center' }}>
              <IconButton
                aria-label='eye'
                color='primary'
                onClick={() => {
                  setShow(!show);
                }}
                size='large'
              >
                <VisibilityIcon />
              </IconButton>
              <br />
              Contribution Table
            </div>

            {/* <IconButton aria-label='delete' color='primary'>
            <DeleteIcon />
          </IconButton> */}
          </Grid>
        </Grid>

        <ProfileContributionTable profile={profile} show={show} />
      </Paper>
    </>
  );
};
