import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IBanks } from '../../../models/banks';
import { vipApi } from '../../../utils/vip-api';
import { bonusesAdminReducer, BonusesAdminSliceType } from '../bonuses.reducer';

const suffix = `/${bonusesAdminReducer.sliceName}/app`;

const GET_BONUS_PROFILES = `GET_BONUS_PROFILES${suffix}`;

export const getBonusProfilesAction = createAction(GET_BONUS_PROFILES);

const GET_BONUS_PROFILES_SUCCESS = `GET_BONUS_PROFILES_SUCCESS${suffix}`;
const getBonusProfilesSuccessAction = createAction<IBanks[]>(GET_BONUS_PROFILES_SUCCESS);

const GET_BONUS_PROFILES_ERROR = `GET_BONUS_PROFILES_ERROR${suffix}`;
const getBonusProfilesErrorAction = createAction<string>(GET_BONUS_PROFILES_ERROR);

function* getBonusProfilesWatcher(): SagaIterator {
  yield takeEvery(GET_BONUS_PROFILES, getBonusProfilesWorker);
}
mergeSaga(getBonusProfilesWatcher);

function* getBonusProfilesWorker(): SagaIterator {
  try {
    const result = yield call(getBonusProfilesApi);
    yield put(getBonusProfilesSuccessAction(result));
  } catch (e: any) {
    yield put(getBonusProfilesErrorAction(e));
  }
}

const getBonusProfilesApi = (): Request => {
  return vipApi(`/cashier/bonus/profile/all`, 'get', {});
};

const reduceHandlers = {
  [GET_BONUS_PROFILES]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BONUS_PROFILES_SUCCESS]: (slice: BonusesAdminSliceType, action: any): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    profiles: action.payload.data,
  }),
  [GET_BONUS_PROFILES_ERROR]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    profiles: [],
  }),
};

bonusesAdminReducer.addActionReducerMap(reduceHandlers);
