import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerInfoAction } from './get-player-info.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const UPDATE_TPI_RIGHTS = `UPDATE_TPI_RIGHTS${suffix}`;

export const updateTpiRightsAction = createAction<any>(UPDATE_TPI_RIGHTS);

const UPDATE_TPI_RIGHTS_SUCCESS = `UPDATE_TPI_RIGHTS_SUCCESS${suffix}`;
const updateTpiRightsSuccessAction = createAction<IPlayerInfo[]>(UPDATE_TPI_RIGHTS_SUCCESS);

const UPDATE_TPI_RIGHTS_ERROR = `UPDATE_TPI_RIGHTS_ERROR${suffix}`;
const updateTpiRightsErrorAction = createAction<string>(UPDATE_TPI_RIGHTS_ERROR);

function* updateTpiRightsWatcher(): SagaIterator {
  yield takeEvery(UPDATE_TPI_RIGHTS, updateTpiRightsWorker);
}
mergeSaga(updateTpiRightsWatcher);

function* updateTpiRightsWorker(action: any): SagaIterator {
  try {
    console.log('yup');
    const result: IGetAll<IPlayerInfo> = yield call(updateTpiRightsApi, action.payload);
    yield put(updateTpiRightsSuccessAction(result.data));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerInfoAction(action.payload.userId));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(updateTpiRightsErrorAction(e.response.data.message));
  }
}

const updateTpiRightsApi = (x: any): Request => {
  return vipApi(`/atpi/UpdateTpiRights`, 'post', x);
};

const reduceHandlers = {
  [UPDATE_TPI_RIGHTS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_TPI_RIGHTS_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [UPDATE_TPI_RIGHTS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
