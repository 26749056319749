import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export const ContributionInfoDialog = (props: any) => {
  const { open, onClose, data, bonus } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <DialogContentText>[{bonus}] Contribution Table</DialogContentText>
          {data}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
