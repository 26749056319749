import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListSubheader,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITourCreatorUpdate } from '../../../models/tour-creator';
import { ITourSections } from '../../../models/tour-sections';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const pageList = [
  { label: 'SportsBook', value: 'sportsbook' },
  { label: 'Casino', value: 'casino' },
  { label: 'Refer-a-friend', value: 'raf' },
  { label: 'Rules', value: 'rules' },
  { label: 'Profile', value: 'profile' },
  { label: 'Player History', value: 'playerHistory' },
  { label: 'Transaction History', value: 'transactionHistory' },
  { label: 'Bonus History', value: 'bonusHistory' },
  { label: 'Agent', value: 'agent' },
  { label: 'Cashier', value: 'cashier' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginLeft: '8px',
      marginRight: '8px',
      marginTop: '8px',
    },
    button: {
      margin: theme.spacing(1),
    },
    textCenter: {
      textAlign: 'center',
    },
    dFlex: {
      display: 'flex',
      alignItems: 'stretch',
    },
    mx2: {
      marginLeft: '8px',
      marginRight: '8px',
    },
    referralInp: {
      width: '180px',
    },
    referralText: {
      marginLeft: '8px',
      marginRight: '8px',
      fontSize: '15px',
    },
    flex1: {
      flex: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addBtn: {
      alignSelf: 'stretch',
      margin: '15px 0',
    },
    addStepBtn: {
      width: '200px',
      margin: '8px 7px 5px 10px',
    },
    selectBox: {
      minWidth: '300px',
    },
    tblContainer: {
      marginTop: '30px',
    },
    addStepRow: {
      margin: '20px 0',
      paddingTop: '20px',
      borderTop: '1px dashed #c8c8c8',
      borderBottom: '1px dashed #c8c8c8',
    },
    previewBox: {
      display: 'flex',
      justifyContent: 'center',
      margin: '20px 0 25px',
      '& > img': {
        maxWidth: '600px',
      },
    },
    actionsBox: {
      padding: '30px 25px 30px',
    },
    dragCell: {
      width: '50px',
    },
    checkIcon: {
      fontSize: '12px',
      marginRight: '5px',
    },
  }),
);

const getStyle = (style: any, snapshot: any) => {
  if (!snapshot.isDropAnimating) {
    if (snapshot.isDragging) {
      return {
        ...style,
        width: '700px',
        display: 'flex',
        backgroundColor: '#e0e0e0',
        justifyContent: 'space-around',
      };
    } else return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  return {
    ...style,
    width: '700px',
    display: 'flex',
    backgroundColor: '#e0e0e0',
    justifyContent: 'space-around',
    transform: `${translate}`,
    transition: `all ${curve} ${duration + 0.1}s`,
  };
};

export interface ExternalProps {
  open: boolean;
  selected: ITourCreatorUpdate;
  sectionsList: ITourSections[];
}

export interface ExternalActionProps {
  onClose: () => void;
  updateStepsAction: (tour: any) => void;
  setSelected: (tour: ITourCreatorUpdate) => void;
}

export const CreateTourDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { sectionsList, updateStepsAction, onClose, open, selected, setSelected } = props;
  const [addedSectionIds, setAddedSectionIds] = useState<any>([]);

  const getGeneralFilter = (sectionsList: ITourSections[]) => {
    return sectionsList.filter(item => item?.target.includes(`tour_tabBar`) || item?.target.includes(`tour_header`));
  };

  const getFilter = (sectionsList: ITourSections[]) => {
    return sectionsList.filter(item => item?.target.includes(`tour_${selected?.page}`));
  };

  useEffect(() => {
    const _addedSectionIds = selected?.steps.reduce((a: [], b: any) => [...a, b.sectionId], []);
    setAddedSectionIds(_addedSectionIds);
  }, [selected.steps]);

  useEffect(() => {
    const selectedSection = sectionsList.filter(item => +item.id === +selected.section);
    setSelected({
      ...selected,
      previewImage: selectedSection[0]?.previewImage,
      stepName: selectedSection[0]?.targetName,
      sectionId: selectedSection[0]?.id,
    });
  }, [selected.section]);

  const addStepToList = () => {
    const newStep = {
      stepNumber: selected.steps.length,
      stepName: selected.stepName,
      target: '',
      targetName: '',
      sectionId: selected.sectionId,
      stepDescription: selected.content,
    };
    setSelected({
      ...selected,
      content: '',
      section: '',
      steps: [...selected.steps, newStep],
    });
    setAddedSectionIds([...addedSectionIds, selected.sectionId]);
  };

  const removeStep = (sectionId: any) => {
    const _updatedSteps = selected.steps.filter((item: any) => item.sectionId !== sectionId);
    setSelected({
      ...selected,
      steps: _updatedSteps,
    });
  };

  const submitStepsChanges = () => {
    const tourData = {
      id: selected.id,
      data: {
        name: selected.name,
        page: selected.page,
        steps: selected?.steps.map((item: any) => {
          return {
            stepNumber: item.stepNumber,
            section: item.sectionId || item.stepNumber,
            content: item.stepDescription,
          };
        }),
      },
    };
    updateStepsAction(tourData);
    onClose();
  };

  const reOrderList = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return false;
    const _reorderedList = reOrderList(selected.steps, result.source.index, result.destination.index);
    setSelected({
      ...selected,
      steps: _reorderedList,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby='draggable-dialog-title' fullWidth={true} maxWidth='lg'>
        <DialogTitle id='draggable-dialog-title'>{selected.id == null ? 'Create Tour' : 'Update Tour'}</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            addStepToList();
          }}
        >
          <DialogContent>
            <div className={classes.dFlex}>
              <TextField
                margin='dense'
                id='name'
                label='Tour Name'
                type='text'
                value={selected.name}
                className={classes.mx2}
                onChange={event =>
                  setSelected({
                    ...selected,
                    name: event.target.value,
                  })
                }
                required
                fullWidth
              />
              <FormControl variant='outlined' className={`${classes.formControl} ${classes.selectBox}`}>
                <InputLabel id='page-label'>Page Name</InputLabel>
                <Select
                  required
                  labelId='page-label'
                  value={selected.page}
                  label='Page Name'
                  onChange={event =>
                    setSelected({
                      ...selected,
                      page: event.target.value as string,
                    })
                  }
                  disabled={!!selected.id}
                  fullWidth
                >
                  {pageList.map(item => {
                    return (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className={classes.addStepRow}>
              <div className={classes.dFlex}>
                <FormControl variant='outlined' className={`${classes.formControl} ${classes.selectBox}`}>
                  <InputLabel id='section-label'>Section Name</InputLabel>
                  <Select
                    required
                    labelId='section-label'
                    id='section'
                    label='Section Name'
                    value={selected.section}
                    onChange={event =>
                      setSelected({
                        ...selected,
                        section: event.target.value,
                      })
                    }
                    disabled={!selected.page}
                  >
                    <ListSubheader>Page Sections</ListSubheader>
                    {getFilter(sectionsList).map((item: any) => {
                      return (
                        <MenuItem value={item.id} key={item.id} disabled={addedSectionIds.includes(item.id)}>
                          {addedSectionIds.includes(item.id) && <CheckIcon className={classes.checkIcon} />}
                          {item.targetName}
                        </MenuItem>
                      );
                    })}
                    <ListSubheader>General Sections</ListSubheader>
                    {getGeneralFilter(sectionsList).map((item: any) => {
                      return (
                        <MenuItem value={item.id} key={item.id} disabled={addedSectionIds.includes(item.id)}>
                          {addedSectionIds.includes(item.id) && <CheckIcon className={classes.checkIcon} />}
                          {item.targetName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  margin='dense'
                  id='content'
                  label='Description'
                  type='text'
                  value={selected.content}
                  onChange={event =>
                    setSelected({
                      ...selected,
                      content: event.target.value,
                    })
                  }
                  required
                  fullWidth
                />
                <Button type='submit' className={classes.addStepBtn} variant='contained' color='primary'>
                  Add Step
                </Button>
              </div>

              <div className={classes.previewBox}>
                <img src={selected?.previewImage} />
              </div>
            </div>

            <Typography variant='h6' component='div'>
              Steps List
            </Typography>
            <TableContainer className={classes.tblContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.dragCell}></TableCell>
                    <TableCell>Step Name</TableCell>
                    <TableCell>Step Description</TableCell>
                    <TableCell align='right'>Action</TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='stepsList'>
                    {(provided: any) => (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                        {selected.steps.map((row: any, i: any) => {
                          return (
                            <Draggable key={String(row.sectionId)} draggableId={String(row.sectionId)} index={i}>
                              {(provided: any, snapshot: any) => (
                                <TableRow
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getStyle(provided.draggableProps.style, snapshot)}
                                >
                                  <TableCell className={classes.dragCell}>
                                    <DragHandleIcon></DragHandleIcon>
                                  </TableCell>
                                  <TableCell>{row.stepName || '-'}</TableCell>
                                  <TableCell>{row.stepDescription || '-'}</TableCell>
                                  <TableCell align='right'>
                                    <div className={classes.actions}>
                                      <IconButton onClick={() => removeStep(row.sectionId)} size='small'>
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                        {selected.steps.length === 0 && (
                          <TableRow>
                            <TableCell className={classes.textCenter} colSpan={4}>
                              No Steps
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions className={classes.actionsBox}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={submitStepsChanges} variant='contained' color='primary' disabled={selected.steps.length > 0 ? false : true}>
              {selected.id == null ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
