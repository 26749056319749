import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IBTCAddressUpdate } from '../../../models/btc-addresses';
import { BtcAddressAdminSliceType, btcAddressAdminReducer } from '../btc-address.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${btcAddressAdminReducer.sliceName}/app`;

const DELETE_BTC_ADDRESS = `DELETE_BTC_ADDRESS${suffix}`;
export const deleteBtcAddressAction = createAction<IBTCAddressUpdate>(DELETE_BTC_ADDRESS);

export const DELETE_BTC_ADDRESS_SUCCESS = `DELETE_BTC_ADDRESS_SUCCESS${suffix}`;
export const deleteBtcAddressSuccessAction = createAction(DELETE_BTC_ADDRESS_SUCCESS);

const DELETE_BTC_ADDRESS_ERROR = `DELETE_BTC_ADDRESS_ERROR${suffix}`;
export const deleteBtcAddressErrorAction = createAction<string>(DELETE_BTC_ADDRESS_ERROR);

function* deleteBtcAddressWatcher(): SagaIterator {
  yield takeEvery(DELETE_BTC_ADDRESS, deleteBtcAddressWorker);
}
mergeSaga(deleteBtcAddressWatcher);

function* deleteBtcAddressWorker(action: any): SagaIterator {
  try {
    yield call(deleteBtcAddress, action.payload.id);
    yield put(deleteBtcAddressSuccessAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteBtcAddressErrorAction(e.response.data.message));
  }
}

const deleteBtcAddress = (id: number): Request => {
  const url = '/cashier/btcAddresses/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_BTC_ADDRESS]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DELETE_BTC_ADDRESS_SUCCESS]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_BTC_ADDRESS_ERROR]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

btcAddressAdminReducer.addActionReducerMap(reduceHandlers);
