import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { IBTCAddressUpdate } from '../../../../models/btc-addresses';
import { IBanks } from '../../../../models/banks';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ITransactionUpdate, emptyUpdate } from '../../../../models/transactions';

interface comapBank {
  bank_id: any;
  id: any;
  method: any;
}

export interface ExternalProps {
  open: boolean;
  selected: IBTCAddressUpdate;
  banks: IBanks[];
  companyBank: comapBank;
  update: ITransactionUpdate;
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertBtcAddresssAction: (btcAddress: IBTCAddressUpdate) => void;
  setSelected: (btcAddress: IBTCAddressUpdate) => void;
  setCompanyBank: (companyBank: comapBank) => void;
  updateTransactionAction: (transaction: ITransactionUpdate) => void;
  setUpdate: (transaction: ITransactionUpdate) => void;
}

export const AddBTCDialog = (props: ExternalProps & ExternalActionProps) => {
  const { upsertBtcAddresssAction, onClose, open, selected, setSelected, banks, updateTransactionAction, update, setUpdate } = props;

  const handleSubmit = () => {
    upsertBtcAddresssAction(selected);
    updateTransactionAction({
      ...update,
      account: selected.addresses,
    });
    setUpdate(emptyUpdate);
    onClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='draggable-dialog-title'>{selected.id == null ? 'Add' : 'Update'} Addresses</DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent>
          <DialogContentText>Please fill out the form below to create addresses.</DialogContentText>
          <FormControl fullWidth style={{ marginBottom: '18px' }}>
            <InputLabel htmlFor='bank'>Bank</InputLabel>
            <Select
              value={selected.bank}
              onChange={event =>
                setSelected({
                  ...selected,
                  bank: event.target.value as unknown as number,
                })
              }
              inputProps={{
                name: 'bank',
                id: 'bank',
              }}
              fullWidth
              required
            >
              {banks.map(item => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            required
            fullWidth
            label='BTC Address'
            value={selected.addresses}
            onChange={e =>
              setSelected({
                ...selected,
                addresses: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            {selected.id == null ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
