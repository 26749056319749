import Axios from 'axios';
import { main } from './app';

const APP_ID = 'desking';

export function loadAppSettings(): Promise<void> {
  const getSettingsJson = () => {
    return 'app.settings.json';
  };

  return Axios.get(getSettingsJson())
    .then(response => {
      const appSettings = response.data;
      main(APP_ID, Object.freeze(appSettings));
    })
    .catch();
}

loadAppSettings();
