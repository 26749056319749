import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IGuideCategoryUpdate, IGuideCategory } from '../../../models/guide-categories';
import { GuideCategoriesSliceType, guideCategoriesReducer } from '../guide-categories.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${guideCategoriesReducer.sliceName}/app`;

const UPSERT_GUIDE_CATEGORY = `UPSERT_GUIDE_CATEGORY${suffix}`;
export const upsertGuideCategoryAction = createAction<IGuideCategoryUpdate>(UPSERT_GUIDE_CATEGORY);

export const UPSERT_GUIDE_CATEGORY_SUCCESS = `UPSERT_GUIDE_CATEGORY_SUCCESS${suffix}`;
export const upsertGuideCategorySuccessAction = createAction<IGuideCategory>(UPSERT_GUIDE_CATEGORY_SUCCESS);

const UPSERT_GUIDE_CATEGORY_ERROR = `UPSERT_GUIDE_CATEGORY_ERROR${suffix}`;
export const upsertGuideCategoryErrorAction = createAction<string>(UPSERT_GUIDE_CATEGORY_ERROR);

function* upsertGuideCategoryWatcher(): SagaIterator {
  yield takeEvery(UPSERT_GUIDE_CATEGORY, upsertGuideCategoryWorker);
}
mergeSaga(upsertGuideCategoryWatcher);

function* upsertGuideCategoryWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateGuideCategory, action.payload);
    } else {
      result = yield call(createGuideCategory, action.payload);
    }
    yield put(upsertGuideCategorySuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertGuideCategoryErrorAction(e.response.data.message));
  }
}

const createGuideCategory = (guideCategory: IGuideCategoryUpdate): Request => {
  const url = '/guides/AddGuideCategory';
  return vipApi(url, 'post', guideCategory);
};

const updateGuideCategory = (guideCategory: IGuideCategoryUpdate): Request => {
  const url = '/guides/EditGuideCategory/' + guideCategory.id;
  return vipApi(url, 'post', guideCategory);
};

const reduceHandlers = {
  [UPSERT_GUIDE_CATEGORY]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isLoading: true,
    isSaving: true,
    isSavingCategory: true,
  }),
  [UPSERT_GUIDE_CATEGORY_SUCCESS]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => {
    return {
      ...slice,
      isSaving: false,
      isSavingCategory: false,
    };
  },
  [UPSERT_GUIDE_CATEGORY_ERROR]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
    isSavingCategory: false,
  }),
};

guideCategoriesReducer.addActionReducerMap(reduceHandlers);
