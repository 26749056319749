import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { referralsReducer, ReferralsSliceType } from '../referrals.reducer';

const suffix = `/${referralsReducer.sliceName}/app`;

const GET_REFERRAL_STATS = `GET_REFERRAL_STATS${suffix}`;
export const getReferralsAction = createAction<any>(GET_REFERRAL_STATS);

const GET_REFERRAL_STATS_ERROR = `GET_REFERRAL_STATS_ERROR${suffix}`;
const getReferralsErrorAction = createAction<any>(GET_REFERRAL_STATS_ERROR);
const GET_REFERRAL_STATS_SUCCESS = `GET_REFERRAL_STATS_SUCCESS${suffix}`;
const getReferralsSuccessAction = createAction<any>(GET_REFERRAL_STATS_SUCCESS);

function* getReferralsWatcher(): SagaIterator {
  yield takeEvery(GET_REFERRAL_STATS, getReferralsWorker);
}
mergeSaga(getReferralsWatcher);

function* getReferralsWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(getReferralsSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getReferralsErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/cashier/GetAllReferralStats`, 'post', {
    start_date: x.start_date,
    end_date: x.end_date,
  });
};

const reduceHandlers = {
  [GET_REFERRAL_STATS]: (slice: ReferralsSliceType): ReferralsSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_REFERRAL_STATS_SUCCESS]: (slice: ReferralsSliceType, action: any): ReferralsSliceType => ({
    ...slice,
    isLoading: false,
    referrals: action.payload.data.referrals,
    topClaimed: action.payload.data.topClaimed,
    topSignedUp: action.payload.data.topSignedUp,
    referralsCount: action.payload.data.referralsCount,
  }),
  [GET_REFERRAL_STATS_ERROR]: (slice: ReferralsSliceType): ReferralsSliceType => ({
    ...slice,
    isLoading: false,
  }),
};
referralsReducer.addActionReducerMap(reduceHandlers);
