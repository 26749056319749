import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPaymentMethods } from '../../../models/payment-methods';
import { paymentMethodAdminReducer, PaymentMethodAdminSliceType } from '../payment-method.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${paymentMethodAdminReducer.sliceName}/app`;

const GET_PAYMENT_METHODS = `GET_PAYMENT_METHODS${suffix}`;

export const getPaymentMethodsAction = createAction(GET_PAYMENT_METHODS);

const GET_PAYMENT_METHODS_SUCCESS = `GET_PAYMENT_METHODS_SUCCESS${suffix}`;
const getPaymentMethodsSuccessAction = createAction<IPaymentMethods[]>(GET_PAYMENT_METHODS_SUCCESS);

const GET_PAYMENT_METHODS_ERROR = `GET_PAYMENT_METHODS_ERROR${suffix}`;
const getPaymentMethodsErrorAction = createAction<string>(GET_PAYMENT_METHODS_ERROR);

function* getPaymentMethodsWatcher(): SagaIterator {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethodsWorker);
}
mergeSaga(getPaymentMethodsWatcher);

function* getPaymentMethodsWorker(): SagaIterator {
  try {
    const result: IGetAll<IPaymentMethods> = yield call(getPaymentMethodsApi);
    if (result.message === 'Successfully retrieved payment methods.') {
      yield put(getPaymentMethodsSuccessAction(result.data));
    } else {
      yield put(getPaymentMethodsErrorAction(result.message));
    }
  } catch (e: any) {
    yield put(getPaymentMethodsErrorAction(e));
  }
}

const getPaymentMethodsApi = (): Request => {
  return vipApi(`/cashier/paymentMethods`, 'get', {});
};

const reduceHandlers = {
  [GET_PAYMENT_METHODS]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PAYMENT_METHODS_SUCCESS]: (slice: PaymentMethodAdminSliceType, action: any): PaymentMethodAdminSliceType => ({
    ...slice,
    isLoading: false,
    paymentMethods: action.payload,
  }),
  [GET_PAYMENT_METHODS_ERROR]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isLoading: false,
    paymentMethods: [],
  }),
};

paymentMethodAdminReducer.addActionReducerMap(reduceHandlers);
