import { IGuideCategory } from '../../models/guide-categories';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'guideCategoriesSlice';

export interface GuideCategoriesSliceType {
  isLoading: boolean;
  isSaving: boolean;
  guideCategories: IGuideCategory[];
  isSavingCategory: boolean;
}

export const initialState: GuideCategoriesSliceType = {
  isLoading: false,
  isSaving: false,
  guideCategories: [],
  isSavingCategory: true,
};

export const guideCategoriesReducer = createAndMergeSliceReducer(sliceName, initialState);
