import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export interface ExternalProps {
  data: any;
}

export default function DepositMethodTable(props: ExternalProps) {
  const { data } = props;

  return (
    <>
      {data &&
        data.map((method: any) => {
          return (
            <TableContainer component={Paper} key={method.id} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant='h6'>{method.name}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ textAlign: 'right' }}>Bank</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>Count</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>Fee</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>BTC</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>USD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {method.data &&
                    method.data.map((row: any) => {
                      return (
                        <TableRow key={row.bank}>
                          <TableCell style={{ textAlign: 'right' }}>{row.bank}</TableCell>
                          <TableCell style={{ textAlign: 'right' }}>{row.count}</TableCell>
                          <TableCell style={{ textAlign: 'right' }}>${row.fee}</TableCell>
                          <TableCell style={{ textAlign: 'right' }}>{row.btcAmount}</TableCell>
                          <TableCell style={{ textAlign: 'right' }}>${row.usdAmount}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
    </>
  );
}
