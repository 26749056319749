import { connect } from 'react-redux';
import { isLoggedInSelector } from '../../api/user/user.selector';
import { PrivateRoute, PrivateRouteExternalProps, PrivateRouteOwnProps } from './private-route.component';

function mapStateToProps(state: any, ownProps: PrivateRouteOwnProps): PrivateRouteExternalProps & PrivateRouteOwnProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
    component: ownProps.component,
    path: ownProps.path,
  };
}

export const AdminPrivateRouteContainer = connect(mapStateToProps, {})(PrivateRoute);
