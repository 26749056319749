import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export interface ExternalProps {
  open: any;
  descriptionElementRef: any;
  bonus: any;
}

export interface ExternalActionProps {
  handleClose: () => void;
}

export default function ContributionTableDialog(props: ExternalProps & ExternalActionProps) {
  const { open, descriptionElementRef, bonus, handleClose } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        maxWidth='sm'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        style={{ maxHeight: '800px', marginTop: '3rem' }}
      >
        <DialogTitle id='scroll-dialog-title'>
          {bonus != null && bonus.code} - {bonus != null && bonus.description}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Category
                    <br />
                    Type
                  </TableCell>
                  <TableCell>Percent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bonus != null &&
                  bonus.contribution_table.map((row: any) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.type}
                          <br />
                          {row.category}
                        </TableCell>
                        <TableCell>{row.percentage * 1}%</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
