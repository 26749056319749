import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { emptyAgent, IAgents, IAgentsUpdate } from '../../../models/agent';
import { IChartAccount } from '../../../models/chart-account';
import { IPaymentMethods } from '../../../models/payment-methods';
import { AgentTable } from './components/agent-table';
import { CreateAgentDialog } from './components/create-agent-dialog';
import { Loading } from './../../../shared/loading/loading.component';
import { IDistributors } from '../../../models/distributor';
import { IAgentCommStructureUpdate } from '../../../models/agent-comm-structure';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '0rem',
    },
  }),
);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export interface ExternalActionProps {
  getAgentAction: () => void;
  upsertAgentAction: (agent: IAgentsUpdate) => void;
  getDistributorAction: () => void;
  getAgentStructureAction: () => void;
}

export interface ExternalProps {
  agents: IAgents[];
  distributors: IDistributors[];
  chartAccounts: IChartAccount[];
  isLoading: boolean;
  isSaving: boolean;
  paymentMethods: IPaymentMethods[];
  structures: IAgentCommStructureUpdate[];
}

export const AgentsPage = (props: ExternalActionProps & ExternalProps) => {
  const {
    agents,
    distributors,
    getAgentAction,
    getDistributorAction,
    upsertAgentAction,
    getAgentStructureAction,
    chartAccounts,
    isLoading,
    isSaving,
    paymentMethods,
    structures,
  } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState<IAgentsUpdate>({
    ...emptyAgent,
  });
  const classes = useStyles();

  const handleOpen = () => {
    setEditOrAddModal(!editOrAddModal);
  };

  useEffect(() => {
    getAgentAction();
    getDistributorAction();
    getAgentStructureAction();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonMargin}
          size='small'
          onClick={() => handleOpen()}
          disableElevation
        >
          Add New Agent
        </Button>

        <CreateAgentDialog
          open={editOrAddModal}
          distributors={distributors}
          onClose={() => {
            setSelected({
              ...emptyAgent,
            });
            setEditOrAddModal(false);
          }}
          chartAccounts={chartAccounts}
          upsertAgentAction={upsertAgentAction}
          paymentMethods={paymentMethods}
          selected={selected}
          setSelected={setSelected}
          structures={structures}
        />
      </div>
      <AgentTable
        agents={agents}
        username={getSessionUser}
        selected={selected}
        setSelected={setSelected}
        setEditOrAddModal={setEditOrAddModal}
      />
      <Loading loading={isLoading || isSaving} />
    </div>
  );
};
