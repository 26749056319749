import { IChartAccount } from '../../models/chart-account';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'chartAccountAdminSlice';

export interface ChartAccountAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  chartAccounts: IChartAccount[];
}

export const initialState: ChartAccountAdminSliceType = {
  isLoading: false,
  isSaving: false,
  chartAccounts: [],
};

export const chartAccountAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
