import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { IBanks, IBanksUpdate, emptyBank } from '../../../../models/banks';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
  }),
);

export interface ExternalProps {
  banks: IBanks[];
  selected: IBanksUpdate;
  setSelected: (yup: IBanksUpdate) => void;
  setEditOrAddModal: (yup: boolean) => void;
  deleteBanksAction: (bank: IBanksUpdate) => void;
  username: any;
  upsertBankAction: (bank: IBanksUpdate) => void;
}

export const BanksTable = (props: ExternalProps) => {
  const { banks, selected, setSelected, setEditOrAddModal, deleteBanksAction, username, upsertBankAction } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          Banks
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.method}</TableCell>
                    <TableCell>{row.reference}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row.enabled == 1 ? true : false}
                        onClick={() =>
                          upsertBankAction({
                            id: row.id,
                            name: row.name,
                            method: row.method_id,
                            reference: row.reference,
                            enabled: row.enabled == 1 ? 0 : 1,
                            staff: username(),
                            coa_id: row.coa_id,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              name: row.name,
                              method: row.method_id,
                              reference: row.reference,
                              enabled: row.enabled,
                              staff: username(),
                              coa_id: row.coa_id,
                            });
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              name: row.name,
                              method: row.method_id,
                              reference: row.reference,
                              enabled: row.enabled,
                              staff: row.created_by,
                              coa_id: row.coa_id,
                            });
                            setIsDeleting(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component='div'
          count={banks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        maxWidth='md'
        onClose={() => {
          setSelected({ ...emptyBank, staff: username() });
          setIsDeleting(false);
        }}
        open={isDeleting}
      >
        <DialogTitle>Are you sure you want to delete {selected.name}?</DialogTitle>
        <DialogContent>
          {'Deleting cannot be undone. If you want to disactive '}
          <br />
          {'the page, please use the toggle to deactive the page.'}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelected({ ...emptyBank, staff: username() });
              setIsDeleting(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteBanksAction(selected);
              setSelected({ ...emptyBank, staff: username() });
              setIsDeleting(false);
            }}
            variant='contained'
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
