import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Grid, Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loading } from '../../../shared/loading/loading.component';
import { TopSignUpsTable } from './components/top-sign-ups-table.component';
import { TopClaimedTable } from './components/top-claimed-table.component';
import { AllReferralsTable } from './components/all-referrals-table.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  statPaper: {
    padding: '20px',
  },
  separator: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  referralCount: {
    marginTop: '2rem',
  },
});

export interface ExternalActionProps {
  getReferralsAction: (x: any) => void;
}

export interface ExternalProps {
  isLoading: any;
  referrals: any;
  topClaimed: any;
  topSignedUp: any;
  referralsCount: any;
}

const getToday = () => {
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const dateObj = new Date();
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const output = month + '/' + day + '/' + year;

  return output;
};

export const ReferralsPage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoading, referrals, topClaimed, topSignedUp, referralsCount, getReferralsAction } = props;

  // console.log(getToday());

  useEffect(() => {
    getReferralsAction({
      start_date: '06/01/2021',
      end_date: getToday(),
    });
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />

      <Typography variant='h5'>Referrals</Typography>

      <hr className={classes.separator} />

      {!isLoading && (
        <Grid container spacing={2}>
          {/* Top Section Stats */}
          <Grid item xs={12} sm={4}>
            <Paper className={classes.statPaper}>
              <Typography variant='h6' gutterBottom>
                # of Referrals
              </Typography>
              <Typography variant='h3' className={classes.referralCount}>
                {referralsCount}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.statPaper}>
              <Typography variant='h6' gutterBottom>
                Top Sign Ups
              </Typography>
              <TopSignUpsTable stats={topSignedUp} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.statPaper}>
              <Typography variant='h6' gutterBottom>
                Top Referrals Claimed
              </Typography>
              <TopClaimedTable stats={topClaimed} />
            </Paper>
          </Grid>
          {/* Referrals List */}
          <Grid item xs={12}>
            <Paper className={classes.statPaper}>
              <Typography variant='h6'>All Referrals</Typography>
              <hr />
              <AllReferralsTable stats={referrals} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
