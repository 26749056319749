import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TransactionAdminSliceType, transactionAdminReducer } from '../transaction.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { ITransactionVerify } from '../../../models/transactions';

const suffix = `/${transactionAdminReducer.sliceName}/app`;

const VERIFY_LOAD_TRANSACTION = `VERIFY_LOAD_TRANSACTION${suffix}`;
export const verifyLoadTransactionAction = createAction<ITransactionVerify>(VERIFY_LOAD_TRANSACTION);

export const VERIFY_LOAD_TRANSACTION_SUCCESS = `VERIFY_LOAD_TRANSACTION_SUCCESS${suffix}`;
export const verifyLoadTransactionSuccessAction = createAction(VERIFY_LOAD_TRANSACTION_SUCCESS);

const VERIFY_LOAD_TRANSACTION_ERROR = `VERIFY_LOAD_TRANSACTION_ERROR${suffix}`;
export const verifyLoadTransactionErrorAction = createAction<string>(VERIFY_LOAD_TRANSACTION_ERROR);

function* verifyLoadTransactionWatcher(): SagaIterator {
  yield takeEvery(VERIFY_LOAD_TRANSACTION, verifyLoadTransactionWorker);
}
mergeSaga(verifyLoadTransactionWatcher);

function* verifyLoadTransactionWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(verifyLoadTransaction, action.payload);
    }
    yield put(verifyLoadTransactionSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(verifyLoadTransactionErrorAction(e.response.data.message));
  }
}

const verifyLoadTransaction = (transaction: ITransactionVerify): Request => {
  const url = '/cashier/deposit/verify';
  return vipApi(url, 'post', transaction);
};

const reduceHandlers = {
  [VERIFY_LOAD_TRANSACTION]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
    callTransactions: false,
  }),
  [VERIFY_LOAD_TRANSACTION_SUCCESS]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      callTransactions: true,
    };
  },
  [VERIFY_LOAD_TRANSACTION_ERROR]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
    callTransactions: false,
  }),
};

transactionAdminReducer.addActionReducerMap(reduceHandlers);
