import React, { useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Table, TableCell, TableContainer, TableHead, Typography, TableRow, TableBody } from '@mui/material';
import { Loading } from '../../../shared/loading/loading.component';
import { UpsertAgentCommStructureDialog } from './components/upsert-comm-structure-dialog.component';
import { DialogProps } from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { emptyCommStructure, IAgentCommStructureUpdate } from '../../../models/agent-comm-structure';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getAgentStructureAction: () => void;
  upsertAgentCommissionAction: (structure: IAgentCommStructureUpdate) => void;
  deleteAgentCommissionAction: (id: number) => void;
}

export interface ExternalProps {
  isLoading: any;
  structures: any;
}

export const AgentCommStructurePage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoading, structures, getAgentStructureAction, upsertAgentCommissionAction, deleteAgentCommissionAction } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll] = React.useState<DialogProps['scroll']>('paper');
  const [action, setAction] = React.useState('Create');
  const [selected, setSelected] = React.useState<IAgentCommStructureUpdate>({
    ...emptyCommStructure,
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAgentStructureAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='h5'>Commission Structures</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setAction('Create');
                  setSelected({
                    ...emptyCommStructure,
                  });
                  setOpen(true);
                }}
                variant='contained'
              >
                Create Commission Structure
              </Button>
              <UpsertAgentCommStructureDialog
                open={open}
                scroll={scroll}
                handleClose={handleClose}
                action={action}
                selected={selected}
                setSelected={setSelected}
                upsertAgentCommissionAction={upsertAgentCommissionAction}
              />
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && (
                  <>
                    {structures.Length == 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant='h6'>No commission structures found. Please create one.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {structures.map((s: any, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{s.id}</TableCell>
                              <TableCell>{s.name}</TableCell>
                              <TableCell dangerouslySetInnerHTML={{ __html: s.description }}></TableCell>
                              <TableCell>
                                <Button
                                  variant='outlined'
                                  startIcon={<EditIcon />}
                                  sx={{ margin: '.25rem' }}
                                  onClick={() => {
                                    setAction('Edit');
                                    setSelected({
                                      id: s.id,
                                      name: s.name,
                                      tiers: s.tiers,
                                    });
                                    setOpen(true);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant='text'
                                  startIcon={<DeleteIcon />}
                                  sx={{ margin: '.25rem' }}
                                  onClick={() => {
                                    if (confirm('Would you like to delete ' + s.name + ' (ID #' + s.id + ')?')) {
                                      deleteAgentCommissionAction(s.id);
                                    }
                                  }}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};
