import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IAgents } from '../../../models/agent';
import { agentAdminReducer, AgentAdminSliceType } from '../agent.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${agentAdminReducer.sliceName}/app`;

const GET_AGENT_STRUCTURE = `GET_AGENT_STRUCTURE${suffix}`;

export const getAgentStructureAction = createAction(GET_AGENT_STRUCTURE);

const GET_AGENT_STRUCTURE_SUCCESS = `GET_AGENT_STRUCTURE_SUCCESS${suffix}`;
const getAgentStructuresSuccessAction = createAction<IAgents[]>(GET_AGENT_STRUCTURE_SUCCESS);

const GET_AGENT_STRUCTURE_ERROR = `GET_AGENT_STRUCTURE_ERROR${suffix}`;
const getAgentStructuresErrorAction = createAction<string>(GET_AGENT_STRUCTURE_ERROR);

function* getAgentStructuresWatcher(): SagaIterator {
  yield takeEvery(GET_AGENT_STRUCTURE, getAgentStructuresWorker);
}
mergeSaga(getAgentStructuresWatcher);

function* getAgentStructuresWorker(): SagaIterator {
  try {
    const result: IGetAll<IAgents> = yield call(getAgentStructuresApi);
    yield put(getAgentStructuresSuccessAction(result.data));
  } catch (e: any) {
    yield put(getAgentStructuresErrorAction(e));
  }
}

const getAgentStructuresApi = (): Request => {
  return vipApi(`/cashier/agent/commission`, 'get', {});
};

const reduceHandlers = {
  [GET_AGENT_STRUCTURE]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_AGENT_STRUCTURE_SUCCESS]: (slice: AgentAdminSliceType, action: any): AgentAdminSliceType => ({
    ...slice,
    isLoading: false,
    structures: action.payload,
  }),
  [GET_AGENT_STRUCTURE_ERROR]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isLoading: false,
    structures: [],
  }),
};

agentAdminReducer.addActionReducerMap(reduceHandlers);
