import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IChartAccount } from '../../../models/chart-account';
import { chartAccountAdminReducer, ChartAccountAdminSliceType } from '../chart-account.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_CHART_ACCOUNT_SUCCESS } from './upsert-chart-account.action';
import { DELETE_CHART_ACCOUNT_SUCCESS } from './delete-chart-account.action';

const suffix = `/${chartAccountAdminReducer.sliceName}/app`;

const GET_CHART_ACCOUNTS = `GET_CHART_ACCOUNTS${suffix}`;

export const getChartAccountsAction = createAction(GET_CHART_ACCOUNTS);

const GET_CHART_ACCOUNTS_SUCCESS = `GET_CHART_ACCOUNTS_SUCCESS${suffix}`;
const getChartAccountsSuccessAction = createAction<IChartAccount[]>(GET_CHART_ACCOUNTS_SUCCESS);

const GET_CHART_ACCOUNTS_ERROR = `GET_CHART_ACCOUNTS_ERROR${suffix}`;
const getChartAccountsErrorAction = createAction<string>(GET_CHART_ACCOUNTS_ERROR);

function* getChartAccountsWatcher(): SagaIterator {
  yield takeEvery([GET_CHART_ACCOUNTS, UPSERT_CHART_ACCOUNT_SUCCESS, DELETE_CHART_ACCOUNT_SUCCESS], getChartAccountsWorker);
}
mergeSaga(getChartAccountsWatcher);

function* getChartAccountsWorker(): SagaIterator {
  try {
    const result: IGetAll<IChartAccount> = yield call(getChartAccountsApi);
    if (result.message === 'Successfully retrieved all accounts') {
      yield put(getChartAccountsSuccessAction(result.data));
    } else {
      yield put(getChartAccountsErrorAction(result.message));
    }
  } catch (e: any) {
    yield put(getChartAccountsErrorAction(e));
  }
}

const getChartAccountsApi = (): Request => {
  return vipApi(`/cashier/accounts`, 'get', {});
};

const reduceHandlers = {
  [GET_CHART_ACCOUNTS]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_CHART_ACCOUNTS_SUCCESS]: (slice: ChartAccountAdminSliceType, action: any): ChartAccountAdminSliceType => ({
    ...slice,
    isLoading: false,
    chartAccounts: action.payload,
  }),
  [GET_CHART_ACCOUNTS_ERROR]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isLoading: false,
    chartAccounts: [],
  }),
};

chartAccountAdminReducer.addActionReducerMap(reduceHandlers);
