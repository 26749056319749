import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { RuleSliceType, ruleReducer } from '../rule.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${ruleReducer.sliceName}/app`;

const UPSERT_RULE = `UPSERT_RULE${suffix}`;
export const upsertRuleAction = createAction<any>(UPSERT_RULE);

export const UPSERT_RULE_SUCCESS = `UPSERT_RULE_SUCCESS${suffix}`;
export const upsertRuleSuccessAction = createAction<any>(UPSERT_RULE_SUCCESS);

const UPSERT_RULE_ERROR = `UPSERT_RULE_ERROR${suffix}`;
export const upsertRuleErrorAction = createAction<string>(UPSERT_RULE_ERROR);

function* upsertRuleWatcher(): SagaIterator {
  yield takeEvery(UPSERT_RULE, upsertRuleWorker);
}
mergeSaga(upsertRuleWatcher);

function* upsertRuleWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateRule, action.payload);
    } else {
      result = yield call(createRule, action.payload);
    }
    yield put(upsertRuleSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertRuleErrorAction(e.response.data.message));
  }
}

const createRule = (rule: any): Request => {
  const url = '/rules/AddRule';
  return vipApi(url, 'post', rule);
};

const updateRule = (rule: any): Request => {
  const url = '/rules/UpdateRule/' + rule.id;
  return vipApi(url, 'post', rule);
};

const reduceHandlers = {
  [UPSERT_RULE]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [UPSERT_RULE_SUCCESS]: (slice: RuleSliceType): RuleSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPSERT_RULE_ERROR]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

ruleReducer.addActionReducerMap(reduceHandlers);
