import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const ContributionInfoTable = (props: any) => {
  const classes = useStyles();
  const { rows } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Wager Type</TableCell>
            <TableCell>Contribution %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row: { id: string; category: string; type: string; percentage: string }) => (
              <TableRow key={row.id}>
                <TableCell component='th' scope='row'>
                  {row.category}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.percentage}%</TableCell>
              </TableRow>
            ))}
          {rows.length == 0 && (
            <TableRow key='notFound'>
              <TableCell colSpan={8} align='center'>
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
