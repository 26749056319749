import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Transactions, ITransactionUpdate, ITransactionFilterData } from '../../../../models/transactions';
import { IBanks } from '../../../../models/banks';
import TransactionPlayerHistoryDialoge from './transaction-player-history-dialoge';
import TablePagination from '@mui/material/TablePagination';
import { Paper } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WithdrawalRow } from '../components/withdrawal-row';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  formControl: {
    width: '25ch',
  },
  selectClass: {
    '& > div': {
      background: '#eaeaea',
      padding: '7px',
    },
    marginBottom: '15px',
  },
  iconOrder: {
    color: '#bababa',
  },
});

export interface ExternalProps {
  transactions: Transactions[];
  banks: IBanks[];
  updateTransactionAction: (transaction: ITransactionUpdate) => void;
  username: string;
  setSelectedLogs: (actions: any) => void;
  setLogsOpen: () => void;
  totalTransactions: number;
  setFilterData: (filterData: ITransactionFilterData) => void;
  filterData: ITransactionFilterData;
  getTransactionsAction: (filterData: ITransactionFilterData) => void;
}

export const WithdrawalTable = (props: ExternalProps) => {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const {
    transactions,
    banks,
    updateTransactionAction,
    username,
    setSelectedLogs,
    setLogsOpen,
    totalTransactions,
    filterData,
    setFilterData,
    getTransactionsAction,
  } = props;

  const [order, setOrder] = useState('id');
  const [orderBy, setOrderBy] = useState('asc');

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilterData({
      ...filterData,
      page: newPage,
    });
    getTransactionsAction({
      ...filterData,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterData({
      ...filterData,
      page: 0,
      count: parseInt(event.target.value, 10),
    });
    getTransactionsAction({
      ...filterData,
      page: 0,
      count: parseInt(event.target.value, 10),
    });
  };

  const getSort = (transactions: Transactions[]) => {
    const data = transactions.sort((a, b) => {
      if (orderBy == 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
    return data;
  };

  return (
    <Paper>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }}>
                Withdrawal ID
                {order == 'id' && orderBy == 'desc' ? (
                  <ExpandMoreIcon
                    className={classes.iconOrder}
                    onClick={() => {
                      setOrder('id');
                      setOrderBy('asc');
                    }}
                  />
                ) : (
                  <ExpandLessIcon
                    className={classes.iconOrder}
                    onClick={() => {
                      setOrder('id');
                      setOrderBy('desc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell style={{ width: '7%' }}>Submitted At</TableCell>
              <TableCell style={{ width: '10%' }}>Player</TableCell>
              <TableCell style={{ width: '11%' }}>Company Bank</TableCell>
              <TableCell style={{ width: '10%' }}>Player&apos;s Destination Account</TableCell>
              <TableCell style={{ width: '15%' }}>Amount</TableCell>
              <TableCell style={{ width: '16%' }}>Notes</TableCell>
              <TableCell style={{ width: '10%' }}>Status</TableCell>
              <TableCell style={{ width: '13%' }}>Last Action Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSort(transactions).map(row => (
              <WithdrawalRow
                key={row.id}
                row={row}
                handleClickOpen={handleClickOpen}
                banks={banks}
                updateTransactionAction={updateTransactionAction}
                username={username}
                setSelectedLogs={setSelectedLogs}
                setLogsOpen={setLogsOpen}
                transactions={transactions}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={totalTransactions}
        // rowsPerPage={filterData.count}
        rowsPerPage={-1}
        page={filterData.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TransactionPlayerHistoryDialoge isOpen={open} handleClose={handleClose} />
    </Paper>
  );
};
