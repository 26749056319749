import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles({
  root: {},
});

interface ExternalProps {
  getGamesLeague: (x: any) => Promise<void>;
  data: any;
  formNewLeagueGameRule: any;
  setFormNewLeagueGameRule: (x: any) => void;
  setOpenModal: (x: boolean) => void;
}

export const RowLeagues = (props: ExternalProps) => {
  const classes = useStyles();
  const { data, getGamesLeague, formNewLeagueGameRule, setFormNewLeagueGameRule, setOpenModal } = props;
  const [openTLeague, setOpenTLeague] = React.useState(false);

  useEffect(() => {
    console.log('');
  }, []);

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onClick={() => setOpenTLeague(!openTLeague)}
        style={{ cursor: 'pointer' }}
        className={classes.root}
      >
        <TableCell>
          <IconButton aria-label='expand row' size='small'>
            {openTLeague ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          <img src={data.thumb} style={{ width: '20px', height: '20px' }} />
          {data.value}
        </TableCell>
        <TableCell align='right'></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openTLeague} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='right'>LEAGUE</TableCell>
                    <TableCell align='right'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.league &&
                    data.league.map((leag: any, i: number) => (
                      <TableRow key={'leagues' + i + leag.id}>
                        <TableCell component='th' scope='row' style={{ cursor: 'pointer' }} onClick={() => getGamesLeague(leag)}>
                          {leag.desc}
                        </TableCell>
                        <TableCell align='right'>
                          <IconButton
                            aria-label='save'
                            size='small'
                            onClick={() => {
                              formNewLeagueGameRule.game = null;
                              formNewLeagueGameRule.league = leag;
                              formNewLeagueGameRule.description = null;
                              formNewLeagueGameRule.rotation = null;
                              setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                              setOpenModal(true);
                            }}
                          >
                            <SaveIcon fontSize='small' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
