import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ILandingPage } from '../../../models/landing-page';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export interface ExternalProps {
  landingPages: ILandingPage[];
  selected: ILandingPage;
  setSelected: (yup: ILandingPage) => void;
  setEditOrAddModal: (yup: boolean) => void;
  updateActiveLandingPageAction: (landingPage: ILandingPage) => void;
  deleteLandingPageAction: (landingPage: ILandingPage) => void;
}

export const LandingPageTable = (props: ExternalProps) => {
  const { landingPages, selected, setSelected, setEditOrAddModal, updateActiveLandingPageAction, deleteLandingPageAction } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align='right'>Name</TableCell>
              <TableCell align='right'>Title</TableCell>
              <TableCell align='right'>Subtitle</TableCell>
              <TableCell align='right'>Active</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {landingPages.map(row => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link href={`http://lp.pdub.lv/?p=${row.id}`} target='_blank' rel='noopener'>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell align='right'>{row.name}</TableCell>
                  <TableCell align='right'>{row.formTitle}</TableCell>
                  <TableCell align='right'>{row.formSubtitle}</TableCell>
                  <TableCell align='right'>
                    <Switch checked={row.active === 1} onChange={() => updateActiveLandingPageAction(row)} />
                  </TableCell>
                  <TableCell align='right'>
                    <div className={classes.actions}>
                      <IconButton
                        size='small'
                        onClick={() => {
                          setSelected(row);
                          setEditOrAddModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size='small'
                        onClick={() => {
                          setSelected(row);
                          setIsDeleting(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog maxWidth='md' onClose={() => setIsDeleting(false)} open={isDeleting}>
        <DialogTitle>Are you sure you want to delete {selected.name}?</DialogTitle>
        <DialogContent>
          {'Deleting cannot be undone. If you want to disactive '}
          <br />
          {'the page, please use the toggle to deactive the page.'}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleting(false)} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteLandingPageAction(selected);
              setIsDeleting(false);
            }}
            variant='contained'
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
