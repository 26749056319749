import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { tourSectionsAdminReducer, TourSectionsAdminSliceType } from '../tourSections.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { ITourSections } from '../../../models/tour-sections';

const suffix = `/${tourSectionsAdminReducer.sliceName}/app`;

const GET_SECTIONS = `GET_SECTIONS${suffix}`;

export const getSectionsAction = createAction(GET_SECTIONS);

const GET_SECTIONS_SUCCESS = `GET_SECTIONS_SUCCESS${suffix}`;
const getSectionsSuccessAction = createAction<ITourSections[]>(GET_SECTIONS_SUCCESS);

const GET_SECTIONS_ERROR = `GET_SECTIONS_ERROR${suffix}`;
const getSectionsErrorAction = createAction<string>(GET_SECTIONS_ERROR);

function* getSectionsWatcher(): SagaIterator {
  yield takeEvery(GET_SECTIONS, getSectionsWorker);
}
mergeSaga(getSectionsWatcher);

function* getSectionsWorker(): SagaIterator {
  try {
    const result: IGetAll<ITourSections> = yield call(getSectionsApi);
    yield put(getSectionsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getSectionsErrorAction(e));
  }
}

const getSectionsApi = (): Request => {
  return vipApi(`/tours/GetTourSections`, 'get', {});
};

const reduceHandlers = {
  [GET_SECTIONS]: (slice: TourSectionsAdminSliceType): TourSectionsAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_SECTIONS_SUCCESS]: (slice: TourSectionsAdminSliceType, action: any): TourSectionsAdminSliceType => ({
    ...slice,
    isLoading: false,
    sectionsList: action.payload,
  }),
  [GET_SECTIONS_ERROR]: (slice: TourSectionsAdminSliceType): TourSectionsAdminSliceType => ({
    ...slice,
    isLoading: false,
    sectionsList: [],
  }),
};

tourSectionsAdminReducer.addActionReducerMap(reduceHandlers);
