import { IPaymentMethods } from '../../models/payment-methods';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'paymentMethodAdminSlice';

export interface PaymentMethodAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  paymentMethods: IPaymentMethods[];
}

export const initialState: PaymentMethodAdminSliceType = {
  isLoading: false,
  isSaving: false,
  paymentMethods: [],
};

export const paymentMethodAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
