import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { emptyBank, IBanks, IBanksUpdate } from '../../../models/banks';
import { IChartAccount } from '../../../models/chart-account';
import { IPaymentMethods } from '../../../models/payment-methods';
import { BanksTable } from './components/banks-table';
import { CreateBankDialog } from './components/create-bank-dialog';
import { Loading } from './../../../shared/loading/loading.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '0rem',
    },
  }),
);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export interface ExternalActionProps {
  getBanksAction: () => void;
  upsertBankAction: (bank: IBanksUpdate) => void;
  deleteBankAction: (bank: IBanksUpdate) => void;
  getChartAccountsAction: () => void;
  getPaymentMethodsAction: () => void;
}

export interface ExternalProps {
  banks: IBanks[];
  chartAccounts: IChartAccount[];
  isLoading: boolean;
  isSaving: boolean;
  paymentMethods: IPaymentMethods[];
}

export const BanksPage = (props: ExternalActionProps & ExternalProps) => {
  const {
    banks,
    getBanksAction,
    upsertBankAction,
    deleteBankAction,
    getChartAccountsAction,
    chartAccounts,
    isLoading,
    isSaving,
    getPaymentMethodsAction,
    paymentMethods,
  } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState<IBanksUpdate>({
    ...emptyBank,
    staff: getSessionUser(),
  });
  const classes = useStyles();

  const handleOpen = () => {
    setEditOrAddModal(!editOrAddModal);
  };

  useEffect(() => {
    getBanksAction();
    getChartAccountsAction();
    getPaymentMethodsAction();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonMargin}
          size='small'
          onClick={() => handleOpen()}
          disableElevation
        >
          Create
        </Button>

        <CreateBankDialog
          open={editOrAddModal}
          onClose={() => {
            setSelected({
              ...emptyBank,
              staff: getSessionUser(),
            });
            setEditOrAddModal(false);
          }}
          chartAccounts={chartAccounts}
          upsertBankAction={upsertBankAction}
          paymentMethods={paymentMethods}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <BanksTable
        banks={banks}
        selected={selected}
        setSelected={setSelected}
        setEditOrAddModal={setEditOrAddModal}
        deleteBanksAction={deleteBankAction}
        username={getSessionUser}
        upsertBankAction={upsertBankAction}
      />
      <Loading loading={isLoading || isSaving} />
    </div>
  );
};
