import { createSelector } from 'reselect';
import { guideCategoriesReducer, GuideCategoriesSliceType } from './guide-categories.reducer';

export const guideCategoriesSliceSelector = (state: any): any => state[guideCategoriesReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  guideCategoriesSliceSelector,
  (guideCategoriesSlice: GuideCategoriesSliceType) => guideCategoriesSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  guideCategoriesSliceSelector,
  (guideCategoriesSlice: GuideCategoriesSliceType) => guideCategoriesSlice.isSaving,
);

export const guideCategoriesSelector = createSelector(
  guideCategoriesSliceSelector,
  (guideCategoriesSlice: GuideCategoriesSliceType) => guideCategoriesSlice.guideCategories,
);
