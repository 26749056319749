import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  methodFees: any;
}

export const MethodFeesCard = (props: ExternalProps) => {
  const classes = useStyles();
  const { methodFees } = props;

  React.useEffect(() => {
    console.log(methodFees);
  });

  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader
          title='Method Fees'
          subheader='Default Method Fees'
          titleTypographyProps={{ fontSize: '1.2rem' }}
          subheaderTypographyProps={{ fontSize: '.8rem' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {methodFees &&
              methodFees.map((fee: any) => {
                return (
                  <React.Fragment key={fee.id}>
                    <Grid item xs={4}>
                      <img src={fee.method_image} style={{ width: '25px', height: 'auto', marginRight: '.5rem' }} />
                      {fee.method}
                    </Grid>
                    <Grid item xs={4}>
                      {fee.transaction_type}
                    </Grid>
                    <Grid item xs={4}>
                      {fee.percent}%
                    </Grid>
                  </React.Fragment>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
