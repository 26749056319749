import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'bonusesAdminSlice';

export interface BonusesAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  profiles: any;
  bonuses: any;
}

export const initialState: BonusesAdminSliceType = {
  isLoading: false,
  isSaving: false,
  profiles: [],
  bonuses: [],
};

export const bonusesAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
