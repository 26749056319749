import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { ruleReducer, RuleSliceType } from '../rule.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_RULE_SUCCESS } from '../actions/upsert-rule.action';
import { DELETE_RULE_SUCCESS } from '../actions/delete-rule.action';

const suffix = `/${ruleReducer.sliceName}/app`;

const GET_RULE = `GET_RULE${suffix}`;

export const getRuleAction = createAction(GET_RULE);

const GET_RULE_SUCCESS = `GET_RULE_SUCCESS${suffix}`;
const getRuleSuccessAction = createAction<any[]>(GET_RULE_SUCCESS);

const GET_RULE_ERROR = `GET_RULE_ERROR${suffix}`;
const getRuleErrorAction = createAction<string>(GET_RULE_ERROR);

function* getRuleWatcher(): SagaIterator {
  yield takeEvery([GET_RULE, UPSERT_RULE_SUCCESS, DELETE_RULE_SUCCESS], getRuleWorker);
}
mergeSaga(getRuleWatcher);

function* getRuleWorker(): SagaIterator {
  try {
    const result: IGetAll<any> = yield call(getRuleApi);
    yield put(getRuleSuccessAction(result.data));
  } catch (e: any) {
    yield put(getRuleErrorAction(e));
  }
}

const getRuleApi = (): Request => {
  return vipApi(`/rules/GetAllRules`, 'get', {});
};

const reduceHandlers = {
  [GET_RULE]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_RULE_SUCCESS]: (slice: RuleSliceType, action: any): RuleSliceType => ({
    ...slice,
    isLoading: false,
    rules: action.payload,
  }),
  [GET_RULE_ERROR]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isLoading: false,
    rules: [],
  }),
};

ruleReducer.addActionReducerMap(reduceHandlers);
