import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { ICognitoStatus } from '../../../models/player-info/cognito-status';
import { getPlayerInfoAction } from './get-player-info.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const UPDATE_COGNITO_STATUS = `UPDATE_COGNITO_STATUS${suffix}`;

export const updateCognitoStatusAction = createAction<any>(UPDATE_COGNITO_STATUS);

const UPDATE_COGNITO_STATUS_SUCCESS = `UPDATE_COGNITO_STATUS_SUCCESS${suffix}`;
const updateCognitoStatusSuccessAction = createAction<IPlayerInfo[]>(UPDATE_COGNITO_STATUS_SUCCESS);

const UPDATE_COGNITO_STATUS_ERROR = `UPDATE_COGNITO_STATUS_ERROR${suffix}`;
const updateCognitoStatusErrorAction = createAction<string>(UPDATE_COGNITO_STATUS_ERROR);

function* updateCognitoStatusWatcher(): SagaIterator {
  yield takeEvery(UPDATE_COGNITO_STATUS, updateCognitoStatusWorker);
}
mergeSaga(updateCognitoStatusWatcher);

function* updateCognitoStatusWorker(action: any): SagaIterator {
  try {
    console.log('yup');
    const result: IGetAll<IPlayerInfo> = yield call(updateCognitoStatusApi, action.payload);
    yield put(updateCognitoStatusSuccessAction(result.data));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerInfoAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(updateCognitoStatusErrorAction(e.response.data.message));
  }
}

const updateCognitoStatusApi = (c: ICognitoStatus): Request => {
  return vipApi(`/cashier/UpdatePlayerCognitoStatus/${c.id}`, 'post', {
    status: c.status,
  });
};

const reduceHandlers = {
  [UPDATE_COGNITO_STATUS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_COGNITO_STATUS_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [UPDATE_COGNITO_STATUS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
