export interface IPaymentMethods {
  id?: number;
  name: string;
  deposit_min: number;
  deposit_max: number;
  withdrawal_min: number;
  withdrawal_max: number;
  image?: string;
  method_type: number;
  status: number;
}

export interface IPaymentMethodsUpload {
  id?: number;
  name?: string;
  deposit_min: any;
  deposit_max: any;
  withdrawal_min?: any;
  withdrawal_max?: any;
  image?: any;
  method_type?: any;
  status?: number;
}

export const emptyPaymentMethod: IPaymentMethodsUpload = {
  name: '',
  deposit_min: '',
  deposit_max: '',
  withdrawal_min: '',
  withdrawal_max: '',
  method_type: '',
  status: 1,
  image: '',
};
