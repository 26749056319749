import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  root: {},
  textCenter: {
    textAlign: 'center',
  },
});

interface ExternalProps {
  addUpdateRule: (x: any) => Promise<void>;
  data: any;
  leagueRules: any[];
  setLeagueRules: (x: any) => void;
  addUpdateLeagueRule: (x: any) => void;
}

export const RowLeaguesRules = (props: ExternalProps) => {
  const classes = useStyles();
  const { data, addUpdateRule, leagueRules, setLeagueRules, addUpdateLeagueRule } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    console.log('');
  }, []);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ background: '#BCDCFC' }} className={classes.root}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {data.description}
        </TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.total}
            onChange={(event: any) => {
              data.total = Number(event.target.value);
              setLeagueRules([...leagueRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.spread}
            onChange={(event: any) => {
              data.spread = Number(event.target.value);
              setLeagueRules([...leagueRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <TextField
            id='outlined-number'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={data.money_line}
            onChange={(event: any) => {
              data.money_line = Number(event.target.value);
              setLeagueRules([...leagueRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <Checkbox
            checked={data.status == 1}
            name='bonus'
            color='primary'
            onChange={() => {
              if (data.status == 1) data.status = 0;
              else data.status = 1;
              setLeagueRules([...leagueRules]);
            }}
          />
        </TableCell>
        <TableCell align='right'>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={() => {
              data.deleted = 1;
              data.status = 0;
              addUpdateRule(data);
            }}
          >
            <DeleteIcon fontSize='inherit' />
          </IconButton>
          <IconButton
            aria-label='save'
            size='small'
            onClick={() => {
              addUpdateRule(data);
            }}
          >
            <SaveIcon fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ background: '' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Leagues
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>LEAGUE</TableCell>
                    <TableCell align='center'>TOTALS</TableCell>
                    <TableCell align='center'>SPREAD</TableCell>
                    <TableCell align='center'>MONEYLINE</TableCell>
                    <TableCell align='center'>OVERWRITE</TableCell>
                    <TableCell align='center'>STATUS</TableCell>
                    <TableCell align='center'>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.leagues &&
                    data.leagues.map((league: any) => (
                      <TableRow key={league.id}>
                        <TableCell component='th' scope='row'>
                          {league.description}
                        </TableCell>
                        <TableCell align='right'>
                          <TextField
                            id='outlined-number'
                            type='number'
                            inputProps={{ inputMode: 'numeric' }}
                            value={league.total}
                            onChange={(event: any) => {
                              league.total = Number(event.target.value);
                              setLeagueRules([...leagueRules]);
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <TextField
                            id='outlined-number'
                            type='number'
                            inputProps={{ inputMode: 'numeric' }}
                            value={league.spread}
                            onChange={(event: any) => {
                              league.spread = Number(event.target.value);
                              setLeagueRules([...leagueRules]);
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <TextField
                            id='outlined-number'
                            type='number'
                            inputProps={{ inputMode: 'numeric' }}
                            value={league.money_line}
                            onChange={(event: any) => {
                              league.money_line = Number(event.target.value);
                              setLeagueRules([...leagueRules]);
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Checkbox
                            checked={league.overwrite == 1}
                            name='bonus'
                            color='primary'
                            onChange={() => {
                              if (league.overwrite == 1) league.overwrite = 0;
                              else league.overwrite = 1;
                              setLeagueRules([...leagueRules]);
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Checkbox
                            checked={league.status == 1}
                            name='bonus'
                            color='primary'
                            onChange={() => {
                              if (league.status == 1) league.status = 0;
                              else league.status = 1;
                              setLeagueRules([...leagueRules]);
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <IconButton
                            aria-label='delete'
                            size='small'
                            onClick={() => {
                              league.deleted = 1;
                              league.status = 0;
                              addUpdateLeagueRule(league);
                            }}
                          >
                            <DeleteIcon fontSize='inherit' />
                          </IconButton>
                          <IconButton
                            aria-label='save'
                            size='small'
                            onClick={() => {
                              addUpdateLeagueRule(league);
                            }}
                          >
                            <SaveIcon fontSize='small' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
