import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { tourCreatorAdminReducer, TourCreatorAdminSliceType } from '../tourCreator.reducer';
import { vipApi } from '../../../utils/vip-api';
import { ITourCreator, ITourCreatorUpdate } from '../../../models/tour-creator';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${tourCreatorAdminReducer.sliceName}/app`;

const UPDATE_STEPS = `UPDATE_STEPS${suffix}`;
export const updateStepsAction = createAction<ITourCreatorUpdate>(UPDATE_STEPS);

export const UPDATE_STEPS_SUCCESS = `UPDATE_STEPS_SUCCESS${suffix}`;
export const updateStepsSuccessAction = createAction<ITourCreator>(UPDATE_STEPS_SUCCESS);

const UPDATE_STEPS_ERROR = `UPDATE_STEPS_ERROR${suffix}`;
export const updateStepsErrorAction = createAction<string>(UPDATE_STEPS_ERROR);

function* updateStepsWatcher(): SagaIterator {
  yield takeEvery(UPDATE_STEPS, updateStepsWorker);
}
mergeSaga(updateStepsWatcher);

function* updateStepsWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateStepsApi, action.payload);
    } else {
      result = yield call(createStepsApi, action.payload.data);
    }
    yield put(updateStepsSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(updateStepsErrorAction(e));
  }
}

const createStepsApi = (steps: any): Request => {
  return vipApi(`/tours/CreateTour`, 'post', steps, 'json');
};
const updateStepsApi = (action: any): Request => {
  const steps = action.data,
    tourId = action.id;
  return vipApi(`/tours/UpdateTour/${tourId}`, 'post', steps, 'json');
};

const reduceHandlers = {
  [UPDATE_STEPS]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_STEPS_SUCCESS]: (slice: TourCreatorAdminSliceType, action: any): TourCreatorAdminSliceType => {
    const checkInArray = slice.stepsList.filter(item => item.id == action.payload.id);
    return {
      ...slice,
      isLoading: false,
      stepsList:
        checkInArray.length > 0
          ? slice.stepsList.map(item => (item.id == action.payload.id ? action.payload : item))
          : [...slice.stepsList, action.payload],
    };
  },
  [UPDATE_STEPS_ERROR]: (slice: TourCreatorAdminSliceType): TourCreatorAdminSliceType => ({
    ...slice,
    isLoading: false,
    stepsList: [],
  }),
};

tourCreatorAdminReducer.addActionReducerMap(reduceHandlers);
