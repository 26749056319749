import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
// import { getAppSettings } from '../../../../utils/app-settings.util';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    padding: 5,
  },
  column50: {
    width: '50%',
    padding: 5,
  },
  imageInput: {
    display: 'none',
  },
  image: {
    maxHeight: 150,
    maxWidth: 150,
  },
});

export interface ExternalProps {
  label: string;
  imgUrl?: string;
  imgFile?: File;
}

export interface ExternalActionProps {
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ImageUploader = (props: ExternalProps & ExternalActionProps) => {
  const { label, imgUrl, imgFile, onImageChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.column50}>
        <Button variant='contained' component='label'>
          <input accept='image/*' className={classes.imageInput} id='header-image' type='file' onChange={onImageChange} />
          {label}
        </Button>
      </div>
      <div className={classes.column50}>
        {!imgFile && !imgUrl && <Typography variant='caption'>No image uploaded</Typography>}
        {imgFile && <img className={classes.image} src={URL.createObjectURL(imgFile)} alt={imgFile.name} />}
        {!imgFile && imgUrl && <img className={classes.image} src={`${imgUrl}`} alt='' />}
      </div>
    </div>
  );
};
