import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar } from '../app-bar/app-bar.component';
import { Routes } from '../../routes';
import { LeftNav } from '../left-nav/left-nav.component';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const appBarHeight = '64px';
const drawerWidth = 230;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    marginTop: appBarHeight,
    // height: `calc(100% - ${appBarHeight})`,
  },
  leftNav: {
    marginLeft: 0,
  },
  leftNavExpanded: {
    marginLeft: drawerWidth,
  },
});

export interface AppRootProps {
  isLoggedIn: boolean;
  user: any;
}

export const AppRoot = ({ isLoggedIn, user }: AppRootProps) => {
  const classes = useStyles();
  function getMediaLeftNav(): boolean {
    return window.innerWidth >= 1025;
  }
  const [isLeftNavOpen, setIsLeftNavOpen] = React.useState(getMediaLeftNav());

  return (
    <div className={classes.root}>
      {isLoggedIn && <AppBar handleDrawerOpen={setIsLeftNavOpen} open={isLeftNavOpen} user={user} />}
      {isLoggedIn && <LeftNav open={isLeftNavOpen} setOpen={setIsLeftNavOpen} user={user} />}
      <div
        className={clsx({
          [classes.content]: isLoggedIn,
          [classes.leftNav]: !isLeftNavOpen && isLoggedIn,
          [classes.leftNavExpanded]: isLeftNavOpen && isLoggedIn,
        })}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Routes />
        </LocalizationProvider>
      </div>
      <ReduxToastr timeOut={2000} transitionIn='fadeIn' transitionOut='fadeOut' />
    </div>
  );
};
