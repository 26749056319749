import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { clearSearchedPlayerAction } from './clear-searched-player.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_NOTES = `GET_PLAYER_NOTES${suffix}`;

export const getPlayerNotesAction = createAction<any>(GET_PLAYER_NOTES);

const GET_PLAYER_NOTES_SUCCESS = `GET_PLAYER_NOTES_SUCCESS${suffix}`;
const getPlayerNotesSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_NOTES_SUCCESS);

const GET_PLAYER_NOTES_ERROR = `GET_PLAYER_NOTES_ERROR${suffix}`;
const getPlayerNotesErrorAction = createAction<string>(GET_PLAYER_NOTES_ERROR);

function* getPlayerNotesWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_NOTES, getPlayerNotesWorker);
}
mergeSaga(getPlayerNotesWatcher);

function* getPlayerNotesWorker(action: any): SagaIterator {
  clearSearchedPlayerAction();
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerNotesApi, action.payload);
    yield put(getPlayerNotesSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerNotesErrorAction(e.response.data.message));
  }
}

const getPlayerNotesApi = (x: any): Request => {
  return vipApi(`/cashier/GetNotesForPlayer?player_number=` + x.player_number, 'get', {});
};

const reduceHandlers = {
  [GET_PLAYER_NOTES]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_NOTES_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      playerNotes: action.payload,
    };
  },
  [GET_PLAYER_NOTES_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
