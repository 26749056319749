import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { emptyTourCreator, ITourCreator, ITourCreatorUpdate } from '../../models/tour-creator';
import { StepsTable } from './components/steps-table';
import { Loading } from './../../shared/loading/loading.component';
import { ITourSections } from '../../models/tour-sections';
import { CreateTourDialog } from './components/create-tour-dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '0rem',
    },
  }),
);

export interface ExternalActionProps {
  getStepsAction: () => void;
  getSectionsAction: () => void;
  updateStepsAction: (tour: any) => void;
  deleteStepsAction: (tour: ITourCreatorUpdate) => void;
}

export interface ExternalProps {
  stepsList: ITourCreator[];
  sectionsList: ITourSections[];
  isLoading: boolean;
  isSaving: boolean;
}

export const TourCreatorPage = (props: ExternalActionProps & ExternalProps) => {
  const { stepsList, sectionsList, getStepsAction, getSectionsAction, updateStepsAction, deleteStepsAction, isLoading, isSaving } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState<ITourCreatorUpdate>({
    ...emptyTourCreator,
  });
  const classes = useStyles();

  useEffect(() => {
    getStepsAction();
    getSectionsAction();
  }, []);

  const handleOpen = () => {
    setEditOrAddModal(!editOrAddModal);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonMargin}
          size='small'
          onClick={() => {
            setSelected({ ...emptyTourCreator });
            handleOpen();
          }}
          disableElevation
        >
          Add New Tour
        </Button>
        <CreateTourDialog
          open={editOrAddModal}
          onClose={() => {
            setSelected({
              ...emptyTourCreator,
            });
            setEditOrAddModal(false);
          }}
          sectionsList={sectionsList}
          updateStepsAction={updateStepsAction}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <StepsTable
        stepsList={stepsList}
        setEditOrAddModal={setEditOrAddModal}
        deleteStepsAction={deleteStepsAction}
        setSelected={setSelected}
        selected={selected}
      />
      <Loading loading={isLoading || isSaving} />
    </div>
  );
};
