import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IRuleCategoryUpdate, IRuleCategory } from '../../../models/rule-categories';
import { RuleCategoriesSliceType, ruleCategoriesReducer } from '../rule-categories.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${ruleCategoriesReducer.sliceName}/app`;

const UPSERT_RULE_CATEGORY = `UPSERT_RULE_CATEGORY${suffix}`;
export const upsertRuleCategoryAction = createAction<IRuleCategoryUpdate>(UPSERT_RULE_CATEGORY);

export const UPSERT_RULE_CATEGORY_SUCCESS = `UPSERT_RULE_CATEGORY_SUCCESS${suffix}`;
export const upsertRuleCategorySuccessAction = createAction<IRuleCategory>(UPSERT_RULE_CATEGORY_SUCCESS);

const UPSERT_RULE_CATEGORY_ERROR = `UPSERT_RULE_CATEGORY_ERROR${suffix}`;
export const upsertRuleCategoryErrorAction = createAction<string>(UPSERT_RULE_CATEGORY_ERROR);

function* upsertRuleCategoryWatcher(): SagaIterator {
  yield takeEvery(UPSERT_RULE_CATEGORY, upsertRuleCategoryWorker);
}
mergeSaga(upsertRuleCategoryWatcher);

function* upsertRuleCategoryWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateRuleCategory, action.payload);
    } else {
      result = yield call(createRuleCategory, action.payload);
    }
    yield put(upsertRuleCategorySuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertRuleCategoryErrorAction(e.response.data.message));
  }
}

const createRuleCategory = (ruleCategory: IRuleCategoryUpdate): Request => {
  const url = '/rules/AddRuleCategory';
  return vipApi(url, 'post', ruleCategory);
};

const updateRuleCategory = (ruleCategory: IRuleCategoryUpdate): Request => {
  const url = '/rules/UpdateRuleCategory/' + ruleCategory.id;
  return vipApi(url, 'post', ruleCategory);
};

const reduceHandlers = {
  [UPSERT_RULE_CATEGORY]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isLoading: true,
    isSaving: true,
    isSavingCategory: true,
  }),
  [UPSERT_RULE_CATEGORY_SUCCESS]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => {
    return {
      ...slice,
      isSaving: false,
      isSavingCategory: false,
    };
  },
  [UPSERT_RULE_CATEGORY_ERROR]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
    isSavingCategory: false,
  }),
};

ruleCategoriesReducer.addActionReducerMap(reduceHandlers);
