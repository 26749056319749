export interface ILandingPage {
  id?: number;
  type: string;
  name: string;
  headerImg: string;
  images: string;
  footerImg: string;
  formTitle: string;
  formSubtitle: string;
  formFooterText: string;
  footerTitle: string;
  footerContent: string;
  campaign: string;
  active: number;
}

export interface ILandingPageUpload {
  id?: number;
  type: string;
  name: string;
  headerImg?: File;
  images?: File;
  footerImg?: File;
  formTitle: string;
  formSubtitle: string;
  formFooterText: string;
  footerTitle: string;
  footerContent: string;
  campaign: string;
  active: number;
}

export const emptyLandingPage: ILandingPage = {
  type: '',
  name: '',
  headerImg: '',
  images: '',
  footerImg: '',
  formTitle: '',
  formSubtitle: '',
  formFooterText: '',
  footerTitle: '',
  footerContent: '',
  campaign: '',
  active: 1,
};
