import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { bonusesAdminReducer, BonusesAdminSliceType } from '../bonuses.reducer';

const suffix = `/${bonusesAdminReducer.sliceName}/app`;

const UPDATE_BONUS = `UPDATE_BONUS${suffix}`;

export const updateBonusAction = createAction(UPDATE_BONUS);

const UPDATE_BONUS_SUCCESS = `UPDATE_BONUS_SUCCESS${suffix}`;
const updateBonusSuccessAction = createAction<any>(UPDATE_BONUS_SUCCESS);

const UPDATE_BONUS_ERROR = `UPDATE_BONUS_ERROR${suffix}`;
const updateBonusErrorAction = createAction<string>(UPDATE_BONUS_ERROR);

function* updateBonusWatcher(): SagaIterator {
  yield takeEvery(UPDATE_BONUS, updateBonusWorker);
}
mergeSaga(updateBonusWatcher);

function* updateBonusWorker(action: any): SagaIterator {
  try {
    const result = yield call(updateBonusApi, action.payload);
    yield put(updateBonusSuccessAction(result));
  } catch (e: any) {
    yield put(updateBonusErrorAction('Error Occures!!'));
  }
}

const updateBonusApi = (x: any): Request => {
  return vipApi(`/cashier/bonus/${x.id}`, 'post', x);
};

const reduceHandlers = {
  [UPDATE_BONUS]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_BONUS_SUCCESS]: (slice: BonusesAdminSliceType, action: any): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    bonuses: action.payload.data,
  }),
  [UPDATE_BONUS_ERROR]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

bonusesAdminReducer.addActionReducerMap(reduceHandlers);
