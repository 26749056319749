export interface IAgentCommStructure {
  id?: number;
  name: string;
  tiers: [];
}

export interface IAgentCommStructureUpdate {
  id?: number;
  name: string;
  tiers: any;
}

export const emptyCommStructure: IAgentCommStructureUpdate = {
  name: '',
  tiers: [{ max_players: 10, type: 0, payout: 1, percent: 5 }],
};
