export interface IBonus {
  id?: any;
  category: string;
  code: string;
  description: string;
  start_date: string | null;
  end_date: string | null;
  min_deposit: string;
  max_deposit: string;
  match_multiplier: string;
  rollover_multiplier: string;
  contribution_id: string;
  ft_bonus_id: string;
  recurring: string;
  active: string;
  image?: any;
  terms?: string;
  details?: string;
}

export const emptyBonus: IBonus = {
  category: '',
  code: '',
  description: '',
  start_date: null,
  end_date: null,
  min_deposit: '',
  max_deposit: '',
  match_multiplier: '',
  rollover_multiplier: '',
  contribution_id: '',
  ft_bonus_id: '',
  recurring: '',
  active: '',
  image: undefined,
  terms: '',
  details: '',
};
