import React from 'react';
import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export interface ExternalProps {
  profile: any;
  show: any;
}

export const ProfileContributionTable = (props: ExternalProps) => {
  const { profile, show } = props;

  return (
    <>
      <Collapse in={show}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Category
                <br />
                Type
              </TableCell>
              <TableCell>Percent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {console.log(profile.contribution_table)} */}
            {profile.contribution_table &&
              profile.contribution_table.map((row: any) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.type}
                      <br />
                      {row.category}
                    </TableCell>
                    <TableCell>{row.percentage * 1}%</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
};
