import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import configureStore from './store';
import { setAppSettings } from './utils/app-settings.util';
import { setupMock } from './app.mock';
import { axiosHelper } from './utils/axios/axios.helper';
import { setHistory } from './utils/history.util';
import { AppRootContainer } from './shared/app-root/app-root.container';

import 'fontsource-roboto';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { ConfirmProvider } from 'material-ui-confirm';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export function main(appId: string, appSettings: any): any {
  setAppSettings(appSettings);
  const appAxios = Axios.create();
  axiosHelper.setAxios(appAxios);

  const isMock = window && window.location.href.split('#')[0].indexOf('mock=1') !== -1;
  if (isMock) {
    // Use mock data
    setupMock(appAxios);
  }

  const history = setHistory();

  const initialState: any = {};
  const store: any = configureStore(initialState, history);

  const MOUNT_NODE = document.getElementById('root');
  if (!MOUNT_NODE) {
    return;
  }

  const render = (): void => {
    ReactDOM.render(
      <Provider store={store}>
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: { autoFocus: true },
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ConnectedRouter history={history}>
                <AppRootContainer />
              </ConnectedRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </ConfirmProvider>
      </Provider>,
      MOUNT_NODE,
    );
  };

  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept('./shared/app-root/app-root.component', () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      });
    }
  }

  render();
}
