import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'userSlice';

export interface UserSliceType {
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: string;
  loggedInUser?: any;
  role: string | undefined;
  dashboardDeposits: any;
  dashboardWithdrawals: any;
  dashboardSignUps: any;
  dashboardReferrals: any;
  dashboardDate: any;
  dashboardPackages: any;
}

const getSessionToken = () => {
  const token = localStorage.getItem('token');

  if (token !== null && token !== '') {
    return true;
  } else {
    return false;
  }
};

const getSessionUser = () => {
  const user = localStorage.getItem('user');
  if (user !== '' && user !== null) {
    return JSON.parse(user);
  } else {
    return {};
  }
};

export const initialState: UserSliceType = {
  isLoading: false,
  isLoggedIn: getSessionToken(),
  error: undefined,
  loggedInUser: getSessionUser(),
  role: undefined,
  dashboardDeposits: [],
  dashboardWithdrawals: [],
  dashboardSignUps: [],
  dashboardReferrals: [],
  dashboardDate: '',
  dashboardPackages: [],
};

export const userReducer = createAndMergeSliceReducer(sliceName, initialState);
