import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';
import { ITransactionVerify } from '../../../../models/transactions';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface ExternalProps {
  open: boolean;
  selected: ITransactionVerify;
}

export interface ExternalActionProps {
  onClose: () => void;
  verifyLoadTransactionAction: (transaction: ITransactionVerify) => void;
  setSelected: (transaction: ITransactionVerify) => void;
}

export const VerifyLoadDialoge = (props: ExternalProps & ExternalActionProps) => {
  const { verifyLoadTransactionAction, onClose, open, selected, setSelected } = props;

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperComponent={PaperComponent} aria-labelledby='draggable-dialog-title' maxWidth='xs'>
        <DialogTitle id='draggable-dialog-title'>Verify</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            verifyLoadTransactionAction(selected);
          }}
        >
          <DialogContent>
            <DialogContentText>Please fill out the pin to verify.</DialogContentText>

            <TextField
              autoFocus
              margin='dense'
              id='pin'
              label='Pin'
              type='number'
              value={selected.pin}
              onChange={event =>
                setSelected({
                  ...selected,
                  pin: event.target.value as unknown as string,
                })
              }
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant='contained' color='primary' type='submit'>
              Verify
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
