import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IChangePwd } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const CHANGE_PASSWORD = `CHANGE_PASSWORD${suffix}`;

export const changePasswordAction = createAction<string>(CHANGE_PASSWORD);

const CHANGE_PASSWORD_SUCCESS = `CHANGE_PASSWORD_SUCCESS${suffix}`;
const changePasswordSuccessAction = createAction<IChangePwd[]>(CHANGE_PASSWORD_SUCCESS);

const CHANGE_PASSWORD_ERROR = `CHANGE_PASSWORD_ERROR${suffix}`;
const changePasswordErrorAction = createAction<string>(CHANGE_PASSWORD_ERROR);

function* changePasswordWatcher(): SagaIterator {
  yield takeEvery(CHANGE_PASSWORD, changePasswordWorker);
}
mergeSaga(changePasswordWatcher);

function* changePasswordWorker(action: any): SagaIterator {
  console.log('changePasswordWorker');
  try {
    const result = yield call(changePasswordApi, action.payload);
    yield put(changePasswordSuccessAction(result));
    yield call(showToast, 'Password changed successfully.', 'success');
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(changePasswordErrorAction(e.response.data.message));
  }
}

const changePasswordApi = (IChangePwd: IChangePwd): Request => {
  return vipApi(`/cashier/changePassword/${IChangePwd.id}`, 'post', {
    password: IChangePwd.password,
    confirm_password: IChangePwd.confirm_password,
  });
};

const reduceHandlers = {
  [CHANGE_PASSWORD]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CHANGE_PASSWORD_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    playerChangePassword: action.payload,
  }),
  [CHANGE_PASSWORD_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
