import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { Loading } from '../../../shared/loading/loading.component';
import { ProfilePaper } from './components/profile-paper-component';
import CreateProfileDialog from './components/create-profile-dialog.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getBonusProfilesAction: () => void;
  createBonusProfileAction: (x: any) => void;
}

export interface ExternalProps {
  profiles: any;
  isLoading: any;
}

export const BonusProfilesPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getBonusProfilesAction, profiles, isLoading, createBonusProfileAction } = props;

  useEffect(() => {
    getBonusProfilesAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <CreateProfileDialog isLoading={isLoading} createBonusProfileAction={createBonusProfileAction} />

      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

      <Loading loading={isLoading} />
      {!isLoading && (
        <Grid container spacing={4}>
          {profiles.map((profile: any) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={profile.id}>
                <ProfilePaper profile={profile} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};
