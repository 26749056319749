import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'appSettingsSlice';

export interface appSettingsSliceType {
  isLoading: boolean;
  feeFree: [];
  communications: [];
  methodFees: [];
}

export const initialState: appSettingsSliceType = {
  isLoading: false,
  feeFree: [],
  communications: [],
  methodFees: [],
};

export const appSettingsSliceReducer = createAndMergeSliceReducer(sliceName, initialState);
