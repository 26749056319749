import { IGuideCategory } from '../../models/guide-categories';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'guideSlice';

export interface GuideSliceType {
  isLoading: boolean;
  isSaving: boolean;
  guides: IGuideCategory[];
}

export const initialState: GuideSliceType = {
  isLoading: false,
  isSaving: false,
  guides: [],
};

export const guideReducer = createAndMergeSliceReducer(sliceName, initialState);
