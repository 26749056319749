import React from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Transactions, ITransactionUpdate, emptyUpdate } from '../../../../models/transactions';
import { IBanks } from '../../../../models/banks';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  formControl: {
    width: '25ch',
  },
  selectClass: {
    '& > div': {
      background: '#eaeaea',
      padding: '7px',
    },
    marginBottom: '15px',
  },
  iconOrder: {
    color: '#bababa',
  },
});

const toPrettyDate = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  const monthFormat = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const dateFormat = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const formatedDate = `${d.getFullYear()}-${monthFormat}-${dateFormat}`;
  const hourFormat = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minuteFormat = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const formatedTime = `${hourFormat}:${minuteFormat}:00`;
  return formatedDate + ' ' + formatedTime;
};

function splitBTC(btc: string) {
  if (!btc || btc == '') {
    return;
  }
  return btc.substr(0, 3) + '...' + btc.substr(btc.length - 5);
}

const statuses = [
  {
    status: 'Expired',
    id: 0,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [{ status: 'Expired', id: 0 }],
  },
  {
    status: 'Pending',
    id: 1,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Require Action', id: 4 },
      { status: 'Complete', id: 6 },
      { status: 'Reverse', id: 7 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Nudge',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 2,
    list: [{ status: 'Nudge', id: 2 }],
  },
  {
    status: 'NudgeX2',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 3,
    list: [{ status: 'NudgeX2', id: 3 }],
  },
  {
    status: 'Action Required',
    id: 4,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Complete', id: 6 },
      { status: 'Reverse', id: 7 },
      { status: 'Mark as Testing', id: -1 },
      { status: 'Cancel', id: 8 },
    ],
  },
  {
    status: 'Unverified',
    id: 5,
    style: { backgroundColor: '#d85645', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Verify', id: 6 },
      { status: 'Expire', id: 0 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Completed',
    id: 6,
    style: { backgroundColor: '#08703a', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Reverse', id: 7 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Reversed',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 7,
    list: [{ status: 'Reverse', id: 7 }],
  },
  {
    status: 'Canceled',
    style: { backgroundColor: '#eaeaea', color: '#000', fontWeight: 800, marginBottom: '10px' },
    id: 8,
    list: [{ status: 'Cancel', id: 8 }],
  },
  {
    status: 'Testing',
    style: { backgroundColor: '#eaeaea', color: '#000', fontWeight: 800, marginBottom: '10px' },
    id: -1,
    list: [{ status: 'Marked as Testing', id: -1 }],
  },
];

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export function WithdrawalRow(props: {
  row: Transactions;
  handleClickOpen: any;
  banks: IBanks[];
  updateTransactionAction: (transaction: ITransactionUpdate) => void;
  username: string;
  setSelectedLogs: (actions: any) => void;
  setLogsOpen: () => void;
  transactions: any;
}) {
  const { row, banks, updateTransactionAction, username, setSelectedLogs, setLogsOpen } = props;
  const [update, setUpdate] = React.useState<ITransactionUpdate>(emptyUpdate);
  const classes = useRowStyles();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const action = row.actions && row.actions.length > 0 ? row.actions[row.actions.length - 1] : {};
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  function handleCopyToClipboard(btc: string) {
    navigator.clipboard.writeText(btc);
    setTooltipOpen(true);
  }

  const capsFirst = (string: string) => {
    if (string != null && string != '') {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  };

  const manageStatus = (data: { id: number; notes: string; status: number; staff: string; amount: number; exchange_rate: any }) => {
    const { id, notes, status, staff, amount, exchange_rate } = data;
    const dataToSend = {
      id,
      notes,
      status,
      staff,
    };
    if (status == 6) {
      Object.assign(dataToSend, { amount }, { exchange_rate });
    }
    updateTransactionAction(dataToSend);
  };

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <StyledTableCell component='th' scope='row'>
          {row.id}
        </StyledTableCell>
        <StyledTableCell>{toPrettyDate(row.created_at)}</StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_number}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_first_name + ' ' + row.player_last_name}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_email}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_phone}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='select-bank'>Bank</InputLabel>
            <Select
              labelId='select-bank'
              id='select-bank'
              className={classes.selectClass}
              value={row.bank_id}
              label='Bank'
              onChange={e => {
                updateTransactionAction({
                  id: row.id,
                  status: row.status,
                  notes: row.notes == null ? '' : row.notes,
                  bank: e.target.value as unknown as number,
                  account: row.method == 'Bitcoin' ? row.address : row.account,
                  staff: username,
                });
                setUpdate(emptyUpdate);
              }}
            >
              <MenuItem value={0}>
                <em>Bank</em>
              </MenuItem>
              {banks
                .filter(item => item.method == row.method)
                .map(item => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </StyledTableCell>
        <StyledTableCell align='center'>
          {row.method == '(Instant) Pay' && (
            <>
              {row.amount_received != null ? (
                <>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Total Amount:</strong> ${row.amount}
                  </Typography>
                  <br />
                </>
              ) : (
                <>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Total Amount:</strong> ${row.parent_amount}
                  </Typography>
                  <br />
                </>
              )}
            </>
          )}

          {row.method}
          <div style={{ display: 'd-flex' }}>
            <a href={`https://mempool.space/address/${row.address}`} target='_blank' rel='noreferrer'>
              <Chip label={splitBTC(row.account)} />
            </a>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title='Copied'
              >
                <Chip clickable label={<FileCopyIcon color='primary' />} onClick={() => handleCopyToClipboard(row.account)} />
              </Tooltip>
            </ClickAwayListener>
          </div>
        </StyledTableCell>
        <StyledTableCell>
          {row.method == '(Instant) Pay' ? (
            <>
              {row.amount_received != null ? (
                <>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Initiated:</strong> ${row.amount}
                  </Typography>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Fee:</strong> ${row.fee}
                  </Typography>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>To Pay:</strong> ${row.amount_received}
                  </Typography>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>
                      <Chip
                        style={
                          row.amount_received == row.amount - row.fee
                            ? { backgroundColor: 'green', color: 'white' }
                            : { backgroundColor: 'orange', color: 'white' }
                        }
                        label={
                          row.amount_received == row.amount - row.fee
                            ? 'Fully Paid: $' + row.amount_received
                            : 'Partially Paid: $' + row.amount_received
                        }
                      />
                    </strong>{' '}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Initiated:</strong> ${row.amount}
                  </Typography>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>Fee:</strong> ${row.fee}
                  </Typography>
                  <Typography variant='subtitle2' gutterBottom>
                    <strong>
                      <Chip style={{ backgroundColor: 'red', color: '#fff' }} label={'Total to be Paid: $' + row.totalToSend} />
                    </strong>
                  </Typography>
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant='subtitle2' gutterBottom>
                <strong>Initiated:</strong> ${row.amount}
              </Typography>
              <Typography variant='subtitle2' gutterBottom>
                <strong>Fee:</strong> ${row.fee}
              </Typography>
              <Typography variant='subtitle2' gutterBottom>
                <strong>{row.status == 6 ? `Sent: $${row.amount_received}` : `To Send: $${row.totalToSend}`}</strong>
                <br />
                <strong>BTC: </strong> {row.totalToSendBtc}
              </Typography>
            </>
          )}
        </StyledTableCell>
        <StyledTableCell>
          <TextField
            defaultValue={row.notes}
            multiline
            variant='outlined'
            onChange={event =>
              setUpdate({
                id: row.id,
                notes: event.target.value,
                status: row.status,
                staff: username,
              })
            }
          />
          <Chip
            label='Save'
            color='primary'
            clickable
            // && update.id == row.id ? false : true
            disabled={update.id == row.id ? false : true}
            style={{ marginTop: '5px' }}
            onClick={() => updateTransactionAction(update)}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Chip
            label={row.status == -1 ? statuses[9].status : statuses[row.status].status}
            style={row.status == -1 ? statuses[9].style : statuses[row.status].style}
          />
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>Action</InputLabel>
            <Select
              className={classes.selectClass}
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              label='Action'
              value={row.status}
              onChange={event => {
                manageStatus({
                  id: row.id,
                  notes: row.notes == null ? '' : row.notes,
                  status: event.target.value as unknown as number,
                  staff: username,
                  amount: row.method == 'Bitcoin' ? row.totalToSendBtc : row.totalToSend,
                  exchange_rate: row.exchangeRate,
                });
              }}
            >
              <MenuItem value={0}>
                <em>Status</em>
              </MenuItem>
              {row.status == -1
                ? statuses[9].list.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.status}
                      </MenuItem>
                    );
                  })
                : statuses[row.status].list.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.status}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{capsFirst(action.username)}</Typography>
          <Typography variant='subtitle1'>{action.timestamp}</Typography>
          <Typography variant='subtitle1'>{capsFirst(action.action)}</Typography>
          <Chip
            label='See More'
            disabled={row.actions && row.actions.length > 1 ? false : true}
            onClick={() => {
              setSelectedLogs(row.actions);
              setLogsOpen();
            }}
            color='primary'
          />
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
