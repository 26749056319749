import { createSelector } from 'reselect';
import { playerInfoReducer, PlayerInfoSliceType } from './player-info.reducer';

export const playerInfoSliceSelector = (state: any): any => state[playerInfoReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.isLoading,
);

export const playerInfoSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.playerInfo,
);

export const playerSearchedSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.playerSearched,
);

export const playerChangePasswordSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.playerChangePassword,
);

export const playerCognitoDataSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.cognitoData,
);

export const playerRightsSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.rights,
);

export const playerBonusHistorySelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.bonusHistory,
);

export const playerDepositsSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.deposits,
);

export const playerWithdrawalsSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.withdrawals,
);

export const playerPendingWagersSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.pendingWagers,
);

export const playerWagerHistorySelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.wagerHistory,
);

export const playerRafHistorySelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.rafHistory,
);

export const playerNotesSelector = createSelector(
  playerInfoSliceSelector,
  (playerInfoSlice: PlayerInfoSliceType) => playerInfoSlice.playerNotes,
);
