export interface IRuleCategory {
  id?: number;
  name: string;
}

export interface IRuleCategoryUpdate {
  id?: number;
  name: string;
}

export const emptyRuleCategory: IRuleCategoryUpdate = {
  name: '',
};
