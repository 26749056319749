import { IAgents } from '../../models/agent';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'agentAdminSlice';

export interface AgentAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  agents: IAgents[];
  structures: [];
  payouts: [];
}

export const initialState: AgentAdminSliceType = {
  isLoading: false,
  isSaving: false,
  agents: [],
  structures: [],
  payouts: [],
};

export const agentAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
