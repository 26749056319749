import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GuideSliceType, guideReducer } from '../guide.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${guideReducer.sliceName}/app`;

const DELETE_GUIDE = `DELETE_GUIDE${suffix}`;
export const deleteGuideAction = createAction<number>(DELETE_GUIDE);

export const DELETE_GUIDE_SUCCESS = `DELETE_GUIDE_SUCCESS${suffix}`;
export const deleteGuideSuccessAction = createAction<number>(DELETE_GUIDE_SUCCESS);

const DELETE_GUIDE_ERROR = `DELETE_GUIDE_ERROR${suffix}`;
export const deleteGuideErrorAction = createAction<string>(DELETE_GUIDE_ERROR);

function* deleteGuideWatcher(): SagaIterator {
  yield takeEvery(DELETE_GUIDE, deleteGuideWorker);
}
mergeSaga(deleteGuideWatcher);

function* deleteGuideWorker(action: any): SagaIterator {
  try {
    yield call(deleteGuide, action.payload);
    yield put(deleteGuideSuccessAction(action.payload));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteGuideErrorAction(e.response.data.message));
  }
}

const deleteGuide = (id: number): Request => {
  const url = '/guides/DeleteGuide/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_GUIDE]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [DELETE_GUIDE_SUCCESS]: (slice: GuideSliceType): GuideSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_GUIDE_ERROR]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

guideReducer.addActionReducerMap(reduceHandlers);
