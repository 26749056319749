import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_PENDING_WAGERS = `GET_PLAYER_PENDING_WAGERS${suffix}`;

export const getPlayerPendingWagersAction = createAction<any>(GET_PLAYER_PENDING_WAGERS);

const GET_PLAYER_PENDING_WAGERS_SUCCESS = `GET_PLAYER_PENDING_WAGERS_SUCCESS${suffix}`;
const getPlayerPendingWagersSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_PENDING_WAGERS_SUCCESS);

const GET_PLAYER_PENDING_WAGERS_ERROR = `GET_PLAYER_PENDING_WAGERS_ERROR${suffix}`;
const getPlayerPendingWagersErrorAction = createAction<string>(GET_PLAYER_PENDING_WAGERS_ERROR);

function* getPlayerPendingWagersWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_PENDING_WAGERS, getPlayerPendingWagersWorker);
}
mergeSaga(getPlayerPendingWagersWatcher);

function* getPlayerPendingWagersWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerPendingWagersApi, action.payload);
    yield put(getPlayerPendingWagersSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerPendingWagersErrorAction(e.response.data.message));
  }
}

const getPlayerPendingWagersApi = (string: any): Request => {
  return vipApi(`/cashier/getPendingWagers/${string}`, 'post', {});
};

const reduceHandlers = {
  [GET_PLAYER_PENDING_WAGERS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_PENDING_WAGERS_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      pendingWagers: action.payload,
    };
  },
  [GET_PLAYER_PENDING_WAGERS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
