import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function NewlineText(text: string) {
  const newText = text.split('\n').map((str: any) => <p key={str} dangerouslySetInnerHTML={{ __html: str }}></p>);

  return newText;
}

const toPrettyDate = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  const monthFormat = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const dateFormat = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const formatedDate = `${d.getFullYear()}-${monthFormat}-${dateFormat}`;
  const hourFormat = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minuteFormat = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const formatedTime = `${hourFormat}:${minuteFormat}:00`;
  return formatedDate + ' ' + formatedTime;
};

export const WageringInfoTable = (props: any) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Ticket ID</TableCell>
            <TableCell>Wager Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Free Play</TableCell>
            <TableCell>Risk</TableCell>
            <TableCell>To Win</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Contribution %</TableCell>
            <TableCell>Contribution Amount</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map(
              (
                row: {
                  timestamp: string;
                  ticket: string;
                  type: string;
                  description: string;
                  risk: number;
                  freePlay: any;
                  toWin: number;
                  result: string;
                  cPercent: number;
                  contribution: number;
                  reason: string;
                },
                index: any,
              ) => (
                <TableRow key={index}>
                  <TableCell component='th' scope='row'>
                    {row.ticket}
                    <br />
                    <br />
                    {toPrettyDate(row.timestamp)}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{NewlineText(row.description)}</TableCell>
                  <TableCell>{row.freePlay.toString()}</TableCell>
                  <TableCell>{row.risk}</TableCell>
                  <TableCell>{row.toWin}</TableCell>
                  <TableCell>{row.result}</TableCell>
                  <TableCell>{row.cPercent}%</TableCell>
                  <TableCell>${row.contribution}</TableCell>
                  <TableCell>{row.reason}</TableCell>
                </TableRow>
              ),
            )}
          {rows.length == 0 && (
            <TableRow key='notFound'>
              <TableCell colSpan={8} align='center'>
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
