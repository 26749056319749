import { ITourSections } from '../../models/tour-sections';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'tourSectionsAdminSlice';

export interface TourSectionsAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  sectionsList: ITourSections[];
}

export const initialState: TourSectionsAdminSliceType = {
  isLoading: false,
  isSaving: false,
  sectionsList: [],
};

export const tourSectionsAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
