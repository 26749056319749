import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo, IManualTransaction } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerInfoAction } from './get-player-info.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const MANUAL_TRANSACTION = `MANUAL_TRANSACTION${suffix}`;

export const manualTransactionAction = createAction<any>(MANUAL_TRANSACTION);

const MANUAL_TRANSACTION_SUCCESS = `MANUAL_TRANSACTION_SUCCESS${suffix}`;
const manualTransactionSuccessAction = createAction<IPlayerInfo[]>(MANUAL_TRANSACTION_SUCCESS);

const MANUAL_TRANSACTION_ERROR = `MANUAL_TRANSACTION_ERROR${suffix}`;
const manualTransactionErrorAction = createAction<string>(MANUAL_TRANSACTION_ERROR);

function* manualTransactionWatcher(): SagaIterator {
  yield takeEvery(MANUAL_TRANSACTION, manualTransactionWorker);
}
mergeSaga(manualTransactionWatcher);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

function* manualTransactionWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(manualTransactionApi, action.payload);
    yield put(manualTransactionSuccessAction(result.data));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerInfoAction(action.payload.userId));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(manualTransactionErrorAction(e.response.data.message));
  }
}

const manualTransactionApi = (req: IManualTransaction): Request => {
  return vipApi(`/cashier/manualTransaction/${req.userId}`, 'post', {
    transactionType: req.transactionType,
    method: req.method,
    amount: req.amount,
    notes: req.notes,
    staff: getSessionUser(),
  });
};

const reduceHandlers = {
  [MANUAL_TRANSACTION]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [MANUAL_TRANSACTION_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [MANUAL_TRANSACTION_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
