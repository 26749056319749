import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IBTCAddress } from '../../../models/btc-addresses';
import { btcAddressAdminReducer, BtcAddressAdminSliceType } from '../btc-address.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${btcAddressAdminReducer.sliceName}/app`;

const GET_BTC_ADDRESSES_BY_BANK = `GET_BTC_ADDRESSES_BY_BANK${suffix}`;

export const getBtcAddressesByBankAction = createAction<number>(GET_BTC_ADDRESSES_BY_BANK);

const GET_BTC_ADDRESSES_BY_BANK_SUCCESS = `GET_BTC_ADDRESSES_BY_BANK_SUCCESS${suffix}`;
const getBtcAddressesByBankSuccessAction = createAction<IBTCAddress[]>(GET_BTC_ADDRESSES_BY_BANK_SUCCESS);

const GET_BTC_ADDRESSES_BY_BANK_ERROR = `GET_BTC_ADDRESSES_BY_BANK_ERROR${suffix}`;
const getBtcAddressesByBankErrorAction = createAction<string>(GET_BTC_ADDRESSES_BY_BANK_ERROR);

function* getBtcAddressesByBankWatcher(): SagaIterator {
  yield takeEvery(GET_BTC_ADDRESSES_BY_BANK, getBtcAddressesWorker);
}
mergeSaga(getBtcAddressesByBankWatcher);

function* getBtcAddressesWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IBTCAddress> = yield call(getBtcAddressesByBankApi, action.payload);
    yield put(getBtcAddressesByBankSuccessAction(result.data));
  } catch (e: any) {
    yield put(getBtcAddressesByBankErrorAction(e));
  }
}

const getBtcAddressesByBankApi = (id: number): Request => {
  return vipApi(`/cashier/btcAddresses/` + id, 'get', {});
};

const reduceHandlers = {
  [GET_BTC_ADDRESSES_BY_BANK]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BTC_ADDRESSES_BY_BANK_SUCCESS]: (slice: BtcAddressAdminSliceType, action: any): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: false,
    btcAddresses: action.payload,
  }),
  [GET_BTC_ADDRESSES_BY_BANK_ERROR]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isLoading: false,
    btcAddresses: [],
  }),
};

btcAddressAdminReducer.addActionReducerMap(reduceHandlers);
