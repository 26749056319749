import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';

export const RemoveBonusAmountDialog = (props: any) => {
  const { open, onClose, removeBonusAmountAction, userId, amount, bonusType, playerNumber } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Alert severity='warning'>
            Are you sure you want to remove ${amount} {bonusType} from {playerNumber}?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              removeBonusAmountAction({
                userId: userId,
                amount: '-' + amount,
              });
              onClose();
            }}
          >
            Remove Bonus Amount
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
