export interface IBanks {
  id?: number;
  name: string;
  method: string;
  coa_id: number;
  deleted: number | null;
  method_id: number;
  reference: string;
  enabled: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

export interface IBanksUpdate {
  id?: number;
  name: string;
  method: any;
  reference: string;
  enabled: number;
  staff: string;
  coa_id: any;
}

export const emptyBank: IBanksUpdate = {
  name: '',
  method: '',
  reference: '',
  enabled: 0,
  staff: '',
  coa_id: '',
};
