export interface IPlayerInfo {
  id: any;
  personal: any;
  balances: any;
  deposits: any;
  withdrawals: any;
  pendingWagers: any;
  wagerHistory: any;
  bonusHistory: any;
  rafHistory: any;
}

export interface IChangePwd {
  id: any;
  password: any;
  confirm_password: any;
}

export interface IManualTransaction {
  userId: any;
  transactionType: any;
  method: any;
  amount: any;
  notes: any;
  staff: any;
}

export interface ISearchedPlayer {
  id?: number;
  username: string;
  role: string;
  site: number;
  name: string;
  phone: string;
  phone_verified_at?: any;
  email: string;
  email_verified_at?: any;
  text_password: string;
  pin: number;
  created_at: string;
  updated_at: string;
  deleted?: any;
  created_by: string;
  updated_by: string;
  last_login: string;
}

export const emptyPlayerInfo: IPlayerInfo = {
  id: '',
  personal: {},
  balances: {},
  deposits: {},
  withdrawals: {},
  pendingWagers: {},
  wagerHistory: {},
  bonusHistory: {},
  rafHistory: {},
};
