import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { default as MuiAppBar } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import PersonIcon from '@mui/icons-material/Person';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { getHistory } from '../../utils/history.util';

const appBarHeight = '64px';
const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  appBar: {
    maxHeight: appBarHeight,
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#121212',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: 'none',
  },
  hide: {
    display: 'none',
  },
  shrinkLogo: {
    maxHeight: '45px',
    maxWidth: '45px',
  },
  grow: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  userButton: {
    maxWidth: 30,
    height: 30,
  },
  white: {
    color: 'white',
  },
  userName: {
    marginLeft: '.5rem',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  pushRight: {
    marginLeft: 'auto',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export interface AppBarProps {
  handleDrawerOpen: (x: boolean) => void;
  open: boolean;
  user: any;
}

export const AppBar = (props: AppBarProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const doLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    location.reload();
  };

  const { user, open, handleDrawerOpen } = props;

  return (
    <MuiAppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          onClick={() => handleDrawerOpen(!open)}
          edge='start'
          color='inherit'
          aria-label='open drawer'
          className={clsx({
            [classes.hide]: open,
          })}
          size='large'
        >
          <MenuIcon />
        </IconButton>

        {/* <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder='Search…'
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div> */}

        <div className={classes.pushRight}>
          <IconButton classes={{ root: classes.white }} onClick={handleClick} size='large'>
            <PersonIcon fontSize='small'></PersonIcon>
            <Typography variant='subtitle1' className={classes.userName}>
              {user && user.username}
            </Typography>
          </IconButton>
          <Popover
            id={id}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List component='nav'>
              <ListItem
                button
                onClick={() => {
                  doLogOut();
                }}
              >
                <ListItemText primary='Log Out' />
              </ListItem>
            </List>
          </Popover>

          <IconButton
            classes={{ root: classes.white }}
            onClick={() => {
              getHistory().push('/app-settings');
            }}
          >
            <SettingsIcon />
          </IconButton>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};
