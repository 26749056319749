import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';
import { ITransactionLoad } from '../../../../models/transactions';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface ExternalProps {
  open: boolean;
  selected: ITransactionLoad;
}

export interface ExternalActionProps {
  onClose: () => void;
  updateLoadTransactionAction: (transaction: ITransactionLoad) => void;
  setSelected: (transaction: ITransactionLoad) => void;
}

export const UpdateLoadDialoge = (props: ExternalProps & ExternalActionProps) => {
  const { updateLoadTransactionAction, onClose, open, selected, setSelected } = props;

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperComponent={PaperComponent} aria-labelledby='draggable-dialog-title' maxWidth='xs'>
        <DialogTitle id='draggable-dialog-title'>Load Amount</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill out the amount to load.</DialogContentText>

          <TextField
            autoFocus
            margin='dense'
            id='amount'
            label='Amount'
            type='number'
            value={selected.amount}
            onChange={event =>
              setSelected({
                ...selected,
                amount: event.target.value as unknown as number,
              })
            }
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              updateLoadTransactionAction(selected);
              onClose();
            }}
            variant='contained'
            color='primary'
          >
            Load
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
