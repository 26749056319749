import { createSelector } from 'reselect';
import { appSettingsSliceType, appSettingsSliceReducer } from './app-settings.reducer';

export const appSettingsSliceSelector = (state: any): any => state[appSettingsSliceReducer.sliceName];

export const appSettingIsLoadingSliceSelector = createSelector(
  appSettingsSliceSelector,
  (appSettingsSliceSelector: appSettingsSliceType) => appSettingsSliceSelector.isLoading,
);

export const appSettingFeeFreeSliceSelector = createSelector(
  appSettingsSliceSelector,
  (appSettingsSliceSelector: appSettingsSliceType) => appSettingsSliceSelector.feeFree,
);
export const appSettingCommunicationsSliceSelector = createSelector(
  appSettingsSliceSelector,
  (appSettingsSliceSelector: appSettingsSliceType) => appSettingsSliceSelector.communications,
);
export const appSettingMethodFeesSliceSelector = createSelector(
  appSettingsSliceSelector,
  (appSettingsSliceSelector: appSettingsSliceType) => appSettingsSliceSelector.methodFees,
);
