import { connect } from 'react-redux';
import { AgentsPage, ExternalProps, ExternalActionProps } from './agent-page.component';
import { getAgentAction } from '../../../api/agent/actions/get-agents.action';
import { upsertAgentAction } from '../../../api/agent/actions/upsert-agent.action';
import { getDistributorAction } from '../../../api/distributor/actions/get-distributors.action';
import {
  agentsSelector,
  commissionStructuresSelector,
  isLoadingSliceSelector,
  isSavingSliceSelector,
} from '../../../api/agent/agent.selector';
import { distributorsSelector } from '../../../api/distributor/distributor.selector';
import { chartAccountsSelector } from '../../../api/chart-accounts/chart-account.selector';
import { paymentMethodsSelector } from '../../../api/payment-method/payment-method.selector';
import { getAgentStructureAction } from '../../../api/agent/actions/get-agent-comm-structures.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    agents: agentsSelector(state),
    distributors: distributorsSelector(state),
    chartAccounts: chartAccountsSelector(state),
    isLoading: isLoadingSliceSelector(state),
    isSaving: isSavingSliceSelector(state),
    paymentMethods: paymentMethodsSelector(state),
    structures: commissionStructuresSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAgentAction,
  upsertAgentAction,
  getDistributorAction,
  getAgentStructureAction,
};

export const AgentPageContainer = connect(mapStateToProps, mapDispatchToProps)(AgentsPage);
