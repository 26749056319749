import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  manualTransactionAction: (x: any) => void;
}

export interface ExternalProps {
  userId: any;
  isLoading: any;
}

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export const ManualTransactionCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { userId, isLoading, manualTransactionAction } = props;

  const [transactionType, setTransactionType] = React.useState(5);
  const [method, setMethod] = React.useState('Free Play');
  const [amount, setAmount] = React.useState('');
  const [notes, setNotes] = React.useState('');

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h6' className={classes.headTitle}>
        Manual Transaction
        <br />
        <small>Add, remove or adjust player balances directly.</small>
      </Typography>

      <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
            }}
          >
            <InputLabel id='demo-simple-select-autowidth-label'>Transaction Type</InputLabel>
            <Select
              labelId='demo-simple-select-autowidth-label'
              id='demo-simple-select-autowidth'
              value={transactionType}
              onChange={(event: any) => {
                setTransactionType(event.target.value);
              }}
              autoWidth
              label='Transaction Type'
            >
              <MenuItem value=''>
                <em>Select a Transaction Type</em>
              </MenuItem>
              <MenuItem value={1}>Adjustment</MenuItem>
              <MenuItem value={2}>Disbursement</MenuItem>
              <MenuItem value={3}>Receipt</MenuItem>
              <MenuItem value={4}>Horse Adjustment</MenuItem>
              <MenuItem value={5}>Free Play</MenuItem>
              <MenuItem value={6}>Bonus Points</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <InputLabel id='demo-simple-select-autowidth-label'>Method</InputLabel>
            <Select
              labelId='demo-simple-select-autowidth-label'
              id='demo-simple-select-autowidth'
              value={method}
              onChange={(event: any) => {
                setMethod(event.target.value);
              }}
              autoWidth
              label='Method'
            >
              <MenuItem value=''>
                <em>Select a Method</em>
              </MenuItem>
              <MenuItem value={'Free Play'}>Free Play</MenuItem>
              <MenuItem value={'Cash'}>Cash</MenuItem>
              <MenuItem value={'Bitcoin'}>Bitcoin</MenuItem>
              <MenuItem value={'Cash App'}>Cash App</MenuItem>
              <MenuItem value={'Credit/Debit Card'}>Credit/Debit Card</MenuItem>
              <MenuItem value={'Apple Pay'}>Apple Pay</MenuItem>
              <MenuItem value={'Zelle'}>Zelle</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <TextField
              label='Amount'
              value={amount}
              onChange={(event: any) => {
                setAmount(event.target.value);
              }}
            />
            <small style={{ textAlign: 'center' }}>
              Positive or Negative amounts accepted. <br />
              Ex: -100, for taking away $100 Free Play
            </small>
          </FormControl>

          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <TextField
              label='Notes'
              value={notes}
              onChange={(event: any) => {
                setNotes(event.target.value);
              }}
              rows={3}
              multiline
            />
            <small style={{ textAlign: 'center' }}>Reason why you are making this manual transaction.</small>
          </FormControl>

          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <Button
              disabled={isLoading}
              onClick={() => {
                manualTransactionAction({
                  userId: userId,
                  transactionType: transactionType,
                  method: method,
                  amount: amount,
                  notes: notes,
                  staff: getSessionUser(),
                });
              }}
            >
              Create Transaction
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='h6'>Need help?:</Typography>
          <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
          This module replicates the DGS manager module which adds/removes balances for players.
        </Grid>
      </Grid>
    </Paper>
  );
};
