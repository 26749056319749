import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Typography, Grid, Paper, Table, TableHead, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loading } from '../../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  hr: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  paper: {
    padding: 15,
  },
});

export interface ExternalActionProps {
  getAdminDashboardAction: (x: any) => void;
}

export interface ExternalProps {
  deposits: any;
  withdrawals: any;
  signups: any;
  referrals: any;
  isLoading: any;
  date: any;
  packages: any;
}

const getToday = () => {
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const dateObj = new Date();
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  // const output = month + '/' + day + '/' + year;
  const output = year + '-' + month + '-' + day;
  return output;
};

export const AdminDashboard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { getAdminDashboardAction, deposits, withdrawals, signups, referrals, isLoading, date, packages } = props;
  const [startDate, setStartDate] = React.useState(getToday());

  useEffect(() => {
    getAdminDashboardAction({
      date: startDate,
    });
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='h5'>
            {' '}
            Admin Dashboard <small>({date})</small>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type='Date'
            value={startDate}
            onChange={(event: any) => {
              setStartDate(event.target.value);
              getAdminDashboardAction({
                date: event.target.value,
              });
            }}
            label='Select a week'
          />
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            {packages &&
              packages.map((p: any) => {
                return (
                  <Paper className={classes.paper} key={p.id} style={{ marginBottom: '1rem' }}>
                    <Typography variant='h6'>{p.name}</Typography>
                    <hr />
                    <Grid container>
                      <Grid item xs={6}>
                        Active
                      </Grid>
                      <Grid item xs={6}>
                        <span>{p.active}</span>
                      </Grid>
                      <Grid item xs={6}>
                        Win/Loss
                      </Grid>
                      <Grid item xs={6}>
                        <span style={p.winloss.includes('-') ? { color: 'red' } : { color: 'green' }}>${p.winloss}</span>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper} style={{ marginBottom: '1rem' }}>
              <Typography variant='h6'>Deposits</Typography>
              <hr />
              <Grid container>
                <Grid item xs={6}>
                  Count
                </Grid>
                <Grid item xs={6}>
                  {deposits.count}
                </Grid>
                <Grid item xs={6}>
                  Total
                </Grid>
                <Grid item xs={6}>
                  ${deposits.total}
                </Grid>
                <Grid item xs={6}>
                  Average
                </Grid>
                <Grid item xs={6}>
                  ${deposits.average}
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant='h6'>Withdrawals</Typography>
              <hr />
              <Grid container>
                <Grid item xs={6}>
                  Count
                </Grid>
                <Grid item xs={6}>
                  {withdrawals.count}
                </Grid>
                <Grid item xs={6}>
                  Total
                </Grid>
                <Grid item xs={6}>
                  ${withdrawals.total}
                </Grid>
                <Grid item xs={6}>
                  Average
                </Grid>
                <Grid item xs={6}>
                  ${withdrawals.average}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              <Typography variant='h6'>Sign Ups</Typography>
              <hr />
              <Grid container>
                <Grid item xs={6}>
                  Count
                </Grid>
                <Grid item xs={6}>
                  {signups.count}
                </Grid>
                <Grid item xs={6}>
                  Deposited Count
                </Grid>
                <Grid item xs={6}>
                  {signups.depositedCount}
                </Grid>
                <Grid item xs={12}>
                  <hr />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Player Number</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  <TableBody>
                    {signups.lastten &&
                      signups.lastten.map((x: any) => {
                        return (
                          <TableRow key={x.id}>
                            <TableCell>{x.username}</TableCell>
                            <TableCell>{x.first_name + ' ' + x.last_name}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              <Typography variant='h6'>Referrals</Typography>
              <hr />
              <Grid container>
                <Grid item xs={6}>
                  Count
                </Grid>
                <Grid item xs={6}>
                  {referrals.count}
                </Grid>
                <Grid item xs={12}>
                  <hr />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Player Number</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                  <TableBody>
                    {referrals.lastten &&
                      referrals.lastten.map((x: any) => {
                        return (
                          <TableRow key={x.id}>
                            <TableCell>{x.username}</TableCell>
                            <TableCell>{x.first_name + ' ' + x.last_name}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
