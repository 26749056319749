import { IRuleCategory } from '../../models/rule-categories';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'ruleSlice';

export interface RuleSliceType {
  isLoading: boolean;
  isSaving: boolean;
  rules: IRuleCategory[];
}

export const initialState: RuleSliceType = {
  isLoading: false,
  isSaving: false,
  rules: [],
};

export const ruleReducer = createAndMergeSliceReducer(sliceName, initialState);
