import { connect } from 'react-redux';
import { referralsSelector } from '../../api/referrals/referrals.selector';
import { getAdminDashboardAction } from '../../api/user/actions/get-admin-dashboard.action';
import { getCustomerDashboardAction } from '../../api/user/actions/get-customer-dashboard.action';
import {
  dateSelector,
  depositsSelector,
  isLoadingSelector,
  loggedInUserSelector,
  packagesSelector,
  signupsSelector,
  withdrawalsSelector,
} from '../../api/user/user.selector';
import { DashboardPage, ExternalProps, ExternalActionProps } from './dashboard-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: loggedInUserSelector(state),
    isLoading: isLoadingSelector(state),
    deposits: depositsSelector(state),
    withdrawals: withdrawalsSelector(state),
    signups: signupsSelector(state),
    referrals: referralsSelector(state),
    date: dateSelector(state),
    packages: packagesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAdminDashboardAction,
  getCustomerDashboardAction,
};

export const DashboardPageContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
