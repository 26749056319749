import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { AgentAdminSliceType, agentAdminReducer } from '../agent.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { IAgentCommStructure } from '../../../models/agent-comm-structure';
import { getAgentStructureAction } from '../actions/get-agent-comm-structures.action';

const suffix = `/${agentAdminReducer.sliceName}/app`;

const DEL_AGENT_COMM_STRUCTURE = `DEL_AGENT_COMM_STRUCTURE${suffix}`;
export const deleteAgentCommissionAction = createAction<number>(DEL_AGENT_COMM_STRUCTURE);

export const DEL_AGENT_COMM_STRUCTURE_SUCCESS = `DEL_AGENT_COMM_STRUCTURE_SUCCESS${suffix}`;
export const deleteAgentCommissionSuccessAction = createAction<IAgentCommStructure>(DEL_AGENT_COMM_STRUCTURE_SUCCESS);

const DEL_AGENT_COMM_STRUCTURE_ERROR = `DEL_AGENT_COMM_STRUCTURE_ERROR${suffix}`;
export const deleteAgentCommissionErrorAction = createAction<string>(DEL_AGENT_COMM_STRUCTURE_ERROR);

function* deleteAgentCommissionWatcher(): SagaIterator {
  yield takeEvery(DEL_AGENT_COMM_STRUCTURE, deleteAgentCommissionWorker);
}
mergeSaga(deleteAgentCommissionWatcher);

function* deleteAgentCommissionWorker(action: any): SagaIterator {
  try {
    const result = yield call(deleteAgentCommission, action.payload);
    yield put(deleteAgentCommissionSuccessAction(result));
    yield put(getAgentStructureAction());
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(deleteAgentCommissionErrorAction(e.response.data.message));
  }
}

const deleteAgentCommission = (id: number): Request => {
  const url = '/cashier/agent/commission/deleteStructure/' + id;
  return vipApi(url, 'delete', {}, 'json');
};

const reduceHandlers = {
  [DEL_AGENT_COMM_STRUCTURE]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DEL_AGENT_COMM_STRUCTURE_SUCCESS]: (slice: AgentAdminSliceType): AgentAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DEL_AGENT_COMM_STRUCTURE_ERROR]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

agentAdminReducer.addActionReducerMap(reduceHandlers);
