import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IBanksUpdate } from '../../../models/banks';
import { BankAdminSliceType, bankAdminReducer } from '../bank.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${bankAdminReducer.sliceName}/app`;

const DELETE_BANK = `DELETE_BANK${suffix}`;
export const deleteBankAction = createAction<IBanksUpdate>(DELETE_BANK);

export const DELETE_BANK_SUCCESS = `DELETE_BANK_SUCCESS${suffix}`;
export const deleteBankSuccessAction = createAction<number>(DELETE_BANK_SUCCESS);

const DELETE_BANK_ERROR = `DELETE_BANK_ERROR${suffix}`;
export const deleteBankErrorAction = createAction<string>(DELETE_BANK_ERROR);

function* deleteBankWatcher(): SagaIterator {
  yield takeEvery(DELETE_BANK, deleteBankWorker);
}
mergeSaga(deleteBankWatcher);

function* deleteBankWorker(action: any): SagaIterator {
  try {
    yield call(deleteBank, action.payload.id);
    yield put(deleteBankSuccessAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteBankErrorAction(e.response.data.message));
  }
}

const deleteBank = (id: number): Request => {
  const url = '/cashier/banks/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_BANK]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DELETE_BANK_SUCCESS]: (slice: BankAdminSliceType, action: any): BankAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      banks: slice.banks.filter(item => item.id != action.payload),
    };
  },
  [DELETE_BANK_ERROR]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

bankAdminReducer.addActionReducerMap(reduceHandlers);
