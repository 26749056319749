import { createAction } from 'redux-actions';
import { emptyPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const CLEAR_SEARCHED_PLAYER = `CLEAR_SEARCHED_PLAYER${suffix}`;

export const clearSearchedPlayerAction = createAction(CLEAR_SEARCHED_PLAYER);

const reduceHandlers = {
  [CLEAR_SEARCHED_PLAYER]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    playerInfo: emptyPlayerInfo,
    playerChangePassword: {
      password: '',
      confirm_password: '',
    },
    cognitoData: {},
    rights: [],
    deposits: [],
    withdrawals: [],
    pendingWagers: [],
    wagerHistory: [],
    bonusHistory: [],
    rafHistory: [],
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
