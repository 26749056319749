import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IAgentsUpdate, IAgents } from '../../../models/agent';
import { AgentAdminSliceType, agentAdminReducer } from '../agent.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getAgentAction } from './get-agents.action';

const suffix = `/${agentAdminReducer.sliceName}/app`;

const UPDATE_AGENT = `UPDATE_AGENT${suffix}`;
export const upsertAgentAction = createAction<IAgentsUpdate>(UPDATE_AGENT);

export const UPDATE_AGENT_SUCCESS = `UPDATE_AGENT_SUCCESS${suffix}`;
export const upsertAgentSuccessAction = createAction<IAgents>(UPDATE_AGENT_SUCCESS);

const UPDATE_AGENT_ERROR = `UPDATE_AGENT_ERROR${suffix}`;
export const upsertAgentErrorAction = createAction<string>(UPDATE_AGENT_ERROR);

function* upsertAgentWatcher(): SagaIterator {
  yield takeEvery(UPDATE_AGENT, upsertAgentWorker);
}
mergeSaga(upsertAgentWatcher);

function* upsertAgentWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateAgent, action.payload);
    } else {
      result = yield call(createAgent, action.payload);
    }
    yield put(upsertAgentSuccessAction(result));
    yield put(getAgentAction());
  } catch (e: any) {
    yield call(showToast, e.response.data, 'error');
    yield put(upsertAgentErrorAction(e.response.data.message));
  }
}

const createAgent = (agent: IAgentsUpdate): Request => {
  const url = '/cashier/AddAgent';
  return vipApi(url, 'post', agent);
};

const updateAgent = (agent: IAgentsUpdate): Request => {
  const url = '/cashier/EditAgent/' + agent.id;
  return vipApi(url, 'put', agent, 'json');
};

const reduceHandlers = {
  [UPDATE_AGENT]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPDATE_AGENT_SUCCESS]: (slice: AgentAdminSliceType, action: any): AgentAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      agents: slice.agents.concat([action.payload.data]),
    };
  },
  [UPDATE_AGENT_ERROR]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

agentAdminReducer.addActionReducerMap(reduceHandlers);
