import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_RAF_STATS = `GET_PLAYER_RAF_STATS${suffix}`;

export const getPlayerRafStatsAction = createAction<any>(GET_PLAYER_RAF_STATS);

const GET_PLAYER_RAF_STATS_SUCCESS = `GET_PLAYER_RAF_STATS_SUCCESS${suffix}`;
const getPlayerRafStatsSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_RAF_STATS_SUCCESS);

const GET_PLAYER_RAF_STATS_ERROR = `GET_PLAYER_RAF_STATS_ERROR${suffix}`;
const getPlayerRafStatsErrorAction = createAction<string>(GET_PLAYER_RAF_STATS_ERROR);

function* getPlayerRafStatsWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_RAF_STATS, getPlayerRafStatsWorker);
}
mergeSaga(getPlayerRafStatsWatcher);

function* getPlayerRafStatsWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerRafStatsApi, action.payload);
    yield put(getPlayerRafStatsSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerRafStatsErrorAction(e.response.data.message));
  }
}

const getPlayerRafStatsApi = (string: any): Request => {
  return vipApi(`/cashier/getRAF/${string}`, 'post', {});
};

const reduceHandlers = {
  [GET_PLAYER_RAF_STATS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_RAF_STATS_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      rafHistory: action.payload,
    };
  },
  [GET_PLAYER_RAF_STATS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
