import * as React from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface ExternalProps {
  wagerHistory: any;
  isLoading: any;
  wagerType: any;
}

export default function WagerHistoryDetails(props: ExternalProps) {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);
  const { wagerHistory, isLoading, wagerType } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function getMediaScrollable(): boolean {
    return window.innerWidth >= 1025;
  }

  const getScrollableBar = () => {
    if (getMediaScrollable()) {
      return 'fullWidth';
    } else {
      return 'scrollable';
    }
  };

  let tabId = -1;

  const GetWeekDay = (index: any) => {
    // wagerHistory.Week1
    switch (index) {
      case 0:
        return wagerHistory.Week1;
      case 1:
        return wagerHistory.Week2;
      case 2:
        return wagerHistory.Week3;
      case 3:
        return wagerHistory.Week4;
      case 4:
        return wagerHistory.Week5;
      case 5:
        return wagerHistory.Week6;
      case 6:
        return wagerHistory.Week7;
    }
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  let tabIndexId = -1;

  return (
    <>
      {!isLoading && wagerHistory && (
        <Box sx={{ width: '100%', marginTop: '1rem' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='scrollable auto tabs example'
              variant={getScrollableBar()}
              scrollButtons='auto'
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {wagerHistory.history != undefined &&
                wagerHistory.history.map((day: any, index: any) => {
                  tabId += 1;
                  return <Tab label={'(' + GetWeekDay(index) + ') ' + day.Date} {...a11yProps(tabId)} key={day.Date} />;
                })}
            </Tabs>
          </Box>
          <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
            {wagerHistory &&
              wagerHistory.history &&
              wagerHistory.history.map((weekDay: any) => {
                tabIndexId += 1;
                return (
                  <TabPanel key={weekDay.Date} value={value} index={tabIndexId}>
                    {weekDay.wager && (
                      <>
                        {weekDay.wager.length == 0 ? (
                          <>{'No History'}</>
                        ) : (
                          <>
                            {Array.isArray(weekDay.wager) ? (
                              weekDay.wager
                                .filter((w: any) => {
                                  if (wagerType == 1) {
                                    if (w.WagerOrTrans == 'TRAN') {
                                      return true;
                                    }
                                  }
                                  if (wagerType == 2) {
                                    if (w.WagerOrTrans == 'WAGER') {
                                      return true;
                                    }
                                  }
                                  if (wagerType == 3) {
                                    if (w.HeaderDesc.includes('Concept') || w.HeaderDesc.includes('Betsoft')) {
                                      return true;
                                    }
                                  }
                                  return true;
                                })
                                .map((wager: any, index: any) => {
                                  return (
                                    <Accordion key={index} defaultExpanded>
                                      <AccordionSummary
                                        sx={{ bgcolor: 'secondary.main' }}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1a-content'
                                        id='panel1a-header'
                                      >
                                        {wager.TicketNumber != 0 ? (
                                          <Typography>
                                            <b>[#{wager.TicketNumber}]</b> {wager.HeaderDesc} | Placed: {wager.PlacedDate} @{' '}
                                            {wager.PlacedTime}
                                          </Typography>
                                        ) : (
                                          <>
                                            <Typography sx={{ marginRight: '.5rem' }}>
                                              <b>[TRANS] </b>
                                            </Typography>
                                            <Typography dangerouslySetInnerHTML={{ __html: wager.HeaderDesc }}></Typography>
                                          </>
                                        )}
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {Array.isArray(wager.detail) ? (
                                          <>
                                            {wager.detail.map((game: any) => {
                                              return (
                                                <>
                                                  {wager.TicketNumber == 0 ? (
                                                    <Grid container key={wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Transaction Date:
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        {wager.PlacedDate} @ {wager.PlacedTime}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Amount:
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        ${wager.WinLoss}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Ending Balance:
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        ${wager.Balance}
                                                      </Grid>
                                                    </Grid>
                                                  ) : (
                                                    <Grid container key={wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Sport
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        {game.IdSport}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Game Date
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        {game.GameDate} @ {game.GameTime}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Description
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        sm={10}
                                                        dangerouslySetInnerHTML={{ __html: game.DetailDesc }}
                                                      ></Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Risk
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        ${wager.RiskAmount}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Win
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        ${wager.WinAmount}
                                                      </Grid>
                                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                        Result
                                                      </Grid>
                                                      <Grid item xs={12} sm={10}>
                                                        <Typography
                                                          sx={
                                                            game.DetailResult == 'WIN'
                                                              ? { color: 'green', fontWeight: 'bold' }
                                                              : { color: 'red', fontWeight: 'bold' }
                                                          }
                                                        >
                                                          {game.DetailResult}
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                  <Divider sx={{ marginBottom: '1rem' }} />
                                                </>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            {wager.TicketNumber == 0 ? (
                                              <Grid container key={wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Transaction Date:
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  {wager.PlacedDate} @ {wager.PlacedTime}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Amount:
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  ${wager.WinLoss}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Ending Balance:
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  ${wager.Balance}
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Grid container key={wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Sport
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  {wager.detail.IdSport}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Game Date
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  {wager.detail.GameDate} @ {wager.detail.GameTime}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Description
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={10}
                                                  dangerouslySetInnerHTML={{ __html: wager.detail.DetailDesc }}
                                                ></Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Risk
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  ${wager.RiskAmount}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Win
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  ${wager.WinAmount}
                                                </Grid>
                                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                                  Result
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                  <Typography
                                                    sx={
                                                      wager.detail.WinLoss > 0
                                                        ? { color: 'green', fontWeight: 'bold' }
                                                        : { color: 'red', fontWeight: 'bold' }
                                                    }
                                                  >
                                                    {wager.detail.WinLoss}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            )}
                                            <Divider sx={{ marginBottom: '1rem' }} />
                                          </>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                })
                            ) : (
                              <>
                                <Accordion defaultExpanded>
                                  <AccordionSummary
                                    sx={{ bgcolor: 'secondary.main' }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                  >
                                    {weekDay.wager.IdWager != 0 ? (
                                      <Typography>
                                        <b>[#{weekDay.wager.IdWager}]</b> {weekDay.wager.HeaderDesc} | Placed: {weekDay.wager.PlacedDate} @{' '}
                                        {weekDay.wager.PlacedTime}
                                      </Typography>
                                    ) : (
                                      <>
                                        <Typography sx={{ marginRight: '.5rem' }}>
                                          <b>[{weekDay.wager.WagerOrTrans == 'TRAN' ? 'TRANSACTION' : ''}] </b>
                                        </Typography>
                                        <Typography dangerouslySetInnerHTML={{ __html: weekDay.wager.HeaderDesc }}></Typography>
                                      </>
                                    )}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {weekDay.wager.WagerOrTrans == 'TRAN' ? (
                                      <Grid container key={weekDay.wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Transaction Date:
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          {weekDay.wager.PlacedDate} @ {weekDay.wager.PlacedTime}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Amount:
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          ${weekDay.wager.WinLoss}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Ending Balance:
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          ${weekDay.wager.Balance}
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid container key={weekDay.wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Sport
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          {weekDay.wager.detail.IdSport}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Game Date
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          {weekDay.wager.detail.GameDate} @ {weekDay.wager.detail.GameTime}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Description
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={10}
                                          dangerouslySetInnerHTML={{ __html: weekDay.wager.detail.DetailDesc }}
                                        ></Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Risk
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          ${weekDay.wager.RiskAmount}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Win
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          ${weekDay.wager.WinAmount}
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                          Result
                                        </Grid>
                                        <Grid item xs={12} sm={10}>
                                          <Typography
                                            sx={
                                              weekDay.wager.detail.DetailResult == 'WIN'
                                                ? { color: 'green', fontWeight: 'bold' }
                                                : { color: 'red', fontWeight: 'bold' }
                                            }
                                          >
                                            {weekDay.wager.detail.DetailResult}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {!weekDay.wager && 'No History'}
                  </TabPanel>
                );
              })}
          </SwipeableViews>
        </Box>
      )}
    </>
  );
}
