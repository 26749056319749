import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { getAppSettings } from '../../../../../utils/app-settings.util';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  updateTpiRightsAction: (x: any) => void;
}

export interface ExternalProps {
  rights: any;
  userId: any;
}

export const ThirdPartyRights = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { rights, userId, updateTpiRightsAction } = props;

  let url = getAppSettings().apiUrl;
  url = url.slice(0, -4);

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h6' className={classes.headTitle}>
        Third Party Rights
        <br />
        <small>Enable/Disable player access to specific third parties.</small>
      </Typography>

      <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
      <Grid container spacing={5}>
        {rights &&
          rights.map((right: any) => {
            return (
              <>
                <Grid key={right.id} item xs={12} sm={6}>
                  {right.image && <img src={url + right.image} style={{ maxWidth: '50px', height: 'auto' }} />}
                  {right.description}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        right.status == 1 ? (
                          <Switch
                            defaultChecked
                            onChange={() => {
                              updateTpiRightsAction({
                                userId: userId,
                                tpiId: right.id,
                                status: 0,
                              });
                            }}
                          />
                        ) : (
                          <Switch
                            onChange={() => {
                              updateTpiRightsAction({
                                userId: userId,
                                tpiId: right.id,
                                status: 1,
                              });
                            }}
                          />
                        )
                      }
                      label={right.status == 1 ? 'Enabled' : 'Disabled'}
                    />
                  </FormGroup>
                </Grid>
              </>
            );
          })}
      </Grid>
    </Paper>
  );
};
