import { createSelector } from 'reselect';
import { paymentMethodAdminReducer, PaymentMethodAdminSliceType } from './payment-method.reducer';

export const paymentMethodAdminSliceSelector = (state: any): any => state[paymentMethodAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  paymentMethodAdminSliceSelector,
  (paymentMethodAdminSlice: PaymentMethodAdminSliceType) => paymentMethodAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  paymentMethodAdminSliceSelector,
  (paymentMethodAdminSlice: PaymentMethodAdminSliceType) => paymentMethodAdminSlice.isSaving,
);

export const paymentMethodsSelector = createSelector(
  paymentMethodAdminSliceSelector,
  (paymentMethodAdminSlice: PaymentMethodAdminSliceType) => paymentMethodAdminSlice.paymentMethods,
);
