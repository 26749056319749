import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getAppSettings } from '../../../utils/app-settings.util';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const IMPERSONATE_PLAYER = `IMPERSONATE_PLAYER${suffix}`;

export const impersonatePlayerAction = createAction<any>(IMPERSONATE_PLAYER);

const IMPERSONATE_PLAYER_SUCCESS = `IMPERSONATE_PLAYER_SUCCESS${suffix}`;
const impersonatePlayerSuccessAction = createAction<any>(IMPERSONATE_PLAYER_SUCCESS);

const IMPERSONATE_PLAYER_ERROR = `IMPERSONATE_PLAYER_ERROR${suffix}`;
const impersonatePlayerErrorAction = createAction<any>(IMPERSONATE_PLAYER_ERROR);

function* impersonatePlayerWatcher(): SagaIterator {
  yield takeEvery(IMPERSONATE_PLAYER, impersonatePlayerWorker);
}
mergeSaga(impersonatePlayerWatcher);

function* impersonatePlayerWorker(action: any): SagaIterator {
  try {
    const result = yield call(impersonatePlayerApi, action.payload);
    yield put(impersonatePlayerSuccessAction(result.token));
    const url = `${getAppSettings().wagerUrl}#/login?imp=` + result.token;
    window.open(url, '_blank');
  } catch (e: any) {
    yield call(showToast, e.response.message, 'error');
    yield put(impersonatePlayerErrorAction(e.response.message));
  }
}

const impersonatePlayerApi = (x: any): Request => {
  return vipApi(`/cashier/impersonatePlayer`, 'post', x);
};

const reduceHandlers = {
  [IMPERSONATE_PLAYER]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [IMPERSONATE_PLAYER_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [IMPERSONATE_PLAYER_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
