import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IChartAccountUpload } from '../../../models/chart-account';
import { ChartAccountAdminSliceType, chartAccountAdminReducer } from '../chart-account.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${chartAccountAdminReducer.sliceName}/app`;

const UPSERT_CHART_ACCOUNT = `UPSERT_CHART_ACCOUNT${suffix}`;
export const upsertChartAccountAction = createAction<IChartAccountUpload>(UPSERT_CHART_ACCOUNT);

export const UPSERT_CHART_ACCOUNT_SUCCESS = `UPSERT_CHART_ACCOUNT_SUCCESS${suffix}`;
export const upsertChartAccountSuccessAction = createAction(UPSERT_CHART_ACCOUNT_SUCCESS);

const UPSERT_CHART_ACCOUNT_ERROR = `UPSERT_CHART_ACCOUNT_ERROR${suffix}`;
export const upsertChartAccountErrorAction = createAction<string>(UPSERT_CHART_ACCOUNT_ERROR);

function* upsertChartAccountWatcher(): SagaIterator {
  yield takeEvery(UPSERT_CHART_ACCOUNT, upsertChartAccountWorker);
}
mergeSaga(upsertChartAccountWatcher);

function* upsertChartAccountWorker(action: any): SagaIterator {
  console.log('upsertChartAccountWorker');

  console.log(action.payload);
  try {
    let result;
    if (action.payload.action == 'update') {
      console.log('attempting update');
      result = yield call(updateChartAccount, action.payload);
    } else {
      result = yield call(createChartAccount, action.payload);
    }
    yield put(upsertChartAccountSuccessAction(result));
    console.log(result);
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertChartAccountErrorAction(e.response.data.message));
  }
}

const createChartAccount = (chartAccount: IChartAccountUpload): Request => {
  const url = '/cashier/accounts';
  return vipApi(url, 'post', chartAccount);
};

const updateChartAccount = (chartAccount: IChartAccountUpload): Request => {
  const url = '/cashier/accounts/' + chartAccount.previousId;
  return vipApi(url, 'post', chartAccount);
};

const reduceHandlers = {
  [UPSERT_CHART_ACCOUNT]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPSERT_CHART_ACCOUNT_SUCCESS]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPSERT_CHART_ACCOUNT_ERROR]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

chartAccountAdminReducer.addActionReducerMap(reduceHandlers);
