import { connect } from 'react-redux';
import { ChartOfAccountsPage, ExternalProps, ExternalActionProps } from './chart-of-accounts-page.component';
import { getChartAccountsAction } from '../../../api/chart-accounts/actions/get-chart-accounts.action';
import { upsertChartAccountAction } from '../../../api/chart-accounts/actions/upsert-chart-account.action';
import { deleteChartAccountAction } from '../../../api/chart-accounts/actions/delete-chart-account.action';
import { chartAccountsSelector, isLoadingSliceSelector } from '../../../api/chart-accounts/chart-account.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    chartAccounts: chartAccountsSelector(state),
    isLoading: isLoadingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getChartAccountsAction,
  upsertChartAccountAction,
  deleteChartAccountAction,
};

export const ChartOfAccountsPageContainer = connect(mapStateToProps, mapDispatchToProps)(ChartOfAccountsPage);
