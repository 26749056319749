import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IBanksUpdate } from '../../../../models/banks';
import { IChartAccount } from '../../../../models/chart-account';
import { IPaymentMethods } from '../../../../models/payment-methods';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export interface ExternalProps {
  open: boolean;
  selected: IBanksUpdate;
  chartAccounts: IChartAccount[];
  paymentMethods: IPaymentMethods[];
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertBankAction: (bank: IBanksUpdate) => void;
  setSelected: (bank: IBanksUpdate) => void;
}

export const CreateBankDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { upsertBankAction, onClose, open, chartAccounts, paymentMethods, selected, setSelected } = props;

  const handleCheck = (event: React.ChangeEvent<{ value: unknown }>, fld: string) => {
    setSelected({
      ...selected,
      [fld]: selected.enabled == 1 ? 0 : 1,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperComponent={PaperComponent} aria-labelledby='draggable-dialog-title' maxWidth='xs'>
        <DialogTitle id='draggable-dialog-title'>{selected.id == null ? 'Create Bank' : 'Update Bank'}</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            upsertBankAction(selected);
            onClose();
          }}
        >
          <DialogContent>
            <DialogContentText>Please fill out the form below to create a bank.</DialogContentText>
            <FormControl className={classes.formControl} fullWidth style={{ marginBottom: '7px' }}>
              <InputLabel htmlFor='method'>Method</InputLabel>
              <Select
                required
                label='Method'
                value={selected.method}
                onChange={event =>
                  setSelected({
                    ...selected,
                    method: event.target.value as string,
                  })
                }
                inputProps={{
                  name: 'method',
                  id: 'method',
                }}
                fullWidth
              >
                {paymentMethods.map(item => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor='coa'>Chart Of Accounts</InputLabel>
              <Select
                label='Chart Of Accounts'
                required
                margin='dense'
                value={selected.coa_id}
                onChange={event =>
                  setSelected({
                    ...selected,
                    coa_id: event.target.value as unknown as number,
                  })
                }
                inputProps={{
                  name: 'coa',
                  id: 'coa',
                }}
                fullWidth
              >
                {chartAccounts.map(item => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.account_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Bank Name'
              type='text'
              value={selected.name}
              onChange={event =>
                setSelected({
                  ...selected,
                  name: event.target.value,
                })
              }
              required
              fullWidth
            />

            <TextField
              autoFocus
              margin='dense'
              id='reference'
              label='Reference ($abc123 or Account)'
              type='text'
              value={selected.reference}
              onChange={event =>
                setSelected({
                  ...selected,
                  reference: event.target.value,
                })
              }
              required
              fullWidth
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.enabled == 1 ? true : false}
                  value={selected.enabled}
                  name='enabled'
                  color='primary'
                  onChange={event => handleCheck(event, 'enabled')}
                />
              }
              label='Enable on Creation'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant='contained' color='primary' type='submit'>
              {selected.id == null ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
