import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';
import { Grid } from '@mui/material';

export interface ExternalProps {
  open: boolean;
  cognitoData: any;
}

export interface ExternalActionProps {
  onClose: () => void;
}

export const CognitoDataDialog = (props: ExternalProps & ExternalActionProps) => {
  const { open, onClose, cognitoData } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Alert severity='info'>
            Info below is {cognitoData.playerNumber}&#39;s cognito data. You are able to make a decision to allow or deny verified payment
            methods.
          </Alert>
          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                Player Number:
              </Grid>
              <Grid item xs={6}>
                {cognitoData.playerNumber}
              </Grid>
              <Grid item xs={6}>
                Full Name:
              </Grid>
              <Grid item xs={6}>
                {cognitoData.fullName}
              </Grid>
              <Grid item xs={6}>
                Phone Used:
              </Grid>
              <Grid item xs={6}>
                {cognitoData.phoneUsed}
              </Grid>
              <Grid item xs={6}>
                Names connected:
                <br />
                <small>{cognitoData.phoneUsed}</small>
              </Grid>
              <Grid item xs={6}>
                {cognitoData &&
                  cognitoData.cognitoNames &&
                  cognitoData.cognitoNames.map((name: any, index: any) => <li key={index}>{name}</li>)}
              </Grid>
              <Grid item xs={6}>
                Addresses connected:
                <br />
                <small>{cognitoData.phoneUsed}</small>
              </Grid>
              <Grid item xs={6}>
                {cognitoData &&
                  cognitoData.cognitoAddresses &&
                  cognitoData.cognitoAddresses.map((address: any, index: any) => (
                    <li key={index} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                      {address}
                    </li>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
