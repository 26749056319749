import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IPlayerInfo, ISearchedPlayer } from '../../../models/player-info';
import { Loading } from './../../../shared/loading/loading.component';
import SearchIcon from '@mui/icons-material/Search';
import { SearchedPlayerDialog } from './components/searched-player-dialoge';
import { ConfirmCognitoStatusDialog } from './components/confirm-cognito-status-dialog';
import { ICognitoStatus } from '../../../models/player-info/cognito-status';
import { CognitoDataDialog } from './components/cognito-data-dialog';
import AccountSettingsTabs from './components/account-settings-tabs';
import AccountTransactionsTab from './components/account-transactions-tabs';
import { PlayerInfoCard } from './components/player-info-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      marginBottom: '1rem',
      width: 'calc(100% - 5px)',
    },
    paper: {
      borderRadius: '8px',
      padding: '20px 20px 40px 20px',
    },
    headTitle: {
      color: '#666',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    tableTitle: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    tableContent: {
      fontWeight: 'bold',
      color: '#666',
    },
    tableLink: {
      fontSize: '20px',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    tableContainer: {
      border: '1px solid #999',
      marginTop: '20px',
      borderRadius: '8px',
      padding: '10px 20px 10px 20px',
    },
    table: {
      '& th': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& td': {
        fontSize: '20px',
      },
    },
    inputText: {
      '& input': {
        padding: '12px 14px',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
    listitem: {
      cursor: 'pointer',
    },
  }),
);

export interface ExternalActionProps {
  searchPlayerAction: (string: string) => void;
  getPlayerInfoAction: (id: any) => void;
  changePasswordAction: (string: string, password: string, confirm_password: string) => void;
  disableRolloverAction: (string: string) => void;
  removeBonusAmountAction: (string: string) => void;
  updateCognitoStatusAction: (c: ICognitoStatus) => void;
  getCognitoDataAction: (id: number) => void;
  impersonatePlayerAction: (x: any) => void;
  clearSearchedPlayerAction: () => void;
  updateTpiRightsAction: (x: any) => void;
  updatePlayerAction: (x: any) => void;
  getPlayerDepositsAction: (x: any) => void;
  getPlayerWithdrawalsAction: (x: any) => void;
  getPlayerPendingWagersAction: (x: any) => void;
  getPlayerWagerHistoryAction: (x: any) => void;
  getPlayerBonusHistoryAction: (x: any) => void;
  getPlayerRafStatsAction: (x: any) => void;
  approveDenyReferralAction: (x: any) => void;
  getPlayerNotesAction: (x: any) => void;
  addPlayerNoteAction: (x: any) => void;
  manualTransactionAction: (x: any) => void;
}

export interface ExternalProps {
  playerInfo: IPlayerInfo;
  isLoading: boolean;
  searchedPlayer: ISearchedPlayer[];
  cognitoData: any;
  rights: any;
  withdrawals: any;
  pendingWagers: any;
  wagerHistory: any;
  bonusHistory: any;
  deposits: any;
  rafHistory: any;
  playerNotes: any;
}

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export const PlayerInfoPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const {
    searchPlayerAction,
    isLoading,
    playerInfo,
    searchedPlayer,
    cognitoData,
    rights,
    withdrawals,
    deposits,
    pendingWagers,
    wagerHistory,
    bonusHistory,
    rafHistory,
    playerNotes,
    getPlayerInfoAction,
    changePasswordAction,
    disableRolloverAction,
    removeBonusAmountAction,
    updateCognitoStatusAction,
    getCognitoDataAction,
    impersonatePlayerAction,
    clearSearchedPlayerAction,
    updateTpiRightsAction,
    updatePlayerAction,
    getPlayerDepositsAction,
    getPlayerWithdrawalsAction,
    getPlayerPendingWagersAction,
    getPlayerWagerHistoryAction,
    getPlayerBonusHistoryAction,
    getPlayerRafStatsAction,
    approveDenyReferralAction,
    getPlayerNotesAction,
    addPlayerNoteAction,
    manualTransactionAction,
  } = props;
  const { player_number, verified } = playerInfo.personal;

  const [searchHistory, setSearchHistory] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [player, setPlayer] = useState('');

  // Cognito Props
  const [cognitoStatusDialogOpen, setCognitoStatusDialogOpen] = useState(false);
  const [cognitoStatus, setCognitoStatus] = useState(-1);
  const [cognitoDataDialogOpen, setCognitoDataDialogOpen] = useState(false);

  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (playerInfo.personal && playerInfo.personal.player_number) {
      setIsInfo(true);
      getCognitoDataAction(playerInfo.personal.id);
    }
  }, [playerInfo]);

  useEffect(() => {
    let searchedPlayers: any = localStorage.getItem('searchedPlayers');
    if (searchedPlayers == null) {
      searchedPlayers = localStorage.setItem('searchedPlayers', '[]');
    }
    searchedPlayers = JSON.parse(searchedPlayers);
    setSearchHistory(searchedPlayers);
  }, []);

  useEffect(() => {
    if (searchedPlayer.length > 0) {
      setSearchOpen(true);
    }
  }, [searchedPlayer]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <SearchedPlayerDialog
          rows={searchedPlayer}
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          getPlayerInfoAction={getPlayerInfoAction}
        />
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TextField
              id='playerinfo'
              label='Search Player'
              variant='outlined'
              value={player}
              style={{ marginRight: '10px' }}
              onChange={e => setPlayer(e.target.value)}
              className={classes.inputText}
            />
            <Button onClick={() => searchPlayerAction(player)} variant='contained' color='primary' size='large'>
              <SearchIcon />
            </Button>

            {isInfo && (
              <Button
                variant='contained'
                size='large'
                color='inherit'
                style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                onClick={() => {
                  clearSearchedPlayerAction();
                  setIsInfo(false);

                  let searchedPlayers: any = localStorage.getItem('searchedPlayers');
                  if (searchedPlayers == null) {
                    searchedPlayers = localStorage.setItem('searchedPlayers', '[]');
                  }

                  searchedPlayers = JSON.parse(searchedPlayers);
                  setSearchHistory(searchedPlayers);
                }}
              >
                Back
              </Button>
            )}
          </Grid>

          {!isInfo && (
            <>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper} elevation={2}>
                  <Typography variant='h6' className={classes.headTitle}>
                    Last 10 Searched Players
                  </Typography>
                  <hr />
                  <Grid container spacing={2}>
                    {searchHistory && searchHistory.length == 0 && (
                      <Grid item xs={12}>
                        <Typography>Please search a player to get started.</Typography>
                      </Grid>
                    )}
                    {searchHistory &&
                      searchHistory.length > 0 &&
                      searchHistory.map((player: any) => {
                        return (
                          <>
                            <Grid item xs={6} key={player.id}>
                              <Typography>
                                {player.player_number} ({player.name})
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                color='primary'
                                variant='contained'
                                style={{ marginLeft: '.5 rem', marginRight: '.5rem' }}
                                onClick={() => {
                                  getPlayerInfoAction(player.id);
                                }}
                              >
                                View Info
                              </Button>
                              <Button
                                color='secondary'
                                variant='contained'
                                style={{ marginLeft: '.5 rem', marginRight: '.5rem' }}
                                onClick={() => {
                                  impersonatePlayerAction({
                                    userId: player.id,
                                    staff: getSessionUser(),
                                  });
                                }}
                              >
                                Impersonate
                              </Button>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}

          <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
          {isInfo && (
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <PlayerInfoCard
                    impersonatePlayerAction={impersonatePlayerAction}
                    changePasswordAction={changePasswordAction}
                    playerInfo={playerInfo}
                    expand={false}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant='h5' gutterBottom>
                    Settings & Tools
                  </Typography>
                  <Divider sx={{ marginBottom: '1rem' }} />
                  <AccountSettingsTabs
                    setCognitoStatus={setCognitoStatus}
                    setCognitoDataDialogOpen={setCognitoDataDialogOpen}
                    setCognitoStatusDialogOpen={setCognitoStatusDialogOpen}
                    verified={verified}
                    rights={rights}
                    updateTpiRightsAction={updateTpiRightsAction}
                    userId={playerInfo.personal.id}
                    updatePlayerAction={updatePlayerAction}
                    personal={playerInfo.personal}
                    playerNotes={playerNotes}
                    getPlayerNotesAction={getPlayerNotesAction}
                    addPlayerNoteAction={addPlayerNoteAction}
                    isLoading={isLoading}
                    manualTransactionAction={manualTransactionAction}
                  />
                </Grid>

                <CognitoDataDialog
                  open={cognitoDataDialogOpen}
                  onClose={() => {
                    setCognitoDataDialogOpen(false);
                  }}
                  cognitoData={cognitoData}
                />

                <ConfirmCognitoStatusDialog
                  open={cognitoStatusDialogOpen}
                  onClose={() => {
                    setCognitoStatusDialogOpen(false);
                  }}
                  id={playerInfo.personal.id}
                  playerNumber={player_number}
                  status={cognitoStatus}
                  action={cognitoStatus == 1 ? 'enable' : 'disable'}
                  updateCognitoStatusAction={updateCognitoStatusAction}
                />

                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    History & Transactions
                  </Typography>
                  <Divider sx={{ marginBottom: '1rem' }} />

                  <AccountTransactionsTab
                    isLoading={isLoading}
                    playerInfo={playerInfo}
                    disableRolloverAction={disableRolloverAction}
                    removeBonusAmountAction={removeBonusAmountAction}
                    deposits={deposits}
                    getPlayerDepositsAction={getPlayerDepositsAction}
                    withdrawals={withdrawals}
                    getPlayerWithdrawalsAction={getPlayerWithdrawalsAction}
                    pendingWagers={pendingWagers}
                    getPlayerPendingWagersAction={getPlayerPendingWagersAction}
                    wagerHistory={wagerHistory}
                    getPlayerWagerHistoryAction={getPlayerWagerHistoryAction}
                    bonusHistory={bonusHistory}
                    getPlayerBonusHistoryAction={getPlayerBonusHistoryAction}
                    rafHistory={rafHistory}
                    getPlayerRafStatsAction={getPlayerRafStatsAction}
                    approveDenyReferralAction={approveDenyReferralAction}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      <Loading loading={isLoading} />
    </div>
  );
};
