import { IBanks } from '../../models/banks';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'bankAdminSlice';

export interface BankAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  banks: IBanks[];
}

export const initialState: BankAdminSliceType = {
  isLoading: false,
  isSaving: false,
  banks: [],
};

export const bankAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
