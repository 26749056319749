import { connect } from 'react-redux';
import { BTCAddressesPage, ExternalProps, ExternalActionProps } from './btc-addresses-page.component';
import { getBtcAddressesAction } from '../../../api/btc-address/actions/get-btc-addresses.action';
import { upsertBtcAddressAction } from '../../../api/btc-address/actions/upsert-btc-address.action';
import { deleteBtcAddressAction } from '../../../api/btc-address/actions/delete-btc-address.action';
import { btcAddressesSelector, isLoadingSliceSelector } from '../../../api/btc-address/btc-address.selector';
import { getBanksAction } from '../../../api/bank/actions/get-banks.action';
import { banksSelector } from '../../../api/bank/bank.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    btcAddresses: btcAddressesSelector(state),
    isLoading: isLoadingSliceSelector(state),
    banks: banksSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getBtcAddressesAction,
  deleteBtcAddressAction,
  upsertBtcAddressAction,
  getBanksAction,
};

export const BTCAddressesPageContainer = connect(mapStateToProps, mapDispatchToProps)(BTCAddressesPage);
