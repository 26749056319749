import { connect } from 'react-redux';
import { LandingPageAdmin, ExternalProps, ExternalActionProps } from './landing-page-admin.component';
import { getLandingPagesAction } from '../../api/landing-page/actions/get-landing-pages.action';
import { upsertLandingPageAction } from '../../api/landing-page/actions/upsert-landing-page.action';
import { updateActiveLandingPageAction } from '../../api/landing-page/actions/update-active-landing-page.action';
import { deleteLandingPageAction } from '../../api/landing-page/actions/delete-landing-page.action';
import { landingPagesSelector } from '../../api/landing-page/landing-page.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    landingPages: landingPagesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getLandingPagesAction,
  upsertLandingPageAction,
  updateActiveLandingPageAction,
  deleteLandingPageAction,
};

export const LandingPageAdminContainer = connect(mapStateToProps, mapDispatchToProps)(LandingPageAdmin);
