import React from 'react';
import { Button, Paper, TextField, Link } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { Redirect } from 'react-router-dom';
import { RoutesDictionary, RoutesEnum } from '../../models/routes';
import { ILogin } from '../../models/login';

export interface ExternalProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: string;
}
export interface ExternalActionProps {
  loginAction: (login: ILogin) => void;
}

export interface InternalProps {
  classes: any;
}

function LoginPageComp(
  props: InternalProps & ExternalProps & ExternalActionProps,
): React.ReactElement<InternalProps & ExternalProps & ExternalActionProps> {
  const { classes, isLoggedIn, loginAction } = props;

  const [login, setLogin] = React.useState<ILogin>({
    username: '',
    password: '',
  });

  const setUsername = (username: string) => {
    setLogin({ ...login, username });
  };

  const setPassword = (password: string) => {
    setLogin({ ...login, password });
  };

  if (isLoggedIn) {
    const { path } = RoutesDictionary[RoutesEnum.Dashboard];
    return <Redirect to={path} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img src={`${process.env.PUBLIC_URL}/logo-black.png`} className={classes.shrinkLogo} />
            <form
              noValidate
              onSubmit={event => {
                event.preventDefault();
                loginAction(login);
              }}
            >
              <TextField
                id='username'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Username'
                value={login.username}
                onChange={event => setUsername(event.target.value)}
                autoFocus
              />
              <TextField
                id='password'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Password'
                type='password'
                value={login.password}
                onChange={event => setPassword(event.target.value)}
              />
              <Link className={classes.moveForgetPasswordToRight} href={`#${RoutesDictionary[RoutesEnum.ForgetPassword].path}`}>
                Forgot Password
              </Link>
              <Button fullWidth variant='contained' color='primary' type='submit'>
                Log In
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}

const styles: any = createStyles({
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: '#2c5a7f',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: 400,
  },
  form: {
    padding: 24,
  },
  moveForgetPasswordToRight: {
    float: 'right',
    display: 'flex',
    paddingBottom: 20,
  },
  shrinkLogo: {
    maxHeight: '90px',
    maxWidth: '90px',
    margin: '0px auto',
    display: 'block',
  },
});

export const LoginPage = withStyles(styles)(LoginPageComp);
