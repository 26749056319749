import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  updatePlayerAction: (x: any) => void;
}

export interface ExternalProps {
  userId: any;
  editedTimes: any;
}

export const UserSettingsCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { userId, editedTimes, updatePlayerAction } = props;

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant='h6' className={classes.headTitle}>
        Player Settings
        <br />
        <small>Additional player settings/resets.</small>
      </Typography>

      <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          Reset # of Profile Updates?
          <br />
          <small>Currently updated: {editedTimes} times.</small>
        </Grid>
        <Grid item xs={6}>
          {editedTimes > 0 ? (
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                updatePlayerAction({
                  userId: userId,
                  is_edited: 0,
                });
              }}
            >
              Reset to 0 Times.
            </Button>
          ) : (
            'User has not updated his profile yet.'
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
