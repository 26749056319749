import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { ILogin } from '../../../models/login';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${userReducer.sliceName}/app`;

const LOGIN = `LOGIN${suffix}`;
export const loginAction = createAction<any>(LOGIN);

const LOGIN_ERROR = `LOGIN_ERROR${suffix}`;
const loginErrorAction = createAction<any>(LOGIN_ERROR);
const LOGIN_SUCCESS = `LOGIN_SUCCESS${suffix}`;
const loginSuccessAction = createAction<any>(LOGIN_SUCCESS);

function* loginWatcher(): SagaIterator {
  yield takeEvery(LOGIN, loginWorker);
}
mergeSaga(loginWatcher);

function* loginWorker(action: any): SagaIterator {
  try {
    const response: any = yield call(getApiPlayer, action.payload);
    const { message, token } = response;

    if (token !== '' && token !== null) {
      setLoginSession(token, response.data);
      yield put(loginSuccessAction(response.data));
    } else {
      yield call(showToast, message, 'error');
      yield put(loginErrorAction(message || 'Something went wrong.'));
    }
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(loginErrorAction(e.response.data.message));
  }
}

const setLoginSession = (token: any, user: any) => {
  try {
    localStorage.setItem('token', token);
    if (user) localStorage.setItem('user', JSON.stringify(user));
    return true;
  } catch (e: any) {
    return false;
  }
};

const getApiPlayer = (login: ILogin): Request => {
  return vipApi(`/login`, 'post', {
    username: login.username,
    password: login.password,
  });
};

const reduceHandlers = {
  [LOGIN]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isLoggedIn: false,
  }),
  [LOGIN_SUCCESS]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
      isLoggedIn: true,
      isLoading: false,
    };
  },
  [LOGIN_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
