export interface ITourCreator {
  id: number;
  name: string;
  page: string;
  steps: any;
  section: string;
  content: string;
  sectionId: any;
  stepName: string;
  stepDescription: string;
  previewImage: string;
  // created_at: string;
  // updated_at: string;
}

export interface ITourCreatorUpdate {
  id?: number;
  name: string;
  page: string;
  steps: any;
  section: string;
  content: string;
  sectionId: any;
  stepName: string;
  stepDescription: string;
  previewImage: string;
  // created_at: string;
  // updated_at: string;
}

export const emptyTourCreator: ITourCreatorUpdate = {
  name: '',
  page: '',
  steps: [],
  section: '',
  content: '',
  sectionId: '',
  stepName: '',
  stepDescription: '',
  previewImage: '',
  // created_at: '',
  // updated_at: '',
};
