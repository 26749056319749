import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_DEPOSITS = `GET_PLAYER_DEPOSITS${suffix}`;

export const getPlayerDepositsAction = createAction<any>(GET_PLAYER_DEPOSITS);

const GET_PLAYER_DEPOSITS_SUCCESS = `GET_PLAYER_DEPOSITS_SUCCESS${suffix}`;
const getPlayerDepositsSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_DEPOSITS_SUCCESS);

const GET_PLAYER_DEPOSITS_ERROR = `GET_PLAYER_DEPOSITS_ERROR${suffix}`;
const getPlayerDepositsErrorAction = createAction<string>(GET_PLAYER_DEPOSITS_ERROR);

function* getPlayerDepositsWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_DEPOSITS, getPlayerDepositsWorker);
}
mergeSaga(getPlayerDepositsWatcher);

function* getPlayerDepositsWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerDepositsApi, action.payload);
    yield put(getPlayerDepositsSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerDepositsErrorAction(e.response.data.message));
  }
}

const getPlayerDepositsApi = (string: any): Request => {
  return vipApi(`/cashier/getPlayerDeposits/${string}`, 'post', {});
};

const reduceHandlers = {
  [GET_PLAYER_DEPOSITS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_DEPOSITS_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      deposits: action.payload,
    };
  },
  [GET_PLAYER_DEPOSITS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
