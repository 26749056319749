export interface IChartAccount {
  id: any;
  account_name: string;
  account_type: string;
  delted: null;
  is_archived: number;
  sub_code: string;
  sub_code_id: string;
  tax_code: string;
  inserted_at: string;
  inserted_by: string;
  updated_at: string;
  updated_by: string;
}

export interface IChartAccountUpload {
  account_name: string;
  account_type: string;
  sub_code: string;
  sub_code_id: string;
  is_archived?: number;
  staff: string;
  id: any;
  previousId: any;
  action: string;
}

export const emptyChartAccount: IChartAccountUpload = {
  account_name: '',
  account_type: '',
  sub_code: '',
  sub_code_id: '',
  is_archived: 0,
  staff: '',
  id: '',
  previousId: '',
  action: '',
};
