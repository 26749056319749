import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { RuleSliceType, ruleReducer } from '../rule.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${ruleReducer.sliceName}/app`;

const DELETE_RULE = `DELETE_RULE${suffix}`;
export const deleteRuleAction = createAction<number>(DELETE_RULE);

export const DELETE_RULE_SUCCESS = `DELETE_RULE_SUCCESS${suffix}`;
export const deleteRuleSuccessAction = createAction<number>(DELETE_RULE_SUCCESS);

const DELETE_RULE_ERROR = `DELETE_RULE_ERROR${suffix}`;
export const deleteRuleErrorAction = createAction<string>(DELETE_RULE_ERROR);

function* deleteRuleWatcher(): SagaIterator {
  yield takeEvery(DELETE_RULE, deleteRuleWorker);
}
mergeSaga(deleteRuleWatcher);

function* deleteRuleWorker(action: any): SagaIterator {
  try {
    yield call(deleteRule, action.payload);
    yield put(deleteRuleSuccessAction(action.payload));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteRuleErrorAction(e.response.data.message));
  }
}

const deleteRule = (id: number): Request => {
  const url = '/rules/DeleteRule/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_RULE]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [DELETE_RULE_SUCCESS]: (slice: RuleSliceType): RuleSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_RULE_ERROR]: (slice: RuleSliceType): RuleSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

ruleReducer.addActionReducerMap(reduceHandlers);
