import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { IAgentPayout } from '../../../models/agent-payout';
import { Loading } from '../../../shared/loading/loading.component';
import { AgentPayoutsTable } from './components/agent-payouts-table.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getAgentPayoutsAction: () => void;
}

export interface ExternalProps {
  payouts: IAgentPayout[];
  isLoading: any;
}

export const AgentPayoutsPage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoading, payouts, getAgentPayoutsAction } = props;

  React.useEffect(() => {
    getAgentPayoutsAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      Agent Payouts Page
      <Loading loading={isLoading} />
      {payouts && <AgentPayoutsTable payouts={payouts} />}
    </div>
  );
};
