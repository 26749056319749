import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { CreatePaymentMethodDialog } from './components/create-payment-method-dialog';
import { emptyPaymentMethod, IPaymentMethods, IPaymentMethodsUpload } from '../../../models/payment-methods';
import { PaymentMethodsTable } from './components/payment-methods-table';
import { Loading } from '../../../shared/loading/loading.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '0rem',
    },
  }),
);

export interface ExternalActionProps {
  getPaymentMethodsAction: () => void;
  upsertPaymentMethodAction: (paymentMethod: IPaymentMethodsUpload) => void;
  deletePaymentMethodAction: (paymentMethod: IPaymentMethodsUpload) => void;
}

export interface ExternalProps {
  paymentMethods: IPaymentMethods[];
  isLoading: boolean;
  isSaving: boolean;
}

export const PaymentMethodsPage = (props: ExternalActionProps & ExternalProps) => {
  const { paymentMethods, getPaymentMethodsAction, upsertPaymentMethodAction, deletePaymentMethodAction, isLoading, isSaving } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState(emptyPaymentMethod);
  const classes = useStyles();

  useEffect(() => {
    getPaymentMethodsAction();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonMargin}
          size='small'
          onClick={() => {
            setSelected(emptyPaymentMethod);
            setEditOrAddModal(true);
          }}
        >
          Create Payment Method
        </Button>
        <CreatePaymentMethodDialog
          open={editOrAddModal}
          onClose={() => {
            setSelected(emptyPaymentMethod);
            setEditOrAddModal(false);
          }}
          selected={selected}
          setSelected={setSelected}
          paymentMethod={selected}
          upsertPaymentMethodAction={upsertPaymentMethodAction}
        />
      </div>

      <PaymentMethodsTable
        paymentMethods={paymentMethods}
        selected={selected}
        setSelected={setSelected}
        setEditOrAddModal={setEditOrAddModal}
        deletePaymentMethodsAction={deletePaymentMethodAction}
        upsertPaymentMethodAction={upsertPaymentMethodAction}
      />
      <Loading loading={isLoading || isSaving} />
    </div>
  );
};
