import { connect } from 'react-redux';
import { getReferralsAction } from '../../../api/referrals/actions/get-all-stats.action';
import {
  isLoadingSelector,
  referralsCountSelector,
  referralsSelector,
  topClaimedSelector,
  topSignedUpSelector,
} from '../../../api/referrals/referrals.selector';
import { ReferralsPage, ExternalActionProps, ExternalProps } from './referrals-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    referrals: referralsSelector(state),
    topClaimed: topClaimedSelector(state),
    topSignedUp: topSignedUpSelector(state),
    referralsCount: referralsCountSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getReferralsAction,
};

export const ReferralsPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReferralsPage);
