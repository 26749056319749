import { ILandingPage } from '../../models/landing-page';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'landingPageAdminSlice';

export interface LandingPageAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  landingPages: ILandingPage[];
}

export const initialState: LandingPageAdminSliceType = {
  isLoading: false,
  isSaving: false,
  landingPages: [],
};

export const landingPageAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
