import { createSelector } from 'reselect';
import { bonusesAdminReducer, BonusesAdminSliceType } from './bonuses.reducer';

export const bonusesAdminSliceSelector = (state: any): any => state[bonusesAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  bonusesAdminSliceSelector,
  (bonusesAdminSlice: BonusesAdminSliceType) => bonusesAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  bonusesAdminSliceSelector,
  (bonusesAdminSlice: BonusesAdminSliceType) => bonusesAdminSlice.isSaving,
);

export const bonusesSelector = createSelector(
  bonusesAdminSliceSelector,
  (bonusesAdminSlice: BonusesAdminSliceType) => bonusesAdminSlice.bonuses,
);

export const profilesSelector = createSelector(
  bonusesAdminSliceSelector,
  (bonusesAdminSlice: BonusesAdminSliceType) => bonusesAdminSlice.profiles,
);
