import { createSelector } from 'reselect';
import { userReducer, UserSliceType } from './user.reducer';

export const userSliceSelector = (state: any): any => state[userReducer.sliceName];

export const isLoadingSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.isLoading || false);

export const isLoggedInSelector = createSelector(
  userSliceSelector,
  (userSlice: UserSliceType) => userSlice.isLoggedIn || (window && window.location.href.split('#')[0].indexOf('mock=1') !== -1),
);

export const errorSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.error);

export const loggedInUserSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.loggedInUser);

export const userRoleSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.role);

export const depositsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardDeposits);

export const withdrawalsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardWithdrawals);

export const signupsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardSignUps);

export const referralsSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardReferrals);

export const dateSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardDate);

export const packagesSelector = createSelector(userSliceSelector, (userSlice: UserSliceType) => userSlice.dashboardPackages);
