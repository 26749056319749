import { createSelector } from 'reselect';
import { ruleReducer, RuleSliceType } from './rule.reducer';

export const ruleSliceSelector = (state: any): any => state[ruleReducer.sliceName];

export const isLoadingSliceSelector = createSelector(ruleSliceSelector, (ruleSlice: RuleSliceType) => ruleSlice.isLoading);

export const isSavingSliceSelector = createSelector(ruleSliceSelector, (ruleSlice: RuleSliceType) => ruleSlice.isSaving);

export const ruleSelector = createSelector(ruleSliceSelector, (ruleSlice: RuleSliceType) => ruleSlice.rules);
