import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ILandingPage } from '../../../models/landing-page';
import { LandingPageAdminSliceType, landingPageAdminReducer } from '../landing-page.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${landingPageAdminReducer.sliceName}/app`;

const UPDATE_ACTIVE_LANDING_PAGE = `UPDATE_ACTIVE_LANDING_PAGE${suffix}`;
export const updateActiveLandingPageAction = createAction<ILandingPage>(UPDATE_ACTIVE_LANDING_PAGE);

export const UPDATE_ACTIVE_LANDING_PAGE_SUCCESS = `UPDATE_ACTIVE_LANDING_PAGE_SUCCESS${suffix}`;
export const updateActiveLandingPageSuccessAction = createAction(UPDATE_ACTIVE_LANDING_PAGE_SUCCESS);

const UPDATE_ACTIVE_LANDING_PAGE_ERROR = `UPDATE_ACTIVE_LANDING_PAGE_ERROR${suffix}`;
export const updateActiveLandingPageErrorAction = createAction<string>(UPDATE_ACTIVE_LANDING_PAGE_ERROR);

function* updateActiveLandingPageWatcher(): SagaIterator {
  yield takeEvery(UPDATE_ACTIVE_LANDING_PAGE, updateActiveLandingPageWorker);
}
mergeSaga(updateActiveLandingPageWatcher);

function* updateActiveLandingPageWorker(action: any): SagaIterator {
  console.log('updateActiveLandingPageWorker');
  try {
    const result = yield call(setActive, action.payload.id, action.payload.active === 1 ? 0 : 1);
    console.log(result);
    if (result.status == 'Success') {
      yield put(updateActiveLandingPageSuccessAction(result));
    } else {
      yield call(showToast, result.msg, 'error');
      yield put(updateActiveLandingPageErrorAction(result.msg));
    }
  } catch (e: any) {
    yield put(updateActiveLandingPageErrorAction(e));
  }
}

const setActive = (id: number, active: number): Request => {
  const url = '/LandingPage/SetActive';
  return vipApi(url, 'post', { id, active });
};

const reduceHandlers = {
  [UPDATE_ACTIVE_LANDING_PAGE]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPDATE_ACTIVE_LANDING_PAGE_SUCCESS]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPDATE_ACTIVE_LANDING_PAGE_ERROR]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

landingPageAdminReducer.addActionReducerMap(reduceHandlers);
