import { createTheme, adaptV4Theme } from '@mui/material/styles';

// const navigationbuttoncolors = "#999999";
// const navigationbackground = "#222222";
// const green: "#86a645";
// const profilebuttononnavcolor = "#5e5e5e";
// const dropdowns: "#ebebeb";
// const reportingpanel: "#d7d7d7";
// const reportingtablerowone: "#e1e1e1";
// const row2: "#ebebeb";
// const dropdownarrows: "#808080";
// const fontdark: "#222222";
// const fontlight: "#808080";
// const lightershadeofgradient: "#caf881";
// const darkershade: "#819f44";

// A custom theme for this app
const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1f4d72', // c8a964
      },
      secondary: {
        main: '#c8a964', // 2c5a7f
      },
    },
  }),
);

export default theme;
