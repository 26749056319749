import React, { useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
} from '@mui/material';
import { Loading } from '../../shared/loading/loading.component';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { LineRulesServiceClient } from './services/line-rules.service';
import { RowLeagues } from './components/row-leagues';
import { RowGames } from './components/row-games';
import { RowLeaguesRules } from './components/row-league-rules';
import { LineRuleForm } from './components/line-rule-form';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { showToast } from '../../utils/toast/show-errors';
import { useConfirm } from 'material-ui-confirm';
import { RowGameRules } from './components/row-game-rules';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    background: '#FFF',
    border: '2px solid #000',
    p: 4,
    padding: '15px',
  },
  textCenter: {
    textAlign: 'center',
  },
});
export interface ExternalActionProps {
  any2: any;
}

export interface ExternalProps {
  any: any;
}
export const LineRulesPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { any, any2 } = props;
  const [view, setView] = React.useState<number>(1);
  const [leagueList, setLeagueList] = React.useState<any[]>([]);
  const [gamesLeagueList, setGamesLeagueList] = React.useState<any[]>([]);
  const [leagueRules, setLeagueRules] = React.useState<any[]>([]);
  const [gameRules, setGameRules] = React.useState<any[]>([]);
  const [formRule, setFormRule] = React.useState<any>({
    description: '',
    total: 0,
    spread: 0,
    money_line: 0,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [formNewLeagueGameRule, setFormNewLeagueGameRule] = React.useState<any>({});
  const _lineRuleService = LineRulesServiceClient;
  const confirm = useConfirm();

  useEffect(() => {
    InitComponent();
    console.log(any);
    console.log(any2);
  }, []);

  const InitComponent = async () => {
    getLeagues();
    getLeagueRules();
    getGamesRules();
  };

  //Here League List
  const getLeagues = async () => {
    setIsLoading(true);
    const leaguesApi = await _lineRuleService.getActiveLeaguesByUsername({
      username: 'VIP16308',
    });
    setLeagueList(leaguesApi.data ?? []);
    setIsLoading(false);
  };

  //Here Rules League List
  const getLeagueRules = async () => {
    setIsLoading(true);
    const leagueRUles = await _lineRuleService.getLeaguesRules({});
    setLeagueRules(leagueRUles?.data?.data ?? []);
    setIsLoading(false);
  };

  const getGamesRules = async () => {
    setIsLoading(true);
    const gameRules = await _lineRuleService.getGamesRules({});
    setGameRules(gameRules?.data?.data ?? []);
    setIsLoading(false);
  };

  const getGamesLeague = async (league: any) => {
    setIsLoading(true);
    const gameLeagues = await _lineRuleService.getSchedulesByUsernameAndLeagueIds({
      username: 'VIP16308',
      leagueIds: league.id,
      Utc: 0 - new Date().getTimezoneOffset() / 60,
    });
    if (gameLeagues?.data) setGamesLeagueList([...gamesLeagueList, gameLeagues.data]);
    setIsLoading(false);
  };

  const addUpdateRule = async (leagueRule: any) => {
    if (leagueRule.deleted == 1) {
      if (
        await confirm({ description: `Yes, I am sure to remove this rule: ` + leagueRule.description })
          .then(() => false)
          .catch(() => true)
      )
        return;
    }
    setIsLoading(true);
    const result = await _lineRuleService.addUpdate(leagueRule);
    setIsLoading(false);
    if ((!result.error && !leagueRule.id) || leagueRule.deleted == 1) {
      setFormRule({
        description: '',
        total: 0,
        spread: 0,
        money_line: 0,
      });
      setView(1);
      getLeagueRules();
    }
  };
  const modalClose = () => setOpenModal(false);

  const addUpdateLeagueGameRuleForm = async () => {
    if (!formNewLeagueGameRule?.league && !formNewLeagueGameRule?.game) {
      showToast({ message: 'Error: Select one league or game' }, 'error');
      return;
    }
    if (formNewLeagueGameRule?.league && !formNewLeagueGameRule?.rule) {
      showToast({ message: 'Error: Select one rule' }, 'error');
      return;
    }
    setIsLoading(true);
    let result = null;
    if (formNewLeagueGameRule.league) result = await _lineRuleService.addUpdateLeagueRule(formNewLeagueGameRule);
    else result = await _lineRuleService.addUpdateGameRule(formNewLeagueGameRule);
    setIsLoading(false);
    if (!result.error) {
      setFormNewLeagueGameRule({});
      setOpenModal(false);
      getLeagueRules();
      getGamesRules();
    }
  };

  const addUpdateLeagueRule = async (data: any) => {
    if (data.deleted == 1) {
      if (
        await confirm({ description: `Yes, I am sure to remove this league-rule: ` + data.description })
          .then(() => false)
          .catch(() => true)
      )
        return;
    }
    setIsLoading(true);
    const result = await _lineRuleService.addUpdateLeagueRule(data);
    setIsLoading(false);
    if (!result.error) {
      getLeagueRules();
    }
  };

  const addUpdateGameRule = async (data: any) => {
    if (data.deleted == 1) {
      if (
        await confirm({ description: `Yes, I am sure to remove this game-rule: ` + data.description })
          .then(() => {
            data.deleted = 1;
            data.status = 0;
            return false;
          })
          .catch(() => {
            data.deleted = 0;
            return true;
          })
      )
        return;
    }
    setIsLoading(true);
    const result = await _lineRuleService.addUpdateGameRule(data);
    setIsLoading(false);
    if (!result.error) {
      getGamesRules();
    }
  };
  return (
    <>
      <div className={clsx(classes.root)}>
        <Loading loading={isLoading} />

        {(view == 1 || view == 2) && (
          <>
            <Grid container direction='row' style={{ marginTop: '15px' }}>
              <Grid item xs={12}>
                <Button variant='contained' endIcon={<SendIcon />} onClick={() => setView(3)}>
                  New Rule
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.textCenter} style={{ marginTop: '25px' }}>
                <Button variant='contained' style={view == 1 ? { background: '#3498DB' } : {}} onClick={() => setView(1)}>
                  Leagues Rules
                </Button>
                <Button variant='contained' style={view == 2 ? { background: '#3498DB' } : {}} onClick={() => setView(2)}>
                  Games Rules
                </Button>
              </Grid>
            </Grid>

            <Grid container direction='row'>
              {view == 1 && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label='collapsible table'>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Rules Leagues</TableCell>
                          <TableCell align='right'>TOTALS</TableCell>
                          <TableCell align='right'>SPREAD</TableCell>
                          <TableCell align='right'>MONEYLINE</TableCell>
                          <TableCell align='right'>STATUS</TableCell>
                          <TableCell align='right'>ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leagueRules.map((data: any, index: number) => (
                          <RowLeaguesRules
                            key={'RTLeagues' + index}
                            data={data}
                            addUpdateRule={addUpdateRule}
                            leagueRules={leagueRules}
                            setLeagueRules={setLeagueRules}
                            addUpdateLeagueRule={addUpdateLeagueRule}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {view == 2 && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table size='small' aria-label='purchases' className={classes.root}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>GAME</TableCell>
                          <TableCell align='center'>ROTATION#</TableCell>
                          <TableCell align='center'>TOTALS</TableCell>
                          <TableCell align='center'>SPREAD</TableCell>
                          <TableCell align='center'>MONEYLINE</TableCell>
                          <TableCell align='center'>OVERWRITE</TableCell>
                          <TableCell align='center'>STATUS</TableCell>
                          <TableCell align='center'>ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gameRules.map((data: any, index: number) => (
                          <RowGameRules
                            key={'RTGames' + index}
                            data={data}
                            gameRules={gameRules}
                            setGameRules={setGameRules}
                            addUpdateGameRule={addUpdateGameRule}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>

            <Grid container direction='row' style={{ marginTop: '15px' }}>
              <Grid item xs={4}>
                <TableContainer component={Paper}>
                  <Table aria-label='collapsible table'>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Sports</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leagueList.map((data: any, index: number) => {
                        return (
                          <RowLeagues
                            data={data}
                            getGamesLeague={getGamesLeague}
                            key={'leagueT' + index}
                            formNewLeagueGameRule={formNewLeagueGameRule}
                            setFormNewLeagueGameRule={setFormNewLeagueGameRule}
                            setOpenModal={setOpenModal}
                          ></RowLeagues>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={8}>
                <TableContainer component={Paper}>
                  <Table aria-label='collapsible table'>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Games</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gamesLeagueList.map((data: any, index: number) => (
                        <RowGames
                          data={data}
                          key={'leagueT' + index}
                          formNewLeagueGameRule={formNewLeagueGameRule}
                          setFormNewLeagueGameRule={setFormNewLeagueGameRule}
                          setOpenModal={setOpenModal}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
        {view == 3 && (
          <LineRuleForm formRule={formRule} setFormRule={setFormRule} addUpdateRule={addUpdateRule} setView={setView}></LineRuleForm>
        )}

        <Modal open={openModal} onClose={modalClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <Box className={classes.modal}>
            <Typography id='modal-modal-title' variant='h6' component='h2' className={classes.textCenter}>
              You are adding a new rule for leagues or games.
            </Typography>
            <Grid container direction='row' style={{ marginTop: '15px' }}>
              {formNewLeagueGameRule.league && (
                <Grid item xs={12}>
                  <TextField
                    label={'League Selected'}
                    style={{ width: '100%' }}
                    id='outlined-number'
                    disabled={true}
                    value={formNewLeagueGameRule.league.desc}
                  />
                </Grid>
              )}

              {formNewLeagueGameRule.game && (
                <Grid item xs={12}>
                  <TextField
                    label={'Game Selected'}
                    style={{ width: '100%', marginBottom: '25px' }}
                    id='outlined-number'
                    disabled={true}
                    value={`${formNewLeagueGameRule.game.vtm} - ${formNewLeagueGameRule.game.htm}`}
                  />
                </Grid>
              )}
              {formNewLeagueGameRule.league && (
                <Grid item xs={12}>
                  <FormControl variant='filled' fullWidth className={classes.formControl} style={{ padding: 15 }}>
                    <InputLabel id='form-rule-select'>Select One Rule</InputLabel>
                    <Select
                      value={formNewLeagueGameRule.rule}
                      fullWidth
                      labelId='form-rule-select'
                      onChange={e => {
                        formNewLeagueGameRule.rule = e.target.value;
                        setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                      }}
                    >
                      {leagueRules &&
                        leagueRules.map((rule: any, j: number) => {
                          return (
                            <MenuItem value={rule} key={'formRule' + j}>
                              <span dangerouslySetInnerHTML={{ __html: rule.description }} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {formNewLeagueGameRule.game && (
                <Grid item xs={8}>
                  <TextField
                    label={'DESCRIPTION'}
                    style={{ width: '100%' }}
                    id='outlined-number'
                    value={formNewLeagueGameRule.description}
                    onChange={(event: any) => {
                      formNewLeagueGameRule.description = event.target.value;
                      setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                    }}
                  />
                </Grid>
              )}

              {formNewLeagueGameRule.game && (
                <Grid item xs={4}>
                  <TextField
                    label={'ROTATION #'}
                    style={{ width: '100%' }}
                    id='outlined-number'
                    disabled={true}
                    value={formNewLeagueGameRule.rotation}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formNewLeagueGameRule.overwrite == 1}
                      name='overwrite'
                      color='primary'
                      onChange={() => {
                        if (formNewLeagueGameRule.overwrite == 1) formNewLeagueGameRule.overwrite = 0;
                        else formNewLeagueGameRule.overwrite = 1;
                        setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                      }}
                    />
                  }
                  label='Overwrite'
                />
                <span style={{ color: '#FD7776' }}>If you check this, you are going to overwrite the values of the original rule.</span>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label={'TOTAL'}
                  id='outlined-number'
                  type='number'
                  inputProps={{ inputMode: 'numeric' }}
                  value={formNewLeagueGameRule.total}
                  onChange={(event: any) => {
                    formNewLeagueGameRule.total = Number(event.target.value);
                    setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={'SPREAD'}
                  id='outlined-number'
                  type='number'
                  inputProps={{ inputMode: 'numeric' }}
                  value={formNewLeagueGameRule.spread}
                  onChange={(event: any) => {
                    formNewLeagueGameRule.spread = Number(event.target.value);
                    setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={'MONEY LINE'}
                  id='outlined-number'
                  type='number'
                  inputProps={{ inputMode: 'numeric' }}
                  value={formNewLeagueGameRule.money_line}
                  onChange={(event: any) => {
                    formNewLeagueGameRule.money_line = Number(event.target.value);
                    setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                  }}
                />
              </Grid>

              <Grid item xs={12} className={classes.textCenter} style={{ marginTop: '25px' }}>
                <Button
                  variant='contained'
                  color='success'
                  style={{ margin: '4px' }}
                  endIcon={<SendIcon />}
                  onClick={() => addUpdateLeagueGameRuleForm()}
                >
                  Save
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  style={{ margin: '4px' }}
                  endIcon={<DoDisturbIcon />}
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
};
