export interface IBonusProfile {
  name: string;
  description: string;
  // sportsbook
  straight_bet: string;
  parlay_bet: string;
  teaser_bet: string;
  if_bet: string;
  reverse_bet: string;
  contest_futures: string;
  props_builder: string;
  live_wager_straight: string;
  live_wager_parlay: string;
  // Casino
  live_casino: string;
  poker: string;
  table_games: string;
  slots: string;
  video_poker: string;
  bingo: string;
  horse_racing: string;
}

export const emptyBonusProfile: IBonusProfile = {
  name: '',
  description: '',
  // Sportsbook
  straight_bet: '',
  parlay_bet: '',
  teaser_bet: '',
  if_bet: '',
  reverse_bet: '',
  contest_futures: '',
  props_builder: '',
  live_wager_straight: '',
  live_wager_parlay: '',
  // Casino
  live_casino: '',
  poker: '',
  table_games: '',
  slots: '',
  video_poker: '',
  bingo: '',
  horse_racing: '',
};
