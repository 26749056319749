import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Loading } from '../loading/loading.component';
import { AdminDashboard } from '../../area/admin/dashboard/admin-dashboard.component';
import { CustomerDashboard } from '../../area/customer/dashboard/customer-dashboard';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getAdminDashboardAction: (x: any) => void;
  getCustomerDashboardAction: (x: any) => void;
}

export interface ExternalProps {
  user: any;
  deposits: any;
  withdrawals: any;
  signups: any;
  referrals: any;
  isLoading: any;
  date: any;
  packages: any;
}

export const DashboardPage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const {
    user,
    isLoading,
    getAdminDashboardAction,
    getCustomerDashboardAction,
    deposits,
    withdrawals,
    signups,
    referrals,
    date,
    packages,
  } = props;

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      {user.role == 'customer' && (
        <CustomerDashboard
          getCustomerDashboardAction={getCustomerDashboardAction}
          signups={signups}
          isLoading={isLoading}
          date={date}
          packages={packages}
        />
      )}
      {user.role == 'admin' && (
        <AdminDashboard
          deposits={deposits}
          withdrawals={withdrawals}
          signups={signups}
          referrals={referrals}
          isLoading={isLoading}
          date={date}
          packages={packages}
          getAdminDashboardAction={getAdminDashboardAction}
        />
      )}
    </div>
  );
};
