export interface IRoute {
  name: RoutesEnum;
  path: string;
}

export enum RoutesEnum {
  ForgetPassword = 'Forget Password',
  LoginPage = 'Login',
  Dashboard = 'Dashboard',
  LandingPageAdmin = 'Landing Page',
  Marketing = 'Marketing',
  // Cashier
  Cashier = 'Cashier',
  Transactions = 'Manage Transactions',
  PaymentMethods = 'Payment Methods',
  Banks = 'Banks',
  BitcoinAddresses = 'BTC Addresses',
  // Player
  PlayerInfo = 'Player Info',
  Referrals = 'Referrals',
  TourCreator = 'Tour Creator',
  // Guide
  Guides = 'Guides',
  Rules = 'Rules',
  // Agent
  AgentManagement = 'Management',
  AgentCommStructures = 'Comm. Structures',
  AgentPayouts = 'Payouts',

  // Accounting
  Reports = 'Reports',
  JournalEntries = 'Journal Entries',
  ChartOfAccounts = 'Chart of Accounts',
  // Bonus
  BonusProfiles = 'Profiles',
  Bonuses = 'Bonuses',
  LineRules = 'Line Rules',
}

export const RoutesDictionary: { [key in RoutesEnum]: IRoute } = {
  [RoutesEnum.ForgetPassword]: {
    name: RoutesEnum.ForgetPassword,
    path: '/forget-password',
  },
  [RoutesEnum.LoginPage]: {
    name: RoutesEnum.LoginPage,
    path: '/login',
  },
  [RoutesEnum.Dashboard]: { name: RoutesEnum.Dashboard, path: '/' },
  [RoutesEnum.LandingPageAdmin]: {
    name: RoutesEnum.LandingPageAdmin,
    path: '/admin/landing-page',
  },
  [RoutesEnum.Marketing]: {
    name: RoutesEnum.LandingPageAdmin,
    path: '/marketing/landing-pages',
  },
  // Cashier
  [RoutesEnum.Cashier]: {
    name: RoutesEnum.Cashier,
    path: '/cashier',
  },
  [RoutesEnum.Transactions]: {
    name: RoutesEnum.Transactions,
    path: '/cashier/transactions',
  },
  [RoutesEnum.PaymentMethods]: {
    name: RoutesEnum.PaymentMethods,
    path: '/cashier/payment-methods',
  },
  [RoutesEnum.Banks]: {
    name: RoutesEnum.Banks,
    path: '/cashier/banks',
  },
  [RoutesEnum.BitcoinAddresses]: {
    name: RoutesEnum.BitcoinAddresses,
    path: '/cashier/btc-addresses',
  },
  // Player
  [RoutesEnum.PlayerInfo]: {
    name: RoutesEnum.PlayerInfo,
    path: '/cashier/player-info',
  },
  [RoutesEnum.Referrals]: {
    name: RoutesEnum.Referrals,
    path: '/cashier/referrals',
  },
  [RoutesEnum.TourCreator]: {
    name: RoutesEnum.TourCreator,
    path: '/tour-creator',
  },
  // Guide
  [RoutesEnum.Guides]: {
    name: RoutesEnum.Guides,
    path: '/guide',
  },
  [RoutesEnum.Rules]: {
    name: RoutesEnum.Rules,
    path: '/rule',
  },
  // Agent
  [RoutesEnum.AgentManagement]: {
    name: RoutesEnum.AgentManagement,
    path: '/agent-management',
  },
  [RoutesEnum.AgentCommStructures]: {
    name: RoutesEnum.AgentCommStructures,
    path: '/agent-comm-structures',
  },
  [RoutesEnum.AgentPayouts]: {
    name: RoutesEnum.AgentPayouts,
    path: '/agent-payouts',
  },
  // Bonus
  [RoutesEnum.BonusProfiles]: {
    name: RoutesEnum.BonusProfiles,
    path: '/bonus-profiles',
  },
  [RoutesEnum.Bonuses]: {
    name: RoutesEnum.Bonuses,
    path: '/bonuses',
  },
  // Accounting
  [RoutesEnum.Reports]: {
    name: RoutesEnum.Reports,
    path: '/accounting/reports',
  },
  [RoutesEnum.JournalEntries]: {
    name: RoutesEnum.JournalEntries,
    path: '/accounting/journal-entries',
  },
  [RoutesEnum.ChartOfAccounts]: {
    name: RoutesEnum.ChartOfAccounts,
    path: '/accounting/chart-of-accounts',
  },
  // Line Rules
  [RoutesEnum.LineRules]: {
    name: RoutesEnum.LineRules,
    path: '/line-rules',
  },
};
