import { createSelector } from 'reselect';
import { landingPageAdminReducer, LandingPageAdminSliceType } from './landing-page.reducer';

export const landingPageAdminSliceSelector = (state: any): any => state[landingPageAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  landingPageAdminSliceSelector,
  (langdingPageAdminSlice: LandingPageAdminSliceType) => langdingPageAdminSlice.isLoading,
);

export const landingPagesSelector = createSelector(
  landingPageAdminSliceSelector,
  (langdingPageAdminSlice: LandingPageAdminSliceType) => langdingPageAdminSlice.landingPages,
);
