import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import { IPaymentMethodsUpload } from '../../../../models/payment-methods';
import { ImageUploader } from './image-uploader.component';

const useStyles = makeStyles({
  dialogBody: {
    padding: '0px 10px 10px 10px',
  },
  row: {
    display: 'flex',
    padding: 5,
  },
  column50: {
    width: '50%',
    padding: 5,
  },
  imageInput: {
    display: 'none',
  },
});

export interface ExternalProps {
  open: boolean;
  paymentMethod: IPaymentMethodsUpload;
  selected: IPaymentMethodsUpload;
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertPaymentMethodAction: (paymentMethod: IPaymentMethodsUpload) => void;
  setSelected: (yup: IPaymentMethodsUpload) => void;
}

export const CreatePaymentMethodDialog = (props: ExternalProps & ExternalActionProps) => {
  const { paymentMethod, upsertPaymentMethodAction, onClose, open, selected, setSelected } = props;

  const classes = useStyles();

  return (
    <Dialog fullWidth={true} maxWidth='md' onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitle id='simple-dialog-title'>{paymentMethod.id == null ? 'Add Payment Method' : 'Edit Payment Method'}</DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <div className={classes.column50}>
            <FormControl fullWidth>
              <InputLabel htmlFor='method_type'>Type</InputLabel>
              <Select
                label='Type'
                value={selected.method_type}
                onChange={event =>
                  setSelected({
                    ...selected,
                    method_type: event.target.value as number,
                  })
                }
                inputProps={{
                  name: 'method_type',
                  id: 'method_type',
                }}
                fullWidth
              >
                <MenuItem value={1}>Deposit Only</MenuItem>
                <MenuItem value={2}>Withdrawal Only</MenuItem>
                <MenuItem value={3}>Both Dep/WD</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.column50}>
            <TextField
              label='Name'
              onChange={event =>
                setSelected({
                  ...selected,
                  name: event.target.value,
                })
              }
              value={selected.name}
              placeholder='Name'
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column50}>
            <TextField
              label='Deposit Min'
              onChange={event =>
                setSelected({
                  ...selected,
                  deposit_min: event.target.value as unknown as number,
                })
              }
              value={selected.deposit_min}
              placeholder='Deposit Min'
              type='number'
              fullWidth
            />
          </div>
          <div className={classes.column50}>
            <TextField
              label='Deposit Max'
              onChange={event =>
                setSelected({
                  ...selected,
                  deposit_max: event.target.value as unknown as number,
                })
              }
              value={selected.deposit_max}
              placeholder='Deposit Max'
              type='number'
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column50}>
            <TextField
              label='Withdrawal Min'
              onChange={event =>
                setSelected({
                  ...selected,
                  withdrawal_min: event.target.value as unknown as number,
                })
              }
              value={selected.withdrawal_min}
              placeholder='Withdrawal Min'
              type='number'
              fullWidth
            />
          </div>
          <div className={classes.column50}>
            <TextField
              label='Withdrawal Max'
              onChange={event =>
                setSelected({
                  ...selected,
                  withdrawal_max: event.target.value as unknown as number,
                })
              }
              value={selected.withdrawal_max}
              placeholder='Withdrawal Max'
              type='number'
              fullWidth
            />
          </div>
        </div>
        <ImageUploader
          label='Image'
          onImageChange={event => {
            if (event && event.target && event.target.files && event.target.files.length > 0)
              setSelected({
                ...selected,
                image: event.target.files ? event.target.files[0] : undefined,
              });
          }}
          imgUrl={selected.id ? selected.image : undefined}
          imgFile={selected.image}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            upsertPaymentMethodAction(selected);
            onClose();
          }}
          variant='contained'
          color='primary'
        >
          {paymentMethod.id == null ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
