import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_WITHDRAWALS = `GET_PLAYER_WITHDRAWALS${suffix}`;

export const getPlayerWithdrawalsAction = createAction<any>(GET_PLAYER_WITHDRAWALS);

const GET_PLAYER_WITHDRAWALS_SUCCESS = `GET_PLAYER_WITHDRAWALS_SUCCESS${suffix}`;
const getPlayerWithdrawalsSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_WITHDRAWALS_SUCCESS);

const GET_PLAYER_WITHDRAWALS_ERROR = `GET_PLAYER_WITHDRAWALS_ERROR${suffix}`;
const getPlayerWithdrawalsErrorAction = createAction<string>(GET_PLAYER_WITHDRAWALS_ERROR);

function* getPlayerWithdrawalsWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_WITHDRAWALS, getPlayerWithdrawalsWorker);
}
mergeSaga(getPlayerWithdrawalsWatcher);

function* getPlayerWithdrawalsWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerWithdrawalsApi, action.payload);
    yield put(getPlayerWithdrawalsSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerWithdrawalsErrorAction(e.response.data.message));
  }
}

const getPlayerWithdrawalsApi = (string: any): Request => {
  return vipApi(`/cashier/getPlayerWithdrawals/${string}`, 'post', {});
};

const reduceHandlers = {
  [GET_PLAYER_WITHDRAWALS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_WITHDRAWALS_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      withdrawals: action.payload,
    };
  },
  [GET_PLAYER_WITHDRAWALS_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
