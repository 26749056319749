import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  iconOrder: {
    color: '#bababa',
  },
});

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const TransactionLogsTable = (props: any) => {
  const classes = useStyles();
  const { selectedLogs } = props;
  const [order, setOrder] = useState('timestamp');
  const [orderBy, setOrderBy] = useState('desc');

  const getSort = (rows: { id: number; username: string; timestamp: string; action: string }[]) => {
    const data = rows.sort((a, b) => {
      if (orderBy == 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
    return data;
  };

  const capsFirst = (string: string) => {
    if (string != null && string != '') {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell>Username</StyledTableCell>
            <StyledTableCell>
              Time
              {order == 'timestamp' && orderBy == 'desc' ? (
                <ExpandMoreIcon
                  className={classes.iconOrder}
                  onClick={() => {
                    setOrder('timestamp');
                    setOrderBy('asc');
                  }}
                />
              ) : (
                <ExpandLessIcon
                  className={classes.iconOrder}
                  onClick={() => {
                    setOrder('timestamp');
                    setOrderBy('desc');
                  }}
                />
              )}
            </StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getSort(selectedLogs).map((row: { id: number; username: string; timestamp: string; action: string }) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component='th' scope='row'>
                {row.id}
              </StyledTableCell>
              <StyledTableCell>{capsFirst(row.username)}</StyledTableCell>
              <StyledTableCell>{row.timestamp}</StyledTableCell>
              <StyledTableCell>{capsFirst(row.action)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
