import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IChartAccountUpload } from '../../../models/chart-account';
import { ChartAccountAdminSliceType, chartAccountAdminReducer } from '../chart-account.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${chartAccountAdminReducer.sliceName}/app`;

const DELETE_CHART_ACCOUNT = `DELETE_CHART_ACCOUNT${suffix}`;
export const deleteChartAccountAction = createAction<IChartAccountUpload>(DELETE_CHART_ACCOUNT);

export const DELETE_CHART_ACCOUNT_SUCCESS = `DELETE_CHART_ACCOUNT_SUCCESS${suffix}`;
export const deleteChartAccountSuccessAction = createAction(DELETE_CHART_ACCOUNT_SUCCESS);

const DELETE_CHART_ACCOUNT_ERROR = `DELETE_CHART_ACCOUNT_ERROR${suffix}`;
export const deleteChartAccountErrorAction = createAction<string>(DELETE_CHART_ACCOUNT_ERROR);

function* deleteChartAccountWatcher(): SagaIterator {
  yield takeEvery(DELETE_CHART_ACCOUNT, deleteChartAccountWorker);
}
mergeSaga(deleteChartAccountWatcher);

function* deleteChartAccountWorker(action: any): SagaIterator {
  console.log('deleteChartAccountWorker');
  try {
    const result = yield call(deleteChartAccount, action.payload.id);
    yield put(deleteChartAccountSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteChartAccountErrorAction(e.response.data.message));
  }
}

const deleteChartAccount = (id: number): Request => {
  const url = '/cashier/accounts/' + id;
  return vipApi(url, 'delete', { id });
};

const reduceHandlers = {
  [DELETE_CHART_ACCOUNT]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DELETE_CHART_ACCOUNT_SUCCESS]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_CHART_ACCOUNT_ERROR]: (slice: ChartAccountAdminSliceType): ChartAccountAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

chartAccountAdminReducer.addActionReducerMap(reduceHandlers);
