export interface IRule {
  id?: number;
  name: string;
  category: number;
  content: string;
  staff: string;
  created_at?: string;
  updated_at?: string;
}

export interface IRuleUpdate {
  id?: number;
  name: string;
  category: number;
  content: string;
  staff: string;
  created_at?: string;
  updated_at?: string;
}

export const emptyRule: IRuleUpdate = {
  name: '',
  category: 0,
  content: '',
  staff: '',
};
