import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TransactionAdminSliceType, transactionAdminReducer } from '../transaction.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { ITransactionVerify } from '../../../models/transactions';

const suffix = `/${transactionAdminReducer.sliceName}/app`;

const REMOVE_BONUS = `REMOVE_BONUS${suffix}`;
export const removeBonusAction = createAction(REMOVE_BONUS);

export const REMOVE_BONUS_SUCCESS = `REMOVE_BONUS_SUCCESS${suffix}`;
export const removeBonusSuccessAction = createAction<ITransactionVerify>(REMOVE_BONUS_SUCCESS);

const REMOVE_BONUS_ERROR = `REMOVE_BONUS_ERROR${suffix}`;
export const removeBonusErrorAction = createAction<string>(REMOVE_BONUS_ERROR);

function* removeBonusWatcher(): SagaIterator {
  yield takeEvery(REMOVE_BONUS, removeBonusWorker);
}
mergeSaga(removeBonusWatcher);

function* removeBonusWorker(action: any): SagaIterator {
  try {
    const result = yield call(removeBonus, action.payload);
    yield put(removeBonusSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(removeBonusErrorAction(e.response.data.message));
  }
}

const removeBonus = (transaction: any): Request => {
  const url = '/cashier/transaction/removeBonus/' + transaction;
  return vipApi(url, 'post', {});
};

const reduceHandlers = {
  [REMOVE_BONUS]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
    callTransactions: false,
  }),
  [REMOVE_BONUS_SUCCESS]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      callTransactions: true,
    };
  },
  [REMOVE_BONUS_ERROR]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
    callTransactions: false,
  }),
};

transactionAdminReducer.addActionReducerMap(reduceHandlers);
