import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ILandingPage } from '../../../models/landing-page';
import { LandingPageAdminSliceType, landingPageAdminReducer } from '../landing-page.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${landingPageAdminReducer.sliceName}/app`;

const DELETE_LANDING_PAGE = `DELETE_LANDING_PAGE${suffix}`;
export const deleteLandingPageAction = createAction<ILandingPage>(DELETE_LANDING_PAGE);

export const DELETE_LANDING_PAGE_SUCCESS = `DELETE_LANDING_PAGE_SUCCESS${suffix}`;
export const deleteLandingPageSuccessAction = createAction(DELETE_LANDING_PAGE_SUCCESS);

const DELETE_LANDING_PAGE_ERROR = `DELETE_LANDING_PAGE_ERROR${suffix}`;
export const deleteLandingPageErrorAction = createAction<string>(DELETE_LANDING_PAGE_ERROR);

function* deleteLandingPageWatcher(): SagaIterator {
  yield takeEvery(DELETE_LANDING_PAGE, deleteLandingPageWorker);
}
mergeSaga(deleteLandingPageWatcher);

function* deleteLandingPageWorker(action: any): SagaIterator {
  console.log('deleteLandingPageWorker');
  try {
    const result = yield call(deleteLandingPage, action.payload.id);
    console.log(result);
    if (result.status == 'Success') {
      yield put(deleteLandingPageSuccessAction(result));
    } else {
      yield call(showToast, result.msg, 'error');
      yield put(deleteLandingPageErrorAction(result.msg));
    }
  } catch (e: any) {
    yield put(deleteLandingPageErrorAction(e));
  }
}

const deleteLandingPage = (id: number): Request => {
  const url = '/LandingPage/Delete';
  return vipApi(url, 'post', { id });
};

const reduceHandlers = {
  [DELETE_LANDING_PAGE]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DELETE_LANDING_PAGE_SUCCESS]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_LANDING_PAGE_ERROR]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

landingPageAdminReducer.addActionReducerMap(reduceHandlers);
