import { createSelector } from 'reselect';
import { distributorAdminReducer, DistributorAdminSliceType } from './distributor.reducer';

export const distributorAdminSliceSelector = (state: any): any => state[distributorAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  distributorAdminSliceSelector,
  (distributorAdminSlice: DistributorAdminSliceType) => distributorAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  distributorAdminSliceSelector,
  (distributorAdminSlice: DistributorAdminSliceType) => distributorAdminSlice.isSaving,
);

export const distributorsSelector = createSelector(
  distributorAdminSliceSelector,
  (distributorAdminSlice: DistributorAdminSliceType) => distributorAdminSlice.distributors,
);
