import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'referralsSlice';

export interface ReferralsSliceType {
  isLoading: boolean;
  referrals: any;
  topClaimed: any;
  topSignedUp: any;
  referralsCount: any;
}

export const initialState: ReferralsSliceType = {
  isLoading: false,
  referrals: [],
  topClaimed: [],
  topSignedUp: [],
  referralsCount: '',
};

export const referralsReducer = createAndMergeSliceReducer(sliceName, initialState);
