import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Toolbar, InputLabel, Typography, Grid, TextField, Button, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import { Loading } from '../../../shared/loading/loading.component';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CKEditor } from 'ckeditor4-react';
import theme from '../../../theme';
import { RuleCategoryDialog } from './components/rule-category-dialog';
import { emptyRuleCategory, IRuleCategoryUpdate } from './../../../models/rule-categories';
import { emptyRule } from './../../../models/rule';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  hr: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  paper: {
    padding: 15,
  },
  headDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topLayer: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
  },
  liveView: {
    border: '1px solid #dadada',
    height: '400px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'auto',
  },
  rightPanel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  inputTextName: {
    marginRight: '15px',
    '& .css-a260yi-MuiFormLabel-root-MuiInputLabel-root': {
      color: '#fff',
    },
    '& label, & input': {
      color: '#fff',
    },
  },
  accordainSummery: {
    padding: 0,
    '& .css-o4b71y-MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  accordainDetail: {
    padding: 0,
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderTopLeftRadius: '10px',
  },
  buttonSave: {
    margin: '20px auto',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export interface ExternalActionProps {
  getRuleCategoriesAction: () => void;
  upsertRuleCategoryAction: (x: IRuleCategoryUpdate) => void;
  deleteRuleCategoryAction: (x: number) => void;
  getRuleAction: () => void;
  upsertRuleAction: (x: any) => void;
  deleteRuleAction: (x: any) => void;
}

export interface ExternalProps {
  isLoading: any;
  ruleCategories: any;
  loggedUser: any;
  rules: any;
}

export const Rule = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [description, setDescription] = React.useState('');
  const {
    getRuleCategoriesAction,
    isLoading,
    ruleCategories,
    upsertRuleCategoryAction,
    deleteRuleCategoryAction,
    getRuleAction,
    upsertRuleAction,
    deleteRuleAction,
    loggedUser,
    rules,
  } = props;
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(emptyRuleCategory);

  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedRule, setSelectedRule] = React.useState({
    ...emptyRule,
    staff: loggedUser?.username,
  });
  const [createRule, setCreateRule] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [deleteRule, setDeleteRule] = React.useState(0);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleChangeAccordain = (panel: string) => {
    setExpanded(panel);
  };

  useEffect(() => {
    getRuleCategoriesAction();
    getRuleAction();
  }, []);

  useEffect(() => {
    if (Array.isArray(ruleCategories) && ruleCategories.length > 0) {
      setSelectedCategory(ruleCategories[0].id);
    }
  }, [ruleCategories]);

  useEffect(() => {
    if (Array.isArray(rules) && rules.length > 0) {
      setCreateRule(false);
      setDescription('');
      setSelectedRule({
        ...emptyRule,
        staff: loggedUser?.username,
      });
    }
  }, [rules]);

  const validate = (request: any) => {
    const username = loggedUser.username;
    request.category = selectedCategory;
    request.content = description;
    request.staff = username;
    upsertRuleAction(request);
  };

  const deleteRuleX = (x: number) => {
    setDeleteRule(x);
    setDeleteOpen(true);
  };

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <div className={classes.headDiv}>
        <div>
          <Typography variant='h3'>Rules</Typography>
          <Typography>Rules for players regrading platform</Typography>
        </div>
        <Button variant='contained' onClick={() => setOpen(true)}>
          Categories
        </Button>
      </div>
      <hr className={classes.hr} />
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <Grid container>
          <Grid item xs={8}>
            <Tabs value={value} onChange={handleChange} indicatorColor='primary' textColor='inherit' aria-label='full width tabs example'>
              {Array.isArray(ruleCategories) &&
                ruleCategories &&
                ruleCategories.map((item: any, i: number) => {
                  return (
                    <Tab
                      key={item.id}
                      onClick={() => setSelectedCategory(item.id)}
                      label={item.name}
                      {...a11yProps(i)}
                      className={clsx({ [classes.tabActive]: i === value })}
                    />
                  );
                })}
            </Tabs>
          </Grid>
          <Grid xs={4} style={{ textAlign: 'right' }}>
            <Button
              variant='outlined'
              onClick={() => {
                setSelectedRule({
                  ...emptyRule,
                  staff: loggedUser?.username,
                });
                setDescription('');
                setCreateRule(!createRule);
                handleChangeAccordain('panel0');
              }}
            >
              Create Rule
            </Button>
          </Grid>
        </Grid>
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
          {Array.isArray(ruleCategories) &&
            ruleCategories &&
            ruleCategories.map((item: any, i: number) => {
              return (
                <TabPanel key={item.id} value={value} index={i} dir={theme.direction}>
                  {createRule && (
                    <Accordion expanded={expanded === 'panel0'} onChange={() => handleChangeAccordain('panel0')}>
                      <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header' className={classes.accordainSummery}>
                        <AppBar position='static'>
                          <Toolbar>
                            <TextField
                              className={classes.inputTextName}
                              label='Name'
                              variant='standard'
                              color='secondary'
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              onChange={e =>
                                setSelectedRule({
                                  ...selectedRule,
                                  name: e.target.value,
                                })
                              }
                            />
                            <Typography component='div' sx={{ flexGrow: 1 }}>
                              By Jack
                            </Typography>
                          </Toolbar>
                        </AppBar>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordainDetail}>
                        <Box className={classes.topLayer}>
                          <Grid container spacing={4}>
                            <Grid item md={6}>
                              <InputLabel style={{ marginBottom: '5px' }}>Edit Rule</InputLabel>
                              <CKEditor
                                type='classic'
                                initData={description}
                                onChange={({ editor }) => {
                                  setDescription(editor.getData());
                                }}
                              />
                              <Button variant='contained' className={classes.buttonSave} onClick={() => validate(selectedRule)}>
                                Save
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              <Typography>Live View</Typography>
                              <Box className={classes.liveView} p={5}>
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {rules.map((ite: any) => {
                    return (
                      ite.category == item.id && (
                        <Accordion
                          key={ite.id}
                          expanded={expanded === `panel${ite.id}`}
                          onChange={() => {
                            handleChangeAccordain(`panel${ite.id}`);
                            setSelectedRule(ite);
                            setDescription(ite.content);
                            setCreateRule(false);
                          }}
                        >
                          <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header' className={classes.accordainSummery}>
                            <AppBar position='static'>
                              <Toolbar>
                                {selectedRule.id == ite.id && (
                                  <TextField
                                    className={classes.inputTextName}
                                    label='Name'
                                    variant='standard'
                                    color='secondary'
                                    value={selectedRule.name}
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    onChange={e =>
                                      setSelectedRule({
                                        ...selectedRule,
                                        name: e.target.value,
                                      })
                                    }
                                  />
                                )}
                                <Typography component='div' sx={{ flexGrow: 1 }}>
                                  {selectedRule.id != ite.id && ite.name} By {selectedRule.staff}
                                </Typography>
                                <div className={classes.rightPanel}>
                                  {selectedRule.id == ite.id && (
                                    <>
                                      <DeleteIcon
                                        style={{ marginLeft: '20px' }}
                                        onClick={e => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          selectedRule.id ? deleteRuleX(selectedRule.id) : '';
                                        }}
                                      />
                                      Delete
                                    </>
                                  )}
                                </div>
                              </Toolbar>
                            </AppBar>
                          </AccordionSummary>
                          <AccordionDetails className={classes.accordainDetail}>
                            <Box className={classes.topLayer}>
                              <Grid container spacing={4}>
                                <Grid item md={6}>
                                  <InputLabel style={{ marginBottom: '5px' }}>Edit Rule</InputLabel>
                                  <CKEditor
                                    type='classic'
                                    initData={ite.content}
                                    onChange={({ editor }) => {
                                      setDescription(editor.getData());
                                    }}
                                  />
                                  <Button variant='contained' className={classes.buttonSave} onClick={() => validate(selectedRule)}>
                                    Save
                                  </Button>
                                </Grid>
                                <Grid item md={6}>
                                  <Typography>Live View</Typography>
                                  <Box className={classes.liveView} p={5}>
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )
                    );
                  })}
                </TabPanel>
              );
            })}
        </SwipeableViews>
      </Box>
      <RuleCategoryDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setSelected(emptyRuleCategory);
        }}
        selected={selected}
        setSelected={setSelected}
        upsertRuleCategoryAction={upsertRuleCategoryAction}
        ruleCategories={ruleCategories}
        deleteRuleCategoryAction={deleteRuleCategoryAction}
      />
      <Dialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Do you want to delete Rule?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>Disagree</Button>
          <Button onClick={() => deleteRuleAction(deleteRule)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
