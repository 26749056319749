import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IAgentCommStructureUpdate } from '../../../../models/agent-comm-structure';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';

export interface ExternalProps {
  open: any;
  scroll: any;
  action: any;
  selected: IAgentCommStructureUpdate;
}

export interface ExternalActionProps {
  handleClose: () => void;
  setSelected: (data: IAgentCommStructureUpdate) => void;
  upsertAgentCommissionAction: (structure: IAgentCommStructureUpdate) => void;
}

export const UpsertAgentCommStructureDialog = (props: ExternalActionProps & ExternalProps) => {
  const { open, scroll, action, selected, handleClose, setSelected, upsertAgentCommissionAction } = props;

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const addTier = () => {
    let newTier = {
      max_players: '1',
      payout: '',
      percent: '',
      type: '',
    };

    const tiers = selected.tiers;
    if (tiers.at(-1)) {
      const lastItem = tiers.at(-1);
      newTier = {
        max_players: (parseInt(lastItem.max_players) + 1).toString(),
        payout: lastItem.payout,
        percent: lastItem.percent,
        type: lastItem.type,
      };
    }

    setSelected({
      ...selected,
      tiers: [...selected.tiers, newTier],
    });
  };

  const updateTier = (index: any, fieldName: any, value: any) => {
    const tiers = selected.tiers;
    tiers[index][fieldName] = value;

    setSelected({
      ...selected,
      tiers: tiers,
    });
  };

  const deleteTier = (value: any) => {
    const tiers = selected.tiers;
    const newTiers = arrayRemove(tiers, value);

    setSelected({
      ...selected,
      tiers: newTiers,
    });
  };

  const arrayRemove = (arr: any, value: any) => {
    return arr.filter(function (ele: any) {
      return ele != value;
    });
  };

  const validate = () => {
    if (selected.name == '') {
      alert('Please enter a structure name.');
      return false;
    } else if (selected.tiers.length == 0) {
      alert('Please create a tier.');
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          {action == 'Create' && 'New Commission'} {action == 'Edit' && 'Edit #' + selected.id}
        </DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (validate() == true) {
              upsertAgentCommissionAction(selected);
            }
          }}
        >
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: '1.2rem',
                    marginTop: '.5rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <TextField
                    variant='outlined'
                    label='Name'
                    helperText='Ex: POST UP General'
                    value={selected.name}
                    fullWidth
                    onChange={event => {
                      setSelected({
                        ...selected,
                        name: event.target.value,
                      });
                    }}
                  />
                </Grid>
                {selected.tiers &&
                  selected.tiers.map((tier: any, index: any) => {
                    return (
                      <>
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sx={{ marginBottom: '1.5rem', borderTop: '1px solid #e0dce0', paddingTop: '1.1rem' }}
                        >
                          <Grid container sx={{ marginBottom: '.5rem' }}>
                            <Grid item xs={6}>
                              <Typography variant='subtitle1' color='black' sx={{ fontWeight: 'normal' }}>
                                Tier {index + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                              <IconButton
                                color='error'
                                size='small'
                                onClick={() => {
                                  deleteTier(tier);
                                }}
                              >
                                <HighlightOffSharpIcon />
                              </IconButton>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <TextField
                                variant='outlined'
                                value={tier.max_players}
                                label='Max Active'
                                onChange={event => {
                                  updateTier(index, 'max_players', event.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                variant='outlined'
                                value={tier.percent}
                                label='Commission %'
                                onChange={event => {
                                  updateTier(index, 'percent', event.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FormControl fullWidth variant='outlined'>
                                <InputLabel id={'demo-simple-select-label' + index}>Commission Basis</InputLabel>
                                <Select
                                  labelId={'demo-simple-select-label' + index}
                                  id={'demo-simple-select' + index}
                                  value={tier.payout}
                                  label='Commission Basis'
                                  variant='outlined'
                                  onChange={event => {
                                    updateTier(index, 'payout', event.target.value);
                                  }}
                                >
                                  <MenuItem>Select a Basis</MenuItem>
                                  <MenuItem value={1}>Net Losses</MenuItem>
                                  <MenuItem value={2}>Gross Losses</MenuItem>
                                  <MenuItem value={3}>Free Play</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                              <FormControl fullWidth>
                                <InputLabel id={'makeup-simple-select-label' + index}>Make Up?</InputLabel>
                                <Select
                                  labelId={'makeup-simple-select-label' + index}
                                  id={'makeup-simple-select' + index}
                                  value={tier.type}
                                  label='Make Up?'
                                  variant='outlined'
                                  onChange={event => {
                                    updateTier(index, 'type', event.target.value);
                                  }}
                                >
                                  <MenuItem>Select a Basis</MenuItem>
                                  <MenuItem value={0}>No</MenuItem>
                                  <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}

                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Button
                    variant='contained'
                    startIcon={<AddBoxIcon />}
                    onClick={() => {
                      addTier();
                    }}
                  >
                    Add Tier
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose} variant='contained' type='submit'>
              {action}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
