import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IBanksUpdate, IBanks } from '../../../models/banks';
import { BankAdminSliceType, bankAdminReducer } from '../bank.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${bankAdminReducer.sliceName}/app`;

const UPSERT_BANK = `UPSERT_BANK${suffix}`;
export const upsertBankAction = createAction<IBanksUpdate>(UPSERT_BANK);

export const UPSERT_BANK_SUCCESS = `UPSERT_BANK_SUCCESS${suffix}`;
export const upsertBankSuccessAction = createAction<IBanks>(UPSERT_BANK_SUCCESS);

const UPSERT_BANK_ERROR = `UPSERT_BANK_ERROR${suffix}`;
export const upsertBankErrorAction = createAction<string>(UPSERT_BANK_ERROR);

function* upsertBankWatcher(): SagaIterator {
  yield takeEvery(UPSERT_BANK, upsertBankWorker);
}
mergeSaga(upsertBankWatcher);

function* upsertBankWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateBank, action.payload);
    } else {
      result = yield call(createBank, action.payload);
    }
    yield put(upsertBankSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertBankErrorAction(e.response.data.message));
  }
}

const createBank = (bank: IBanksUpdate): Request => {
  const url = '/cashier/banks';
  return vipApi(url, 'post', bank);
};

const updateBank = (bank: IBanksUpdate): Request => {
  const url = '/cashier/banks/' + bank.id;
  return vipApi(url, 'post', bank);
};

const reduceHandlers = {
  [UPSERT_BANK]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPSERT_BANK_SUCCESS]: (slice: BankAdminSliceType, action: any): BankAdminSliceType => {
    const checkInArray = slice.banks.filter(item => item.id == action.payload.data.id);
    return {
      ...slice,
      isSaving: false,
      banks:
        checkInArray.length > 0
          ? slice.banks.map(item => {
              if (item.id == action.payload.data.id) {
                return action.payload.data;
              } else {
                return item;
              }
            })
          : slice.banks.concat([action.payload.data]),
    };
  },
  [UPSERT_BANK_ERROR]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

bankAdminReducer.addActionReducerMap(reduceHandlers);
