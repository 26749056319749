import React from 'react';
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles: any = makeStyles({
  root: {
    padding: 0,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  stats: any;
}

export const TopSignUpsTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { stats } = props;

  return (
    <div className={clsx(classes.root)}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Player Number (Invite Code)</TableCell>
            <TableCell># of Sign Ups</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats &&
            stats.map((row: any) => {
              return (
                <TableRow key={row.inviter_code}>
                  <TableCell>{row.inviter_code}</TableCell>
                  <TableCell>{row.count}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};
