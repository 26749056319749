import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { appSettingsSliceReducer, appSettingsSliceType } from '../app-settings.reducer';
import { getAppSettingAction } from './get-app-settings.action';

const suffix = `/${appSettingsSliceReducer.sliceName}/app`;

const UPDATE_APP_SETTING = `UPDATE_APP_SETTING${suffix}`;

export const updateAppSettingAction = createAction(UPDATE_APP_SETTING);

const UPDATE_APP_SETTING_SUCCESS = `UPDATE_APP_SETTING_SUCCESS${suffix}`;
const updateAppSettingsSuccessAction = createAction<any>(UPDATE_APP_SETTING_SUCCESS);

const UPDATE_APP_SETTING_ERROR = `UPDATE_APP_SETTING_ERROR${suffix}`;
const updateAppSettingsErrorAction = createAction<string>(UPDATE_APP_SETTING_ERROR);

function* updateAppSettingsWatcher(): SagaIterator {
  yield takeEvery(UPDATE_APP_SETTING, updateAppSettingsWorker);
}
mergeSaga(updateAppSettingsWatcher);

function* updateAppSettingsWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(updateAppSettingsApi, action.payload);
    yield put(updateAppSettingsSuccessAction(result));
    yield put(getAppSettingAction());
  } catch (e: any) {
    yield put(updateAppSettingsErrorAction(e));
  }
}

const updateAppSettingsApi = (x: any): Request => {
  return vipApi(`/cashier/UpdateAppSetting/` + x.id, 'post', x);
};

const reduceHandlers = {
  [UPDATE_APP_SETTING]: (slice: appSettingsSliceType): appSettingsSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_APP_SETTING_SUCCESS]: (slice: appSettingsSliceType): appSettingsSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [UPDATE_APP_SETTING_ERROR]: (slice: appSettingsSliceType): appSettingsSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

appSettingsSliceReducer.addActionReducerMap(reduceHandlers);
