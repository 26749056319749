import React from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Transactions, ITransactionUpdate, emptyUpdate, ITransactionLoad, ITransactionVerify } from '../../../../models/transactions';
import { IBanks } from '../../../../models/banks';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IBTCAddress, IBTCAddressUpdate } from '../../../../models/btc-addresses';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  formControl: {
    width: '25ch',
  },
  selectClass: {
    '& > div': {
      background: '#eaeaea',
      padding: '7px',
    },
    marginBottom: '15px',
  },
  iconOrder: {
    color: '#bababa',
  },
});

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const toPrettyDate = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  const monthFormat = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const dateFormat = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const formatedDate = `${d.getFullYear()}-${monthFormat}-${dateFormat}`;
  const hourFormat = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minuteFormat = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const formatedTime = `${hourFormat}:${minuteFormat}:00`;
  return formatedDate + ' ' + formatedTime;
};

function splitBTC(btc: string) {
  return btc.substr(0, 3) + '...' + btc.substr(btc.length - 5);
}

const statuses = [
  {
    status: 'Expired',
    id: 0,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Mark as Testing', id: -1 },
      { status: 'Return to previous status', id: 10 },
    ],
  },
  {
    status: 'Pending',
    id: 1,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Action Required', id: 4 },
      { status: 'Load', id: 5 },
      { status: 'Expire', id: 0 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Nudge',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 2,
    list: [{ status: 'Nudge', id: 2 }],
  },
  {
    status: 'NudgeX2',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 3,
    list: [{ status: 'NudgeX2', id: 3 }],
  },
  {
    status: 'Action Required',
    id: 4,
    style: { backgroundColor: '#076399', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Load', id: 5 },
      { status: 'Expire', id: 0 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Unverified',
    id: 5,
    style: { backgroundColor: '#d85645', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Verify', id: 6 },
      { status: 'Expire', id: 0 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Completed',
    id: 6,
    style: { backgroundColor: '#08703a', color: '#fff', fontWeight: 800, marginBottom: '10px' },
    list: [
      { status: 'Reverse', id: 7 },
      { status: 'Mark as Testing', id: -1 },
    ],
  },
  {
    status: 'Reversed',
    style: { backgroundColor: '#efe40e', color: '#555', fontWeight: 800, marginBottom: '10px' },
    id: 7,
    list: [{ status: 'Reversed', id: 7 }],
  },
  {
    status: 'Canceled',
    style: { backgroundColor: '#eaeaea', color: '#000', fontWeight: 800, marginBottom: '10px' },
    id: 8,
    list: [{ status: 'Cancel', id: 8 }],
  },
  {
    status: 'Testing',
    style: { backgroundColor: '#eaeaea', color: '#000', fontWeight: 800, marginBottom: '10px' },
    id: -1,
    list: [{ status: 'Testing', id: -1 }],
  },
];

export function TransactionRow(props: {
  row: Transactions;
  handleClickOpen: any;
  banks: IBanks[];
  updateTransactionAction: (transaction: ITransactionUpdate) => void;
  username: string;
  getBtcAddressesByBankAction: (id: number) => void;
  btcAddresses: IBTCAddress[];
  setLoadSelected: (transaction: ITransactionLoad) => void;
  setLoadOpen: () => void;
  loadSelected: ITransactionLoad;
  setVerifySelected: (transaction: ITransactionVerify) => void;
  setVerifyOpen: () => void;
  verifySelected: ITransactionVerify;
  companyBank: { id: any; method: any; bank_id: any };
  setCompanyBank: (company: { id: any; method: any; bank_id: any }) => void;
  setSelectedLogs: (actions: any) => void;
  setLogsOpen: () => void;
  setBtcOpen: () => void;
  setBtcSelected: (btcAddress: IBTCAddressUpdate) => void;
  btcSelected: IBTCAddressUpdate;
  update: ITransactionUpdate;
  setUpdate: (trnsaction: ITransactionUpdate) => void;
  removeBonusAction: (id: any) => void;
}) {
  const {
    row,
    banks,
    updateTransactionAction,
    username,
    getBtcAddressesByBankAction,
    btcAddresses,
    setLoadSelected,
    setLoadOpen,
    setVerifySelected,
    setVerifyOpen,
    companyBank,
    setCompanyBank,
    setSelectedLogs,
    setLogsOpen,
    setBtcOpen,
    setBtcSelected,
    btcSelected,
    update,
    setUpdate,
    removeBonusAction,
  } = props;
  const classes = useRowStyles();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [isChanging, setIsChanging] = React.useState(false);
  const [updateStatusData, setUpdateStatusData] = React.useState<any>(null);
  const action = row.actions && row.actions.length > 0 ? row.actions[row.actions.length - 1] : {};
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  function handleCopyToClipboard(btc: string) {
    navigator.clipboard.writeText(btc);
    setTooltipOpen(true);
  }
  const getBtcAddressByBank = (id: number) => {
    getBtcAddressesByBankAction(id);
  };
  const updateStatus = (data: { id: number; status: number; notes: string; staff: string; player: string }) => {
    const { id, status, notes, staff, player } = data;
    if (status === 5) {
      setLoadSelected({
        username: player,
        id,
        amount: '',
        updated_by: staff,
      });
      setLoadOpen();
    } else if (status === 6) {
      setVerifySelected({
        id: id,
        pin: '',
        username: staff,
      });
      setVerifyOpen();
    } else if (status === 10) {
      let newStatus = 1;
      if (row.amount_received !== null && row.amount_received > 0) {
        newStatus = 5;
      }
      updateTransactionAction({ id, notes, status: newStatus, staff });
    } else {
      setUpdateStatusData({ id, notes, status, staff });
      setIsChanging(true);
    }
  };
  const onBankChange = (data: {
    id: number;
    status: number;
    notes: string;
    bank: number;
    account: string;
    staff: string;
    method: string;
  }) => {
    const { id, status, notes, bank, account, staff, method } = data;
    getBtcAddressByBank(bank);
    setUpdate({
      id,
      status,
      notes,
      bank,
      account,
      staff,
    });
    if (method !== 'Bitcoin') {
      updateTransactionAction({
        id,
        status,
        notes,
        bank,
        staff,
      });
      setCompanyBank({ bank_id: '', id: '', method: '' });
      setUpdate(emptyUpdate);
    }
  };
  const btcSelect = (account: string) => {
    if (account === 'ADD_NEW_BTC') {
      setBtcOpen();
      return;
    }
    updateTransactionAction({ ...update, account });
    setCompanyBank({ bank_id: '', id: '', method: '' });
    setUpdate(emptyUpdate);
  };
  const capsFirst = (string: string) => {
    if (string != null && string != '') {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  };
  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <StyledTableCell component='th' scope='row'>
          {row.id}
        </StyledTableCell>
        <StyledTableCell>{toPrettyDate(row.created_at)}</StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_number}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_first_name + ' ' + row.player_last_name}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_email}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            {row.player_phone}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          {row.method}
          <br />
          {row.method != 'Bitcoin' && row.account}
        </StyledTableCell>
        <StyledTableCell>
          {companyBank.id == row.id && (
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='select-bank'>Bank</InputLabel>
              <Select
                labelId='select-bank'
                id='select-bank'
                className={classes.selectClass}
                defaultValue={row.bank_id}
                label='Bank'
                onChange={e => {
                  onBankChange({
                    id: row.id,
                    status: row.status,
                    notes: row.notes == null ? '' : row.notes,
                    bank: e.target.value as unknown as number,
                    account: row.address,
                    staff: username,
                    method: row.method,
                  });
                }}
              >
                <MenuItem value={0}>
                  <em>Select Bank</em>
                </MenuItem>
                {banks
                  .filter(item => item.method == row.method)
                  .map(item => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
          {companyBank.id == row.id && row.method == 'Bitcoin' && (
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='select-btc'>BTC</InputLabel>
              <Select
                labelId='select-btc'
                defaultValue={row.account}
                id='select-btc'
                className={classes.selectClass}
                label='BTC'
                onChange={e => btcSelect(e.target.value as string)}
              >
                <MenuItem value='ADD_NEW_BTC' key={0}>
                  <em>Add New BTC Address</em>
                </MenuItem>
                {update.id == row.id &&
                  btcAddresses.map(item => {
                    return (
                      <MenuItem value={item.address} key={item.id}>
                        {item.address}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
          {companyBank.id != row.id && row.bank && <Chip label={row.bank} style={{ marginBottom: '10px' }} />}
          {companyBank.id != row.id && row.account && (
            <div style={{ display: 'flex' }}>
              <a href={`https://mempool.space/address/${row.account}`} target='_blank' rel='noreferrer'>
                <Chip label={splitBTC(row.account)} />
              </a>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title='Copied'
                  >
                    <Chip clickable label={<FileCopyIcon color='primary' />} onClick={() => handleCopyToClipboard(row.account)} />
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </div>
          )}
          <br />
          <Chip
            label='Edit'
            clickable
            onClick={() => {
              setCompanyBank({ id: row.id, method: row.method, bank_id: row.bank_id });
              getBtcAddressByBank(row.bank_id);
              setUpdate({
                id: row.id,
                status: row.status,
                notes: row.notes == null ? '' : row.notes,
                bank: row.bank_id,
                account: row.account,
                staff: username,
              });
              setBtcSelected({
                ...btcSelected,
                bank: row.bank_id,
                created_by: username,
              });
            }}
            color='primary'
            disabled={[1, 4].indexOf(row.status) == -1 ? true : false}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle2' gutterBottom>
            <strong>Initiated:</strong> ${row.amount}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <strong>Bonus:</strong>{' '}
            {row.bonus === null ? (
              'None'
            ) : (
              <>
                {row.bonus}{' '}
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => {
                    removeBonusAction(row.id);
                  }}
                >
                  {' '}
                  Remove Bonus
                </Button>
              </>
            )}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <strong>Fee:</strong> ${row.fee}
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <strong>{[5, 6].indexOf(row.status) === -1 ? 'To Load:' : 'Loaded:'}</strong> $
            {(row.status === 1 || row.status === 4) && row.totalToSend} {(row.status === 5 || row.status === 6) && row.amount_received}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <TextField
            defaultValue={row.notes}
            multiline
            variant='outlined'
            onChange={event =>
              setUpdate({
                id: row.id,
                notes: event.target.value,
                status: row.status,
                staff: username,
              })
            }
          />
          <Chip
            label='Save'
            color='primary'
            clickable
            disabled={update.notes != row.notes && update.id == row.id ? false : true}
            style={{ marginTop: '5px' }}
            onClick={() => updateTransactionAction(update)}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Chip
            label={row.status == -1 ? statuses[9].status : statuses[row.status].status}
            style={row.status == -1 ? statuses[9].style : statuses[row.status].style}
          />
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>Action</InputLabel>
            <Select
              className={classes.selectClass}
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              label='Action'
              value={row.status}
              onChange={event => {
                updateStatus({
                  id: row.id,
                  notes: row.notes == null ? '' : row.notes,
                  status: event.target.value as unknown as number,
                  staff: username,
                  player: row.player_number,
                });
              }}
            >
              <MenuItem value={0}>
                <em>Status</em>
              </MenuItem>
              {row.status == -1
                ? statuses[9].list.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.status}
                      </MenuItem>
                    );
                  })
                : statuses[row.status].list.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.status}
                      </MenuItem>
                    );
                  })}
            </Select>

            {row.invoice != null && (row.status == 6 || row.status == 5) && (
              <Button
                color='secondary'
                variant='contained'
                onClick={() => {
                  window.open(row.invoice, '_Blank');
                }}
              >
                Invoice
              </Button>
            )}
          </FormControl>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant='subtitle1'>{capsFirst(action.username)}</Typography>
          <Typography variant='subtitle1'>{action.timestamp}</Typography>
          <Typography variant='subtitle1'>{capsFirst(action.action)}</Typography>
          <Chip
            label='See More'
            disabled={row.actions && row.actions.length > 1 ? false : true}
            onClick={() => {
              setSelectedLogs(row.actions);
              setLogsOpen();
            }}
            color='primary'
          />
        </StyledTableCell>
      </StyledTableRow>
      <Dialog
        maxWidth='md'
        onClose={() => {
          // setSelected({ ...emptyBank, staff: username() });
          setIsChanging(false);
        }}
        open={isChanging}
      >
        <DialogTitle>
          Are you sure you want to change status to{' '}
          {updateStatusData ? (updateStatusData.status == -1 ? statuses[9].status : statuses[updateStatusData.status].status) : 0}?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setUpdateStatusData(null);
              setIsChanging(false);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateTransactionAction(updateStatusData);
              setUpdateStatusData(null);
              setIsChanging(false);
            }}
            variant='contained'
            color='secondary'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
