import { createSelector } from 'reselect';
import { agentAdminReducer, AgentAdminSliceType } from './agent.reducer';

export const agentAdminSliceSelector = (state: any): any => state[agentAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  agentAdminSliceSelector,
  (agentAdminSlice: AgentAdminSliceType) => agentAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  agentAdminSliceSelector,
  (agentAdminSlice: AgentAdminSliceType) => agentAdminSlice.isSaving,
);

export const agentsSelector = createSelector(agentAdminSliceSelector, (agentAdminSlice: AgentAdminSliceType) => agentAdminSlice.agents);

export const commissionStructuresSelector = createSelector(
  agentAdminSliceSelector,
  (agentAdminSlice: AgentAdminSliceType) => agentAdminSlice.structures,
);

export const agentPayoutsSelector = createSelector(
  agentAdminSliceSelector,
  (agentAdminSlice: AgentAdminSliceType) => agentAdminSlice.payouts,
);
