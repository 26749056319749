import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { showToast } from '../../../utils/toast/show-errors';
import { vipApi } from '../../../utils/vip-api';
import { bonusesAdminReducer, BonusesAdminSliceType } from '../bonuses.reducer';

const suffix = `/${bonusesAdminReducer.sliceName}/app`;

const CREATE_BONUS = `CREATE_BONUS${suffix}`;

export const createBonusAction = createAction(CREATE_BONUS);

const CREATE_BONUS_SUCCESS = `CREATE_BONUS_SUCCESS${suffix}`;
const createBonusSuccessAction = createAction<any>(CREATE_BONUS_SUCCESS);

const CREATE_BONUS_ERROR = `CREATE_BONUS_ERROR${suffix}`;
const createBonusErrorAction = createAction<any>(CREATE_BONUS_ERROR);

function* createBonusWatcher(): SagaIterator {
  yield takeEvery(CREATE_BONUS, createBonusWorker);
}
mergeSaga(createBonusWatcher);

function* createBonusWorker(action: any): SagaIterator {
  try {
    const result = yield call(createBonusApi, action.payload);
    yield put(createBonusSuccessAction(result));
    yield call(showToast, 'Successfully created bonus.', 'success');
  } catch (e: any) {
    yield call(showToast, 'Please make sure all required fields are filled.', 'error');
    yield put(createBonusErrorAction(e));
  }
}

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

const parseDate = (x: any) => {
  const date: any = new Date(x);
  const [year] = [date.getFullYear()];
  const [hour, minutes, seconds] = [date.getHours(), date.getMinutes(), date.getSeconds()];

  //2021-08-31 14:05:22
  return (
    year +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2) +
    ' ' +
    hour +
    ':' +
    minutes +
    ':' +
    seconds
  );
};

const createBonusApi = (x: any): Request => {
  const url = `/cashier/bonus`;
  const data = {
    category: x.category,
    code: x.code,
    description: x.description,
    details: x.details,
    terms: x.terms,
    type: 'Free Play',
    start_date: parseDate(x.start_date),
    end_date: parseDate(x.end_date),
    min_deposit: x.min_deposit,
    max_deposit: x.max_deposit,
    match_multiplier: parseFloat(x.match_multiplier) / 100,
    rollover_multiplier: x.rollover_multiplier,
    contribution_id: x.contribution_id,
    ft_bonus_id: x.ft_bonus_id,
    recurring: x.recurring,
    active: x.active,
    image: x.image,
    mobile_image: x.image,
    created_by: getSessionUser(),
    updated_by: getSessionUser(),
  };
  return vipApi(url, 'post', data);
};

const reduceHandlers = {
  [CREATE_BONUS]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CREATE_BONUS_SUCCESS]: (slice: BonusesAdminSliceType, action: any): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    bonuses: action.payload.data,
  }),
  [CREATE_BONUS_ERROR]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

bonusesAdminReducer.addActionReducerMap(reduceHandlers);
