import { IPlayerInfo, emptyPlayerInfo, ISearchedPlayer } from '../../models/player-info';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'playerInfoSlice';

export interface PlayerInfoSliceType {
  isLoading: boolean;
  playerInfo: IPlayerInfo;
  playerSearched: ISearchedPlayer[];
  playerChangePassword: {
    password: string;
    confirm_password: string;
  };
  playerNotes: any;
  cognitoData: any;
  rights: any;
  deposits: any;
  withdrawals: any;
  pendingWagers: any;
  wagerHistory: any;
  bonusHistory: any;
  rafHistory: any;
}

export const initialState: PlayerInfoSliceType = {
  isLoading: false,
  playerInfo: emptyPlayerInfo,
  playerSearched: [],
  playerChangePassword: {
    password: '',
    confirm_password: '',
  },
  playerNotes: null,
  cognitoData: {},
  rights: [],
  deposits: null,
  withdrawals: null,
  pendingWagers: null,
  wagerHistory: null,
  bonusHistory: null,
  rafHistory: null,
};

export const playerInfoReducer = createAndMergeSliceReducer(sliceName, initialState);
