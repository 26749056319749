import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { showToast } from '../../../utils/toast/show-errors';
import { vipApi } from '../../../utils/vip-api';
import { bonusesAdminReducer, BonusesAdminSliceType } from '../bonuses.reducer';

const suffix = `/${bonusesAdminReducer.sliceName}/app`;

const CREATE_BONUS_PROFILE = `CREATE_BONUS_PROFILE${suffix}`;

export const createBonusProfileAction = createAction(CREATE_BONUS_PROFILE);

const CREATE_BONUS_PROFILE_SUCCESS = `CREATE_BONUS_PROFILE_SUCCESS${suffix}`;
const createBonusProfileSuccessAction = createAction<any>(CREATE_BONUS_PROFILE_SUCCESS);

const CREATE_BONUS_PROFILE_ERROR = `CREATE_BONUS_PROFILE_ERROR${suffix}`;
const createBonusProfileErrorAction = createAction<string>(CREATE_BONUS_PROFILE_ERROR);

function* createBonusProfileWatcher(): SagaIterator {
  yield takeEvery(CREATE_BONUS_PROFILE, createBonusProfileWorker);
}
mergeSaga(createBonusProfileWatcher);

function* createBonusProfileWorker(action: any): SagaIterator {
  try {
    const result = yield call(createBonusProfileApi, action.payload);
    yield put(createBonusProfileSuccessAction(result));
    yield call(showToast, 'Successfully created profile.', 'success');
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(createBonusProfileErrorAction(e));
  }
}

const createBonusProfileApi = (x: any): Request => {
  return vipApi(`/cashier/bonus/profile/create`, 'post', {
    name: x.name,
    description: x.description,
    straight_bet: x.straight_bet,
    parlay_bet: x.parlay_bet,
    teaser_bet: x.teaser_bet,
    if_bet: x.if_bet,
    reverse_bet: x.reverse_bet,
    contest_futures: x.contest_futures,
    props_builder: x.props_builder,
    live_wager_straight: x.live_wager_straight,
    live_wager_parlay: x.live_wager_parlay,
    live_casino: x.live_casino,
    poker: x.poker,
    table_games: x.table_games,
    slots: x.slots,
    video_poker: x.video_poker,
    bingo: x.bingo,
    horse_racing: x.horse_racing,
  });
};

const reduceHandlers = {
  [CREATE_BONUS_PROFILE]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CREATE_BONUS_PROFILE_SUCCESS]: (slice: BonusesAdminSliceType, action: any): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
    profiles: action.payload.data,
  }),
  [CREATE_BONUS_PROFILE_ERROR]: (slice: BonusesAdminSliceType): BonusesAdminSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

bonusesAdminReducer.addActionReducerMap(reduceHandlers);
