import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardActions, CardContent, CardHeader, Collapse, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { ChangePasswordDialog } from './change-password-dialog';
import LoginIcon from '@mui/icons-material/Login';
import { ExpandMoreOutlined } from '@material-ui/icons';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const useStyles: any = makeStyles({
  root: {
    // padding: 24,
    width: '100%',
  },
  balancesBg: {
    backgroundColor: 'rgba(255,255,255,.1)',
    marginBottom: '1rem',
    padding: 5,
  },
});

export interface ExternalActionProps {
  impersonatePlayerAction: (x: any) => void;
  changePasswordAction: (string: string, password: string, confirm_password: string) => void;
}

export interface ExternalProps {
  playerInfo: any;
}

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const PlayerInfoCard = (props: ExternalActionProps & ExternalProps & ExpandMoreProps) => {
  const classes = useStyles();
  const { playerInfo, impersonatePlayerAction, changePasswordAction } = props;
  const { id, player_number, name, email, phone, verificationCode, is_credit, agent, signup_ip, signup_fingerprint } = playerInfo.personal;
  const { available, freeplay, pending, pending_wagers } = playerInfo.balances;
  const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={clsx(classes.root)}>
      <ChangePasswordDialog
        open={pwdDialogOpen}
        onClose={() => {
          setPwdDialogOpen(false);
        }}
        changePasswordAction={changePasswordAction}
        id={id}
      />

      <Card>
        <CardHeader
          title={player_number}
          subheader={name}
          sx={{ bgcolor: '#121212' }}
          titleTypographyProps={{ color: '#fff' }}
          subheaderTypographyProps={{ color: '#fff' }}
          action={
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => {
                impersonatePlayerAction({
                  userId: playerInfo.personal.id,
                  staff: getSessionUser(),
                });
              }}
            >
              <LoginIcon /> Impersonate
            </Button>
          }
        />
        <CardContent sx={{ bgcolor: 'primary.main', color: 'common.white' }}>
          <div className={classes.balancesBg}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant='h6' className={classes.tableTitle}>
                  Available:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='h6' className={classes.tableContent}>
                  ${available}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Divider light sx={{ marginBottom: '1rem' }} />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Free Play:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                ${freeplay}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Pending:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                ${pending}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Pending Wagers:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                {pending_wagers}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: '1rem', marginTop: '1rem' }} />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Agent:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                {agent}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Player Type:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant='subtitle2'
                className={classes.tableContent}
                sx={is_credit == 'No' ? { color: 'secondary.main', fontWeight: 'bold' } : { color: 'orange', fontWeight: 'bold' }}
              >
                {is_credit == 'No' ? 'Post-up' : 'Credit'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing sx={{ bgcolor: 'primary.main' }}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            sx={{ color: 'common.white' }}
          >
            <ExpandMoreOutlined />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={{ bgcolor: 'common.white', color: 'common.black' }}>
            <Typography variant='subtitle1'>Additional Info</Typography>
            <Divider sx={{ marginBottom: '1rem' }} />
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Email :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  {email}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Phone :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  {phone}
                  <br />
                  <small>Verification Code: {verificationCode}</small>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  IP :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  {signup_ip == null ? '-' : signup_ip}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Fingerprint :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  {signup_fingerprint == null ? '-' : signup_fingerprint}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Password :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant='contained'
                  onClick={() => {
                    setPwdDialogOpen(true);
                  }}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>

      {/* <Card sx={{ marginTop: '1rem' }}>
        <CardHeader subheader='Additional Information' />
        <Divider />
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Email :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                {email}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Phone :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2' className={classes.tableContent}>
                {phone}
                <br />
                <small>Verification Code: {verificationCode}</small>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' className={classes.tableTitle}>
                Password :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Button
                variant='contained'
                onClick={() => {
                  setPwdDialogOpen(true);
                }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
    </div>
  );
};
