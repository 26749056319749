import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ILandingPageUpload } from '../../../models/landing-page';
import { LandingPageAdminSliceType, landingPageAdminReducer } from '../landing-page.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${landingPageAdminReducer.sliceName}/app`;

const UPSERT_LANDING_PAGE = `UPSERT_LANDING_PAGE${suffix}`;
export const upsertLandingPageAction = createAction<ILandingPageUpload>(UPSERT_LANDING_PAGE);

export const UPSERT_LANDING_PAGE_SUCCESS = `UPSERT_LANDING_PAGE_SUCCESS${suffix}`;
export const upsertLandingPageSuccessAction = createAction(UPSERT_LANDING_PAGE_SUCCESS);

const UPSERT_LANDING_PAGE_ERROR = `UPSERT_LANDING_PAGE_ERROR${suffix}`;
export const upsertLandingPageErrorAction = createAction<string>(UPSERT_LANDING_PAGE_ERROR);

function* upsertLandingPageWatcher(): SagaIterator {
  yield takeEvery(UPSERT_LANDING_PAGE, upsertLandingPageWorker);
}
mergeSaga(upsertLandingPageWatcher);

function* upsertLandingPageWorker(action: any): SagaIterator {
  console.log('upsertLandingPageWorker');
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateLandingPage, action.payload);
    } else {
      result = yield call(createLandingPage, action.payload);
    }
    console.log(result);
    if (result.status == 'Success') {
      yield put(upsertLandingPageSuccessAction(result));
    } else {
      yield call(showToast, result.msg, 'error');
      yield put(upsertLandingPageErrorAction(result.msg));
    }
  } catch (e: any) {
    yield put(upsertLandingPageErrorAction(e));
  }
}

const createLandingPage = (landingPage: ILandingPageUpload): Request => {
  const url = '/LandingPage/Create';
  return vipApi(url, 'post', landingPage);
};

const updateLandingPage = (landingPage: ILandingPageUpload): Request => {
  const url = '/LandingPage/Update';
  return vipApi(url, 'post', landingPage);
};

const reduceHandlers = {
  [UPSERT_LANDING_PAGE]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPSERT_LANDING_PAGE_SUCCESS]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPSERT_LANDING_PAGE_ERROR]: (slice: LandingPageAdminSliceType): LandingPageAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

landingPageAdminReducer.addActionReducerMap(reduceHandlers);
