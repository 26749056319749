import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IRuleCategory } from '../../../models/rule-categories';
import { ruleCategoriesReducer, RuleCategoriesSliceType } from '../rule-categories.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_RULE_CATEGORY_SUCCESS } from '../actions/upsert-rule-categories.action';
import { DELETE_RULE_CATEGORY_SUCCESS } from '../actions/delete-rule-categories.action';

const suffix = `/${ruleCategoriesReducer.sliceName}/app`;

const GET_RULE_CATEGORIES = `GET_RULE_CATEGORIES${suffix}`;

export const getRuleCategoriesAction = createAction(GET_RULE_CATEGORIES);

const GET_RULE_CATEGORIES_SUCCESS = `GET_RULE_CATEGORIES_SUCCESS${suffix}`;
const getRuleCategoriesSuccessAction = createAction<IRuleCategory[]>(GET_RULE_CATEGORIES_SUCCESS);

const GET_RULE_CATEGORIES_ERROR = `GET_RULE_CATEGORIES_ERROR${suffix}`;
const getRuleCategoriesErrorAction = createAction<string>(GET_RULE_CATEGORIES_ERROR);

function* getRuleCategoriesWatcher(): SagaIterator {
  yield takeEvery([GET_RULE_CATEGORIES, UPSERT_RULE_CATEGORY_SUCCESS, DELETE_RULE_CATEGORY_SUCCESS], getRuleCategoriesWorker);
}
mergeSaga(getRuleCategoriesWatcher);

function* getRuleCategoriesWorker(): SagaIterator {
  try {
    const result: IGetAll<IRuleCategory> = yield call(getRuleCategoriesApi);
    yield put(getRuleCategoriesSuccessAction(result.data));
  } catch (e: any) {
    yield put(getRuleCategoriesErrorAction(e));
  }
}

const getRuleCategoriesApi = (): Request => {
  return vipApi(`/rules/GetRuleCategories`, 'get', {});
};

const reduceHandlers = {
  [GET_RULE_CATEGORIES]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_RULE_CATEGORIES_SUCCESS]: (slice: RuleCategoriesSliceType, action: any): RuleCategoriesSliceType => ({
    ...slice,
    isLoading: false,
    ruleCategories: action.payload,
  }),
  [GET_RULE_CATEGORIES_ERROR]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isLoading: false,
    ruleCategories: [],
  }),
};

ruleCategoriesReducer.addActionReducerMap(reduceHandlers);
