import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { AccountingSliceType, accountingReducer } from '../accounting.reducer';

const suffix = `/${accountingReducer.sliceName}/app`;

const GET_DETAILED_REPORT = `GET_DETAILED_REPORT${suffix}`;
export const getDetailedReportAction = createAction<any>(GET_DETAILED_REPORT);

const GET_DETAILED_REPORT_ERROR = `GET_DETAILED_REPORT_ERROR${suffix}`;
const getDetailedReportErrorAction = createAction<any>(GET_DETAILED_REPORT_ERROR);
const GET_DETAILED_REPORT_SUCCESS = `GET_DETAILED_REPORT_SUCCESS${suffix}`;
const getDetailedReportSuccessAction = createAction<any>(GET_DETAILED_REPORT_SUCCESS);

function* getDetailedReportWatcher(): SagaIterator {
  yield takeEvery(GET_DETAILED_REPORT, getDetailedReportWorker);
}
mergeSaga(getDetailedReportWatcher);

function* getDetailedReportWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(getDetailedReportSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getDetailedReportErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/cashier/accounting/GetDetailedReport`, 'post', {
    start_date: x.start_date,
    end_date: x.end_date,
  });
};

const reduceHandlers = {
  [GET_DETAILED_REPORT]: (slice: AccountingSliceType): AccountingSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_DETAILED_REPORT_SUCCESS]: (slice: AccountingSliceType, action: any): AccountingSliceType => ({
    ...slice,
    isLoading: false,
    deposits: action.payload.data.deposits,
    withdrawals: action.payload.data.withdrawals,
  }),
  [GET_DETAILED_REPORT_ERROR]: (slice: AccountingSliceType): AccountingSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

accountingReducer.addActionReducerMap(reduceHandlers);
