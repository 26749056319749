import React, { useEffect, useState } from 'react';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { emptyBTCAddress, IBTCAddress, IBTCAddressUpdate } from '../../../models/btc-addresses';
import { AddBTCAddressesDialog } from './components/add-btc-addresses-dialog';
import { BTCAddressesTable } from './components/btc-addresses-table';
import { Loading } from '../../../shared/loading/loading.component';
import { IBanks } from '../../../models/banks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    header: {
      display: 'inline-flex',
      marginBottom: '1rem',
    },
    buttonMargin: {
      marginLeft: '0rem',
    },
  }),
);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export interface ExternalActionProps {
  getBtcAddressesAction: () => void;
  upsertBtcAddressAction: (btcAddress: IBTCAddressUpdate) => void;
  deleteBtcAddressAction: (btcAddress: IBTCAddressUpdate) => void;
  getBanksAction: () => void;
}

export interface ExternalProps {
  btcAddresses: IBTCAddress[];
  isLoading: boolean;
  banks: IBanks[];
}

export const BTCAddressesPage = (props: ExternalActionProps & ExternalProps) => {
  const { btcAddresses, getBtcAddressesAction, upsertBtcAddressAction, deleteBtcAddressAction, isLoading, banks, getBanksAction } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [chips, setChips] = React.useState<Array<string>>([]);
  const [selected, setSelected] = useState({
    ...emptyBTCAddress,
    created_by: getSessionUser(),
  });
  const classes = useStyles();

  useEffect(() => {
    getBtcAddressesAction();
    getBanksAction();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Button
          variant='contained'
          color='primary'
          className={classes.buttonMargin}
          size='small'
          onClick={() => {
            setSelected({ ...emptyBTCAddress, created_by: getSessionUser() });
            setEditOrAddModal(true);
          }}
          disableElevation
        >
          Create BTC Address
        </Button>
      </div>
      <BTCAddressesTable
        btcAddresses={btcAddresses}
        selected={selected}
        setSelected={setSelected}
        setEditOrAddModal={setEditOrAddModal}
        deleteBtcsAddressesAction={deleteBtcAddressAction}
        username={getSessionUser()}
        setChips={setChips}
      />
      <AddBTCAddressesDialog
        open={editOrAddModal}
        onClose={() => {
          setSelected(emptyBTCAddress);
          setEditOrAddModal(false);
          setChips([]);
        }}
        upsertBtcAddresssAction={upsertBtcAddressAction}
        selected={selected}
        setSelected={setSelected}
        banks={banks.filter(item => item.method == 'Bitcoin')}
        chips={chips}
        setChips={setChips}
      />
      <Loading loading={isLoading} />
    </div>
  );
};
