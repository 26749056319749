import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IBTCAddress, IBTCAddressUpdate } from '../../../../models/btc-addresses';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
    iconOrder: {
      color: '#bababa',
    },
  }),
);

const toPrettyDate = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  const monthFormat = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const dateFormat = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const formatedDate = `${d.getFullYear()}-${monthFormat}-${dateFormat}`;
  const hourFormat = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minuteFormat = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const formatedTime = `${hourFormat}:${minuteFormat}:00`;
  return formatedDate + ' ' + formatedTime;
};

export interface ExternalProps {
  btcAddresses: IBTCAddress[];
  selected: IBTCAddressUpdate;
  username: string;
  setSelected: (yup: IBTCAddressUpdate) => void;
  setEditOrAddModal: (yup: boolean) => void;
  deleteBtcsAddressesAction: (btcAddress: IBTCAddressUpdate) => void;
  setChips: (x: string[]) => void;
}

export const BTCAddressesTable = (props: ExternalProps) => {
  const { btcAddresses, selected, setSelected, setEditOrAddModal, deleteBtcsAddressesAction, username, setChips } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const classes = useStyles();
  const [order, setOrder] = useState('id');
  const [orderBy, setOrderBy] = useState('desc');
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSort = (btcAddresses: IBTCAddress[]) => {
    const data = btcAddresses.sort((a, b) => {
      if (orderBy == 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
    return data;
  };

  return (
    <Paper>
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        BTC Address
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Bank</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Deposit #</TableCell>
              <TableCell>Requested By</TableCell>
              <TableCell>Redeemed</TableCell>
              <TableCell>
                Created At
                {order == 'id' && orderBy == 'desc' ? (
                  <ExpandMoreIcon
                    className={classes.iconOrder}
                    onClick={() => {
                      setOrder('id');
                      setOrderBy('asc');
                    }}
                  />
                ) : (
                  <ExpandLessIcon
                    className={classes.iconOrder}
                    onClick={() => {
                      setOrder('id');
                      setOrderBy('desc');
                    }}
                  />
                )}
              </TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSort(btcAddresses)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.bank_name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.request_id}</TableCell>
                    <TableCell>{row.requested_by}</TableCell>
                    <TableCell>{row.redeemed}</TableCell>
                    <TableCell>{toPrettyDate(row.created_at)}</TableCell>
                    <TableCell align='right'>
                      <div className={classes.actions}>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              bank: row.bank,
                              addresses: row.address,
                              created_by: username,
                            });
                            setChips([row.address]);
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              bank: row.bank,
                              addresses: row.address,
                              created_by: row.created_by,
                            });
                            setIsDeleting(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={btcAddresses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog maxWidth='md' onClose={() => setIsDeleting(false)} open={isDeleting}>
        <DialogTitle>Are you sure you want to delete {selected.addresses}?</DialogTitle>
        <DialogContent>
          {'Deleting cannot be undone. If you want to disactive '}
          <br />
          {'the page, please use the toggle to deactive the page.'}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleting(false)} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteBtcsAddressesAction(selected);
              setIsDeleting(false);
            }}
            variant='contained'
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
