import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const SearchedPlayerDialog = (props: any) => {
  const { open, onClose, rows, getPlayerInfoAction } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title' style={{ marginTop: '100px' }}>
        <DialogContent>
          <DialogContentText>Searched Players</DialogContentText>
          <TableContainer component={Paper}>
            <Table style={{ width: '100%' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(
                  (row: {
                    id: number;
                    first_name: string;
                    last_name: string;
                    username: string;
                    email: string;
                    phone: string;
                    verification_code: string;
                  }) => (
                    <TableRow
                      hover
                      key={row.id}
                      onClick={() => {
                        getPlayerInfoAction(row.id);
                        onClose();
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.phone}
                        <br />
                        <small>Verification Code: {row.verification_code}</small>
                      </TableCell>
                      <TableCell>
                        <VisibilityIcon />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
