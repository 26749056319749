import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_COGNITO_DATA = `GET_COGNITO_DATA${suffix}`;

export const getCognitoDataAction = createAction<number>(GET_COGNITO_DATA);

const GET_COGNITO_DATA_SUCCESS = `GET_COGNITO_DATA_SUCCESS${suffix}`;
const getCognitoDataSuccessAction = createAction<IPlayerInfo[]>(GET_COGNITO_DATA_SUCCESS);

const GET_COGNITO_DATA_ERROR = `GET_COGNITO_DATA_ERROR${suffix}`;
const getCognitoDataErrorAction = createAction<string>(GET_COGNITO_DATA_ERROR);

function* getCognitoDataWatcher(): SagaIterator {
  yield takeEvery(GET_COGNITO_DATA, getCognitoDataWorker);
}
mergeSaga(getCognitoDataWatcher);

function* getCognitoDataWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getCognitoDataApi, action.payload);
    yield put(getCognitoDataSuccessAction(result));
  } catch (e: any) {
    // yield call(showToast, e.response.data.message, 'error');
    yield put(getCognitoDataErrorAction(e.response.data.message));
  }
}

const getCognitoDataApi = (id: any): Request => {
  return vipApi(`/cashier/GetCognitoData/${id}`, 'get', {});
};

const reduceHandlers = {
  [GET_COGNITO_DATA]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_COGNITO_DATA_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    cognitoData: action.payload,
  }),
  [GET_COGNITO_DATA_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
