import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerInfoAction } from './get-player-info.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const DISABLE_ROLLOVER = `DISABLE_ROLLOVER${suffix}`;

export const disableRolloverAction = createAction<any>(DISABLE_ROLLOVER);

const DISABLE_ROLLOVER_SUCCESS = `DISABLE_ROLLOVER_SUCCESS${suffix}`;
const disableRolloverSuccessAction = createAction<IPlayerInfo[]>(DISABLE_ROLLOVER_SUCCESS);

const DISABLE_ROLLOVER_ERROR = `DISABLE_ROLLOVER_ERROR${suffix}`;
const disableRolloverErrorAction = createAction<string>(DISABLE_ROLLOVER_ERROR);

function* disableRolloverWatcher(): SagaIterator {
  yield takeEvery(DISABLE_ROLLOVER, disableRolloverWorker);
}
mergeSaga(disableRolloverWatcher);

function* disableRolloverWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(disableRolloverApi, action.payload.playerNumber);
    yield put(disableRolloverSuccessAction(result.data));
    yield call(showToast, 'Rollover disabled successfully.', 'success');
    yield put(getPlayerInfoAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(disableRolloverErrorAction(e.response.data.message));
  }
}
const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

const disableRolloverApi = (string: any): Request => {
  return vipApi(`/cashier/rollovers/${string}`, 'post', {
    staff: getSessionUser(),
  });
};

const reduceHandlers = {
  [DISABLE_ROLLOVER]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [DISABLE_ROLLOVER_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [DISABLE_ROLLOVER_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
