import { createSelector } from 'reselect';
import { AccountingSliceType, accountingReducer } from './accounting.reducer';

export const accountingSliceSelector = (state: any): any => state[accountingReducer.sliceName];

export const isLoadingSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.isLoading || false,
);

export const isAccountingLoadingSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.isLoading,
);

export const reportOverviewSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.overview,
);

export const reportDepositsSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.deposits,
);

export const reportWithdrawalsSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.withdrawals,
);

export const reportTotalsSelector = createSelector(
  accountingSliceSelector,
  (accountingSlice: AccountingSliceType) => accountingSlice.total,
);
