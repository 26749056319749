import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { PaperProps, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IRuleCategoryUpdate } from './../../../../models/rule-categories';
// import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginLeft: '8px',
      marginRight: '8px',
      marginTop: '8px',
    },
    button: {
      margin: theme.spacing(1),
    },
    dFlex: {
      display: 'flex',
      alignItems: 'baseline',
    },
    mx2: {
      marginLeft: '8px',
      marginRight: '8px',
    },
    referralInp: {
      width: '180px',
    },
    referralText: {
      marginLeft: '8px',
      marginRight: '8px',
      fontSize: '15px',
    },
    dialogeHeader: {
      backgroundColor: '#000',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 20px',
      alignItems: 'center',
      '& p': {
        color: '#fff',
        fontWeight: 'bold',
      },
    },
    categoryInput: {
      display: 'flex',
    },
    inputField: {
      '& input': {
        height: '40px',
        padding: '0 20px',
      },
    },
    inputSearch: {
      '& input': {
        height: '40px',
        padding: '0 20px',
      },
    },
    rowNameText: {
      fontWeight: 600,
    },
    rowIdText: {
      color: '#9a9a9a',
    },
  }),
);

export interface ExternalProps {
  open: boolean;
  selected: IRuleCategoryUpdate;
  ruleCategories: any;
}

export interface ExternalActionProps {
  onClose: () => void;
  setSelected: (x: IRuleCategoryUpdate) => void;
  upsertRuleCategoryAction: (x: IRuleCategoryUpdate) => void;
  deleteRuleCategoryAction: (x: number) => void;
}

export const RuleCategoryDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { open, selected, onClose, setSelected, upsertRuleCategoryAction, ruleCategories, deleteRuleCategoryAction } = props;
  const [search, setSearch] = React.useState('');
  const [cat, setCat] = React.useState(ruleCategories);

  const searchCategory = (value: string) => {
    if (value != '') {
      const filterCategories = cat.filter((item: any) => item.name.indexOf(value) >= 0);
      setCat(filterCategories);
    } else {
      setCat(ruleCategories);
    }
  };

  useEffect(() => {
    if (ruleCategories) {
      setCat(ruleCategories);
    }
  }, [ruleCategories]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
        fullWidth={true}
        maxWidth='sm'
      >
        <div className={classes.dialogeHeader}>
          <Typography>Rule Categories</Typography>
          <Button color='primary' variant='contained' onClick={() => onClose()}>
            Close
          </Button>
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
            upsertRuleCategoryAction(selected);
            onClose();
          }}
        >
          <DialogContent>
            <div className={classes.categoryInput}>
              <TextField
                variant='outlined'
                value={selected.name}
                onChange={event =>
                  setSelected({
                    ...selected,
                    name: event.target.value,
                  })
                }
                className={classes.inputField}
                fullWidth
                placeholder='Category Name'
              />
              <Button variant='contained' onClick={() => upsertRuleCategoryAction(selected)} style={{ width: '250px' }}>
                {selected.id ? 'Update Category' : 'Add Category'}
              </Button>
            </div>
            <TableContainer component={Paper}>
              <Table size='small' aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TextField
                        fullWidth
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value);
                          searchCategory(e.target.value);
                        }}
                        className={classes.inputSearch}
                        placeholder='Search'
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cat.map((row: any) => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component='th' scope='row'>
                        <Typography className={classes.rowNameText}>{row.name}</Typography>
                        <Typography className={classes.rowIdText}>id: #{row.id}</Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <EditIcon onClick={() => setSelected(row)} />
                        <DeleteIcon onClick={() => deleteRuleCategoryAction(row.id)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};
