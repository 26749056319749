import React, { useState } from 'react';
import {
  Button,
  IconButton,
  // IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { IAgents, IAgentsUpdate } from '../../../../models/agent';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    dFlex: {
      display: 'flex',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
    iconOrder: {
      color: '#bababa',
      cursor: 'pointer',
    },
  }),
);

export interface ExternalProps {
  agents: IAgents[];
  username: any;
  selected: IAgentsUpdate;
}

export interface ExternalActionProps {
  setSelected: (agent: IAgentsUpdate) => void;
  setEditOrAddModal: (open: boolean) => void;
}

export const AgentTable = (props: ExternalProps & ExternalActionProps) => {
  const { agents, selected, setSelected, setEditOrAddModal } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = useState('id');
  const [orderBy, setOrderBy] = useState('desc');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const copyToClipboard = (player_username: string, brand: string, referral_link: string) => {
    let data = `Player Number: ${player_username || '-'} \n`;
    data += `Password: See email for password reset link. \n`;
    data += `Brand Name: ${brand || '-'} \n`;
    data += `Invite Link to Share: ${referral_link || '-'}`;
    navigator.clipboard.writeText(data || '');
  };

  const getSort = (agents: IAgents[]) => {
    return agents.sort((a, b) => (orderBy == 'asc' ? a.id - b.id : b.id - a.id));
  };

  const formatDateTimeToPst = (time: any) => {
    let m = moment(time);
    m = m.utcOffset('-07:00');
    return m.format('MM/DD/YYYY hh:mm:ss');
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          Agents List
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Brand Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Comm. Structure</TableCell>
                <TableCell>Invite Link</TableCell>
                <TableCell className={classes.dFlex}>
                  Created Date
                  {order == 'id' && orderBy == 'desc' ? (
                    <ExpandMoreIcon
                      className={classes.iconOrder}
                      onClick={() => {
                        setOrder('id');
                        setOrderBy('asc');
                      }}
                    />
                  ) : (
                    <ExpandLessIcon
                      className={classes.iconOrder}
                      onClick={() => {
                        setOrder('id');
                        setOrderBy('desc');
                      }}
                    />
                  )}
                </TableCell>
                {/* <TableCell>Commision Structure</TableCell> */}
                <TableCell>Agent info</TableCell>
                {/* <TableCell># of Players</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {getSort(agents)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.brand || '-'}</TableCell>
                      <TableCell>{row.player_username || '-'}</TableCell>
                      <TableCell>
                        <IconButton
                          color='primary'
                          onClick={() => {
                            setSelected({
                              ...selected,
                              id: row.id,
                              first_name: row.first_name,
                              last_name: row.last_name,
                              email: row.email,
                              phone: row.phone,
                              brand: row.brand,
                              identifier: row.identifier,
                              agent_commission_profile: row.agent_commission_profile,
                              master_agent_id: row.master_agent_id,
                            });
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditTwoToneIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {row.commission_structure || 'N/A'}
                        <br />
                        {row.commission_desc == null ? (
                          'Please assign a commission structure to this agent.'
                        ) : (
                          <small dangerouslySetInnerHTML={{ __html: row.commission_desc }}></small>
                        )}
                      </TableCell>
                      <TableCell>{row.referral_link || '-'}</TableCell>
                      <TableCell>{row.created_at ? formatDateTimeToPst(row.created_at) : '-'}</TableCell>
                      {/* <TableCell>{row.commission_structure || '-'}</TableCell> */}
                      <TableCell>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={() => copyToClipboard(row.player_username, row.brand, row.referral_link)}
                          disableElevation
                        >
                          Copy
                        </Button>
                      </TableCell>
                      {/* <TableCell>{row.number_of_players || '-'}</TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component='div'
          count={agents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
