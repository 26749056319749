import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { AgentAdminSliceType, agentAdminReducer } from '../agent.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { IAgentCommStructure, IAgentCommStructureUpdate } from '../../../models/agent-comm-structure';
import { getAgentStructureAction } from '../actions/get-agent-comm-structures.action';

const suffix = `/${agentAdminReducer.sliceName}/app`;

const ADD_AGENT_COMM_STRUCTURE = `ADD_AGENT_COMM_STRUCTURE${suffix}`;
export const upsertAgentCommissionAction = createAction<IAgentCommStructureUpdate>(ADD_AGENT_COMM_STRUCTURE);

export const ADD_AGENT_COMM_STRUCTURE_SUCCESS = `ADD_AGENT_COMM_STRUCTURE_SUCCESS${suffix}`;
export const upsertAgentCommissionSuccessAction = createAction<IAgentCommStructure>(ADD_AGENT_COMM_STRUCTURE_SUCCESS);

const ADD_AGENT_COMM_STRUCTURE_ERROR = `ADD_AGENT_COMM_STRUCTURE_ERROR${suffix}`;
export const upsertAgentCommissionErrorAction = createAction<string>(ADD_AGENT_COMM_STRUCTURE_ERROR);

function* upsertAgentCommissionWatcher(): SagaIterator {
  yield takeEvery(ADD_AGENT_COMM_STRUCTURE, upsertAgentCommissionWorker);
}
mergeSaga(upsertAgentCommissionWatcher);

function* upsertAgentCommissionWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateAgentCommission, action.payload);
    } else {
      result = yield call(createAgentCommission, action.payload);
    }
    yield put(upsertAgentCommissionSuccessAction(result));
    yield put(getAgentStructureAction());
  } catch (e: any) {
    if (e.response.data.errors) {
      // parseValidationErrors(e.response.data.errors);
      yield call(showToast, e.response.data.errors, 'error');
    } else {
      yield call(showToast, e.response.data, 'error');
    }
    yield put(upsertAgentCommissionErrorAction(e.response.data.message));
  }
}

// const parseValidationErrors = (errors: any) => {
//   const errorMessage = '';

//   const err = [];
//   for (const [key, values] of Object.entries(errors)) {
//     const eobj: any = {};

//     Array.isArray(values) &&
//       values.map((value: any) => {
//         const errorsList: any = [];
//         errorsList.push(value.split('.')[1]);
//         eobj[key] = errorsList;
//       });
//     console.log(eobj);
//     // err.push(eobj);
//   }

//   // for (const [key, values] of Object.entries(err)) {
//   //   // console.log(key, values);

//   //   for (const [k, v] of Object.entries(values)) {
//   //     console.log(k, v);
//   //   }
//   // }

//   // err.map((e: any, index: any) => {
//   //   console.log(index);
//   //   console.log(e);
//   // });

//   return errorMessage;
// };

const createAgentCommission = (structure: IAgentCommStructureUpdate): Request => {
  const url = '/cashier/agent/commission/CreateStructure';
  return vipApi(url, 'post', structure, 'json');
};

const updateAgentCommission = (structure: IAgentCommStructureUpdate): Request => {
  const url = '/cashier/agent/commission/updateStructure/' + structure.id;
  return vipApi(url, 'post', structure, 'json');
};

const reduceHandlers = {
  [ADD_AGENT_COMM_STRUCTURE]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [ADD_AGENT_COMM_STRUCTURE_SUCCESS]: (slice: AgentAdminSliceType): AgentAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [ADD_AGENT_COMM_STRUCTURE_ERROR]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

agentAdminReducer.addActionReducerMap(reduceHandlers);
