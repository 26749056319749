import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IPaymentMethodsUpload, IPaymentMethods } from '../../../models/payment-methods';
import { PaymentMethodAdminSliceType, paymentMethodAdminReducer } from '../payment-method.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${paymentMethodAdminReducer.sliceName}/app`;

const UPSERT_PAYMENT_METHOD = `UPSERT_PAYMENT_METHOD${suffix}`;
export const upsertPaymentMethodAction = createAction<IPaymentMethodsUpload>(UPSERT_PAYMENT_METHOD);

export const UPSERT_PAYMENT_METHOD_SUCCESS = `UPSERT_PAYMENT_METHOD_SUCCESS${suffix}`;
export const upsertPaymentMethodSuccessAction = createAction<IPaymentMethods>(UPSERT_PAYMENT_METHOD_SUCCESS);

const UPSERT_PAYMENT_METHOD_ERROR = `UPSERT_PAYMENT_METHOD_ERROR${suffix}`;
export const upsertPaymentMethodErrorAction = createAction<string>(UPSERT_PAYMENT_METHOD_ERROR);

function* upsertPaymentMethodWatcher(): SagaIterator {
  yield takeEvery(UPSERT_PAYMENT_METHOD, upsertPaymentMethodWorker);
}
mergeSaga(upsertPaymentMethodWatcher);

function* upsertPaymentMethodWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updatePaymentMethod, action.payload);
    } else {
      result = yield call(createPaymentMethod, action.payload);
    }
    yield put(upsertPaymentMethodSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertPaymentMethodErrorAction(e.response.data.message));
  }
}

const createPaymentMethod = (paymentMethod: IPaymentMethodsUpload): Request => {
  const url = '/cashier/paymentMethods';
  return vipApi(url, 'post', paymentMethod);
};

const updatePaymentMethod = (paymentMethod: IPaymentMethodsUpload): Request => {
  const url = '/cashier/paymentMethods/' + paymentMethod.id;
  return vipApi(url, 'post', paymentMethod);
};

const reduceHandlers = {
  [UPSERT_PAYMENT_METHOD]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPSERT_PAYMENT_METHOD_SUCCESS]: (slice: PaymentMethodAdminSliceType, action: any): PaymentMethodAdminSliceType => {
    const checkInArray = slice.paymentMethods.filter(item => item.id == action.payload.data.id);
    return {
      ...slice,
      isSaving: false,
      paymentMethods:
        checkInArray.length > 0
          ? slice.paymentMethods.map(item => {
              if (item.id == action.payload.data.id) {
                return action.payload.data;
              } else {
                return item;
              }
            })
          : slice.paymentMethods.concat([action.payload.data]),
    };
  },
  [UPSERT_PAYMENT_METHOD_ERROR]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

paymentMethodAdminReducer.addActionReducerMap(reduceHandlers);
