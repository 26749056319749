import { connect } from 'react-redux';
import { PlayerInfoPage, ExternalProps, ExternalActionProps } from './player-info-page.component';
import { changePasswordAction } from '../../../api/player-info/actions/change-password.action';
import { getPlayerInfoAction } from '../../../api/player-info/actions/get-player-info.action';
import { searchPlayerAction } from '../../../api/player-info/actions/search-player.action';
import { disableRolloverAction } from '../../../api/player-info/actions/disable-rollover.action';
import { removeBonusAmountAction } from '../../../api/player-info/actions/remove-bonus-amount';
import { updateCognitoStatusAction } from '../../../api/player-info/actions/update-cognito-status.action';
import { getCognitoDataAction } from '../../../api/player-info/actions/get-cognito-data.action';
import {
  isLoadingSliceSelector,
  playerBonusHistorySelector,
  playerCognitoDataSelector,
  playerDepositsSelector,
  playerInfoSelector,
  playerNotesSelector,
  playerPendingWagersSelector,
  playerRafHistorySelector,
  playerRightsSelector,
  playerSearchedSelector,
  playerWagerHistorySelector,
  playerWithdrawalsSelector,
} from '../../../api/player-info/player-info.selector';
import { impersonatePlayerAction } from '../../../api/player-info/actions/impersonate-player.action';
import { clearSearchedPlayerAction } from '../../../api/player-info/actions/clear-searched-player.action';
import { updateTpiRightsAction } from '../../../api/player-info/actions/update-tpi-rights.action';
import { updatePlayerAction } from '../../../api/player-info/actions/update-player.action';
import { getPlayerDepositsAction } from '../../../api/player-info/actions/get-player-deposits.action';
import { getPlayerWithdrawalsAction } from '../../../api/player-info/actions/get-player-withdrawals.action';
import { getPlayerPendingWagersAction } from '../../../api/player-info/actions/get-player-pending-wagers.action';
import { getPlayerWagerHistoryAction } from '../../../api/player-info/actions/get-player-wager-history.action';
import { getPlayerBonusHistoryAction } from '../../../api/player-info/actions/get-player-bonus-history.action';
import { getPlayerRafStatsAction } from '../../../api/player-info/actions/get-player-raf-stats.action';
import { approveDenyReferralAction } from '../../../api/player-info/actions/approve-deny-referral.action';
import { getPlayerNotesAction } from '../../../api/player-info/actions/get-player-notes.action';
import { addPlayerNoteAction } from '../../../api/player-info/actions/add-player-note.action';
import { manualTransactionAction } from '../../../api/player-info/actions/manual-transaction.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    playerInfo: playerInfoSelector(state),
    searchedPlayer: playerSearchedSelector(state),
    // playerChangePassword: playerChangePasswordSelector(state),
    isLoading: isLoadingSliceSelector(state),
    cognitoData: playerCognitoDataSelector(state),
    rights: playerRightsSelector(state),
    deposits: playerDepositsSelector(state),
    withdrawals: playerWithdrawalsSelector(state),
    pendingWagers: playerPendingWagersSelector(state),
    wagerHistory: playerWagerHistorySelector(state),
    bonusHistory: playerBonusHistorySelector(state),
    rafHistory: playerRafHistorySelector(state),
    playerNotes: playerNotesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPlayerInfoAction,
  searchPlayerAction,
  changePasswordAction,
  disableRolloverAction,
  removeBonusAmountAction,
  updateCognitoStatusAction,
  getCognitoDataAction,
  impersonatePlayerAction,
  clearSearchedPlayerAction,
  updateTpiRightsAction,
  updatePlayerAction,
  getPlayerDepositsAction,
  getPlayerWithdrawalsAction,
  getPlayerPendingWagersAction,
  getPlayerWagerHistoryAction,
  getPlayerBonusHistoryAction,
  getPlayerRafStatsAction,
  approveDenyReferralAction,
  getPlayerNotesAction,
  addPlayerNoteAction,
  manualTransactionAction,
};

export const PlayerInfoPageContainer = connect(mapStateToProps, mapDispatchToProps)(PlayerInfoPage);
