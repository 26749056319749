import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IBanks } from '../../../models/banks';
import { bankAdminReducer, BankAdminSliceType } from '../bank.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${bankAdminReducer.sliceName}/app`;

const GET_BANKS = `GET_BANKS${suffix}`;

export const getBanksAction = createAction(GET_BANKS);

const GET_BANKS_SUCCESS = `GET_BANKS_SUCCESS${suffix}`;
const getBanksSuccessAction = createAction<IBanks[]>(GET_BANKS_SUCCESS);

const GET_BANKS_ERROR = `GET_BANKS_ERROR${suffix}`;
const getBanksErrorAction = createAction<string>(GET_BANKS_ERROR);

function* getBanksWatcher(): SagaIterator {
  yield takeEvery(GET_BANKS, getBanksWorker);
}
mergeSaga(getBanksWatcher);

function* getBanksWorker(): SagaIterator {
  try {
    const result: IGetAll<IBanks> = yield call(getBanksApi);
    yield put(getBanksSuccessAction(result.data));
  } catch (e: any) {
    yield put(getBanksErrorAction(e));
  }
}

const getBanksApi = (): Request => {
  return vipApi(`/cashier/banks`, 'get', {});
};

const reduceHandlers = {
  [GET_BANKS]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BANKS_SUCCESS]: (slice: BankAdminSliceType, action: any): BankAdminSliceType => ({
    ...slice,
    isLoading: false,
    banks: action.payload,
  }),
  [GET_BANKS_ERROR]: (slice: BankAdminSliceType): BankAdminSliceType => ({
    ...slice,
    isLoading: false,
    banks: [],
  }),
};

bankAdminReducer.addActionReducerMap(reduceHandlers);
