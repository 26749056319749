import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IAgents } from '../../../models/agent';
import { agentAdminReducer, AgentAdminSliceType } from '../agent.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';

const suffix = `/${agentAdminReducer.sliceName}/app`;

const GET_AGENTS = `GET_AGENTS${suffix}`;

export const getAgentAction = createAction(GET_AGENTS);

const GET_AGENTS_SUCCESS = `GET_AGENTS_SUCCESS${suffix}`;
const getAgentsSuccessAction = createAction<IAgents[]>(GET_AGENTS_SUCCESS);

const GET_AGENTS_ERROR = `GET_AGENTS_ERROR${suffix}`;
const getAgentsErrorAction = createAction<string>(GET_AGENTS_ERROR);

function* getAgentsWatcher(): SagaIterator {
  yield takeEvery(GET_AGENTS, getAgentsWorker);
}
mergeSaga(getAgentsWatcher);

function* getAgentsWorker(): SagaIterator {
  try {
    const result: IGetAll<IAgents> = yield call(getAgentsApi);
    yield put(getAgentsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getAgentsErrorAction(e));
  }
}

const getAgentsApi = (): Request => {
  return vipApi(`/cashier/ListAgents`, 'get', {});
};

const reduceHandlers = {
  [GET_AGENTS]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_AGENTS_SUCCESS]: (slice: AgentAdminSliceType, action: any): AgentAdminSliceType => ({
    ...slice,
    isLoading: false,
    agents: action.payload,
  }),
  [GET_AGENTS_ERROR]: (slice: AgentAdminSliceType): AgentAdminSliceType => ({
    ...slice,
    isLoading: false,
    agents: [],
  }),
};

agentAdminReducer.addActionReducerMap(reduceHandlers);
