import { connect } from 'react-redux';
import { LineRulesPage, ExternalActionProps, ExternalProps } from './line-rules-page.component';
function mapStateToProps(): ExternalProps {
  return {
    any: false,
  };
}
const mapDispatchToProps: ExternalActionProps = {
  any2: false,
};
export const LineRulePageContainer = connect(mapStateToProps, mapDispatchToProps)(LineRulesPage);
