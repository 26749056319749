import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { guideReducer, GuideSliceType } from '../guide.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_GUIDE_SUCCESS } from '../actions/upsert-guide.action';
import { DELETE_GUIDE_SUCCESS } from '../actions/delete-guide.action';

const suffix = `/${guideReducer.sliceName}/app`;

const GET_GUIDE = `GET_GUIDE${suffix}`;

export const getGuideAction = createAction(GET_GUIDE);

const GET_GUIDE_SUCCESS = `GET_GUIDE_SUCCESS${suffix}`;
const getGuideSuccessAction = createAction<any[]>(GET_GUIDE_SUCCESS);

const GET_GUIDE_ERROR = `GET_GUIDE_ERROR${suffix}`;
const getGuideErrorAction = createAction<string>(GET_GUIDE_ERROR);

function* getGuideWatcher(): SagaIterator {
  yield takeEvery([GET_GUIDE, UPSERT_GUIDE_SUCCESS, DELETE_GUIDE_SUCCESS], getGuideWorker);
}
mergeSaga(getGuideWatcher);

function* getGuideWorker(): SagaIterator {
  try {
    const result: IGetAll<any> = yield call(getGuideApi);
    yield put(getGuideSuccessAction(result.data));
  } catch (e: any) {
    yield put(getGuideErrorAction(e));
  }
}

const getGuideApi = (): Request => {
  return vipApi(`/guides/GetAllGuides`, 'get', {});
};

const reduceHandlers = {
  [GET_GUIDE]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_GUIDE_SUCCESS]: (slice: GuideSliceType, action: any): GuideSliceType => ({
    ...slice,
    isLoading: false,
    guides: action.payload,
  }),
  [GET_GUIDE_ERROR]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isLoading: false,
    guides: [],
  }),
};

guideReducer.addActionReducerMap(reduceHandlers);
