import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { emptyLandingPage, ILandingPage, ILandingPageUpload } from '../../models/landing-page';
import { LandingPageTable } from './components/landing-page-table.component';
import { LandingPageModal } from './components/landing-page-modal.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 12,
  },
});

export interface ExternalActionProps {
  getLandingPagesAction: () => void;
  upsertLandingPageAction: (landingPage: ILandingPageUpload) => void;
  updateActiveLandingPageAction: (landingPage: ILandingPage) => void;
  deleteLandingPageAction: (landingPage: ILandingPage) => void;
}

export interface ExternalProps {
  landingPages: ILandingPage[];
}

export const LandingPageAdmin = (props: ExternalActionProps & ExternalProps) => {
  const { landingPages, getLandingPagesAction, upsertLandingPageAction, updateActiveLandingPageAction, deleteLandingPageAction } = props;
  const [editOrAddModal, setEditOrAddModal] = useState(false);
  const [selected, setSelected] = useState(emptyLandingPage);
  const classes = useStyles();

  useEffect(() => {
    getLandingPagesAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.title}>
        <Typography variant='h4'>Landing Page</Typography>
        <Button
          onClick={() => {
            setSelected(emptyLandingPage);
            setEditOrAddModal(true);
          }}
        >
          Create Landing Page
        </Button>
      </div>
      <LandingPageTable
        landingPages={landingPages}
        selected={selected}
        setSelected={setSelected}
        setEditOrAddModal={setEditOrAddModal}
        updateActiveLandingPageAction={updateActiveLandingPageAction}
        deleteLandingPageAction={deleteLandingPageAction}
      />
      <LandingPageModal
        open={editOrAddModal}
        onClose={() => {
          setSelected(emptyLandingPage);
          setEditOrAddModal(false);
        }}
        landingPage={selected}
        upsertLandingPageAction={upsertLandingPageAction}
      />
    </div>
  );
};
