import { createSelector } from 'reselect';
import { transactionAdminReducer, TransactionAdminSliceType } from './transaction.reducer';

export const transactionAdminSliceSelector = (state: any): any => state[transactionAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  transactionAdminSliceSelector,
  (transactionAdminSlice: TransactionAdminSliceType) => transactionAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  transactionAdminSliceSelector,
  (transactionAdminSlice: TransactionAdminSliceType) => transactionAdminSlice.isSaving,
);

export const transactionsSelector = createSelector(
  transactionAdminSliceSelector,
  (transactionAdminSlice: TransactionAdminSliceType) => transactionAdminSlice.transactions,
);

export const totalTransactionsSelector = createSelector(
  transactionAdminSliceSelector,
  (transactionAdminSlice: TransactionAdminSliceType) => transactionAdminSlice.totalTransactions,
);

export const callTransactionsSelector = createSelector(
  transactionAdminSliceSelector,
  (transactionAdminSlice: TransactionAdminSliceType) => transactionAdminSlice.callTransactions,
);
