import { connect } from 'react-redux';
import { getAppSettingAction } from '../../api/app-settings/actions/get-app-settings.action';
import { updateAppSettingAction } from '../../api/app-settings/actions/update-app-setting.action';
import {
  appSettingCommunicationsSliceSelector,
  appSettingFeeFreeSliceSelector,
  appSettingIsLoadingSliceSelector,
  appSettingMethodFeesSliceSelector,
} from '../../api/app-settings/app-settings.selector';
import { AppSettingsPage, ExternalActionProps, ExternalProps } from './app-settings-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: appSettingIsLoadingSliceSelector(state),
    feeFree: appSettingFeeFreeSliceSelector(state),
    communications: appSettingCommunicationsSliceSelector(state),
    methodFees: appSettingMethodFeesSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAppSettingAction,
  updateAppSettingAction,
};

export const AppSettingsPageContainer = connect(mapStateToProps, mapDispatchToProps)(AppSettingsPage);
