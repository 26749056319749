import { Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import {
  Transactions,
  ITransactionUpdate,
  ITransactionLoad,
  emptyLoad,
  ITransactionVerify,
  emptyVerify,
  emptyUpdate,
  emptyFilterData,
  ITransactionFilterData,
} from '../../../models/transactions';
import { IBanks } from '../../../models/banks';
import { TransactionsTable } from './components/transactions-table';
import { WithdrawalTable } from './components/withdrawal-table';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UpdateLoadDialoge } from './components/update-load-dialog';
import { TransactionLogsDialoge } from './components/transaction-logs-dialoge';
import { Loading } from './../../../shared/loading/loading.component';
import { VerifyLoadDialoge } from './components/verify-load-dialog';
import TextField from '@mui/material/TextField';
import { AddBTCDialog } from './components/add-btc-dialoge';
import { IBTCAddress, IBTCAddressUpdate, emptyBTCAddress } from '../../../models/btc-addresses';
// import TransactionPlayerHistoryTable from './components/transaction-player-history-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPadding: {
      padding: theme.spacing(2),
    },
    formClass: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '45ch',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: '45ch',
    },
    tabClass: {
      '& button.Mui-selected': {
        backgroundColor: '#c8a964',
      },
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={0} style={{ border: '5px solid #c8a964' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface ExternalActionProps {
  getTransactionsAction: (filterData: ITransactionFilterData) => void;
  getBanksAction: () => void;
  updateTransactionAction: (transaction: ITransactionUpdate) => void;
  getBtcAddressesByBankAction: (id: number) => void;
  updateLoadTransactionAction: (transaction: ITransactionLoad) => void;
  verifyLoadTransactionAction: (transaction: ITransactionVerify) => void;
  upsertBtcAddressAction: (btcAddress: IBTCAddressUpdate) => void;
  removeBonusAction: () => void;
}

export interface ExternalProps {
  transactions: Transactions[];
  banks: IBanks[];
  btcAddresses: IBTCAddress[];
  isSaving: boolean;
  isLoading: boolean;
  totalTransactions: number;
  callTransactions: boolean;
}

export const TransactionsPage = (props: ExternalActionProps & ExternalProps) => {
  const {
    transactions,
    getTransactionsAction,
    getBanksAction,
    banks,
    updateTransactionAction,
    updateLoadTransactionAction,
    getBtcAddressesByBankAction,
    btcAddresses,
    isLoading,
    isSaving,
    verifyLoadTransactionAction,
    upsertBtcAddressAction,
    totalTransactions,
    callTransactions,
    removeBonusAction,
  } = props;
  const [value, setValue] = useState(0);
  const [loadOpen, setLoadOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [loadSelected, setLoadSelected] = useState({ ...emptyLoad, updated_by: getSessionUser() });
  const [verifySelected, setVerifySelected] = useState({ ...emptyVerify, username: getSessionUser() });
  const [companyBank, setCompanyBank] = useState<{ bank_id: any; id: any; method: any }>({ bank_id: '', id: '', method: '' });
  const classes = useStyles();
  const [btcOpen, setBtcOpen] = useState(false);
  const [btcSelected, setBtcSelected] = useState(emptyBTCAddress);
  const [update, setUpdate] = useState<ITransactionUpdate>(emptyUpdate);
  const [filterData, setFilterData] = useState(emptyFilterData);
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    getTransactionsAction({
      ...emptyFilterData,
      type: newValue + 1,
    });
    setFilterData({
      ...emptyFilterData,
      type: newValue + 1,
    });
    setValue(newValue);
  };

  useEffect(() => {
    getTransactionsAction(filterData);
    getBanksAction();
  }, []);

  useEffect(() => {
    setVerifySelected({ ...emptyVerify, username: getSessionUser() });
    setVerifyOpen(false);
  }, [transactions]);

  useEffect(() => {
    if (callTransactions) {
      getTransactionsAction(filterData);
    }
  }, [callTransactions]);

  // function doFilter(data: any) {
  //   return Object.keys(data).some(key => {
  //     return key != 'actions' && data[key] ? data[key].toString().toLowerCase().includes(search.toLowerCase()) : false;
  //   });
  // }

  return (
    <div className={classes.containerPadding}>
      <Typography variant='h6'>Transactions</Typography>
      <div>
        <AppBar position='static'>
          <Tabs value={value} onChange={handleChange} className={classes.tabClass}>
            <Tab label='Deposits' {...a11yProps(0)} />
            <Tab label='Withdrawals' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <FormControl variant='outlined' className={classes.formControl} style={{ marginTop: '15px' }}>
            <InputLabel id='status-filter-deposit-label'>Status</InputLabel>
            <Select
              labelId='status-filter-deposit-label'
              id='status-filter-deposit'
              label='Action'
              value={filterData.status}
              size='small'
              onChange={e => {
                setFilterData({
                  ...filterData,
                  type: 1,
                  status: e.target.value as unknown as number,
                });
                getTransactionsAction({
                  ...filterData,
                  type: 1,
                  status: e.target.value as unknown as number,
                });
              }}
            >
              <MenuItem value='all'>
                <em>All</em>
              </MenuItem>
              <MenuItem value={1}>Pending</MenuItem>
              <MenuItem value={4}>Action Required</MenuItem>
              <MenuItem value={5}>Unverified</MenuItem>
              <MenuItem value={6}>Completed</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant='outlined' className={classes.formControl} style={{ marginTop: '15px' }}>
            <TextField
              id='playerSearch'
              label='Search'
              variant='outlined'
              size='small'
              value={filterData.search}
              onChange={e => {
                setFilterData({
                  ...filterData,
                  search: e.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl style={{ marginTop: '15px' }}>
            <Button
              variant='outlined'
              size='large'
              onClick={() => {
                console.log(filterData);
                getTransactionsAction({
                  ...filterData,
                  type: 1,
                });
              }}
            >
              Search
            </Button>
          </FormControl>
          <TransactionsTable
            getBtcAddressesByBankAction={getBtcAddressesByBankAction}
            btcAddresses={btcAddresses}
            username={getSessionUser()}
            transactions={transactions}
            banks={banks}
            updateTransactionAction={updateTransactionAction}
            setLoadSelected={setLoadSelected}
            setLoadOpen={() => setLoadOpen(true)}
            loadSelected={loadSelected}
            setVerifySelected={setVerifySelected}
            setVerifyOpen={() => setVerifyOpen(true)}
            verifySelected={verifySelected}
            companyBank={companyBank}
            setCompanyBank={setCompanyBank}
            setSelectedLogs={setSelectedLogs}
            setLogsOpen={() => setLogsOpen(true)}
            setBtcOpen={() => setBtcOpen(true)}
            setBtcSelected={setBtcSelected}
            btcSelected={btcSelected}
            update={update}
            setUpdate={setUpdate}
            totalTransactions={totalTransactions}
            setFilterData={setFilterData}
            filterData={filterData}
            getTransactionsAction={getTransactionsAction}
            removeBonusAction={removeBonusAction}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormControl variant='outlined' className={classes.formControl} style={{ marginTop: '15px' }}>
            <InputLabel id='status-filter-withdrawal-label'>Status</InputLabel>
            <Select
              labelId='status-filter-withdrawal-label'
              id='status-filter-withdrawal'
              label='Action'
              value={filterData.status}
              size='small'
              onChange={e => {
                setFilterData({
                  ...filterData,
                  type: 2,
                  status: e.target.value as unknown as number,
                });
                getTransactionsAction({
                  ...filterData,
                  type: 2,
                  status: e.target.value as unknown as number,
                });
              }}
            >
              <MenuItem value='all'>
                <em>All</em>
              </MenuItem>
              <MenuItem value={1}>Pending</MenuItem>
              <MenuItem value={4}>Action Required</MenuItem>
              <MenuItem value={5}>Unverified</MenuItem>
              <MenuItem value={6}>Completed</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant='outlined' className={classes.formControl} style={{ marginTop: '15px' }}>
            <TextField
              id='playerSearch'
              label='Search'
              variant='outlined'
              value={filterData.search}
              size='small'
              onChange={e => {
                setFilterData({
                  ...filterData,
                  search: e.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl style={{ marginTop: '15px' }}>
            <Button
              variant='outlined'
              size='large'
              onClick={() => {
                getTransactionsAction({
                  ...filterData,
                  type: 2,
                });
              }}
            >
              Search
            </Button>
          </FormControl>
          <WithdrawalTable
            username={getSessionUser()}
            transactions={transactions}
            banks={banks}
            updateTransactionAction={updateTransactionAction}
            setSelectedLogs={setSelectedLogs}
            setLogsOpen={() => setLogsOpen(true)}
            totalTransactions={totalTransactions}
            setFilterData={setFilterData}
            filterData={filterData}
            getTransactionsAction={getTransactionsAction}
          />
        </TabPanel>
      </div>
      <UpdateLoadDialoge
        updateLoadTransactionAction={updateLoadTransactionAction}
        open={loadOpen}
        onClose={() => {
          setLoadSelected({ ...emptyLoad, updated_by: getSessionUser() });
          setLoadOpen(false);
        }}
        selected={loadSelected}
        setSelected={setLoadSelected}
      />
      <TransactionLogsDialoge
        open={logsOpen}
        handleClose={() => {
          setLogsOpen(false);
          setSelectedLogs([]);
        }}
        selectedLogs={selectedLogs}
      />
      <VerifyLoadDialoge
        selected={verifySelected}
        setSelected={setVerifySelected}
        verifyLoadTransactionAction={verifyLoadTransactionAction}
        open={verifyOpen}
        onClose={() => {
          setVerifySelected({ ...emptyVerify, username: getSessionUser() });
          setVerifyOpen(false);
        }}
      />
      <AddBTCDialog
        open={btcOpen}
        onClose={() => {
          setBtcOpen(false);
        }}
        selected={btcSelected}
        setSelected={setBtcSelected}
        banks={banks}
        upsertBtcAddresssAction={upsertBtcAddressAction}
        companyBank={companyBank}
        setCompanyBank={setCompanyBank}
        updateTransactionAction={updateTransactionAction}
        update={update}
        setUpdate={setUpdate}
      />
      <Loading loading={isLoading || isSaving} />
    </div>
  );
};
