import React from 'react';
import { IBonus, emptyBonus } from '../../../../models/bonus';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormHelperText, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ImageUploader } from './image-uploader.component';
import { CKEditor } from 'ckeditor4-react';
import InputAdornment from '@mui/material/InputAdornment';
import DatePicker from '@mui/lab/DatePicker';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
// import { Theme } from '@mui/material/styles';

const useStyles = makeStyles(() =>
  createStyles({
    blockSelect: {
      '& .MuiMenu-list li': {
        display: 'block',
        padding: '10px',
      },
    },
  }),
);

export interface ExternalActionProps {
  createBonusAction: (x: any) => void;
  // setCreateOpen: (x: boolean) => void;
  handleCreateClose: () => void;
  updateBonusAction: (x: any) => void;
  setSelected: (a: IBonus) => void;
}

export interface ExternalProps {
  isLoading: any;
  bonuses: any;
  profiles: any;
  bonus: any | null;
  createOpen: boolean;
  selected: IBonus;
}

export default function CreateBonusDialog(props: ExternalActionProps & ExternalProps) {
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const { createBonusAction, handleCreateClose, isLoading, profiles, bonuses, createOpen, updateBonusAction, selected, setSelected } =
    props;
  const [details, setDetails] = React.useState(selected.details);
  const [description, setDescription] = React.useState(selected.description);
  const [terms, setTerms] = React.useState(selected.terms);

  const validate = (request: any) => {
    request.terms = terms == '' ? selected.terms : terms;
    request.description = description == '' ? selected.description : description;
    request.details = details == '' ? selected.details : details;
    if (request.id === undefined) {
      createBonusAction(request);
    } else {
      updateBonusAction(request);
    }
    handleCreateClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={createOpen}
        onClose={handleCreateClose}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleCreateClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Bonus
            </Typography>
            <Button
              disabled={btnDisabled && isLoading}
              autoFocus
              color='inherit'
              onClick={() => {
                setBtnDisabled(!btnDisabled);
                setSelected(emptyBonus);
                validate(selected);
              }}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>Please complete the form below to create a new bonus.</DialogContentText>
          <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />

          <Grid container spacing={3}>
            {/* Bonus Code */}
            <Grid item xs={12} sm={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='category'>Bonus Type</InputLabel>
                <Select
                  label='Bonus Type'
                  labelId='bonustype'
                  id='bonustype-filled'
                  value={selected.category}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      category: event.target.value,
                    });
                  }}
                  required
                >
                  <MenuItem value={''}>Select a Type</MenuItem>
                  <MenuItem value='deposit'>Deposit</MenuItem>
                  <MenuItem value='riskfreebet'>Risk Free Bet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id='codeId'
                label='Code'
                fullWidth
                // required
                onChange={(event: any) => {
                  setSelected({
                    ...selected,
                    code: event.target.value,
                  });
                }}
                value={selected.code}
              />
              <small>The simple code to display or redeem in the future. EX: 200FP7X</small>
            </Grid>

            {/* Max Deposit */}
            <Grid item xs={6} sm={4}>
              <FormControl variant='outlined'>
                <TextField
                  id='minDeposit'
                  value={selected.min_deposit}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      min_deposit: event.target.value,
                    });
                  }}
                  label='Min Deposit'
                  placeholder='0.00'
                  required
                  InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                  sx={{ marginRight: '.5rem', marginLeft: '.5rem' }}
                />
              </FormControl>
              <FormControl variant='outlined'>
                <TextField
                  id='maxDeposit'
                  label='Max Deposit'
                  value={selected.max_deposit}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      max_deposit: event.target.value,
                    });
                  }}
                  placeholder='0.00'
                  required
                  InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                  sx={{ marginRight: '.5rem', marginLeft: '.5rem' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ marginBottom: '5px' }}>Description</InputLabel>
              <CKEditor
                type='classic'
                initData={selected.description}
                onChange={({ editor }) => {
                  setDescription(editor.getData());
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ marginBottom: '5px' }}>Details</InputLabel>
              <CKEditor
                type='classic'
                initData={selected.details}
                onChange={({ editor }) => {
                  setDetails(editor.getData());
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel style={{ marginBottom: '5px' }}>Terms</InputLabel>
              <CKEditor
                type='classic'
                initData={selected.terms}
                onChange={({ editor }) => {
                  setTerms(editor.getData());
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='ctable'>Contribution Table</InputLabel>
                <Select
                  className={classes.blockSelect}
                  label='Contribution Table'
                  labelId='ctable'
                  id='ctable-filled'
                  value={selected.contribution_id}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      contribution_id: event.target.value,
                    });
                  }}
                  required
                >
                  {profiles.map((profile: any) => {
                    return (
                      <MenuItem value={profile.id} key={profile.id}>
                        {profile.name} - {profile.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <TextField
                  value={selected.match_multiplier}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      match_multiplier: event.target.value,
                    });
                  }}
                  placeholder='100%'
                  label='% Match'
                  variant='outlined'
                  required
                />
                <FormHelperText>Ex: 100 for 100%, 50 for 50%</FormHelperText>
              </FormControl>
            </Grid>

            {/* Rollover Multiplier */}
            <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <TextField
                  value={selected.rollover_multiplier}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      rollover_multiplier: event.target.value,
                    });
                  }}
                  placeholder='0'
                  label='X Rollover '
                  variant='outlined'
                  required
                />
                <FormHelperText>Ex: 1 for 1x and .5 for .5x</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='recurring'>Recurring?</InputLabel>
                <Select
                  label='Recurring?'
                  labelId='recurring'
                  id='recurring-filled'
                  value={selected.recurring}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      recurring: event.target.value,
                    });
                  }}
                  required
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
                <FormHelperText>Recurring Bonus?</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='enableId'>Enable?</InputLabel>
                <Select
                  label='Enable?'
                  labelId='enableId'
                  id='enableId-filled'
                  value={selected.active}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      active: event.target.value,
                    });
                  }}
                  required
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
                <FormHelperText>Enable on Creation?</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' fullWidth>
                <DatePicker
                  renderInput={props => <TextField {...props} />}
                  label='Start Date Time'
                  value={selected.start_date}
                  disablePast
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      start_date: event,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant='outlined' fullWidth>
                <DatePicker
                  renderInput={props => <TextField {...props} />}
                  label='End Date Time'
                  value={selected.end_date}
                  disablePast
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      end_date: event,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                Want player to redeem another bonus first? Select 1 from the drop down. <br />
                <br />
                If not, skip this part.
              </DialogContentText>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='prebonusId'>Pre-Bonus</InputLabel>
                <Select
                  label='Pre-Bonus'
                  labelId='prebonusId'
                  id='prebonusId'
                  value={selected.ft_bonus_id}
                  onChange={(event: any) => {
                    setSelected({
                      ...selected,
                      ft_bonus_id: event.target.value,
                    });
                  }}
                  required
                >
                  {bonuses.map((bonus: any) => {
                    return (
                      <MenuItem value={bonus.id} key={bonus.id}>
                        ID: {bonus.id} [{bonus.code}] {bonus.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl style={{ overflow: 'hidden' }}>
                <ImageUploader
                  label='Image'
                  onImageChange={event => {
                    if (event && event.target && event.target.files && event.target.files.length > 0)
                      setSelected({
                        ...selected,
                        image: event.target.files ? event.target.files[0] : undefined,
                      });
                  }}
                  imgUrl={selected.id ? selected.image : undefined}
                  imgFile={selected.id ? undefined : selected.image}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
