import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';
import { ICognitoStatus } from '../../../../models/player-info/cognito-status';

export interface ExternalProps {
  open: boolean;
  playerNumber: string;
  action: string;
  status: number;
  id: number;
}

export interface ExternalActionProps {
  onClose: () => void;
  updateCognitoStatusAction: (cognitoStatus: ICognitoStatus) => void;
}

export const ConfirmCognitoStatusDialog = (props: ExternalProps & ExternalActionProps) => {
  const { open, onClose, playerNumber, action, status, updateCognitoStatusAction, id } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Alert severity='warning'>
            Are you sure you want to <b>{action}</b> verified payment methods for <b>{playerNumber}</b>?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              updateCognitoStatusAction({
                id: id,
                status: status,
              });
              onClose();
            }}
          >
            {action}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
