import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { appSettingsSliceReducer, appSettingsSliceType } from '../app-settings.reducer';

const suffix = `/${appSettingsSliceReducer.sliceName}/app`;

const GET_APP_SETTINGS = `GET_APP_SETTINGS${suffix}`;

export const getAppSettingAction = createAction(GET_APP_SETTINGS);

const GET_APP_SETTINGS_SUCCESS = `GET_APP_SETTINGS_SUCCESS${suffix}`;
const getAppSettingsSuccessAction = createAction<any>(GET_APP_SETTINGS_SUCCESS);

const GET_APP_SETTINGS_ERROR = `GET_APP_SETTINGS_ERROR${suffix}`;
const getAppSettingsErrorAction = createAction<string>(GET_APP_SETTINGS_ERROR);

function* getAppSettingsWatcher(): SagaIterator {
  yield takeEvery(GET_APP_SETTINGS, getAppSettingsWorker);
}
mergeSaga(getAppSettingsWatcher);

function* getAppSettingsWorker(): SagaIterator {
  try {
    const result: any = yield call(getAppSettingsApi);
    console.log(result);
    yield put(getAppSettingsSuccessAction(result));
  } catch (e: any) {
    yield put(getAppSettingsErrorAction(e));
  }
}

const getAppSettingsApi = (): Request => {
  return vipApi(`/cashier/GetAppSettings`, 'get', {});
};

const reduceHandlers = {
  [GET_APP_SETTINGS]: (slice: appSettingsSliceType): appSettingsSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_APP_SETTINGS_SUCCESS]: (slice: appSettingsSliceType, action: any): appSettingsSliceType => ({
    ...slice,
    isLoading: false,
    feeFree: action.payload.FeeFree,
    communications: action.payload.Communications,
    methodFees: action.payload.MethodFees,
  }),
  [GET_APP_SETTINGS_ERROR]: (slice: appSettingsSliceType): appSettingsSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

appSettingsSliceReducer.addActionReducerMap(reduceHandlers);
