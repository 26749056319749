import { connect } from 'react-redux';
import { upsertAgentCommissionAction } from '../../../api/agent/actions/add-agent-comm-structure.action';
import { deleteAgentCommissionAction } from '../../../api/agent/actions/delete-agent-comm-structure.action';
import { getAgentStructureAction } from '../../../api/agent/actions/get-agent-comm-structures.action';
import { commissionStructuresSelector, isLoadingSliceSelector } from '../../../api/agent/agent.selector';
import { AgentCommStructurePage, ExternalActionProps, ExternalProps } from './agent-comm-structure-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    structures: commissionStructuresSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAgentStructureAction,
  upsertAgentCommissionAction,
  deleteAgentCommissionAction,
};

export const AgentCommStructurePageContainer = connect(mapStateToProps, mapDispatchToProps)(AgentCommStructurePage);
