import { createSelector } from 'reselect';
import { tourSectionsAdminReducer, TourSectionsAdminSliceType } from './tourSections.reducer';

export const tourSectionsAdminSliceSelector = (state: any): any => state[tourSectionsAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  tourSectionsAdminSliceSelector,
  (tourSectionsAdminSlice: TourSectionsAdminSliceType) => tourSectionsAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  tourSectionsAdminSliceSelector,
  (tourSectionsAdminSlice: TourSectionsAdminSliceType) => tourSectionsAdminSlice.isSaving,
);

export const tourSectionsSelector = createSelector(
  tourSectionsAdminSliceSelector,
  (tourSectionsAdminSlice: TourSectionsAdminSliceType) => tourSectionsAdminSlice.sectionsList,
);
