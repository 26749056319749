import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export interface PrivateRouteExternalProps {
  isLoggedIn: boolean;
}

export interface PrivateRouteOwnProps {
  component: any;
  path: string;
}

export const PrivateRoute = ({ component, path, isLoggedIn }: PrivateRouteOwnProps & PrivateRouteExternalProps) => {
  return isLoggedIn ? <Route exact path={path} component={component} /> : <Redirect push to='/login' />;
};
