import { Transactions } from '../../models/transactions';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'transactionAdminSlice';

export interface TransactionAdminSliceType {
  isLoading: boolean;
  transactions: Transactions[];
  isSaving: boolean;
  totalTransactions: number;
  callTransactions: boolean;
}

export const initialState: TransactionAdminSliceType = {
  isLoading: false,
  transactions: [],
  isSaving: false,
  totalTransactions: 0,
  callTransactions: false,
};

export const transactionAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
