import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'accountingSlice';

export interface AccountingSliceType {
  isLoading: boolean;
  overview: [];
  deposits: [];
  withdrawals: [];
  total: [];
}

export const initialState: AccountingSliceType = {
  isLoading: false,
  overview: [],
  deposits: [],
  withdrawals: [],
  total: [],
};

export const accountingReducer = createAndMergeSliceReducer(sliceName, initialState);
