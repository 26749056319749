import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Chip,
  CardActions,
  Button,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  approveDenyReferralAction: (x: any) => void;
}

export interface ExternalProps {
  rafHistory: any;
  isLoading: any;
  playerInfo: any;
}

export const ReferralsHistoryCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { rafHistory, isLoading, playerInfo, approveDenyReferralAction } = props;

  const [filter, setFilter] = React.useState(0);

  return (
    <div className={clsx(classes.root)}>
      {!isLoading && (
        <>
          {rafHistory.invited == undefined ? (
            'No Referral History.'
          ) : (
            <React.Fragment>
              <Grid container sx={{ marginBottom: '1rem' }} spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={filter}
                      label='Status'
                      onChange={(event: any) => {
                        setFilter(event.target.value);
                      }}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Need Approval</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper sx={{ padding: 1 }}>
                    <Typography variant='h6'>
                      {rafHistory.invited}
                      <small> Invited</small>
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper sx={{ padding: 1 }}>
                    <Typography variant='h6'>
                      {rafHistory.claimed}
                      <small> Claimed</small>
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Paper sx={{ padding: 1 }}>
                    <Typography variant='h6'>
                      ${rafHistory.referralDeposits}
                      <small> Deposits</small>
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: '2rem' }} />
              <Grid container spacing={4}>
                {rafHistory.referrals &&
                  rafHistory.referrals
                    .filter((r: any) => {
                      if (filter == 1) {
                        if (
                          r.need_approval == true &&
                          r.deposit != null &&
                          r.wagered != null &&
                          r.invite_approved == null &&
                          r.invite_redeemed == null
                        ) {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    })
                    .map((referral: any) => {
                      return (
                        <Grid item xs={12} sm={4} key={referral.id}>
                          <Card elevation={2}>
                            <CardHeader title={referral.username} subheader={referral.first_name + ' ' + referral.last_name} />
                            <Divider />
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  Sign Up IP:
                                </Grid>
                                <Grid item xs={6}>
                                  {referral.signup_ip == null ? (
                                    <span style={{ color: 'orange' }}>N/A | Not Logged</span>
                                  ) : (
                                    referral.signup_ip
                                  )}
                                  <br />
                                  {referral.signup_ip_match == true && <Chip label='IP MATCH' color='error' size='small' />}
                                </Grid>
                                <Grid item xs={6}>
                                  Sign Up FP:
                                </Grid>
                                <Grid item xs={6}>
                                  {referral.signup_fingerprint == null ? (
                                    <span style={{ color: 'orange' }}>N/A | Not Logged</span>
                                  ) : (
                                    referral.signup_fingerprint
                                  )}
                                  <br />
                                  {referral.signup_fingerprint_match == true && <Chip label='FP MATCH' color='error' size='small' />}
                                </Grid>
                                {referral.deposit != null && (
                                  <>
                                    <Grid item xs={6}>
                                      Deposit:
                                    </Grid>
                                    <Grid item xs={6}>
                                      {referral.deposit == null ? (
                                        <Typography style={{ color: 'orange' }}>Need to Deposit</Typography>
                                      ) : (
                                        '$' + referral.deposit
                                      )}
                                    </Grid>
                                  </>
                                )}
                                {referral.wagered != null && (
                                  <>
                                    <Grid item xs={6}>
                                      Wagered?:
                                    </Grid>
                                    <Grid item xs={6}>
                                      {referral.wagered == null ? (
                                        <Typography style={{ color: 'orange' }}>Need a Graded Wager</Typography>
                                      ) : (
                                        'Yes'
                                      )}
                                    </Grid>
                                  </>
                                )}
                                {referral.invite_redeemed != null && (
                                  <>
                                    <Grid item xs={6}>
                                      Redeemed?
                                    </Grid>
                                    <Grid item xs={6}>
                                      {referral.invite_redeemed}
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </CardContent>
                            <CardActions>
                              <div style={{ margin: '0px auto', display: 'block' }}>
                                {referral.need_approval == true &&
                                  referral.deposit != null &&
                                  referral.wagered != null &&
                                  referral.invite_approved == null &&
                                  referral.invite_redeemed == null && (
                                    <>
                                      <Button
                                        variant='contained'
                                        color='inherit'
                                        style={{ margin: 5 }}
                                        onClick={() => {
                                          approveDenyReferralAction({
                                            player_number: playerInfo.personal.player_number,
                                            id: referral.id,
                                            status: 0,
                                          });
                                        }}
                                      >
                                        Deny
                                      </Button>
                                      <Button
                                        variant='contained'
                                        color='primary'
                                        style={{ margin: 5 }}
                                        onClick={() => {
                                          approveDenyReferralAction({
                                            player_number: playerInfo.personal.player_number,
                                            id: referral.id,
                                            status: 1,
                                          });
                                        }}
                                      >
                                        Approve
                                      </Button>
                                    </>
                                  )}
                              </div>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
              </Grid>
            </React.Fragment>
          )}
        </>
      )}
    </div>
  );
};
