import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Chip, Divider, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  withdrawals: any;
  isLoading: any;
}

export const WithdrawalHistoryCard = (props: ExternalProps) => {
  const classes = useStyles();
  const { withdrawals, isLoading } = props;

  const GetStatusChip = (status: number) => {
    if (status == 1) {
      return <Chip label='Pending' />;
    }
    if (status == 4) {
      return <Chip label='Action Required' sx={{ bgcolor: 'orange' }} />;
    }
    if (status == 6) {
      return <Chip label='Completed' sx={{ bgcolor: 'green' }} />;
    }
    if (status == 7) {
      return <Chip label='Reversed' />;
    }
    if (status == 8) {
      return <Chip label='Canceled' />;
    }
  };

  const formatDateTimeToPst = (time: any) => {
    let m = moment(time);
    m = m.utcOffset('-07:00');
    return m.format('MM/DD/YYYY hh:mm:ss');
  };

  return (
    <div className={clsx(classes.root)}>
      <Typography variant='h6' gutterBottom>
        Withdrawal History
      </Typography>
      <Divider sx={{ marginBottom: '1rem' }} />

      <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant='h6'>{withdrawals && withdrawals.count}</Typography>
            <small>Withdrawals</small>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant='h6'>${withdrawals && withdrawals.paid}</Typography>
            <small>Paid Out</small>
          </Paper>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Withdrawal Info:</TableCell>
            <TableCell>Requested:</TableCell>
            <TableCell>Paid</TableCell>
            <TableCell sx={{ maxWidth: '200px' }}>Status & Notes</TableCell>
            <TableCell>Monitoring</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && (
            <>
              {withdrawals.transactions.length == 0 ? (
                'No Withdrawal History.'
              ) : (
                <>
                  {withdrawals.transactions.map((row: any) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          ID: #{row.id}
                          <br />
                          {row.created_at ? formatDateTimeToPst(row.created_at) : '-'}
                          <br />
                          <br />
                        </TableCell>
                        <TableCell>
                          <img src={row.method_image} style={{ maxWidth: '30px', height: 'auto' }} /> {row.method_name}
                          <br />
                          Pay To: {row.account}
                          <br />
                          Amount: ${row.amount}
                        </TableCell>
                        <TableCell>
                          Fee: ${row.fee}
                          <br />
                          Paid: ${row.amount_received}
                        </TableCell>
                        <TableCell sx={{ maxWidth: '200px' }}>
                          {GetStatusChip(row.status)}
                          <br /> <br />
                          {row.notes != null && (
                            <>
                              Notes:
                              <br />
                              {row.notes}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          IP: {row.ip}
                          <br />
                          FP: {row.fingerprint}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
