import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo, IManualTransaction } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const REMOVE_BONUS_AMOUNT = `REMOVE_BONUS_AMOUNT${suffix}`;

export const removeBonusAmountAction = createAction<any>(REMOVE_BONUS_AMOUNT);

const REMOVE_BONUS_AMOUNT_SUCCESS = `REMOVE_BONUS_AMOUNT_SUCCESS${suffix}`;
const removeBonusAmountSuccessAction = createAction<IPlayerInfo[]>(REMOVE_BONUS_AMOUNT_SUCCESS);

const REMOVE_BONUS_AMOUNT_ERROR = `REMOVE_BONUS_AMOUNT_ERROR${suffix}`;
const removeBonusAmountErrorAction = createAction<string>(REMOVE_BONUS_AMOUNT_ERROR);

function* removeBonusAmountWatcher(): SagaIterator {
  yield takeEvery(REMOVE_BONUS_AMOUNT, removeBonusAmountWorker);
}
mergeSaga(removeBonusAmountWatcher);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

function* removeBonusAmountWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(removeBonusAmountApi, action.payload);
    yield put(removeBonusAmountSuccessAction(result.data));
    yield call(showToast, 'Successfully removed bonus amount.', 'success');
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(removeBonusAmountErrorAction(e.response.data.message));
  }
}

const removeBonusAmountApi = (req: IManualTransaction): Request => {
  return vipApi(`/cashier/manualTransaction/${req.userId}`, 'post', {
    transactionType: 5,
    method: 'Free Play',
    amount: req.amount,
    notes: 'Removing bonus amount for rollover disabling.',
    staff: getSessionUser(),
  });
};

const reduceHandlers = {
  [REMOVE_BONUS_AMOUNT]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [REMOVE_BONUS_AMOUNT_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [REMOVE_BONUS_AMOUNT_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
