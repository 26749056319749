import { IRuleCategory } from '../../models/rule-categories';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'ruleCategoriesSlice';

export interface RuleCategoriesSliceType {
  isLoading: boolean;
  isSaving: boolean;
  ruleCategories: IRuleCategory[];
  isSavingCategory: boolean;
}

export const initialState: RuleCategoriesSliceType = {
  isLoading: false,
  isSaving: false,
  ruleCategories: [],
  isSavingCategory: true,
};

export const ruleCategoriesReducer = createAndMergeSliceReducer(sliceName, initialState);
