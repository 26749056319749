import { connect } from 'react-redux';
import { TourCreatorPage, ExternalProps, ExternalActionProps } from './tour-page.component';
import { getStepsAction } from '../../api/tourCreator/actions/get-steps.action';
import { updateStepsAction } from '../../api/tourCreator/actions/update-steps.action';
import { deleteStepsAction } from '../../api/tourCreator/actions/delete-steps.action';
import { getSectionsAction } from '../../api/tourSections/actions/get-sections.action';
import { isLoadingSliceSelector, isSavingSliceSelector, tourCreatorSelector } from '../../api/tourCreator/tourCreator.selector';
import { tourSectionsSelector } from '../../api/tourSections/tourSections.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    stepsList: tourCreatorSelector(state),
    sectionsList: tourSectionsSelector(state),
    isLoading: isLoadingSliceSelector(state),
    isSaving: isSavingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getStepsAction,
  getSectionsAction,
  updateStepsAction,
  deleteStepsAction,
};

export const TourCreatorPageContainer = connect(mapStateToProps, mapDispatchToProps)(TourCreatorPage);
