import { IBTCAddress } from '../../models/btc-addresses';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'btcAddressAdminSlice';

export interface BtcAddressAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  btcAddresses: IBTCAddress[];
}

export const initialState: BtcAddressAdminSliceType = {
  isLoading: false,
  isSaving: false,
  btcAddresses: [],
};

export const btcAddressAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
