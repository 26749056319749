import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, Paper, PaperProps, Select, MenuItem } from '@mui/material';
import Draggable from 'react-draggable';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IAgentsUpdate } from '../../../../models/agent';
import { IChartAccount } from '../../../../models/chart-account';
import { IPaymentMethods } from '../../../../models/payment-methods';
import { IDistributors } from '../../../../models/distributor';
import { IAgentCommStructureUpdate } from '../../../../models/agent-comm-structure';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginLeft: '8px',
      marginRight: '8px',
      marginTop: '8px',
    },
    button: {
      margin: theme.spacing(1),
    },
    dFlex: {
      display: 'flex',
      alignItems: 'baseline',
    },
    mx2: {
      marginLeft: '8px',
      marginRight: '8px',
    },
    referralInp: {
      width: '180px',
    },
    referralText: {
      marginLeft: '8px',
      marginRight: '8px',
      fontSize: '15px',
    },
  }),
);

export interface ExternalProps {
  open: boolean;
  selected: IAgentsUpdate;
  chartAccounts: IChartAccount[];
  paymentMethods: IPaymentMethods[];
  distributors: IDistributors[];
  structures: IAgentCommStructureUpdate[];
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertAgentAction: (agent: IAgentsUpdate) => void;
  setSelected: (agent: IAgentsUpdate) => void;
}

export const CreateAgentDialog = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { distributors, upsertAgentAction, onClose, open, selected, setSelected, structures } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='draggable-dialog-title'>{selected.id == null ? 'Create Agent' : 'Update Agent'}</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            upsertAgentAction(selected);
            onClose();
          }}
        >
          <DialogContent>
            <DialogContentText>Please fill out the form below to create a agent.</DialogContentText>

            <div className={classes.dFlex}>
              <TextField
                margin='dense'
                id='first_name'
                label='First Name'
                type='text'
                value={selected.first_name}
                className={classes.mx2}
                onChange={event =>
                  setSelected({
                    ...selected,
                    first_name: event.target.value,
                  })
                }
                required
                fullWidth
              />

              <TextField
                margin='dense'
                id='last_name'
                label='Last Name'
                type='text'
                value={selected.last_name}
                className={classes.mx2}
                onChange={event =>
                  setSelected({
                    ...selected,
                    last_name: event.target.value,
                  })
                }
                required
                fullWidth
              />
            </div>

            <div className={classes.dFlex}>
              <TextField
                margin='dense'
                id='email'
                label='Email'
                type='email'
                value={selected.email}
                className={classes.mx2}
                onChange={event =>
                  setSelected({
                    ...selected,
                    email: event.target.value,
                  })
                }
                required
                fullWidth
              />

              <TextField
                margin='dense'
                id='phone'
                label='Mobile'
                type='text'
                value={selected.phone}
                className={classes.mx2}
                onChange={event =>
                  setSelected({
                    ...selected,
                    phone: event.target.value,
                  })
                }
                required
                fullWidth
              />
            </div>

            {selected.id == null && (
              <>
                <div className={classes.dFlex}>
                  <TextField
                    margin='dense'
                    id='brand'
                    label='Brand Name'
                    type='text'
                    value={selected.brand}
                    className={classes.mx2}
                    inputProps={{
                      maxlength: 7,
                    }}
                    onChange={event =>
                      setSelected({
                        ...selected,
                        brand: event.target.value,
                      })
                    }
                    required
                    fullWidth
                  />
                </div>

                <div className={classes.dFlex}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor='method'>Distributor</InputLabel>
                    <Select
                      required
                      value={selected.master_agent_id}
                      onChange={event =>
                        setSelected({
                          ...selected,
                          master_agent_id: event.target.value as string,
                        })
                      }
                      inputProps={{
                        Agent: 'master_agent_id',
                        IdAgent: 'master_agent_id',
                      }}
                      fullWidth
                    >
                      {distributors.map(item => {
                        return (
                          <MenuItem value={item.IdAgent} key={item.IdAgent}>
                            {item.Agent}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}

            <div className={classes.dFlex}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor='method'>Commission Structure</InputLabel>
                <Select
                  required
                  value={selected.agent_commission_profile}
                  onChange={event =>
                    setSelected({
                      ...selected,
                      agent_commission_profile: event.target.value as string,
                    })
                  }
                  // inputProps={{
                  //   Agent: 'master_agent_id',
                  //   IdAgent: 'master_agent_id',
                  // }}
                  fullWidth
                >
                  {structures.map(item => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className={classes.dFlex}>
              <p className={classes.referralText}>Referral URL: https://vipclub.lv/#/signup/</p>
              <TextField
                margin='dense'
                id='identifier'
                label='Preferred identifier'
                type='text'
                className={classes.referralInp}
                value={selected.identifier}
                onChange={event =>
                  setSelected({
                    ...selected,
                    identifier: event.target.value,
                  })
                }
                required
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant='contained' color='primary' type='submit'>
              {selected.id == null ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
