import React from 'react';
import Button from '@mui/material/Button';
import { Dialog, Grid, TextField, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IBonusProfile, emptyBonusProfile } from '../../../../models/bonus-profile';

export interface ExternalProps {
  isLoading: any;
}

export interface ExternalActionProps {
  createBonusProfileAction: (x: any) => void;
}

export default function CreateProfileDialog(props: ExternalProps & ExternalActionProps) {
  const [open, setOpen] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const { isLoading, createBonusProfileAction } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setSelected({
    //   ...emptyBonusProfile,
    // });
    setOpen(false);
  };

  const [selected, setSelected] = React.useState<IBonusProfile>({
    ...emptyBonusProfile,
  });

  return (
    <div>
      <Button variant='contained' color='primary' style={{ margin: 4 }} onClick={handleClickOpen}>
        Create Cont. Profile
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        // scroll='body'
        style={{ marginTop: '3rem' }}
        maxWidth='sm'
      >
        <DialogTitle id='responsive-dialog-title'>{'Create Cont. Profile'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please complete the form below to create a contribution table profile.</DialogContentText>

          <Grid container spacing={2}>
            {/* Profile Name */}
            <Grid item xs={12}>
              <TextField
                id='standard-basic'
                label='Profile Name'
                variant='filled'
                fullWidth
                required
                onChange={event => {
                  setSelected({
                    ...selected,
                    name: event.target.value as string,
                  });
                }}
                value={selected.name}
              />
              <small>Short Name for Profile</small>
            </Grid>

            {/* Profile Description */}
            <Grid item xs={12}>
              <TextField
                id='outlined-multiline-static'
                label='Profile Description'
                multiline
                rows={4}
                variant='filled'
                fullWidth
                required
                value={selected.description}
                onChange={event => {
                  setSelected({
                    ...selected,
                    description: event.target.value as string,
                  });
                }}
              />
            </Grid>
          </Grid>

          <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />

          <DialogContentText>Please enter contribution percents for each category.</DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6'>Straight Bets</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.straight_bet}
                onChange={event => {
                  setSelected({
                    ...selected,
                    straight_bet: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Parlay Bets</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.parlay_bet}
                onChange={event => {
                  setSelected({
                    ...selected,
                    parlay_bet: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Teaser Bets</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.teaser_bet}
                onChange={event => {
                  setSelected({
                    ...selected,
                    teaser_bet: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>If Bets</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.if_bet}
                onChange={event => {
                  setSelected({
                    ...selected,
                    if_bet: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Reverse Bets</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.reverse_bet}
                onChange={event => {
                  setSelected({
                    ...selected,
                    reverse_bet: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Contest / Futures</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.contest_futures}
                onChange={event => {
                  setSelected({
                    ...selected,
                    contest_futures: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Props Builder</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.props_builder}
                onChange={event => {
                  setSelected({
                    ...selected,
                    props_builder: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Live Wager - Straight</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.live_wager_straight}
                onChange={event => {
                  setSelected({
                    ...selected,
                    live_wager_straight: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Live Wager - Parlay</Typography>
              <small>Sportsbook</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.live_wager_parlay}
                onChange={event => {
                  setSelected({
                    ...selected,
                    live_wager_parlay: event.target.value as string,
                  });
                }}
              />
            </Grid>
          </Grid>

          <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant='h6'>Live Casino</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.live_casino}
                onChange={event => {
                  setSelected({
                    ...selected,
                    live_casino: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Poker</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.poker}
                onChange={event => {
                  setSelected({
                    ...selected,
                    poker: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Table Games</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.table_games}
                onChange={event => {
                  setSelected({
                    ...selected,
                    table_games: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Slots</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.slots}
                onChange={event => {
                  setSelected({
                    ...selected,
                    slots: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Video Poker</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.video_poker}
                onChange={event => {
                  setSelected({
                    ...selected,
                    video_poker: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Bingo</Typography>
              <small>Casino</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.bingo}
                onChange={event => {
                  setSelected({
                    ...selected,
                    bingo: event.target.value as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6'>Horse Racing</Typography>
              <small>Racetrack</small>
            </Grid>
            <Grid>
              <TextField
                label='Percent'
                fullWidth
                required
                placeholder='100'
                value={selected.horse_racing}
                onChange={event => {
                  setSelected({
                    ...selected,
                    horse_racing: event.target.value as string,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Close
          </Button>
          <Button
            disabled={btnDisabled && isLoading}
            color='primary'
            variant='contained'
            onClick={() => {
              setBtnDisabled(!btnDisabled);
              createBonusProfileAction(selected);
              handleClose();
            }}
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
