import { createSelector } from 'reselect';
import { ruleCategoriesReducer, RuleCategoriesSliceType } from './rule-categories.reducer';

export const ruleCategoriesSliceSelector = (state: any): any => state[ruleCategoriesReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  ruleCategoriesSliceSelector,
  (ruleCategoriesSlice: RuleCategoriesSliceType) => ruleCategoriesSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  ruleCategoriesSliceSelector,
  (ruleCategoriesSlice: RuleCategoriesSliceType) => ruleCategoriesSlice.isSaving,
);

export const ruleCategoriesSelector = createSelector(
  ruleCategoriesSliceSelector,
  (ruleCategoriesSlice: RuleCategoriesSliceType) => ruleCategoriesSlice.ruleCategories,
);
