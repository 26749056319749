import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IGuideCategory } from '../../../models/guide-categories';
import { guideCategoriesReducer, GuideCategoriesSliceType } from '../guide-categories.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { UPSERT_GUIDE_CATEGORY_SUCCESS } from '../actions/upsert-guide-categories.action';
import { DELETE_GUIDE_CATEGORY_SUCCESS } from '../actions/delete-guide-categories.action';

const suffix = `/${guideCategoriesReducer.sliceName}/app`;

const GET_GUIDE_CATEGORIES = `GET_GUIDE_CATEGORIES${suffix}`;

export const getGuideCategoriesAction = createAction(GET_GUIDE_CATEGORIES);

const GET_GUIDE_CATEGORIES_SUCCESS = `GET_GUIDE_CATEGORIES_SUCCESS${suffix}`;
const getGuideCategoriesSuccessAction = createAction<IGuideCategory[]>(GET_GUIDE_CATEGORIES_SUCCESS);

const GET_GUIDE_CATEGORIES_ERROR = `GET_GUIDE_CATEGORIES_ERROR${suffix}`;
const getGuideCategoriesErrorAction = createAction<string>(GET_GUIDE_CATEGORIES_ERROR);

function* getGuideCategoriesWatcher(): SagaIterator {
  yield takeEvery([GET_GUIDE_CATEGORIES, UPSERT_GUIDE_CATEGORY_SUCCESS, DELETE_GUIDE_CATEGORY_SUCCESS], getGuideCategoriesWorker);
}
mergeSaga(getGuideCategoriesWatcher);

function* getGuideCategoriesWorker(): SagaIterator {
  try {
    const result: IGetAll<IGuideCategory> = yield call(getGuideCategoriesApi);
    yield put(getGuideCategoriesSuccessAction(result.data));
  } catch (e: any) {
    yield put(getGuideCategoriesErrorAction(e));
  }
}

const getGuideCategoriesApi = (): Request => {
  return vipApi(`/guides/GetAllGuideCategories`, 'get', {});
};

const reduceHandlers = {
  [GET_GUIDE_CATEGORIES]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_GUIDE_CATEGORIES_SUCCESS]: (slice: GuideCategoriesSliceType, action: any): GuideCategoriesSliceType => ({
    ...slice,
    isLoading: false,
    guideCategories: action.payload,
  }),
  [GET_GUIDE_CATEGORIES_ERROR]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isLoading: false,
    guideCategories: [],
  }),
};

guideCategoriesReducer.addActionReducerMap(reduceHandlers);
