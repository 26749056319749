import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Button, Chip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import RolloverOptionsMenu from '../rollover-options-menu';
import { RemoveBonusAmountDialog } from '../remove-bonus-amount-dialog';
import { DisableRolloverDialog } from '../disable-rollover-dialog';
import { WageringInfoDialog } from '../wagering-info-dialoge';
import { WageringInfoTable } from '../wagering-info-table';
import { ContributionInfoDialog } from '../contribution-info-dialog';
import { ContributionInfoTable } from '../contribution-info-table';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  disableRolloverAction: (string: string) => void;
  removeBonusAmountAction: (string: string) => void;
}

export interface ExternalProps {
  playerInfo: any;
  bonusHistory: any;
  isLoading: any;
}

export const BonusHistoryCard = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { isLoading, playerInfo, bonusHistory, removeBonusAmountAction, disableRolloverAction } = props;

  const [bonusAmountDialogOpen, setBonusAmountDialogOpen] = useState(false);
  const [bonusAmount, setBonusAmount] = useState(null);
  const [rollDisDialogOpen, setRollDisDialogOpen] = useState(false);
  const [bonusType, setBonusType] = useState(null);

  const [open, setOpen] = useState(false);
  const [wagerData, setWagerData] = useState(null);

  const [contTableOpen, setContTableOpen] = useState(false);
  const [contTableData, setContTableData] = useState(null);
  const [contBonus, setContBonus] = useState('');

  return (
    <>
      <RemoveBonusAmountDialog
        open={bonusAmountDialogOpen}
        onClose={() => {
          setBonusAmountDialogOpen(false);
        }}
        removeBonusAmountAction={removeBonusAmountAction}
        userId={playerInfo.personal.id}
        amount={bonusAmount}
        bonusType={bonusType}
        playerNumber={playerInfo.personal.player_number}
      />

      <DisableRolloverDialog
        open={rollDisDialogOpen}
        onClose={() => {
          setRollDisDialogOpen(false);
        }}
        playerNumber={playerInfo.personal.player_number}
        disableRolloverAction={disableRolloverAction}
        id={playerInfo.personal.id}
      />

      <WageringInfoDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setWagerData(null);
        }}
        data={<WageringInfoTable rows={Array.isArray(wagerData) ? wagerData : []} />}
      />

      <ContributionInfoDialog
        open={contTableOpen}
        onClose={() => {
          setContTableOpen(false);
          setContTableData(null);
          setContBonus('');
        }}
        data={<ContributionInfoTable rows={Array.isArray(contTableData) ? contTableData : []} />}
        bonus={contBonus}
      />

      <Paper className={classes.paper} elevation={0}>
        {!isLoading && (
          <>
            {bonusHistory.length == 0 ? (
              'No Bonus History'
            ) : (
              <>
                {bonusHistory.map((rolloverEntry: any) => (
                  <React.Fragment key={rolloverEntry.id}>
                    <Grid item xs={12} sx={{ marginBottom: '1.5rem' }}>
                      <Paper className={classes.paper} elevation={1}>
                        <Grid container spacing={5}>
                          <Grid item xs={6}>
                            <Typography variant='h6' className={classes.headTitle}>
                              {rolloverEntry.active == 1 ? (
                                <Chip color='secondary' label='Active' />
                              ) : (
                                <Chip color='default' label='Ended' />
                              )}{' '}
                              [{rolloverEntry.bonus.code}] {rolloverEntry.bonus.description}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {rolloverEntry.active == 1 && (
                              <RolloverOptionsMenu
                                rolloverEntry={rolloverEntry}
                                setBonusAmount={setBonusAmount}
                                setBonusType={setBonusType}
                                setRollDisDialogOpen={setRollDisDialogOpen}
                                setBonusAmountDialogOpen={setBonusAmountDialogOpen}
                              />
                            )}
                          </Grid>
                        </Grid>

                        <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
                        <Grid container spacing={5}>
                          <Grid item xs={6}>
                            <Grid container spacing={5}>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Rollover ID :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  #{rolloverEntry.id}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Player Number :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  {rolloverEntry.player_number}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Deposit Amount :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.deposit_amount}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Bonus Amount :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.bonus_amount}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Rollover Amount :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.rollover_amount}
                                </Typography>
                              </Grid>
                              {rolloverEntry.previous_rollover != null && (
                                <>
                                  <Grid item xs={6}>
                                    <Typography variant='subtitle2' className={classes.tableTitle}>
                                      Previous Rollover :
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant='subtitle2' className={classes.tableContent}>
                                      ${rolloverEntry.previous_rollover}
                                    </Typography>
                                  </Grid>
                                </>
                              )}

                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Starting Rollover :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.rollover_data ? rolloverEntry.rollover_data.starting_rollover : ''}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={5}>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Free Play Winings :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.rollover_data ? rolloverEntry.rollover_data.free_play_winnings : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Amount Wagered :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.rollover_data ? rolloverEntry.rollover_data.amount_wagered : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Remaining Rollover :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  ${rolloverEntry.rollover_data ? rolloverEntry.rollover_data.remaining_rollover : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableTitle}>
                                  Start Date :
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant='subtitle2' className={classes.tableContent}>
                                  {rolloverEntry.start_date}
                                </Typography>
                              </Grid>
                              {rolloverEntry.active == 0 && (
                                <>
                                  <Grid item xs={6}>
                                    <Typography variant='subtitle2' className={classes.tableTitle}>
                                      End :
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant='subtitle2' className={classes.tableContent}>
                                      {rolloverEntry.end_reason}
                                      <br />
                                      {rolloverEntry.end_date}
                                    </Typography>
                                  </Grid>
                                </>
                              )}

                              <Button
                                onClick={() => {
                                  setWagerData(rolloverEntry.wager_data);
                                  setOpen(true);
                                }}
                                variant='contained'
                                color='primary'
                                sx={{ marginLeft: '5px', marginRight: '5px', marginTop: '.5rem' }}
                              >
                                Wagers
                              </Button>

                              <Button
                                onClick={() => {
                                  setContBonus(rolloverEntry.bonus.code);
                                  setContTableData(rolloverEntry.contribution_table);
                                  setContTableOpen(true);
                                }}
                                variant='contained'
                                color='inherit'
                                sx={{ marginLeft: '5px', marginRight: '5px', marginTop: '.5rem' }}
                              >
                                Contribution Table
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};
