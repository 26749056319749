import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
// import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, Chip, DialogActions, DialogContent } from '@mui/material';
import { IBTCAddressUpdate } from '../../../../models/btc-addresses';
// import ChipInput from 'material-ui-chip-input';
import { IBanks } from '../../../../models/banks';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
// import clsx from 'clsx';

// const useStyles = makeStyles(() => ({
//   // alertError: {
//   //   color: '#ad1305',
//   // },
//   // hidden: {
//   //   display: 'none',
//   // },
// }));
export interface ExternalProps {
  open: boolean;
  selected: IBTCAddressUpdate;
  banks: IBanks[];
  chips: string[];
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertBtcAddresssAction: (btcAddress: IBTCAddressUpdate) => void;
  setSelected: (btcAddress: IBTCAddressUpdate) => void;
  setChips: (x: string[]) => void;
}

export const AddBTCAddressesDialog = (props: ExternalProps & ExternalActionProps) => {
  // const classes = useStyles();
  const { upsertBtcAddresssAction, onClose, open, selected, chips, setChips, setSelected, banks } = props;
  // const [err, setErr] = React.useState(false);
  const [newBtc, setNewBtc] = React.useState('');

  const setNewChip = () => {
    setChips(chips.concat([newBtc]));
    setNewBtc('');
  };

  const handleDelete = (x: string) => {
    const y = chips.filter(z => z != x);
    setChips(y);
  };
  const handleSubmit = () => {
    if (chips.join(',') == '' || selected.bank == '') {
      // setErr(true);
      return;
    } else {
      upsertBtcAddresssAction({ ...selected, addresses: chips.join(',') });
      // setErr(false);
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => {
        onClose();
        // setErr(false);
      }}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='draggable-dialog-title'>{selected.id == null ? 'Add' : 'Update'} Addresses</DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent>
          <DialogContentText>Please fill out the form below to create addresses.</DialogContentText>
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <InputLabel id='bankId'>Bank</InputLabel>
            <Select
              labelId='bankId'
              value={selected.bank}
              onChange={event =>
                setSelected({
                  ...selected,
                  bank: event.target.value as unknown as number,
                })
              }
              inputProps={{
                name: 'bank',
                id: 'bank',
              }}
              label='Bank'
              fullWidth
              required
            >
              {banks.map(item => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <ChipInput
            variant='outlined'
            fullWidth
            label='Bitcoin Address'
            placeholder='You can enter multiple addresses. Please press enter after every address you would like to add.'
            defaultValue={selected.addresses == '' ? [] : selected.addresses.split(',')}
            onChange={chips => {
              setSelected({
                ...selected,
                addresses: chips.join(','),
              });
              // setErr(false);
            }}
          /> */}
          <div style={{ marginBottom: '20px' }}>
            {chips.map(item => (
              <Chip style={{ marginRight: '5px' }} label={item} key={item} onDelete={() => handleDelete(item)} />
            ))}
          </div>
          <TextField
            variant='outlined'
            fullWidth
            label='Bitcoin Address'
            value={newBtc}
            onChange={e => setNewBtc(e.target.value)}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.preventDefault();
                setNewChip();
              }
            }}
          />
          <FormHelperText id='BTC Address Helper Text'>Once you add BTC Address, Please press enter to add</FormHelperText>
          <FormHelperText
            id='ErrorText'
            // className={clsx(classes.alertError, {
            //   [classes.hidden]: !err,
            // })}
          >
            Please enter BTC Address.
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
              // setErr(false);
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            {selected.id == null ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
