import { connect } from 'react-redux';
import { createBonusProfileAction } from '../../../api/bonuses/actions/create-profile.action';
import { getBonusProfilesAction } from '../../../api/bonuses/actions/get-profiles.action';
import { isLoadingSliceSelector, profilesSelector } from '../../../api/bonuses/bonuses.selector';
import { BonusProfilesPage, ExternalActionProps, ExternalProps } from './bonus-profiles-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    profiles: profilesSelector(state),
    isLoading: isLoadingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getBonusProfilesAction,
  createBonusProfileAction,
};

export const BonusProfilesPageContainer = connect(mapStateToProps, mapDispatchToProps)(BonusProfilesPage);
