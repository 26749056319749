import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IBTCAddress, IBTCAddressUpdate } from '../../../models/btc-addresses';
import { BtcAddressAdminSliceType, btcAddressAdminReducer } from '../btc-address.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${btcAddressAdminReducer.sliceName}/app`;

const UPSERT_BTC_ADDRESS = `UPSERT_BTC_ADDRESS${suffix}`;
export const upsertBtcAddressAction = createAction<IBTCAddressUpdate>(UPSERT_BTC_ADDRESS);

export const UPSERT_BTC_ADDRESS_SUCCESS = `UPSERT_BTC_ADDRESS_SUCCESS${suffix}`;
export const upsertBtcAddressSuccessAction = createAction(UPSERT_BTC_ADDRESS_SUCCESS);

const UPSERT_BTC_ADDRESS_ERROR = `UPSERT_BTC_ADDRESS_ERROR${suffix}`;
export const upsertBtcAddressErrorAction = createAction<string>(UPSERT_BTC_ADDRESS_ERROR);

function* upsertBtcAddressWatcher(): SagaIterator {
  yield takeEvery(UPSERT_BTC_ADDRESS, upsertBtcAddressWorker);
}
mergeSaga(upsertBtcAddressWatcher);

function* upsertBtcAddressWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateBtcAddress, action.payload);
    } else {
      result = yield call(createBtcAddress, action.payload);
    }
    yield put(upsertBtcAddressSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertBtcAddressErrorAction(e.response.data.message));
  }
}

const createBtcAddress = (btcAddress: IBTCAddress): Request => {
  const url = '/cashier/btcAddresses';
  return vipApi(url, 'post', btcAddress);
};

const updateBtcAddress = (btcAddress: IBTCAddressUpdate): Request => {
  const url = '/cashier/btcAddresses/' + btcAddress.id;
  return vipApi(url, 'put', btcAddress);
};

const reduceHandlers = {
  [UPSERT_BTC_ADDRESS]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [UPSERT_BTC_ADDRESS_SUCCESS]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPSERT_BTC_ADDRESS_ERROR]: (slice: BtcAddressAdminSliceType): BtcAddressAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

btcAddressAdminReducer.addActionReducerMap(reduceHandlers);
