import { connect } from 'react-redux';
import { loginAction } from '../../api/user/actions/login-action';
import { LoginPage, ExternalProps, ExternalActionProps } from '../login-page/login-page.component';
import { isLoadingSelector, isLoggedInSelector, errorSelector } from '../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    isLoggedIn: isLoggedInSelector(state),
    error: errorSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  loginAction,
};

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
