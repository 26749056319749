import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Divider, Grid, Typography } from '@mui/material';
import { CommunicationsCard } from './components/communications-card';
import { FeeFreeCard } from './components/fee-free-card';
import { MethodFeesCard } from './components/method-fees-card';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export interface ExternalActionProps {
  getAppSettingAction: () => void;
  updateAppSettingAction: (x: any) => void;
}

export interface ExternalProps {
  isLoading: any;
  feeFree: any;
  communications: any;
  methodFees: any;
}

export const AppSettingsPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { isLoading, feeFree, communications, methodFees, getAppSettingAction, updateAppSettingAction } = props;

  React.useEffect(() => {
    getAppSettingAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <Typography variant='h6' gutterBottom>
        App Settings
      </Typography>
      <Divider sx={{ marginBottom: '1rem' }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FeeFreeCard fees={feeFree} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommunicationsCard communications={communications} updateAppSettingAction={updateAppSettingAction} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MethodFeesCard methodFees={methodFees} />
        </Grid>
      </Grid>
    </div>
  );
};
