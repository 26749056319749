import { connect } from 'react-redux';
import { getGuideCategoriesAction } from './../../../api/guide-category/actions/get-guide-categories.action';
import { isLoadingSliceSelector, guideCategoriesSelector } from './../../../api/guide-category/guide-categories.selector';
import { Guide, ExternalActionProps, ExternalProps } from './guide.component';
import { upsertGuideCategoryAction } from './../../../api/guide-category/actions/upsert-guide-categories.action';
import { deleteGuideCategoryAction } from './../../../api/guide-category/actions/delete-guide-categories.action';
import { getGuideAction } from './../../../api/guide/actions/get-guide.action';
import { deleteGuideAction } from './../../../api/guide/actions/delete-guide.action';
import { upsertGuideAction } from './../../../api/guide/actions/upsert-guide.action';
import { loggedInUserSelector } from './../../../api/user/user.selector';
import { guideSelector, isLoadingSliceSelector as guideLoadingSelector } from './../../../api/guide/guide.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state) || guideLoadingSelector(state),
    guideCategories: guideCategoriesSelector(state),
    loggedUser: loggedInUserSelector(state),
    guides: guideSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getGuideCategoriesAction,
  upsertGuideCategoryAction,
  deleteGuideCategoryAction,
  getGuideAction,
  upsertGuideAction,
  deleteGuideAction,
};

export const GuideContainer = connect(mapStateToProps, mapDispatchToProps)(Guide);
