import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ILandingPage, ILandingPageUpload } from '../../../models/landing-page';
import { ImageUploader } from './image-uploader.component';

const useStyles = makeStyles({
  dialogBody: {
    padding: '0px 10px 10px 10px',
  },
  row: {
    display: 'flex',
    padding: 5,
  },
  column50: {
    width: '50%',
    padding: 5,
  },
  imageInput: {
    display: 'none',
  },
});

export interface ExternalProps {
  open: boolean;
  landingPage: ILandingPage;
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertLandingPageAction: (landingPage: ILandingPageUpload) => void;
}

export const LandingPageModal = (props: ExternalProps & ExternalActionProps) => {
  const { landingPage, upsertLandingPageAction, onClose, open } = props;
  const [selected, setSelected] = useState<ILandingPageUpload>({
    ...landingPage,
    headerImg: undefined,
    images: undefined,
    footerImg: undefined,
  });

  useEffect(() => {
    setSelected({ ...landingPage, headerImg: undefined, images: undefined, footerImg: undefined });
  }, [landingPage]);

  const classes = useStyles();

  return (
    <Dialog fullWidth={true} maxWidth='md' onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitle id='simple-dialog-title'>{landingPage.id == null ? 'Add Landing Page' : 'Edit Landing Page'}</DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <div className={classes.column50}>
            <FormControl fullWidth>
              <InputLabel htmlFor='type'>Type</InputLabel>
              <Select
                value={selected.type}
                onChange={event =>
                  setSelected({
                    ...selected,
                    type: event.target.value as string,
                  })
                }
                inputProps={{
                  name: 'Type',
                  id: 'type',
                }}
                fullWidth
              >
                <MenuItem value='raf'>Refer a Friend</MenuItem>
                <MenuItem value='bonus'>Bonus</MenuItem>
                <MenuItem value='signup'>Sign Up</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.column50}>
            <TextField
              label='Campaign'
              onChange={event =>
                setSelected({
                  ...selected,
                  campaign: event.target.value,
                })
              }
              value={selected.campaign}
              placeholder='Campaign'
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column50}>
            <TextField
              label='Name'
              onChange={event =>
                setSelected({
                  ...selected,
                  name: event.target.value,
                })
              }
              value={selected.name}
              placeholder='Name'
              fullWidth
            />
          </div>
          <div className={classes.column50}>
            <TextField
              label='Form Title'
              onChange={event =>
                setSelected({
                  ...selected,
                  formTitle: event.target.value,
                })
              }
              value={selected.formTitle}
              placeholder='Form Title'
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column50}>
            <TextField
              label='Form Subtitle'
              onChange={event =>
                setSelected({
                  ...selected,
                  formSubtitle: event.target.value,
                })
              }
              value={selected.formSubtitle}
              placeholder='Form Subtitle'
              multiline
              fullWidth
            />
          </div>
          <div className={classes.column50}>
            <TextField
              label='Form Footer Text'
              onChange={event =>
                setSelected({
                  ...selected,
                  formFooterText: event.target.value,
                })
              }
              value={selected.formFooterText}
              placeholder='Form Footer Text'
              multiline
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column50}>
            <TextField
              label='Footer Title'
              onChange={event =>
                setSelected({
                  ...selected,
                  footerTitle: event.target.value,
                })
              }
              value={selected.footerTitle}
              placeholder='Footer Title'
              fullWidth
            />
          </div>
          <div className={classes.column50}>
            <TextField
              label='Footer Content'
              onChange={event =>
                setSelected({
                  ...selected,
                  footerContent: event.target.value,
                })
              }
              value={selected.footerContent}
              placeholder='Footer Content'
              multiline
              fullWidth
            />
          </div>
        </div>
        <ImageUploader
          label='Header Image'
          onImageChange={event => {
            if (event && event.target && event.target.files && event.target.files.length > 0)
              setSelected({
                ...selected,
                headerImg: event.target.files ? event.target.files[0] : undefined,
              });
          }}
          imgUrl={landingPage.id ? landingPage.headerImg : undefined}
          imgFile={selected.headerImg}
        />
        <ImageUploader
          label='Content Image'
          onImageChange={event => {
            if (event && event.target && event.target.files && event.target.files.length > 0)
              setSelected({
                ...selected,
                images: event.target.files ? event.target.files[0] : undefined,
              });
          }}
          imgUrl={landingPage.id ? landingPage.images : undefined}
          imgFile={selected.images}
        />
        <ImageUploader
          label='Footer Image'
          onImageChange={event => {
            if (event && event.target && event.target.files && event.target.files.length > 0)
              setSelected({
                ...selected,
                footerImg: event.target.files ? event.target.files[0] : undefined,
              });
          }}
          imgUrl={landingPage.id ? landingPage.footerImg : undefined}
          imgFile={selected.footerImg}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            upsertLandingPageAction(selected);
            onClose();
          }}
          variant='contained'
          color='primary'
        >
          {landingPage.id == null ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
