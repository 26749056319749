import { GenericResponsePost } from '../../../utils/http/http';
import Axios from 'axios';

export class LineRulesService {
  private static instance: LineRulesService;

  public static getInstance(): LineRulesService {
    if (!LineRulesService.instance) {
      LineRulesService.instance = new LineRulesService();
    }
    return LineRulesService.instance;
  }

  private _url = '';
  private _headers: any;
  constructor() {
    Axios.get('app.settings.json')
      .then(response => {
        const appSettings = response?.data;
        this._url = appSettings?.apiUrl;
      })
      .catch();

    this._headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
  }

  async getSchedulesByUsernameAndLeagueIds(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/wager/getSchedulesByUsernameAndLeagueIds',
        headers: this._headers,
      },
      pRequest,
    );
  }

  async getActiveLeaguesByUsername(pRequest: any) {
    return await GenericResponsePost<any[]>(
      {
        path: this._url + '/wager/getActiveLeaguesByUsername',
        headers: this._headers,
      },
      pRequest,
    );
  }
  async getLeaguesRules(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/line-rules/getLeaguesRules',
        headers: this._headers,
      },
      pRequest,
    );
  }

  async getGamesRules(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/line-rules/getGamesRules',
        headers: this._headers,
      },
      pRequest,
    );
  }

  async addUpdate(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/line-rules/addUpdate',
        headers: this._headers,
      },
      pRequest,
      { erros: true, success: true },
    );
  }

  async addUpdateLeagueRule(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/line-rules/addUpdateLeagueRule',
        headers: this._headers,
      },
      pRequest,
      { erros: true, success: true },
    );
  }

  async addUpdateGameRule(pRequest: any) {
    return await GenericResponsePost<any>(
      {
        path: this._url + '/line-rules/addUpdateGameRule',
        headers: this._headers,
      },
      pRequest,
      { erros: true, success: true },
    );
  }
}

export const LineRulesServiceClient = LineRulesService.getInstance();
