import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';
import { TextField } from '@mui/material';

const useStyles = makeStyles(() =>
  createStyles({
    inputField: {
      marginTop: '1rem',
      marginBottom: '1rem',
      display: 'block',
      width: 'calc(100% - 5px)',
    },
  }),
);

export const ChangePasswordDialog = (props: any) => {
  const classes = useStyles();
  const { open, onClose, changePasswordAction, id } = props;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Alert severity='warning'>Please enter a new password to change password.</Alert>

          <TextField
            id='password'
            label='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
          <TextField
            id='confirm-password'
            label='Confirm Password'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            className={classes.inputField}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              changePasswordAction({
                id: id,
                password: password,
                confirm_password: confirmPassword,
              });
              onClose();
            }}
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
