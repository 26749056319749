import React from 'react';
import clsx from 'clsx';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles: any = makeStyles({
  root: {
    padding: 0,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  stats: any;
}

export const AllReferralsTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { stats } = props;

  //   {
  //     "id": 1883,
  //     "created_at": "2021-07-08T19:39:40.000000Z",
  //     "username": "VIP18",
  //     "first_name": "Arnulfo",
  //     "last_name": "Quintana",
  //     "phone": "+1(760) 413-3016",
  //     "email": "fitoq760@gmail.com",
  //     "inviter_code": "RAF780",
  //     "invite_redeemed": null,
  //     "invite_approved": null,
  //     "signup_ip": null,
  //     "signup_fingerprint": null
  // },

  //   const [page, setPage] = React.useState(0);
  //   const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //   const emptyRows = rowsPerPage - Math.min(rowsPerPage, stats.length - page * rowsPerPage);

  //   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  return (
    <div className={clsx(classes.root)}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sign Up Date:</TableCell>
            <TableCell>Player Info</TableCell>
            <TableCell>Inviter</TableCell>
            <TableCell>Redeemed?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats &&
            stats.map((row: any) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.created_at}</TableCell>
                  <TableCell>
                    {row.username}
                    <br />
                    {row.first_name + ' ' + row.last_name}
                    <br />
                    {row.phone}
                    <br />
                    {row.email}
                    <br />
                    {row.signup_up}
                    <br />
                    {row.fingerprint}
                  </TableCell>
                  <TableCell>{row.inviter_code}</TableCell>
                  <TableCell>{row.invite_redeemed != null ? row.invite_redeemed : 'Not Yet'}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
        {/* <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={stats.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter> */}
      </Table>
    </div>
  );
};
