import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GuideCategoriesSliceType, guideCategoriesReducer } from '../guide-categories.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${guideCategoriesReducer.sliceName}/app`;

const DELETE_GUIDE_CATEGORY = `DELETE_GUIDE_CATEGORY${suffix}`;
export const deleteGuideCategoryAction = createAction<number>(DELETE_GUIDE_CATEGORY);

export const DELETE_GUIDE_CATEGORY_SUCCESS = `DELETE_GUIDE_CATEGORY_SUCCESS${suffix}`;
export const deleteGuideCategorySuccessAction = createAction<number>(DELETE_GUIDE_CATEGORY_SUCCESS);

const DELETE_GUIDE_CATEGORY_ERROR = `DELETE_GUIDE_CATEGORY_ERROR${suffix}`;
export const deleteGuideCategoryErrorAction = createAction<string>(DELETE_GUIDE_CATEGORY_ERROR);

function* deleteGuideCategoryWatcher(): SagaIterator {
  yield takeEvery(DELETE_GUIDE_CATEGORY, deleteGuideCategoryWorker);
}
mergeSaga(deleteGuideCategoryWatcher);

function* deleteGuideCategoryWorker(action: any): SagaIterator {
  try {
    yield call(deleteGuideCategory, action.payload);
    yield put(deleteGuideCategorySuccessAction(action.payload));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteGuideCategoryErrorAction(e.response.data.message));
  }
}

const deleteGuideCategory = (id: number): Request => {
  const url = '/guides/DeleteGuideCategory/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_GUIDE_CATEGORY]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [DELETE_GUIDE_CATEGORY_SUCCESS]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_GUIDE_CATEGORY_ERROR]: (slice: GuideCategoriesSliceType): GuideCategoriesSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

guideCategoriesReducer.addActionReducerMap(reduceHandlers);
