import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { transactionAdminReducer, TransactionAdminSliceType } from '../transaction.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { Transactions, ITransactionFilterData } from '../../../models/transactions';

const suffix = `/${transactionAdminReducer.sliceName}/app`;

const GET_TRANSACTIONS = `GET_TRANSACTIONS${suffix}`;

export const getTransactionsAction = createAction<ITransactionFilterData>(GET_TRANSACTIONS);

const GET_TRANSACTIONS_SUCCESS = `GET_TRANSACTIONS_SUCCESS${suffix}`;
const getTransactionsSuccessAction = createAction<Transactions[]>(GET_TRANSACTIONS_SUCCESS);

const GET_TRANSACTIONS_ERROR = `GET_TRANSACTIONS_ERROR${suffix}`;
const getTransactionsErrorAction = createAction<string>(GET_TRANSACTIONS_ERROR);

function* getTransactionsWatcher(): SagaIterator {
  yield takeEvery(GET_TRANSACTIONS, getTransactionsWorker);
}

mergeSaga(getTransactionsWatcher);

function* getTransactionsWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<Transactions> = yield call(getTransactionsApi, action.payload);
    yield put(getTransactionsSuccessAction(result.data));
  } catch (e: any) {
    yield put(getTransactionsErrorAction(e));
  }
}

const getTransactionsApi = (filterData: ITransactionFilterData): Request => {
  const { type, count, status, page, search } = filterData;
  let searchQuery = `type=${type}&count=${count}&status=${status}&page=${page + 1}`;
  if (search != undefined && search != '' && search != null) {
    searchQuery += `&search=${search}`;
  }
  return vipApi(`/cashier/transactions?` + searchQuery, 'get', {});
};

const reduceHandlers = {
  [GET_TRANSACTIONS]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TRANSACTIONS_SUCCESS]: (slice: TransactionAdminSliceType, action: any): TransactionAdminSliceType => ({
    ...slice,
    isLoading: false,
    transactions: action.payload.data,
    totalTransactions: action.payload.total,
  }),
  [GET_TRANSACTIONS_ERROR]: (slice: TransactionAdminSliceType): TransactionAdminSliceType => ({
    ...slice,
    isLoading: false,
    transactions: [],
  }),
};

transactionAdminReducer.addActionReducerMap(reduceHandlers);
