export interface IAgents {
  id: number;
  first_name: string;
  last_name: string;
  created_at: string;
  commission_structure: string;
  commission_desc: string;
  referral_link: string;
  number_of_players: number;
  email: string;
  phone: string;
  brand: string;
  identifier: string;
  player_username: string;
  agent_commission_profile: string;
  master_agent_id: string;
}

export interface IAgentsUpdate {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  brand: string;
  identifier: string;
  agent_commission_profile: string;
  master_agent_id: string;
}

export const emptyAgent: IAgentsUpdate = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  brand: '',
  identifier: '',
  agent_commission_profile: '',
  master_agent_id: '',
};
