import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo, emptyPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const SEARCH_PLAYER = `SEARCH_PLAYER${suffix}`;

export const searchPlayerAction = createAction<string>(SEARCH_PLAYER);

const SEARCH_PLAYER_SUCCESS = `SEARCH_PLAYER_SUCCESS${suffix}`;
const searchPlayerSuccessAction = createAction<IPlayerInfo[]>(SEARCH_PLAYER_SUCCESS);

const SEARCH_PLAYER_ERROR = `SEARCH_PLAYER_ERROR${suffix}`;
const searchPlayerErrorAction = createAction<string>(SEARCH_PLAYER_ERROR);

function* searchPlayerWatcher(): SagaIterator {
  yield takeEvery(SEARCH_PLAYER, searchPlayerWorker);
}
mergeSaga(searchPlayerWatcher);

function* searchPlayerWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(searchPlayerApi, action.payload);
    yield put(searchPlayerSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(searchPlayerErrorAction(e.response.data.message));
  }
}

const searchPlayerApi = (string: string): Request => {
  return vipApi(`/cashier/searchPlayer`, 'post', { search: string });
};

const reduceHandlers = {
  [SEARCH_PLAYER]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [SEARCH_PLAYER_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    playerSearched: action.payload,
  }),
  [SEARCH_PLAYER_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    playerInfo: emptyPlayerInfo,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
