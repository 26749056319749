import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IPaymentMethodsUpload } from '../../../models/payment-methods';
import { PaymentMethodAdminSliceType, paymentMethodAdminReducer } from '../payment-method.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${paymentMethodAdminReducer.sliceName}/app`;

const DELETE_PAYMENT_METHOD = `DELETE_PAYMENT_METHOD${suffix}`;
export const deletePaymentMethodAction = createAction<IPaymentMethodsUpload>(DELETE_PAYMENT_METHOD);

export const DELETE_PAYMENT_METHOD_SUCCESS = `DELETE_PAYMENT_METHOD_SUCCESS${suffix}`;
export const deletePaymentMethodSuccessAction = createAction<number>(DELETE_PAYMENT_METHOD_SUCCESS);

const DELETE_PAYMENT_METHOD_ERROR = `DELETE_PAYMENT_METHOD_ERROR${suffix}`;
export const deletePaymentMethodErrorAction = createAction<string>(DELETE_PAYMENT_METHOD_ERROR);

function* deletePaymentMethodWatcher(): SagaIterator {
  yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethodWorker);
}
mergeSaga(deletePaymentMethodWatcher);

function* deletePaymentMethodWorker(action: any): SagaIterator {
  try {
    yield call(deletePaymentMethod, action.payload.id);
    yield put(deletePaymentMethodSuccessAction(action.payload.id));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deletePaymentMethodErrorAction(e.response.data.message));
  }
}

const deletePaymentMethod = (id: number): Request => {
  const url = '/cashier/paymentMethods/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_PAYMENT_METHOD]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isSaving: true,
  }),
  [DELETE_PAYMENT_METHOD_SUCCESS]: (slice: PaymentMethodAdminSliceType, action: any): PaymentMethodAdminSliceType => {
    return {
      ...slice,
      isSaving: false,
      paymentMethods: slice.paymentMethods.filter(item => item.id != action.payload.data),
    };
  },
  [DELETE_PAYMENT_METHOD_ERROR]: (slice: PaymentMethodAdminSliceType): PaymentMethodAdminSliceType => ({
    ...slice,
    isSaving: false,
  }),
};

paymentMethodAdminReducer.addActionReducerMap(reduceHandlers);
