import { connect } from 'react-redux';
import { PaymentMethodsPage, ExternalProps, ExternalActionProps } from './payment-methods-page.component';
import { getPaymentMethodsAction } from '../../../api/payment-method/actions/get-payment-methods.action';
import { upsertPaymentMethodAction } from '../../../api/payment-method/actions/upsert-payment-method.action';
import { deletePaymentMethodAction } from '../../../api/payment-method/actions/delete-payment-method.action';
import { paymentMethodsSelector, isLoadingSliceSelector, isSavingSliceSelector } from '../../../api/payment-method/payment-method.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    paymentMethods: paymentMethodsSelector(state),
    isLoading: isLoadingSliceSelector(state),
    isSaving: isSavingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPaymentMethodsAction,
  upsertPaymentMethodAction,
  deletePaymentMethodAction,
};

export const PaymentMethodsContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage);
