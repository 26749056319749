export interface IBTCAddress {
  id: number;
  bank: number;
  bank_name?: string;
  address: string;
  request_id: string;
  requested_by: string;
  redeemed: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

export interface IBTCAddressUpdate {
  id?: number;
  bank: any;
  addresses: string;
  created_by: string;
}

export const emptyBTCAddress: IBTCAddressUpdate = {
  bank: '',
  addresses: '',
  created_by: '',
};
