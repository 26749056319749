import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@mui/icons-material/Send';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
const useStyles = makeStyles({
  root: {},
  textCenter: {
    textAlign: 'center',
  },
});

interface ExternalProps {
  formRule: any;
  setFormRule: (x: any) => void;
  addUpdateRule: (x: any) => Promise<void>;
  setView: (x: number) => void;
}

export const LineRuleForm = (props: ExternalProps) => {
  const classes = useStyles();
  const { formRule, setFormRule, addUpdateRule, setView } = props;

  useEffect(() => {
    console.log('');
  }, []);

  return (
    <Paper>
      <h2>Create new Rule</h2>

      <Grid container direction='row' style={{ marginTop: '15px' }}>
        <Grid item xs={3}>
          <TextField
            required
            id='outlined-required'
            label='Description'
            value={formRule.description}
            onChange={(event: any) => {
              formRule.description = event.target.value;
              setFormRule({ ...formRule, formRule });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id='outlined-number'
            label='Total'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={formRule.total}
            onChange={(event: any) => {
              formRule.total = Number(event.target.value);
              setFormRule({ ...formRule, formRule });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id='outlined-number'
            label='Spread'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={formRule.spread}
            onChange={(event: any) => {
              formRule.spread = Number(event.target.value);
              setFormRule({ ...formRule, formRule });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id='outlined-number'
            label='Money Line'
            type='number'
            inputProps={{ inputMode: 'numeric' }}
            value={formRule.money_line}
            onChange={(event: any) => {
              formRule.money_line = Number(event.target.value);
              setFormRule({ ...formRule, formRule });
            }}
          />
        </Grid>

        <Grid item xs={12} className={classes.textCenter} style={{ marginTop: '25px' }}>
          <Button
            variant='contained'
            color='success'
            style={{ margin: '4px' }}
            endIcon={<SendIcon />}
            onClick={() => addUpdateRule(formRule)}
          >
            Save
          </Button>
          <Button variant='contained' color='error' style={{ margin: '4px' }} endIcon={<DoDisturbIcon />} onClick={() => setView(1)}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
