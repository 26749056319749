import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IChartAccountUpload } from '../../../../models/chart-account';
import { NativeSelect } from '@mui/material';

export interface ExternalProps {
  open: boolean;
  chartAccount: IChartAccountUpload;
  selected: IChartAccountUpload;
}

export interface ExternalActionProps {
  onClose: () => void;
  upsertChartAccountAction: (chartAcoount: IChartAccountUpload) => void;
  setSelected: (chartAccount: IChartAccountUpload) => void;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const CreateAccountDialog = (props: ExternalProps & ExternalActionProps) => {
  const { upsertChartAccountAction, onClose, open, selected, setSelected } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperComponent={PaperComponent} aria-labelledby='draggable-dialog-title' maxWidth='xs'>
        <DialogTitle id='draggable-dialog-title'>{selected.action == 'create' ? 'Add Account' : 'Edit Account'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select an account type, enter a specific ID and type in a name.</DialogContentText>

          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor='account_type'>Type</InputLabel>
            <Select
              labelId='account_type'
              value={selected.account_type}
              onChange={event =>
                setSelected({
                  ...selected,
                  account_type: event.target.value as string,
                })
              }
              inputProps={{
                name: 'account_type',
                id: 'account_type',
              }}
            >
              <MenuItem value={1}>Income</MenuItem>
              <MenuItem value={2}>Contra</MenuItem>
              <MenuItem value={3}>Expense</MenuItem>
              <MenuItem value={4}>Current Assets</MenuItem>
              <MenuItem value={5}>Long Term Asset</MenuItem>
              <MenuItem value={6}>Current Liabilities</MenuItem>
              <MenuItem value={7}>Long Term Liabilities</MenuItem>
              <MenuItem value={8}>Equity</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor='sub_code'>Sub Code</InputLabel>
            <NativeSelect
              // labelId='sub_code'
              value={selected.sub_code}
              onChange={event =>
                setSelected({
                  ...selected,
                  sub_code: event.target.value as string,
                })
              }
              inputProps={{
                name: 'sub_code',
                id: 'sub_code',
              }}
            >
              <option value=''></option>
              <optgroup label='Current assets'>
                <option value={10}>Cash and cash equivalents</option>
                <option value={50}>Other current assets</option>
              </optgroup>
              <optgroup label='Long term assets'>
                <option value={70}>Property, plant and equipment</option>
                <option value={80}>Goodwill</option>
                <option value={82}>Intellectual property</option>
              </optgroup>
              <optgroup label='Current liabilities'>
                <option value={110}>Other current liabilities</option>
                <option value={100}>Accounts payable</option>
              </optgroup>
              <optgroup label='Long term debt'>
                <option value={122}>Long term loans</option>
              </optgroup>
              <optgroup label='Equity'>
                <option value={130}>Capital</option>
                <option value={140}>Retained earnings</option>
              </optgroup>
              <optgroup label='Income'>
                <option value={200}>Revenue</option>
              </optgroup>
              <optgroup label='Contra'>
                <option value={220}>Contra Accounts</option>
              </optgroup>
              <optgroup label='Expense'>
                <option value={240}>Sales and marketing</option>
                <option value={250}>General and administrative</option>
                <option value={310}>Depreciation</option>
                <option value={330}>Other income and gains</option>
                <option value={320}>Other expenses and losses</option>
              </optgroup>
            </NativeSelect>
          </FormControl>

          <TextField
            autoFocus
            margin='dense'
            id='id'
            label='ID #'
            value={selected.id}
            onChange={event => setSelected({ ...selected, id: event.target.value })}
            type='text'
            fullWidth
          />

          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Account Name'
            value={selected.account_name}
            onChange={event => setSelected({ ...selected, account_name: event.target.value })}
            type='text'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              upsertChartAccountAction(selected);
              onClose();
            }}
            variant='contained'
            color='primary'
          >
            {selected.action == 'create' ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
