import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_WAGER_HISTORY = `GET_PLAYER_WAGER_HISTORY${suffix}`;

export const getPlayerWagerHistoryAction = createAction<any>(GET_PLAYER_WAGER_HISTORY);

const GET_PLAYER_WAGER_HISTORY_SUCCESS = `GET_PLAYER_WAGER_HISTORY_SUCCESS${suffix}`;
const getPlayerWagerHistorySuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_WAGER_HISTORY_SUCCESS);

const GET_PLAYER_WAGER_HISTORY_ERROR = `GET_PLAYER_WAGER_HISTORY_ERROR${suffix}`;
const getPlayerWagerHistoryErrorAction = createAction<string>(GET_PLAYER_WAGER_HISTORY_ERROR);

function* getPlayerWagerHistoryWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_WAGER_HISTORY, getPlayerWagerHistoryWorker);
}
mergeSaga(getPlayerWagerHistoryWatcher);

function* getPlayerWagerHistoryWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerWagerHistoryApi, action.payload);
    yield put(getPlayerWagerHistorySuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerWagerHistoryErrorAction(e.response.data.message));
  }
}

const getPlayerWagerHistoryApi = (x: any): Request => {
  return vipApi(`/cashier/getPlayerWagerHistory`, 'post', x);
};

const reduceHandlers = {
  [GET_PLAYER_WAGER_HISTORY]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_WAGER_HISTORY_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    return {
      ...slice,
      isLoading: false,
      wagerHistory: action.payload,
    };
  },
  [GET_PLAYER_WAGER_HISTORY_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
