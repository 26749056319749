import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GuideSliceType, guideReducer } from '../guide.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${guideReducer.sliceName}/app`;

const UPSERT_GUIDE = `UPSERT_GUIDE${suffix}`;
export const upsertGuideAction = createAction<any>(UPSERT_GUIDE);

export const UPSERT_GUIDE_SUCCESS = `UPSERT_GUIDE_SUCCESS${suffix}`;
export const upsertGuideSuccessAction = createAction<any>(UPSERT_GUIDE_SUCCESS);

const UPSERT_GUIDE_ERROR = `UPSERT_GUIDE_ERROR${suffix}`;
export const upsertGuideErrorAction = createAction<string>(UPSERT_GUIDE_ERROR);

function* upsertGuideWatcher(): SagaIterator {
  yield takeEvery(UPSERT_GUIDE, upsertGuideWorker);
}
mergeSaga(upsertGuideWatcher);

function* upsertGuideWorker(action: any): SagaIterator {
  try {
    let result;
    if (action.payload.id) {
      result = yield call(updateGuide, action.payload);
    } else {
      result = yield call(createGuide, action.payload);
    }
    yield put(upsertGuideSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(upsertGuideErrorAction(e.response.data.message));
  }
}

const createGuide = (guide: any): Request => {
  const url = '/guides/AddGuide';
  return vipApi(url, 'post', guide);
};

const updateGuide = (guide: any): Request => {
  const url = '/guides/UpdateGuide/' + guide.id;
  return vipApi(url, 'post', guide);
};

const reduceHandlers = {
  [UPSERT_GUIDE]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [UPSERT_GUIDE_SUCCESS]: (slice: GuideSliceType): GuideSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [UPSERT_GUIDE_ERROR]: (slice: GuideSliceType): GuideSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

guideReducer.addActionReducerMap(reduceHandlers);
