import { connect } from 'react-redux';
import { BanksPage, ExternalProps, ExternalActionProps } from './banks-page.component';
import { getBanksAction } from '../../../api/bank/actions/get-banks.action';
import { upsertBankAction } from '../../../api/bank/actions/upsert-bank.action';
import { deleteBankAction } from '../../../api/bank/actions/delete-bank.action';
import { banksSelector, isLoadingSliceSelector, isSavingSliceSelector } from '../../../api/bank/bank.selector';
import { getChartAccountsAction } from '../../../api/chart-accounts/actions/get-chart-accounts.action';
import { chartAccountsSelector } from '../../../api/chart-accounts/chart-account.selector';
import { getPaymentMethodsAction } from '../../../api/payment-method/actions/get-payment-methods.action';
import { paymentMethodsSelector } from '../../../api/payment-method/payment-method.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    banks: banksSelector(state),
    chartAccounts: chartAccountsSelector(state),
    isLoading: isLoadingSliceSelector(state),
    isSaving: isSavingSliceSelector(state),
    paymentMethods: paymentMethodsSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getBanksAction,
  upsertBankAction,
  deleteBankAction,
  getChartAccountsAction,
  getPaymentMethodsAction,
};

export const BanksPageContainer = connect(mapStateToProps, mapDispatchToProps)(BanksPage);
