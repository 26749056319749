import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFoundPage } from './area/not-found/not-found-page.component';
import { LandingPageAdminContainer } from './area/landing-page-admin/landing-page-admin.container';
import { LoginPageContainer } from './area/login-page/login-page.container';
import { AdminPrivateRouteContainer } from './shared/private-route/admin-private-route.container';
import { ForgetPasswordPageContainer } from './area/forget-password-page/forgot-password-page.container';
import { ReportsPageContainer } from './area/cashier/reporting/reports-page.container';
import { TransactionsPageContainer } from './area/cashier/transactions/transactions-page.container';
import { ChartOfAccountsPageContainer } from './area/cashier/chart-of-accounts/chart-of-accounts-page.container';
import { PaymentMethodsContainer } from './area/cashier/payment-methods/payment-methods-page.container';
import { BanksPageContainer } from './area/cashier/banks/banks-page.container';
import { BTCAddressesPageContainer } from './area/cashier/btc-addresses/btc-addresses-page.container';
import { PlayerInfoPageContainer } from './area/cashier/player-info/player-info-page.container';
import { BonusProfilesPageContainer } from './area/bonus/profiles/bonus-profiles-page.container';
import { BonusesPageContainer } from './area/bonus/bonuses/bonuses-page.container';
import { ReferralsPageContainer } from './area/cashier/referrals/referrals-page.container';
import { DashboardPageContainer } from './shared/dashboard/dashboard-page.container';
import { AppSettingsPageContainer } from './area/app-settings/app-settings-page.container';
import { AgentPageContainer } from './area/agent/list-agents-page/agent-page.container';
import { TourCreatorPageContainer } from './area/tour-creator/tour-page.container';
import { GuideContainer } from './area/admin/guide/guide.container';
import { RuleContainer } from './area/admin/rule/rule.container';
import { LineRulePageContainer } from './area/line-rules/line-rules-page.container';
import { AgentCommStructurePageContainer } from './area/agent/agent-comm-structure-page/agent-comm-structure-page.container';
import { AgentPayoutsPageContainer } from './area/agent/agent-payouts-page/agent-payouts-page.container';
export const Routes = (): React.ReactElement<void> => (
  <Switch>
    <Route exact path='/login' component={LoginPageContainer} />
    <Route exact path='/forget-password' component={ForgetPasswordPageContainer} />

    <AdminPrivateRouteContainer path='/admin/landing-page' component={LandingPageAdminContainer} />
    <AdminPrivateRouteContainer path='/cashier/transactions' component={TransactionsPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/payment-methods' component={PaymentMethodsContainer} />
    <AdminPrivateRouteContainer path='/cashier/banks' component={BanksPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/btc-addresses' component={BTCAddressesPageContainer} />

    {/* Player Tools Routes */}
    <AdminPrivateRouteContainer path='/cashier/player-info' component={PlayerInfoPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/referrals' component={ReferralsPageContainer} />
    <AdminPrivateRouteContainer path='/tour-creator' component={TourCreatorPageContainer} />
    {/* Guide */}
    <AdminPrivateRouteContainer path='/guide' component={GuideContainer} />

    {/* Agent */}
    <AdminPrivateRouteContainer path='/agent-management' component={AgentPageContainer} />
    <AdminPrivateRouteContainer path='/agent-comm-structures' component={AgentCommStructurePageContainer} />
    <AdminPrivateRouteContainer path='/agent-payouts' component={AgentPayoutsPageContainer} />

    {/* Line Rules */}
    <AdminPrivateRouteContainer path='/line-rules' component={LineRulePageContainer} />

    {/* Bonus Routes */}
    <AdminPrivateRouteContainer path='/bonus-profiles' component={BonusProfilesPageContainer} />
    <AdminPrivateRouteContainer path='/bonuses' component={BonusesPageContainer} />

    {/* Accounting */}
    <AdminPrivateRouteContainer path='/accounting/reports' component={ReportsPageContainer} />
    <AdminPrivateRouteContainer path='/accounting/chart-of-accounts' component={ChartOfAccountsPageContainer} />
    {/* Guide */}
    <AdminPrivateRouteContainer path='/guide' component={GuideContainer} />
    <AdminPrivateRouteContainer path='/rule' component={RuleContainer} />
    {/* Common Routes */}
    <AdminPrivateRouteContainer path='/app-settings' component={AppSettingsPageContainer} />
    <AdminPrivateRouteContainer path='/' component={DashboardPageContainer} />
    <Route path='' component={NotFoundPage} />
  </Switch>
);
