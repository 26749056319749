import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { RuleCategoriesSliceType, ruleCategoriesReducer } from '../rule-categories.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${ruleCategoriesReducer.sliceName}/app`;

const DELETE_RULE_CATEGORY = `DELETE_RULE_CATEGORY${suffix}`;
export const deleteRuleCategoryAction = createAction<number>(DELETE_RULE_CATEGORY);

export const DELETE_RULE_CATEGORY_SUCCESS = `DELETE_RULE_CATEGORY_SUCCESS${suffix}`;
export const deleteRuleCategorySuccessAction = createAction<number>(DELETE_RULE_CATEGORY_SUCCESS);

const DELETE_RULE_CATEGORY_ERROR = `DELETE_RULE_CATEGORY_ERROR${suffix}`;
export const deleteRuleCategoryErrorAction = createAction<string>(DELETE_RULE_CATEGORY_ERROR);

function* deleteRuleCategoryWatcher(): SagaIterator {
  yield takeEvery(DELETE_RULE_CATEGORY, deleteRuleCategoryWorker);
}
mergeSaga(deleteRuleCategoryWatcher);

function* deleteRuleCategoryWorker(action: any): SagaIterator {
  try {
    yield call(deleteRuleCategory, action.payload);
    yield put(deleteRuleCategorySuccessAction(action.payload));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(deleteRuleCategoryErrorAction(e.response.data.message));
  }
}

const deleteRuleCategory = (id: number): Request => {
  const url = '/rules/DeleteRuleCategory/' + id;
  return vipApi(url, 'delete', {});
};

const reduceHandlers = {
  [DELETE_RULE_CATEGORY]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isSaving: true,
    isLoading: true,
  }),
  [DELETE_RULE_CATEGORY_SUCCESS]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => {
    return {
      ...slice,
      isSaving: false,
    };
  },
  [DELETE_RULE_CATEGORY_ERROR]: (slice: RuleCategoriesSliceType): RuleCategoriesSliceType => ({
    ...slice,
    isSaving: false,
    isLoading: false,
  }),
};

ruleCategoriesReducer.addActionReducerMap(reduceHandlers);
