import { createSelector } from 'reselect';
import { tourCreatorAdminReducer, TourCreatorAdminSliceType } from './tourCreator.reducer';

export const tourCreatorAdminSliceSelector = (state: any): any => state[tourCreatorAdminReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  tourCreatorAdminSliceSelector,
  (tourCreatorAdminSlice: TourCreatorAdminSliceType) => tourCreatorAdminSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  tourCreatorAdminSliceSelector,
  (tourCreatorAdminSlice: TourCreatorAdminSliceType) => tourCreatorAdminSlice.isSaving,
);

export const tourCreatorSelector = createSelector(
  tourCreatorAdminSliceSelector,
  (tourCreatorAdminSlice: TourCreatorAdminSliceType) => tourCreatorAdminSlice.stepsList,
);
