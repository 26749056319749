import { ReportsPage, ExternalProps, ExternalActionProps } from './reporting-page.component';
import { connect } from 'react-redux';
import { getReportingOverviewAction } from '../../../api/accounting/actions/get-reporting-overview.action';
import {
  isAccountingLoadingSelector,
  reportDepositsSelector,
  reportOverviewSelector,
  reportTotalsSelector,
  reportWithdrawalsSelector,
} from '../../../api/accounting/accounting.selector';
import { getDetailedReportAction } from '../../../api/accounting/actions/get-detailed-report.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isAccountingLoadingSelector(state),
    overview: reportOverviewSelector(state),
    deposits: reportDepositsSelector(state),
    withdrawals: reportWithdrawalsSelector(state),
    totals: reportTotalsSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getReportingOverviewAction,
  getDetailedReportAction,
};

export const ReportsPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
