import { IDistributors } from '../../models/distributor';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'distributorAdminSlice';

export interface DistributorAdminSliceType {
  isLoading: boolean;
  isSaving: boolean;
  distributors: IDistributors[];
}

export const initialState: DistributorAdminSliceType = {
  isLoading: false,
  isSaving: false,
  distributors: [],
};

export const distributorAdminReducer = createAndMergeSliceReducer(sliceName, initialState);
