import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { IPaymentMethods, emptyPaymentMethod, IPaymentMethodsUpload } from '../../../../models/payment-methods';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      padding: theme.spacing(2),
    },
    methodImg: {
      maxWidth: '40px',
      height: 'auto',
      paddingRight: '10px',
    },
    tbcell2: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const getMethodType = (type: number) => {
  if (type === 1) {
    return 'Deposit Only';
  }
  if (type === 2) {
    return 'Withdrawal Only';
  }
  return 'Deposits & Withdrawals';
};

export interface ExternalProps {
  paymentMethods: IPaymentMethods[];
  selected: IPaymentMethodsUpload;
  setSelected: (yup: IPaymentMethodsUpload) => void;
  setEditOrAddModal: (yup: boolean) => void;
  deletePaymentMethodsAction: (paymentMethod: IPaymentMethodsUpload) => void;
  upsertPaymentMethodAction: (paymentMethod: IPaymentMethodsUpload) => void;
}

export const PaymentMethodsTable = (props: ExternalProps) => {
  const { paymentMethods, selected, setSelected, setEditOrAddModal, deletePaymentMethodsAction, upsertPaymentMethodAction } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          Payment Methods
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Min Deposite</TableCell>
                <TableCell>Max Deposite</TableCell>
                <TableCell>Min Withdrawal</TableCell>
                <TableCell>Max Withdrawal</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <div className={classes.tbcell2}>
                        <img src={row.image} className={classes.methodImg} />
                        <Typography variant='h6'>{row.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>${row.deposit_min.toLocaleString('en')}</TableCell>
                    <TableCell>${row.deposit_max.toLocaleString('en')}</TableCell>
                    <TableCell>${row.withdrawal_min.toLocaleString('en')}</TableCell>
                    <TableCell>${row.withdrawal_max.toLocaleString('en')}</TableCell>
                    <TableCell>{getMethodType(row.method_type)}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row.status == 1 ? true : false}
                        onClick={() =>
                          upsertPaymentMethodAction({
                            id: row.id,
                            name: row.name,
                            deposit_min: row.deposit_min,
                            deposit_max: row.deposit_max,
                            withdrawal_min: row.withdrawal_min,
                            withdrawal_max: row.withdrawal_max,
                            method_type: row.method_type,
                            status: row.status == 1 ? 0 : 1,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <div className={classes.actions}>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected({
                              id: row.id,
                              name: row.name,
                              deposit_min: row.deposit_min,
                              deposit_max: row.deposit_max,
                              withdrawal_min: row.withdrawal_min,
                              withdrawal_max: row.withdrawal_max,
                              method_type: row.method_type,
                              status: row.status,
                            });
                            setEditOrAddModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => {
                            setSelected(row);
                            setIsDeleting(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={paymentMethods.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        maxWidth='md'
        onClose={() => {
          setIsDeleting(false);
          setSelected(emptyPaymentMethod);
        }}
        open={isDeleting}
      >
        <DialogTitle>Are you sure you want to delete {selected.name}?</DialogTitle>
        <DialogContent>
          {'Deleting cannot be undone. If you want to disactive '}
          <br />
          {'the page, please use the toggle to deactive the page.'}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleting(false);
              setSelected(emptyPaymentMethod);
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deletePaymentMethodsAction(selected);
              setSelected(emptyPaymentMethod);
              setIsDeleting(false);
            }}
            variant='contained'
            color='secondary'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
