import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${userReducer.sliceName}/app`;

const GET_CUSTOMER_DASHBOARD = `GET_CUSTOMER_DASHBOARD${suffix}`;
export const getCustomerDashboardAction = createAction<any>(GET_CUSTOMER_DASHBOARD);

const GET_CUSTOMER_DASHBOARD_ERROR = `GET_CUSTOMER_DASHBOARD_ERROR${suffix}`;
const getCustomerDashboardErrorAction = createAction<any>(GET_CUSTOMER_DASHBOARD_ERROR);
const GET_CUSTOMER_DASHBOARD_SUCCESS = `GET_CUSTOMER_DASHBOARD_SUCCESS${suffix}`;
const getCustomerDashboardSuccessAction = createAction<any>(GET_CUSTOMER_DASHBOARD_SUCCESS);

function* getCustomerDashboardWatcher(): SagaIterator {
  yield takeEvery(GET_CUSTOMER_DASHBOARD, getCustomerDashboardWorker);
}
mergeSaga(getCustomerDashboardWatcher);

function* getCustomerDashboardWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);

    yield put(getCustomerDashboardSuccessAction(result));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getCustomerDashboardErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/cashier/GetCustomerDashboard`, 'post', x);
};

const reduceHandlers = {
  [GET_CUSTOMER_DASHBOARD]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_CUSTOMER_DASHBOARD_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      dashboardSignUps: action.payload.data.signup,
      dashboardDate: action.payload.data.dates,
      dashboardPackages: action.payload.data.packages,
    };
  },
  [GET_CUSTOMER_DASHBOARD_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
