import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Alert, Typography, TextField, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles(() =>
  createStyles({
    inputField: {
      marginTop: '1rem',
      marginBottom: '1rem',
      display: 'block',
      width: 'calc(100% - 5px)',
    },
  }),
);

const getSessionUser = () => {
  const user: any = localStorage.getItem('user');
  const userObj: any = JSON.parse(user);
  const username = userObj.username;
  if (username !== '' && username !== null) {
    return username;
  } else {
    return '';
  }
};

export const AddPlayerNoteDialog = (props: any) => {
  const classes = useStyles();
  const { open, onClose, addPlayerNoteAction, personal } = props;
  const [note, setNote] = useState('');
  const [priority, setPriority] = useState('');
  const [subject, setSubject] = useState('');

  const validate = () => {
    if (priority == '') {
      alert('Please select a priority.');
    } else if (subject == '') {
      alert('Please select a subject.');
    } else if (note == '') {
      alert('Please enter a note.');
    } else {
      addPlayerNoteAction({
        priority: priority,
        player_number: personal.player_number,
        staff: getSessionUser(),
        subject: subject,
        note: note,
      });
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <Typography variant='h6' gutterBottom>
            Add Player Note
          </Typography>
          <Divider sx={{ marginBottom: '1rem' }} />
          <Alert severity='info' sx={{ marginBottom: '1rem' }}>
            To add a note/comment on this player please fill out the form below.
          </Alert>

          <FormControl sx={{ minWidth: 80, width: '100%' }}>
            <InputLabel id='demo-simple-select-autowidth-label'>Priority</InputLabel>
            <Select
              labelId='demo-simple-select-autowidth-label'
              id='demo-simple-select-autowidth'
              value={priority}
              onChange={(event: any) => {
                setPriority(event.target.value);
              }}
              autoWidth
              label='Priority'
            >
              <MenuItem value=''>
                <em>Select a Priority</em>
              </MenuItem>
              <MenuItem value={5}>
                <img src={`${process.env.PUBLIC_URL}/images/5.png`} /> Critical
              </MenuItem>
              <MenuItem value={4}>
                <img src={`${process.env.PUBLIC_URL}/images/4.png`} /> Major
              </MenuItem>
              <MenuItem value={3}>
                <img src={`${process.env.PUBLIC_URL}/images/3.png`} /> Medium
              </MenuItem>
              <MenuItem value={2}>
                <img src={`${process.env.PUBLIC_URL}/images/2.png`} /> Low
              </MenuItem>
              <MenuItem value={1}>
                <img src={`${process.env.PUBLIC_URL}/images/1.png`} /> Minor
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            sx={{
              minWidth: 80,
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <InputLabel id='demo-simple-select-autowidth-label'>Subject</InputLabel>
            <Select
              labelId='demo-simple-select-autowidth-label'
              id='demo-simple-select-autowidth'
              value={subject}
              onChange={(event: any) => {
                setSubject(event.target.value);
              }}
              autoWidth
              label='Subject'
            >
              <MenuItem value=''>
                <em>Select a Subject</em>
              </MenuItem>
              <MenuItem value={'Fraud'}>Fraud</MenuItem>
              <MenuItem value={'Wagering'}>Wagering</MenuItem>
              <MenuItem value={'Chargeback'}>Chargeback</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id='note'
            label='Note'
            value={note}
            onChange={e => setNote(e.target.value)}
            className={classes.inputField}
            fullWidth
            rows={5}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              validate();
            }}
          >
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
