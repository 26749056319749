import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { Button, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { AddPlayerNoteDialog } from './dialogs/add-player-note-dialog';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    padding: '20px 20px 40px 20px',
  },
});

export interface ExternalActionProps {
  addPlayerNoteAction: (x: any) => void;
}

export interface ExternalProps {
  notes: any;
  personal: any;
}

export const PlayerNotes = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { notes, personal, addPlayerNoteAction } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getPriorityText = (value: any) => {
    if (value == 5) {
      return (
        <>
          <img src={`${process.env.PUBLIC_URL}/images/5.png`} />
          <br /> <small>Critical</small>
        </>
      );
    }
    if (value == 4) {
      return (
        <>
          <img src={`${process.env.PUBLIC_URL}/images/4.png`} />
          <br /> <small>Major</small>
        </>
      );
    }
    if (value == 3) {
      return (
        <>
          <img src={`${process.env.PUBLIC_URL}/images/3.png`} />
          <br /> <small>Medium</small>
        </>
      );
    }
    if (value == 2) {
      return (
        <>
          <img src={`${process.env.PUBLIC_URL}/images/2.png`} />
          <br /> <small>Low</small>
        </>
      );
    }
    return (
      <>
        <img src={`${process.env.PUBLIC_URL}/images/1.png`} />
        <br /> <small>Minor</small>
      </>
    );
  };

  const [addNoteDialogOpen, setAddNoteDialogOpen] = React.useState(false);
  const closeNoteDialog = () => {
    setAddNoteDialogOpen(false);
  };

  const formatDateTimeToPst = (time: any) => {
    let m = moment(time);
    m = m.utcOffset('-07:00');
    return m.format('MM/DD/YYYY hh:mm:ss');
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant='h6' className={classes.headTitle}>
            Notes
            <br />
            <small>Notes & Important comments for this player.</small>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant='contained'
            color='inherit'
            onClick={() => {
              setAddNoteDialogOpen(true);
            }}
          >
            Add Note
          </Button>
        </Grid>
      </Grid>

      <AddPlayerNoteDialog
        open={addNoteDialogOpen}
        onClose={closeNoteDialog}
        addPlayerNoteAction={addPlayerNoteAction}
        personal={personal}
      />

      <Divider style={{ marginBottom: '30px', marginTop: '15px' }} />
      {notes &&
        notes.map((note: any) => {
          return (
            <Accordion expanded={expanded === 'panel' + note.id} onChange={handleChange('panel' + note.id)} key={note.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'panel' + note.id + 'bh-content'}
                id={'panel' + note.id + 'bh-header'}
              >
                <Typography sx={{ width: '15%', flexShrink: 0, textAlign: 'center' }}>{getPriorityText(note.priority)}</Typography>
                <Typography sx={{ color: 'text.white' }}>
                  {note.subject} - {note.staff}
                  <br />
                  {/* <small>{moment(note.created_at).format('MM/DD/YYYY HH:MM:SS')}</small> */}
                  <small>{formatDateTimeToPst(note.created_at)}</small>
                  {/* <small>{note.created_at}</small> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: '2rem', paddingTop: '.5rem' }}>
                <Typography></Typography>
                <Typography variant='body1' color='text.secondary'>
                  {note.note}
                </Typography>
                <Typography variant='subtitle1' color='text.secondary'>
                  - {note.staff}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Paper>
  );
};
