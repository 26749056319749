import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo, emptyPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { clearSearchedPlayerAction } from './clear-searched-player.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const GET_PLAYER_INFO = `GET_PLAYER_INFO${suffix}`;

export const getPlayerInfoAction = createAction<any>(GET_PLAYER_INFO);

const GET_PLAYER_INFO_SUCCESS = `GET_PLAYER_INFO_SUCCESS${suffix}`;
const getPlayerInfoSuccessAction = createAction<IPlayerInfo[]>(GET_PLAYER_INFO_SUCCESS);

const GET_PLAYER_INFO_ERROR = `GET_PLAYER_INFO_ERROR${suffix}`;
const getPlayerInfoErrorAction = createAction<string>(GET_PLAYER_INFO_ERROR);

function* getPlayerInfoWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_INFO, getPlayerInfoWorker);
}
mergeSaga(getPlayerInfoWatcher);

function* getPlayerInfoWorker(action: any): SagaIterator {
  clearSearchedPlayerAction();
  try {
    const result: IGetAll<IPlayerInfo> = yield call(getPlayerInfoApi, action.payload);
    yield put(getPlayerInfoSuccessAction(result.data));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerInfoErrorAction(e.response.data.message));
  }
}

const getPlayerInfoApi = (string: any): Request => {
  return vipApi(`/cashier/playerInfo/${string}`, 'post', {});
};

const reduceHandlers = {
  [GET_PLAYER_INFO]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_INFO_SUCCESS]: (slice: PlayerInfoSliceType, action: any): PlayerInfoSliceType => {
    let searchedPlayers: any = localStorage.getItem('searchedPlayers');
    if (searchedPlayers == null) {
      localStorage.setItem('searchedPlayers', '[]');
    }
    if (searchedPlayers != null) {
      searchedPlayers = JSON.parse(searchedPlayers);
      if (searchedPlayers.length >= 10) {
        searchedPlayers.shift();
      }
      searchedPlayers.push(action.payload.personal);
      localStorage.setItem('searchedPlayers', JSON.stringify(searchedPlayers));
    }

    return {
      ...slice,
      isLoading: false,
      playerInfo: action.payload,
      rights: action.payload.rights,
    };
  },
  [GET_PLAYER_INFO_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
    playerInfo: emptyPlayerInfo,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
