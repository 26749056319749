import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Divider, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  isLoading: any;
  wagers: any;
}

export const PendingWagersCard = (props: ExternalProps) => {
  const classes = useStyles();
  const { isLoading, wagers } = props;

  console.log(wagers);

  return (
    <div className={clsx(classes.root)}>
      <Typography variant='h6' gutterBottom>
        Pending Wagers
      </Typography>
      <Divider sx={{ marginBottom: '1rem' }} />

      {!isLoading && (
        <>
          {wagers && (
            <>
              {wagers.length == 0
                ? 'No Pending Wagers'
                : wagers.map((wager: any) => {
                    return (
                      <Accordion key={wager.TicketNumber} expanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                          sx={{ bgcolor: 'secondary.main' }}
                        >
                          <Typography>
                            <b>[#{wager.TicketNumber}]</b> {wager.HeaderDesc} | Placed: {wager.PlacedDate} @ {wager.PlacedTime}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Array.isArray(wager.detail) ? (
                            <>
                              {wager.detail.map((game: any) => {
                                return (
                                  <>
                                    <Grid container key={game.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                        Sport
                                      </Grid>
                                      <Grid item xs={12} sm={10}>
                                        {game.IdSport}
                                      </Grid>
                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                        Game Date
                                      </Grid>
                                      <Grid item xs={12} sm={10}>
                                        {game.GameDate} @ {game.GameTime}
                                      </Grid>
                                      <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                        Description
                                      </Grid>
                                      <Grid item xs={12} sm={10} dangerouslySetInnerHTML={{ __html: game.DetailDesc }}></Grid>
                                    </Grid>
                                    <Divider sx={{ marginBottom: '1rem' }} />
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <Grid container key={wager.detail.IdGame} spacing={1} sx={{ marginBottom: '1rem' }}>
                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                  Sport
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                  {wager.detail.IdSport}
                                </Grid>
                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                  Game Date
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                  {wager.detail.GameDate} @ {wager.detail.GameTime}
                                </Grid>
                                <Grid item xs={12} sm={2} sx={{ fontWeight: 'bold' }}>
                                  Description
                                </Grid>
                                <Grid item xs={12} sm={10} dangerouslySetInnerHTML={{ __html: wager.detail.DetailDesc }}></Grid>
                              </Grid>
                              <Divider sx={{ marginBottom: '1rem' }} />
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
            </>
          )}
        </>
      )}
    </div>
  );
};
