import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { IPlayerInfo } from '../../../models/player-info';
import { playerInfoReducer, PlayerInfoSliceType } from '../player-info.reducer';
import { vipApi } from '../../../utils/vip-api';
import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';
import { getPlayerInfoAction } from './get-player-info.action';

const suffix = `/${playerInfoReducer.sliceName}/app`;

const UPDATE_PLAYER = `UPDATE_PLAYER${suffix}`;

export const updatePlayerAction = createAction<any>(UPDATE_PLAYER);

const UPDATE_PLAYER_SUCCESS = `UPDATE_PLAYER_SUCCESS${suffix}`;
const updatePlayerSuccessAction = createAction<IPlayerInfo[]>(UPDATE_PLAYER_SUCCESS);

const UPDATE_PLAYER_ERROR = `UPDATE_PLAYER_ERROR${suffix}`;
const updatePlayerErrorAction = createAction<string>(UPDATE_PLAYER_ERROR);

function* updatePlayerWatcher(): SagaIterator {
  yield takeEvery(UPDATE_PLAYER, updatePlayerWorker);
}
mergeSaga(updatePlayerWatcher);

function* updatePlayerWorker(action: any): SagaIterator {
  try {
    const result: IGetAll<IPlayerInfo> = yield call(updatePlayerApi, action.payload);
    yield put(updatePlayerSuccessAction(result.data));
    yield call(showToast, result.message, 'success');
    yield put(getPlayerInfoAction(action.payload.userId));
  } catch (e: any) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(updatePlayerErrorAction(e.response.data.message));
  }
}

const updatePlayerApi = (x: any): Request => {
  return vipApi(`/cashier/updatePlayer`, 'post', x);
};

const reduceHandlers = {
  [UPDATE_PLAYER]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_PLAYER_SUCCESS]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [UPDATE_PLAYER_ERROR]: (slice: PlayerInfoSliceType): PlayerInfoSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

playerInfoReducer.addActionReducerMap(reduceHandlers);
