import { connect } from 'react-redux';
import { getRuleCategoriesAction } from './../../../api/rule-category/actions/get-rule-categories.action';
import { isLoadingSliceSelector, ruleCategoriesSelector } from './../../../api/rule-category/rule-categories.selector';
import { Rule, ExternalActionProps, ExternalProps } from './rule.component';
import { upsertRuleCategoryAction } from './../../../api/rule-category/actions/upsert-rule-categories.action';
import { deleteRuleCategoryAction } from './../../../api/rule-category/actions/delete-rule-categories.action';
import { getRuleAction } from './../../../api/rule/actions/get-rule.action';
import { deleteRuleAction } from './../../../api/rule/actions/delete-rule.action';
import { upsertRuleAction } from './../../../api/rule/actions/upsert-rule.action';
import { loggedInUserSelector } from './../../../api/user/user.selector';
import { ruleSelector, isLoadingSliceSelector as ruleLoadingSelector } from './../../../api/rule/rule.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state) || ruleLoadingSelector(state),
    ruleCategories: ruleCategoriesSelector(state),
    loggedUser: loggedInUserSelector(state),
    rules: ruleSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getRuleCategoriesAction,
  upsertRuleCategoryAction,
  deleteRuleCategoryAction,
  getRuleAction,
  upsertRuleAction,
  deleteRuleAction,
};

export const RuleContainer = connect(mapStateToProps, mapDispatchToProps)(Rule);
