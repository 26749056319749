import React from 'react';
import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 100000,
      // color: '#fff',
      // opacity: 0.1,
    },
  }),
);

interface LoadingProps {
  loading: boolean;
}

export const Loading = ({ loading }: LoadingProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      {/* <CircularProgress color='inherit' /> */}
      <img src={process.env.PUBLIC_URL + '/images/misc/christmaslights2.gif'} />
    </Backdrop>
  );
};
