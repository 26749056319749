import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles({
  root: {},
});

interface ExternalProps {
  data: any;
  formNewLeagueGameRule: any;
  setFormNewLeagueGameRule: (x: any) => void;
  setOpenModal: (x: boolean) => void;
}

export const RowGames = (props: ExternalProps) => {
  const classes = useStyles();
  const { data, formNewLeagueGameRule, setFormNewLeagueGameRule, setOpenModal } = props;
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    console.log('');
  }, []);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.root}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {data.Description}
        </TableCell>
        <TableCell align='right'></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='right'>Games</TableCell>
                    <TableCell align='right'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.game &&
                    data.game.map((gam: any, i: number) => (
                      <TableRow key={'games' + i + gam.id}>
                        <TableCell component='th' scope='row'>
                          {gam.vtm} - {gam.htm}
                        </TableCell>
                        <TableCell align='right'>
                          <IconButton
                            aria-label='save'
                            size='small'
                            onClick={() => {
                              formNewLeagueGameRule.league = null;
                              formNewLeagueGameRule.game = gam;
                              formNewLeagueGameRule.description = `${gam.vtm} - ${gam.htm}`;
                              formNewLeagueGameRule.rotation = gam.vnum;
                              setFormNewLeagueGameRule({ ...formNewLeagueGameRule, ...formNewLeagueGameRule });
                              setOpenModal(true);
                            }}
                          >
                            <SaveIcon fontSize='small' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
